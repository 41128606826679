import { useEffect, useState } from 'react';

import { Loader } from '../../components';
import cn from '../../lib/classNames';
import { api } from '../../sagas';
import Layout from '../Layout/LayoutDashboard';

import styles from './Stats.module.scss';

// TODO: In progress.

type StatsType = {
	dateFrom: string;
	dateTo: string;
	generated: number;
	paid: number;
};

export default function Stats() {
	const [data, setdata] = useState<StatsType>();
	const [isLoader, setIsLoader] = useState(false);

	const getStatistics = () => {
		setIsLoader(true);
		api
			.getReportFormStatistics()
			.then((response) => {
				if (response.ok) {
					setdata(response.data);
				}
			})
			.catch((error) => {
				console.error(error.message);
			})
			.finally(() => setIsLoader(false));
	};

	const reportGenerated = data?.generated || 0;
	const reportPaid = data?.paid || 0;

	useEffect(() => {
		getStatistics();
	}, []);

	const renderCardDataItem = (
		title: string,
		footer?: string,
		counter: number = 0,
		percent: number = 0,
	) => {
		return (
			<li className={cn(styles['card-data-item'], 'section-wrap-white')}>
				<h6 className={styles['widget-title']}>{title}</h6>
				<div className={styles['values-wrap']}>
					<h3 className={styles.value}>{counter}</h3>
					<p
						className={cn(styles.percent, {
							[styles['percent-minus']]: percent < 0,
							[styles['percent-plus']]: percent > 0,
						})}
					>
						{percent !== 0 && (
							<span className={cn('material-icons', styles['percent-arrow'])}>north</span>
						)}
						{percent}%
					</p>
				</div>
				<div className={styles.footer}>{footer}</div>
			</li>
		);
	};

	return (
		<Layout>
			<div className={styles['stats-container']}>
				<header>
					<h2>Widget Analytics</h2>
					<ul className={styles.filters}>
						<li
							className={cn(
								styles['filter-item'],
								styles['filter-item-calendar'],
								'section-wrap-white',
							)}
						>
							<h5 className={styles['date-range']}>Auto date range</h5>
							<div className={styles['option-wrap']}>
								<span className={cn('material-icons', styles['calendar-icon'])}>
									calendar_month
								</span>
								<span className={styles.option}>Last 30 days</span>
							</div>
						</li>
						{/* // TODO: The page in development */}
						{/* <li className={cn(styles['filter-item'], 'section-wrap-white')}>
							<h5>Reports generated</h5>
							<div className={styles['option-wrap']}>
								<span className={styles['option']}>All</span>
							</div>
						</li>
						<li className={cn(styles['filter-item'], 'section-wrap-white')}>
							<h5>Reports purchased</h5>
							<div className={styles['option-wrap']}>
								<span className={styles['option']}>All</span>
							</div>
						</li> */}
					</ul>
				</header>
				<section>
					{isLoader ? (
						<Loader />
					) : (
						<ul className={styles['cards-data']}>
							{renderCardDataItem('Reports generated', 'vs previous 30 days', reportGenerated, 0)}
							{renderCardDataItem('Reports purchased', 'vs previous 30 days', reportPaid, 0)}
							{/* {renderCardDataItem('Reports generated', data?.generated )} */}
							{/* {renderCardDataItem('Reports purchased', data?.paid)} */}
						</ul>
					)}
				</section>
			</div>
		</Layout>
	);
}
