import Button from '../../components/Buttons/Button';

import styles from './AccountDelete.module.scss';

type AccountDeleteType = {
	onSave: () => void;
	handleAccountDelete: () => void;
};

export default function AccountDelete(props: AccountDeleteType) {
	const { onSave, handleAccountDelete } = props;

	return (
		<div className={styles['account-delete-wrap']}>
			<h2>Are you sure you want to delete your account?</h2>
			<p>You’ll lose all your safe-esteem history, friends connections and all settings</p>
			<div className={styles['btn-wrap']}>
				<Button variant="filled" color="primary" onClick={onSave}>
					Do not delete
				</Button>
				<Button
					className={styles['btn-delete-account']}
					variant="text"
					color="primary"
					onClick={handleAccountDelete}
				>
					Delete anyway
				</Button>
			</div>
		</div>
	);
}
