import profileIcon from '../../../images/Profile.svg';
import { FACEBOOK_LOGIN_STATUS } from '../UserRedux';

export const user = (state) => state.user;
export const userToken = (state) => user(state).token;
export const userGoogleOAuth = (state) => user(state).googleOAuth;
export const userGoogleOAuthIdToken = (state) => userGoogleOAuth(state).idToken;
export const userFacebook = (state) => user(state).facebook;
export const userFacebookLoginStatus = (state) => userFacebook(state).loginStatus;
export const userFacebookAuthResponse = (state) => userFacebook(state).authResponse;
export const userFacebookToken = (state) => userFacebookAuthResponse(state).accessToken;
export const userFacebookIsLogged = (state) =>
	userFacebook(state).loginStatus === FACEBOOK_LOGIN_STATUS.CONNECTED;
export const userFacebookData = (state) => userFacebook(state).data;
export const userFacebookDataLoaded = (state) => userFacebook(state).dataLoaded;
export const userFacebookUserId = (state) => userFacebookData(state).id;
export const userGoogle = (state) => user(state).google;
export const userGoogleToken = (state) => userGoogle(state).token;
export const userProfile = (state) => user(state).profile;
export const userProfileData = (state) => userProfile(state).data;
export const userProfileDataLoaded = (state) => userProfile(state).dataLoaded;
export const userIsLogged = (state) => userProfileData(state).isLogged;
export const userPicture = (state) => {
	// const f = userFacebookData(state);
	const p = userProfileData(state);
	// if (f.picture) return f.picture;
	if (p.seAvatar) return p.seAvatar;
	if (p.fbAvatar) return p.fbAvatar;
	if (p.googleAvatar) return p.googleAvatar;
	return profileIcon;
};
export const userFullName = (state) => {
	// const f = userFacebookData(state);
	const p = userProfileData(state);
	// if (f.firstName) {
	//     let name = f.firstName;
	//     if (f.lastName) name = `${name} ${f.lastName}`;
	//     return name;
	// }
	if (p.seName) return p.seName;
	if (p.fbName) return p.fbName;
	if (p.googleName) return p.googleName;
	if (p.appleName) return p.appleName;
	return '';
};
export const userCompany = (state) => userProfileData(state).company;
