import { MouseEventHandler } from 'react';
import AppleLogin from 'react-apple-login';
import AppleIcon from '@material-ui/icons/Apple';

import cn from '../lib/classNames';

import Config from '../config';

import styles from './ButtonAppleAuth.module.scss';

export default function ButtonAppleAuth() {
	const renderAppleBtn = ({ onClick }: { onClick: MouseEventHandler<HTMLDivElement> }) => {
		return (
			<div onClick={onClick} className={cn(styles['apple-auth-btn'], 'button')}>
				<AppleIcon className={styles['apple-icon']} />
				Continue with Apple
			</div>
		);
	};

	return (
		<AppleLogin
			clientId={Config.appleAuthId}
			redirectURI={Config.appleRedirectURI}
			scope={Config.appleScope}
			responseType="code+id_token"
			responseMode="form_post"
			render={renderAppleBtn}
		/>
	);
}
