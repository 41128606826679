import { ReactComponent as TryFreeToolImg } from '../../../../images/try_free_tool.svg';
import { ReactComponent as GetFullVersionImg } from '../../../../images/unlock_full_version.svg';
import ButtonBuyReport from '../../../components/ButtonBuyReport';

import './AdditionalFeaturesItem.scss';

type extraFeaturesType = {
	[key: string]: JSX.Element;
};

type AdditionalFeaturesItemType = {
	title: string;
	description: JSX.Element | string;
	buttonText: string;
	buttonNumberOld?: string;
	buttonNumber?: string;
	extraClass: string;
	link?: string | null;
	destination?: string;
};

const extraFeatures: extraFeaturesType = {
	'unlock-pro-version-report': <GetFullVersionImg />,
	'try-our-free-tool': <TryFreeToolImg />,
};

function AdditionalFeaturesItem(props: AdditionalFeaturesItemType) {
	const {
		title,
		description,
		buttonText,
		buttonNumberOld = '',
		buttonNumber = '',
		extraClass,
		link,
		destination,
	} = props;

	return (
		<div className={`additional-features-wrapper ${extraClass}`}>
			<div className="additional-features-block additional-features-left">
				<h4 className="additional-features-title">{title}</h4>
				<div className="additional-features-description">{description}</div>
				<div className="button-wrapper">
					<ButtonBuyReport
						link={link}
						buttonText={buttonText}
						buttonNumberOld={buttonNumberOld}
						buttonNumber={buttonNumber}
						destination={destination}
					/>
				</div>
			</div>

			<div className="additional-features-block additional-features-right">
				<div className={`img-wrapper ${extraClass}`}>{extraFeatures[extraClass]}</div>
			</div>
		</div>
	);
}

export default AdditionalFeaturesItem;
