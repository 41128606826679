import { ReactNode } from 'react';

import cn from '../../lib/classNames';

import styles from './LayoutReport.module.scss';

type LayoutReportType = {
	className?: string;
	children: ReactNode;
};

function LayoutReport(props: LayoutReportType) {
	const { className, children } = props;

	return <div className={cn(styles['page-report'], className)}>{children}</div>;
}

export default LayoutReport;
