import { ReactNode } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import cn from '../lib/classNames';

import './AccordionCustom.scss';

type AccordionCustomType = {
	panel: string;
	panelContent: string;
	headerText: string;
	expanded: string;
	setExpanded: (_panel: string) => void;
	children: ReactNode;
};

function AccordionCustom(props: AccordionCustomType) {
	const { headerText, expanded, panel, setExpanded, panelContent, children } = props;

	return (
		<div className={cn('accordion-custom', { 'accordion-custom-active': expanded === panel })}>
			<Accordion
				className="accordion-container"
				expanded={expanded === panel}
				onChange={(event, isExpanded) => {
					setExpanded(isExpanded ? panel : '');
				}}
			>
				<AccordionSummary
					className="accordion-header"
					aria-controls={panelContent}
					id={panel}
					classes={{ content: 'accordion-summary-content-wrap' }}
				>
					<div className="accordion-header-content">
						<h4 className="overflow-row-1">{headerText}</h4>
						<span className="opened-closed-icon opened-icon material-icons-outlined">
							add_circle_outline
						</span>
						<span className="opened-closed-icon closed-icon material-icons-outlined">
							do_disturb_on
						</span>
					</div>
				</AccordionSummary>
				<AccordionDetails className="accordion-details">{children}</AccordionDetails>
			</Accordion>
		</div>
	);
}

export default AccordionCustom;
