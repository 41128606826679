export const classRiskColor = (isConflict, isNoData, riskValue) => {
	let result;

	if (isConflict) {
		result = 'graph-color-conflict';
	} else if (isNoData) {
		result = 'heatmap-00';
	} else {
		result = riskValue < 10 ? `heatmap-0${riskValue}` : `heatmap-${riskValue}`;
	}
	return result;
};
