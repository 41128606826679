import { useContext, useEffect, useState } from 'react';

import { ApiReportHistoryListType } from '../../../api/types/apiGetReportHistoryList';
import { Context, Loader } from '../../../components';
import { formaterDate } from '../../../lib';
import { api } from '../../../sagas';

import ReportHistoryGroup from './ReportHistoryGroup';
import ReportHistoryItem from './ReportHistoryItem';

import styles from './ReportHistoryDashboard.module.scss';

export type reportType = 'free' | 'purchased' | 'all';

const LIMIT_REPORT = 3;

export default function ReportHistoryDashboard() {
	const [historyData, setHistoryData] = useState<ApiReportHistoryListType[]>([]);
	const [isLoading, setLoading] = useState(true);

	const { dailyViewedLimitedList, setDailyViewedLimitedList, userProfileData } =
		useContext(Context);

	const isLimitedUser = userProfileData.company === null || userProfileData.company?.billableReport;

	const BASE_URL = '/home-destination-report/';

	const handleAPIs = async () => {
		setLoading(true);
		try {
			const [result1, result2] = await Promise.all([
				api.getDailyViewedLimitedList(),
				api.getHomeDestinationReportsHistoryList(30),
			]);

			if (result1.ok) setDailyViewedLimitedList(result1.data);
			if (result2.ok) setHistoryData(result2.data);
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		handleAPIs();
	}, []);

	useEffect(() => {
		renderReportHistory('all');
	}, [historyData]);

	const renderReportItem = (reportType: reportType, limit?: number) => {
		let data: ApiReportHistoryListType[] = [];

		if (reportType === 'free') {
			// data = dailyViewedLimitedList;
			data = historyData.filter((e) => !e.paid).filter((e, index) => index < 3);
		} else if (reportType === 'purchased') {
			data = historyData.filter((e) => e.paid);
		} else if (limit && limit > 0) {
			data = historyData.filter((e, index) => index < limit);
		} else {
			data = historyData;
		}

		if (data.length) {
			return data.map((e) => {
				return (
					<ReportHistoryItem
						hashLink={BASE_URL + e.hashLink}
						createdAt={formaterDate(e.createdAt, 'MMMM D, yyyy H:mm a')}
						expiredAt={formaterDate(e.expiredAt, 'MMMM D, yyyy')}
						mbLocName={e.mbLocName}
						paid={e.paid}
						isPro={!isLimitedUser || e.paid}
						key={e.hashLink}
					/>
				);
			});
		}
	};

	const renderReportHistory = (reportType: reportType, limit?: number) => {
		if (isLoading) {
			return <Loader size="small" />;
		}

		if (historyData.length > 0) {
			return renderReportItem(reportType, limit);
		}
	};

	return (
		<div className={styles['report-history']}>
			<ReportHistoryGroup
				title="Your recent searches"
				linkSeeMore
				stub="No recent reports yet"
				list={renderReportHistory('all', LIMIT_REPORT)}
				reportTypeClass="all"
			/>
			{
				// TODO: In progress (No design and logic)
				// isLimitedUser
				// 	?
				// 	<>
				// 		<ReportHistoryGroup title='Your recent searches' stub='No recent reports yet' list={renderReportHistory('free')} reportTypeClass='free' />
				// 		<ReportHistoryGroup title='Purchased reports' stub='No reports purchased yet' list={renderReportHistory('purchased')} reportTypeClass='purchased' />
				// 		{/* {dataPaidAmount > 3 ? <button className={styles['show-all-reports-button']}>All purchased reports</button> : ''} */}
				// 	</>
				// 	:
				// 	<ReportHistoryGroup title='Your recent searches' stub='No recent reports yet' list={renderReportHistory('all')} reportTypeClass='all' />
			}
		</div>
	);
}
