import cn from '../../lib/classNames';

import styles from './InfoCards.module.scss';

type InfoCardsType = {
	classPageContent?: string;
};

export default function InfoCards(props: InfoCardsType) {
	const { classPageContent } = props;

	return (
		<section className={styles['info-cards']}>
			<div className={cn(styles.content, classPageContent)}>
				<div className={cn(styles.col, styles['col-1'])}>
					<div className={styles['text-wrap']}>
						<h3>
							Elevate Your <br /> Safe-Esteem
						</h3>
						<p className={styles.description}>
							Improve your personal risk awareness and decision quality.
						</p>
					</div>
				</div>
				<div className={cn(styles.col, styles['col-2'])}>
					<div className={styles['text-wrap']}>
						<h3>Understand Your Destination</h3>
						<p className={styles.description}>
							See how travel will affect your safety and security, and make smarter choices.
						</p>
					</div>
				</div>
				<div className={cn(styles.col, styles['col-3'])}>
					<div className={styles['text-wrap']}>
						<h3>Reduce Anxiety and Vulnerability</h3>
						<p className={styles.description}>
							Minimize disinformation and bias about risk, which lead to stress, fear and poor
							self-protective behaviors.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
