import cn from '../../lib/classNames';
import AiPlaceholder from '../Report/RiskSafetySummary/AiPlaceholder/AiPlaceholder';

import SectionHeader from './SectionHeader';

import styles from './AIPoweredRiskIntelligenceBrief.module.scss';

type AIPoweredRiskIntelligenceBriefType = {
	classPageContent?: string;
};

export default function AIPoweredRiskIntelligenceBrief(props: AIPoweredRiskIntelligenceBriefType) {
	const { classPageContent } = props;

	return (
		<section className={styles['ai-powered-risk-intelligence-brief']}>
			<div className={cn(styles.content, classPageContent)}>
				<SectionHeader
					className={styles.header}
					title="AI-Powered Risk Intelligence Brief"
					subTitle="A comprehensive analysis and critical information resource for your destination. Includes:"
				/>
				<AiPlaceholder />
			</div>
		</section>
	);
}
