import ContextNavigationProvider from './components/ContextNavigation';
import OrttoProvider from './context/ortto';
import App from './App';
import { Context, ContextProvider } from './components';

function Root() {
	return (
		<ContextProvider value="">
			<OrttoProvider>
				<ContextNavigationProvider>
					<Context.Consumer>{(context) => <App context={context} />}</Context.Consumer>
				</ContextNavigationProvider>
			</OrttoProvider>
		</ContextProvider>
	);
}

export default Root;
