import React from 'react';
import * as d3 from 'd3';

import { Color } from '../../../theme';

const axisColor = '#ff8080';
const gridColor = '#e9ebec';
const gridLabelColor = '#7D8C97';

export const createSvg = (props) => {
	const { svg } = props;
	const keys = Object.keys(props).filter((el) => el !== 'svg');
	const attrs = [];
	keys.forEach((el) => {
		attrs.push(`${el}="${props[el]}"`);
	});
	if (!keys.includes('fill')) attrs.push('fill="none"');
	const result = `<svg ${attrs.join(
		' ',
	)} preserveAspectRatio="slice" xmlns="http://www.w3.org/2000/svg">${svg}</svg>`;
	return result;
};

export const createElement = (tag) => document.createElementNS('http://www.w3.org/2000/svg', tag);

export const appendHorizontalAxis = (props) => {
	const { parent, width = 100, height = 12, fill = 'red' } = props;

	const r = height / 2;
	const w = width - r;

	try {
		parent
			.append('path')
			.attr('d', `M0,0 h${w} a${r},${r} 0 0 1 0,${height} h-${w} Z`)
			.attr('fill', fill)
			.attr('stroke', 'none');
	} catch (e) {
		console.log('=== appendHorizontalAxis', e);
	}
};

export const appendVerticalAxis = (props) => {
	const { parent, width = 12, height = 100, fill = 'red' } = props;

	const r = width / 2;
	const h = height - r;

	try {
		parent
			.append('path')
			.attr('d', `M0,${r} a${r},-${r} 0 0 1 ${width},0 v${h} h-${width} Z`)
			.attr('fill', fill)
			.attr('stroke', 'none');
	} catch (e) {
		console.log('=== appendVerticalAxis', e);
	}
};

export const appendLinearGradient = (props) => {
	const {
		defs,
		id,
		x1 = '0%',
		x2 = '100%',
		y1 = '0%',
		y2 = '0%',
		color1 = axisColor,
		color2 = axisColor,
		color1Opacity = '0.15',
		color2Opacity = '0.95',
	} = props;

	try {
		const axisGradient = defs
			.append('linearGradient')
			.attr('id', id)
			.attr('x1', x1)
			.attr('y1', y1)
			.attr('x2', x2)
			.attr('y2', y2)
			.attr('gradientUnits', 'userSpaceOnUse');
		axisGradient
			.append('stop')
			.attr('offset', '0%')
			.style('stop-color', color1)
			.style('stop-opacity', color1Opacity);
		axisGradient
			.append('stop')
			.attr('offset', '100%')
			.style('stop-color', color2)
			.style('stop-opacity', color2Opacity);
	} catch (e) {
		console.log('=== appendLinearGradient', e);
	}
};

export const appendColorLinearGradient = (props) => {
	const {
		defs,
		id,
		x1 = '0%',
		x2 = '100%',
		y1 = '0%',
		y2 = '0%',
		colors = [
			Color.heatmap1,
			Color.heatmap2,
			Color.heatmap3,
			Color.heatmap4,
			Color.heatmap5,
			Color.heatmap6,
			Color.heatmap7,
			Color.heatmap8,
			Color.heatmap9,
			Color.heatmap10,
		],
	} = props;

	try {
		const axisGradient = defs
			.append('linearGradient')
			.attr('id', id)
			.attr('x1', x1)
			.attr('y1', y1)
			.attr('x2', x2)
			.attr('y2', y2)
			.attr('gradientUnits', 'userSpaceOnUse');

		colors.forEach((color, idx) => {
			axisGradient
				.append('stop')
				.attr('offset', `${idx * 10}%`)
				.style('stop-color', color);
		});
	} catch (e) {
		console.log('=== appendLinearGradient', e);
	}
};

export const getLinePoint = (props) => {
	const { radius1 = 12, radius2 = 15 } = props;

	const points = [
		d3
			.arc()
			.innerRadius(0)
			.outerRadius(radius2)
			.startAngle(0)
			.endAngle(2 * Math.PI),
		d3
			.arc()
			.innerRadius(0)
			.outerRadius(radius1)
			.startAngle(0)
			.endAngle(2 * Math.PI),
	];

	return points;
};

export const appendGrid = (props) => {
	const { parent, width, height, xScale, yScale, axisStroke } = props;

	try {
		const line = d3
			.line()
			.x((d) => d.x)
			.y((d) => d.y);
		const stepsX = 320;
		const stepsY = 6;
		const stepXs = [0, 10, 100, 1000];
		const stepY = height / stepsY;

		const addTextStyles = (text) => {
			text.style('text-anchor', 'middle').attr('font-size', '12px').attr('fill', gridLabelColor);
		};
		const addBottomTextStyles = (text) => {
			text.attr('font-size', '12px').attr('fill', gridLabelColor);
		};

		const bottomMargin = 25;
		for (let i = 0; i <= stepXs.length; i++) {
			const stepX = stepXs[i];
			const moveToLeft = 2;
			const x1 = xScale((stepsX - moveToLeft) * i) + axisStroke;
			const y1 = 0;
			const x2 = x1;
			const y2 = height;
			parent
				.append('path')
				.attr(
					'd',
					line([
						{ x: x1, y: y1 },
						{ x: x2, y: y2 + bottomMargin },
					]),
				)
				.attr('stroke', gridColor)
				.attr('stroke-width', '1');
			const text = parent
				.append('text')
				.text(stepX)
				.attr('x', x2 + 1)
				.attr('y', y2 + bottomMargin - 2);
			addBottomTextStyles(text);
		}

		const leftMargin = 25;
		for (let i = stepsY; i > 0; i--) {
			const x1 = 0;
			const y1 = stepY * i;
			const x2 = width;
			const y2 = y1;
			parent
				.append('path')
				.attr(
					'd',
					line([
						{ x: x1 - leftMargin / 2, y: y1 },
						{ x: x2, y: y2 },
					]),
				)
				.attr('stroke', gridColor)
				.attr('stroke-width', '1');
			const text = parent
				.append('text')
				.text(Math.round(yScale(y2)))
				.attr('x', x1 - leftMargin)
				.attr('y', y2 + 4);
			addTextStyles(text);
		}
	} catch (e) {
		console.log('=== appendGrid', e);
	}
};

export const arrowSmall = () =>
	'M6.34315 5.65686L0.686291 5.20981e-06L12 6.19888e-06L6.34315 5.65686Z';

export const homeIconD = () => 'M8,0 L16,6 V16 H11 V11 A8,11 0 0 0 5,11 V16 H0 V6 Z';
export const homeIconPath = (props = { fill: 'red' }) => {
	const el = createElement('path');
	el.setAttribute('fill', props.fill);
	el.setAttribute('d', homeIconD());
	return el;
};

export const homeIconSvg = (props) => {
	const { fill, width = '16' } = props;
	return (
		<svg width={width} viewBox="0 0 16 16">
			<g fill={fill}>
				<path d={homeIconD()} />
			</g>
		</svg>
	);
};

export const attentionIconSvg = (props) => {
	const { fill, width = '20' } = props;
	return (
		<svg width={width} viewBox="0 0 20 20">
			<g fill={fill}>
				<path
					d="M9.13402 3.5C9.51892 2.83333 10.4812 2.83333 10.8661 3.5L18.3716 16.5C18.7565 17.1667 18.2754 18 17.5056 18H2.49448C1.72469 18 1.24356 17.1667 1.62846 16.5L9.13402 3.5Z"
					stroke="white"
					strokeWidth="2"
				/>
				<path d="M10 7.00188L10 12.3352" stroke="white" strokeWidth="2" strokeLinecap="round" />
				<path d="M10 15.3333V15.3267" stroke="white" strokeWidth="2.5" strokeLinecap="round" />
			</g>
		</svg>
	);
};

export const destinationIconD = () => 'M1,9 A7,7 90 1 1 13,9 L7,20 Z';
export const destinationIconPath = (props = { fill: 'red' }) => {
	const el = createElement('path');
	el.setAttribute('fill', props.fill);
	el.setAttribute('d', destinationIconD());
	el.setAttribute('mask', 'url(#mask-destination)');
	return el;
};

export const destinationIconSvg = (props) => {
	const { fill, width = '16' } = props;
	return (
		<svg width={width} viewBox="0 0 16 22">
			<g fill={fill} transform="translate(0, 2)">
				<path d={destinationIconD()} mask="url(#mask-destination)" />
			</g>
		</svg>
	);
};

export const getHomePoint = (props) => {
	const { radius1 = 23, radius2 = 29 } = props;

	const points = [
		d3
			.arc()
			.innerRadius(0)
			.outerRadius(radius2)
			.startAngle(0)
			.endAngle(2 * Math.PI),
		d3
			.arc()
			.innerRadius(0)
			.outerRadius(radius1)
			.startAngle(0)
			.endAngle(2 * Math.PI),
		homeIconPath,
	];

	return points;
};

export const getDestinationPoint = (props) => {
	const { radius1 = 23, radius2 = 29 } = props;

	const points = [
		d3
			.arc()
			.innerRadius(0)
			.outerRadius(radius2)
			.startAngle(0)
			.endAngle(2 * Math.PI),
		d3
			.arc()
			.innerRadius(0)
			.outerRadius(radius1)
			.startAngle(0)
			.endAngle(2 * Math.PI),
		destinationIconPath,
	];

	return points;
};
