import cn from '../../../lib/classNames';

import styles from './RiskScoreDinamics.module.scss';

type RiskScoreDinamicsType = {
	className: 'increase' | 'decrease' | 'no-significant-change';
	value: number;
	label: string;
};

export default function RiskScoreDinamics(props: RiskScoreDinamicsType) {
	const { className, value, label } = props;

	const renderDinamicsIcon = () => {
		if (className === 'no-significant-change') {
			return 'graphic_eq';
		}
		if (className === 'increase') {
			return 'arrow_upward';
		}
		if (className === 'decrease') {
			return 'arrow_downward';
		}
	};

	const renderValue = value > 0 ? `+${value}` : value;

	return (
		<div
			className={cn(
				styles['risk-score-dinamics-container'],
				styles[`risk-score-dinamics-container-${className}`],
			)}
		>
			<span className={cn(styles['icon-dinamics-wrap'], 'material-icons-outlined')}>
				{renderDinamicsIcon()}
			</span>
			<div className={styles.label}>{label}</div>
			<div className={styles.value}>{renderValue}%</div>
		</div>
	);
}
