import { all, takeEvery } from 'redux-saga/effects';

import Api from '../api';
import { UserTypes } from '../store/UserRedux';

import {
	userLogout,
	userSetFacebookData,
	userSetFacebookLoginStatus,
	userSetGoogleOAuthData,
	userSetProfileData,
} from './UserSagas';

export const api = Api.create();

function* watchdog(action) {
	const { status } = action;
	if (status === 401) {
		// yield put(UserTypes.USER_LOGOUT);
		yield true;
	}
}

export default function* root() {
	yield all([
		takeEvery('*', watchdog),
		takeEvery(UserTypes.USER_SET_FACEBOOK_LOGIN_STATUS, userSetFacebookLoginStatus, api),
		takeEvery(UserTypes.USER_SET_FACEBOOK_DATA, userSetFacebookData, api),
		takeEvery(UserTypes.USER_SET_GOOGLE_O_AUTH_DATA, userSetGoogleOAuthData, api),
		takeEvery(UserTypes.USER_LOGOUT, userLogout),
		takeEvery(UserTypes.USER_SET_PROFILE_DATA, userSetProfileData),
	]);
}
