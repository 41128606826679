import { useRef, useState } from 'react';
import { saveAs } from 'file-saver';

import { Download, GraphColor, TooltipWidth220 } from '../../../components';
import RelativeRiskChartV2 from '../../../components/RelativeRiskChartV2';
import cn from '../../../lib/classNames';
import html2canvas from '../../../lib/html2canvas';

import styles from './KeyPersonalRiskDrivers.module.scss';

type KeyPersonalRiskDriversType = {
	label: string;
	typeIcon: string;
	location?: string;
	ratio?: { [key: string]: number };
	showDownloadScreenIcon?: boolean;
};

type RenderGraphColorType = {
	classColor: string;
	title: string;
	value?: number;
};

export default function KeyPersonalRiskDrivers(props: KeyPersonalRiskDriversType) {
	const { label, typeIcon, location, ratio, showDownloadScreenIcon = false } = props;

	const [tipActive, setTipActive] = useState(0);
	const elementToShare = useRef<HTMLDivElement>(null);

	const handleClickShare = () => {
		html2canvas(elementToShare.current).then((canvas) => {
			saveAs(canvas.toDataURL(), 'key-personal-risk-drivers.png');
		});
	};

	const renderTooltip = (toolTipId: number, description: string) => {
		return (
			<TooltipWidth220
				id={`tooltip-${toolTipId}`}
				open={tipActive === toolTipId}
				arrow
				maxWidth={258}
				placement="bottom"
				title={
					<>
						{description}
						<p
							className="link tooltip-link"
							onClick={() => {
								setTipActive(0);
							}}
						>
							CLOSE
						</p>
					</>
				}
			>
				<div
					className="block-info-icon"
					onClick={() => {
						setTipActive(toolTipId);
					}}
				>
					<span className="icon-info icons-animation material-icons">info_outline</span>
				</div>
			</TooltipWidth220>
		);
	};

	const renderGraphColor = ({ classColor, title, value }: RenderGraphColorType) => {
		const valueFormated = value && value >= 2 ? value : '<2';
		return (
			<div className={styles['graph-color-item']}>
				<div className={styles.wrap}>
					<GraphColor className={`graph-color-${classColor}`} />
					<div className={styles['graph-color-data']}>
						<div className={styles['data-title']}>
							<p className={styles['risk-name']}>{title}</p>
							<p className={styles.value}>{valueFormated}%</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div
			ref={elementToShare}
			className={cn(styles['key-personal-risk-drivers'], 'section-wrap-white')}
		>
			<div className={styles['key-personal-risk-drivers-container']}>
				<div className={styles.header}>
					<div className={styles['block-text']}>
						<h4 className={styles.title}>Key Personal Risk Drivers</h4>
						{renderTooltip(
							1,
							'This chart displays the estimated relative distribution of weighted life and limb risks by domain for someone of your age and gender in the selected geographic region.',
						)}
						<span className={styles.label}>{label}</span>
					</div>
					{showDownloadScreenIcon ? (
						<div className="download-icon" onClick={handleClickShare}>
							<Download className="icons-animation" />
						</div>
					) : (
						''
					)}
				</div>
				<div className={styles['block-body']}>
					<div className={styles['block-left']}>
						<div className={styles['relative-risk-chart']}>
							<RelativeRiskChartV2
								data={{
									health: ratio?.health,
									crime: ratio?.crime,
									accidents: ratio?.accidents,
								}}
							/>

							<span className={cn(styles['risk-location-icon'], 'material-icons')}>{typeIcon}</span>
						</div>
						<p className={cn(styles.location, 'overflow-row-1')} title={location}>
							{location}
						</p>
					</div>
					<div className={styles['block-right']}>
						<div className={styles['graph-color']}>
							{renderGraphColor({
								classColor: 'crime',
								title: 'Crime',
								value: ratio?.crime,
							})}
							{renderGraphColor({
								classColor: 'safety',
								title: 'Accidents',
								value: ratio?.accidents,
							})}
							{renderGraphColor({
								classColor: 'health',
								title: 'Health',
								value: ratio?.health,
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
