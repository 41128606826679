import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { TooltipWidth220 } from '../../../components';

import { ScoresDataType } from './types';

import styles from './LinearIndicator.module.scss';

type LinearIndicatorType = {
	riscScore: ScoresDataType;
	emptyColor?: string;
	tipIndex: number;
	tipActive: number;
	setTipActive: (idx: number) => void;
};

export default function LinearIndicator(props: LinearIndicatorType) {
	const { riscScore, emptyColor = '#DEE2E5', tipIndex, tipActive, setTipActive } = props;

	const { name = '', value: originalValue = 0, color = '#DEE2E5', info = '' } = riscScore;
	const maxValue = 100;
	const value = Math.round(originalValue);

	const riskData = [{ name, value, empty: maxValue - value }];

	return (
		<div className={styles.container}>
			<div className={styles['risk-name']}>{name}</div>
			<div className={styles['line-box']}>
				<ResponsiveContainer className={styles.graph} height={36}>
					<BarChart data={riskData} layout="vertical">
						<XAxis type="number" hide />
						<YAxis type="category" dataKey="value" hide />
						<Bar dataKey="value" fill={color} stackId="risk" isAnimationActive={false} />
						<Bar dataKey="empty" fill={emptyColor} stackId="risk" isAnimationActive={false} />
					</BarChart>
				</ResponsiveContainer>
				<div className={styles['score-info']}>
					<div className={styles['score-number']}>{value}</div>
					<div className={styles['score-tooltip']}>
						<TooltipWidth220
							id={`tooltip-${tipIndex}`}
							open={tipActive === tipIndex}
							arrow
							maxWidth={258}
							placement="bottom"
							title={
								<>
									{info}
									<p
										className="link tooltip-link"
										onClick={() => {
											setTipActive(0);
										}}
									>
										CLOSE
									</p>
								</>
							}
						>
							<div
								className="block-info-icon"
								onClick={() => {
									setTipActive(tipIndex);
								}}
							>
								<span className="icon-info icons-animation material-icons">info_outline</span>
							</div>
						</TooltipWidth220>
					</div>
				</div>
			</div>
		</div>
	);
}
