import { useCallback, useContext, useEffect, useState } from 'react';
import { ImageType } from 'react-images-uploading';
import { CredentialResponse } from '@react-oauth/google';

import cn from '../../lib/classNames';

import avatarPlaceholder from '../../../images/profile-photo-placeholder.svg';
import { Context } from '../../components';
import { getGoogleUserDataFromCredential } from '../../components/ContextProvider.helper';
import { api } from '../../sagas';

import ProfileButton from './ProfilePreview/ProfileButton';
import ProfileField from './ProfilePreview/ProfileField';
import { UploadFile } from '.';

import styles from './ProfilePreview.module.scss';

type ProfilePreviewType = {
	linkedError: string;
};

enum ExternalServiceLinkType {
	apple = 'apple',
	google = 'google',
}

enum LinkStatusType {
	linked = 'Linked',
	notLinked = 'Not linked',
}

export default function ProfilePreview(props: ProfilePreviewType) {
	const { linkedError } = props;

	const [errorLinkedApple, setErrorLinkedApple] = useState(linkedError);
	const [errorLinkedGoogle, setErrorLinkedGoogle] = useState('');

	const { userProfileData, setUserProfileData, userAvatar } = useContext(Context);

	const { appleId, googleId } = userProfileData ?? {};

	useEffect(() => {
		setErrorLinkedApple(linkedError);
	}, [linkedError]);

	const onSelect = (selectedFile: ImageType) => {
		if (selectedFile.length) {
			const formData = new FormData();
			formData.append('file', selectedFile[0].file);
			api.apiInstance.setHeader('Content-Type', 'multipart/form-data');
			api.uploadAvatar(formData).then((response) => {
				if (response.ok) {
					setUserProfileData({ ...userProfileData, seAvatar: response.data.avatarId });
				}
			});
			api.apiInstance.setHeader('Content-Type', '');
		}
	};

	const handleAddGoogle = useCallback(
		(response: CredentialResponse) => {
			const res = getGoogleUserDataFromCredential(response);

			const data = {
				fbToken: null,
				fbEmail: null,
				fbName: null,
				fbAvatar: null,

				googleToken: res?.idToken,
				googleEmail: res?.email,
				googleAvatar: res?.picture,
				googleName: res?.name,
			};

			api.linkGoogleProfile(data).then((response) => {
				if (!response.ok) {
					setErrorLinkedGoogle(response.data.message);
				}
				setUserProfileData({ ...response.data });
			});
		},
		[setUserProfileData],
	);

	const handleDelete = (btnName: string) => {
		let unlinkAPI;

		if (btnName === ExternalServiceLinkType.apple) {
			unlinkAPI = api.unlinkAppleProfile();
		}

		if (btnName === ExternalServiceLinkType.google) {
			unlinkAPI = api.unlinkGoogleProfile();
		}

		if (unlinkAPI) {
			unlinkAPI.then((response) => {
				if (response.ok) {
					setUserProfileData({ ...response.data });
				}
			});
		}
	};

	const avatarURL = () => {
		// TODO: What about fbAvatar or googleAvatar or profileIcon?
		if (userProfileData.seAvatar) {
			return userAvatar;
		}
		return avatarPlaceholder;
	};

	return (
		<div className={styles['profile-preview-wrap']}>
			<div>
				<h3 className={styles.title}>Account Information</h3>
				<UploadFile maxFileSize={2000000} acceptType={['jpg', 'png', 'jpeg']} onSelect={onSelect}>
					<div className={styles['avatar-wrap']}>
						<div className={styles.avatar} style={{ backgroundImage: `url("${avatarURL()}")` }} />
						<span className={cn(styles['edit-icon'], 'material-icons-two-tone')}>edit</span>
					</div>
				</UploadFile>
			</div>
			<div className={styles.body}>
				<ProfileField helpText="My name" value={userProfileData?.seName} />
				<ProfileField helpText="Email" value={userProfileData?.seLogin} />
				<ProfileField
					helpText="Password"
					placeholder={userProfileData?.passwordSet ? '' : 'Create Your Safe-Xplore Password'}
					value={userProfileData?.passwordSet ? '******' : ''}
				/>
				<ProfileButton
					helpText={appleId ? LinkStatusType.linked : LinkStatusType.notLinked}
					errorText={errorLinkedApple || ''}
					value={appleId ? 'Apple' : ''}
					placeholder="Apple"
					btn={{
						flag: true,
						name: ExternalServiceLinkType.apple,
						value: appleId,
						removable: userProfileData.appleRemovable,
						handleDelete: () => handleDelete(ExternalServiceLinkType.apple),
					}}
				/>
				<ProfileButton
					helpText={googleId ? LinkStatusType.linked : LinkStatusType.notLinked}
					errorText={errorLinkedGoogle || ''}
					value={googleId ? 'Google' : ''}
					placeholder="Google"
					btn={{
						flag: true,
						name: ExternalServiceLinkType.google,
						value: googleId,
						removable: userProfileData.googleRemovable,
						onSuccess: handleAddGoogle,
						handleDelete: () => handleDelete(ExternalServiceLinkType.google),
					}}
				/>
			</div>
		</div>
	);
}
