type GetStartedStep0Type = {
	isForgotPassword: boolean;
	renderSignIn: JSX.Element;
	renderForgot: JSX.Element;
};

export default function GetStartedStep0(props: GetStartedStep0Type) {
	const { isForgotPassword, renderSignIn, renderForgot } = props;
	return (
		<div className="get-started-steps-container block-login">
			{!isForgotPassword ? renderSignIn : renderForgot}
		</div>
	);
}
