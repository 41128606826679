import { Logotype } from '../../components';
import cn from '../../lib/classNames';

import MarketsBtn from './MarketsBtn';

import styles from './Footer.module.scss';

type FooterType = {
	classPageContent?: string;
};

export default function Footer(props: FooterType) {
	const { classPageContent } = props;

	return (
		<footer className={styles.footer}>
			<div className={cn(styles.content, classPageContent)}>
				<div className={styles['row-1']}>
					<div className={styles['col-1']}>
						<div className={styles.logo}>
							<Logotype color="dark" />
						</div>
						<MarketsBtn classBtn={styles['market-btns']} />
					</div>
					<div className={styles['col-2']}>
						<div className={styles.title}>FOLLOW US ON</div>
						<div className={styles['links-wrap']}>
							<a className={styles.link} href="https://twitter.com/Safe_Esteem">
								Twitter
							</a>
							<a className={styles.link} href="https://www.facebook.com/Safe.esteem/">
								Facebook
							</a>
							<a className={styles.link} href="https://www.linkedin.com/company/safe-esteem/">
								LinkedIn
							</a>
						</div>
					</div>
					<div className={styles['col-3']}>
						<div className="title">LINKS</div>
						<div className={styles['links-wrap']}>
							<a className={styles.link} href="https://www.safe-esteem.com/about">
								About Us
							</a>
							<a className={styles.link} href="https://www.safe-esteem.com/terms-of-use">
								Terms & Conditions
							</a>
							<a className={styles.link} href="https://www.safe-esteem.com/privacy">
								Privacy Policy
							</a>
						</div>
					</div>
				</div>
				<div className={styles.copy}>© Safe-esteem, Inc. All rights reserved</div>
			</div>
		</footer>
	);
}
