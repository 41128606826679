import { ReactNode } from 'react';
import MUButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { BtnStyleDefault, BtnStyleDefaultSmall, Color, Shadow } from '../theme';

type ButtonWhiteType = {
	children: ReactNode;
	withShadow?: boolean;
	upperCase?: boolean;
	small?: boolean;
	onClick: () => void;
};

type StylesType = {
	withShadow: boolean;
	upperCase: boolean;
	small: boolean;
};

const useStyles = (props: StylesType) => {
	const { withShadow, upperCase, small } = props;
	const btnStyle = small ? BtnStyleDefaultSmall : BtnStyleDefault;
	const boxWithShadow = withShadow ? Shadow : {};

	return makeStyles({
		root: {
			...btnStyle,
			background: Color.white,
			color: Color.blueDark,
			...boxWithShadow,
			textTransform: upperCase ? 'uppercase' : 'inherit',
		},
	})();
};

export default function ButtonWhite(props: ButtonWhiteType) {
	const { children, withShadow = false, upperCase = true, small = false, ...rest } = props;
	const classes = useStyles({ withShadow, upperCase, small });
	return (
		<MUButton variant="contained" classes={classes} {...rest}>
			{children}
		</MUButton>
	);
}
