import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import alertsIconDark from '../../../images/alerts_icon_dark.svg';
import { ButtonClose, Context } from '../../components';
import { debounce, EVENT, EventManager } from '../../lib';
import { api } from '../../sagas';
import { Color } from '../../theme';

import { NotificationStoryItem } from '.';

const useStyles = makeStyles((theme) => ({
	modal: {
		backgroundColor: 'rgba(35, 53, 68, .9)',
		overflow: 'auto',
		zIndex: '1501 !important',

		'@media only screen and (max-width: 640px)': {
			'&': {
				display: 'block',
			},
		},
	},
	paper: {
		maxWidth: 370,
		width: '100%',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		background: Color.white,
		boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
		overflow: 'auto',

		'& .notifications-story-header': {
			display: 'flex',
			padding: '4rem 3rem 2.3rem',
			borderBottom: `1px solid ${Color.grayLight}`,
		},

		'& .header-alerts': {
			marginRight: 10,
		},

		'& h3': {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%',
			color: Color.blueDark,
		},

		'& .block-btn': {
			display: 'flex',
			alignItems: 'center',
		},

		'& .all-read-btn': {
			margin: '3px 20px 0 0',

			'& .icon-done-all': {
				fontSize: 36,
				color: Color.gray,
				cursor: 'pointer',
				transition: '0.25s',

				'&:hover': {
					color: Color.violet,
					transform: 'scale(1.2)',
				},
			},
		},

		'& .close-btn': {
			fontSize: '2.8rem',
			color: Color.gray,
			cursor: 'pointer',
		},

		'& .block-body': {
			padding: '1.9rem 3rem 1.5rem',
			position: 'relative',

			'& .items-period:first-of-type .items-group-header': {
				marginTop: 0,
			},
		},

		'& .items-group-header': {
			fontSize: '1.3rem',
			color: Color.gray,
			marginTop: 20,
			marginBottom: 12,
		},

		'& .notification-item-delete': {
			display: 'none',
		},

		'@media only screen and (max-width: 640px)': {
			'&': {
				// padding: '2rem',
			},
		},
	},
}));

function NotificationsStory(props) {
	const {
		notificationsStoreOpen,
		notificationsStoreClose,
		// googleToken,
		// personalNotifications,
		// setPersonalNotifications,
		// periods: { todayItems, weekItems, earlierItems },
	} = props;

	const ref = useRef();

	const refContentWrap = useRef();

	const classes = useStyles();
	const [open, setOpen] = useState(notificationsStoreOpen);
	const [notificationFetching, setNotificationfetching] = useState(false);
	const [containerHeight, setContainerHeight] = useState(0);
	const containerHeightRef = useRef();
	containerHeightRef.current = containerHeight;
	const [contentWrapFullHeight, setContentWrapFullHeight] = useState(0);
	const contentWrapFullHeightRef = useRef();
	contentWrapFullHeightRef.current = contentWrapFullHeight;
	const [items, setItems] = useState({});
	const [scroll, setScroll] = useState(0);
	const scrollRef = useRef();
	scrollRef.current = scroll;
	const [totalPages, setTotalPages] = useState(0);
	const totalPagesRef = useRef();
	totalPagesRef.current = totalPages;
	const [pageNum, setPageNum] = useState(0);
	const pageNumRef = useRef();
	pageNumRef.current = pageNum;
	const [notificationsReadOld, setNotificationsReadOld] = useState([]);
	const [notificationDeleteIds, setNotificationDeleteIds] = useState([]);
	const [isUnRead, setIsUnRead] = useState();

	const {
		googleToken,
		personalNotifications,
		setPersonalNotifications,
		notificationsPersonalPeriods,
	} = useContext(Context);

	const { todayItems, weekItems, earlierItems } = notificationsPersonalPeriods;

	const getContentWrap = () => {
		const contentWrap = refContentWrap.current;
		setContentWrapFullHeight(contentWrap.getBoundingClientRect().height);
	};

	const handlerScroolPagination = () => {
		if (
			contentWrapFullHeightRef.current > 0 &&
			totalPagesRef.current > pageNumRef.current &&
			contentWrapFullHeightRef.current - (containerHeightRef.current + scrollRef.current) < 150
		) {
			setPageNum((prev) => prev + 1);
		}
	};

	const handlerScroolPaginationDeb = useMemo(() => {
		return debounce(() => {
			handlerScroolPagination();
		}, 100);
	}, []);

	useEffect(() => {
		handlerScroolPaginationDeb();
	}, [scroll]);

	const loadApiPagination = () => {
		if (!notificationFetching) {
			setNotificationfetching(true);
			api.getPersonalNotifications(googleToken, `&size=10&page=${pageNum}`).then((response) => {
				if (response.ok) {
					setTotalPages(response.data.totalPages);
					const dataContent = response.data.content;
					setPersonalNotifications({
						...response.data,
						...{ content: [...personalNotifications.list, ...response.data.content] },
					});
					setNotificationsReadOld((prev) => [...prev, ...dataContent.filter((e) => e.read)]);
				}
				setNotificationfetching(false);
			});
		}
	};

	useEffect(() => {
		if (pageNum > 0 && pageNum < totalPages) loadApiPagination();
	}, [pageNum]);

	const loadPersonalNotifications = (size = 10, page = 0) => {
		setItems({});

		if (!notificationFetching) {
			setNotificationfetching(true);
			api.getPersonalNotifications(googleToken, `&size=${size}&page=${page}`).then((response) => {
				if (response.ok) {
					setTotalPages(response.data.totalPages);
					const dataContent = response.data.content;
					setPersonalNotifications(response.data);
					setNotificationsReadOld(dataContent.filter((e) => e.read));
				}
				setNotificationfetching(false);
			});
		}
	};

	useEffect(() => {
		if (googleToken) {
			loadPersonalNotifications();
		}
	}, [googleToken]);

	useEffect(() => {
		setOpen(notificationsStoreOpen);
	}, [notificationsStoreOpen]);

	const handleClose = () => {
		setOpen(notificationsStoreOpen);
		handleReadNotification();
		loadPersonalNotifications();
		notificationsStoreClose(false);
		setPageNum(0);
	};

	const notificationReadSize = () => {
		const getNotificationContainer = ref.current;
		ref.current.addEventListener('scroll', () => setScroll(ref.current.scrollTop));
		setContainerHeight(getNotificationContainer.getBoundingClientRect().height);
	};

	const handleRef = (data) => {
		setItems((prev) => ({ ...prev, [data.id]: data.ref }));
	};

	const itemCalc = () => {
		if (
			personalNotifications.list.length &&
			Object.keys(items).length === personalNotifications.list.length
		) {
			const list = items;
			const itemsRead = [];

			personalNotifications.list.forEach((e) => {
				const props = list[e.id].getBoundingClientRect();
				const { height } = props;
				const position = props.y;

				if (position + height - 10 < containerHeight) {
					itemsRead.push({ ...e, read: true });
				} else {
					itemsRead.push({ ...e });
				}
			});

			setPersonalNotifications({
				...personalNotifications,
				...{ content: itemsRead },
			});
		}
	};

	useEffect(() => {
		itemCalc();
	}, [items, scroll]);

	const getNotificationDeleteIds = (id) => {
		setNotificationDeleteIds([...notificationDeleteIds, id]);
	};

	const handleNotificationDelete = () => {
		api.deleteNotifications([...new Set(notificationDeleteIds)], googleToken).then((response) => {
			if (response.ok) {
				loadPersonalNotifications();
				setNotificationDeleteIds([]);
			}
		});
	};

	const handleReadNotification = () => {
		const readNotificationsIds = [];
		personalNotifications.list.forEach((e) => {
			if (e.read && !notificationsReadOld.find((x) => x.id === e.id)) {
				readNotificationsIds.push(e.id);
			}
		});

		api.readNotifications(readNotificationsIds, googleToken).then((response) => {
			if (response.ok) {
				loadPersonalNotifications();
				handleNotificationDelete();
			}
		});
	};

	const handleReadAllNotifications = () => {
		api.readAllNotifications().then((response) => {
			if (response.ok) {
				loadPersonalNotifications();
				setIsUnRead(false);
				console.log('*** handleReadAllNotifications: ', response.data);
			}
		});
	};

	const handleIssUnRead = () => {
		const arrNotifications = personalNotifications.list;

		for (let i = 0; i < arrNotifications.length; i++) {
			const { read } = arrNotifications[i];
			if (!read) {
				setIsUnRead(!read);
				break;
			}
		}
	};

	useEffect(() => {
		handleIssUnRead();
	}, [personalNotifications]);

	const onClickQuestion = (id, questionCode) => {
		EventManager.publish(EVENT.NOTIFICATIONS_QUIZ_QUESTION, { id, questionCode });
	};

	const renderNotificationItem = (arr, title) => {
		return arr.length ? (
			<div className="items-period">
				<div className="items-group-header">{title}</div>
				{arr.map((e) => {
					return (
						<div className="notification-item" dataid={e.id} key={e.id}>
							<NotificationStoryItem
								id={e.id}
								date={e.date}
								title={e.title}
								subtitle=""
								message={e.message}
								notificationClass={e.notificationClass}
								read={e.read}
								url={e.url}
								questionCode={e.questionCode}
								getNotificationDeleteIds={getNotificationDeleteIds}
								onRef={handleRef}
								onClickQuestion={onClickQuestion}
								close={handleClose}
							/>
						</div>
					);
				})}
			</div>
		) : null;
	};

	const handleReload = () => {
		loadPersonalNotifications();
	};

	const handleVisibilityChange = () => {
		if (document.visibilityState === 'visible')
			EventManager.publish(EVENT.NOTIFICATIONS_STORY_RELOAD);
	};

	useEffect(() => {
		const modalWrapClick = (e) => {
			const modalRoot = document.querySelector('.MuiBackdrop-root');
			if (e.target === modalRoot) handleClose();
		};

		window.addEventListener('click', (e) => {
			modalWrapClick(e);
		});

		window.removeEventListener('click', (e) => {
			modalWrapClick(e);
		});

		const events = [];
		events.push(EventManager.subscribe(EVENT.NOTIFICATIONS_STORY_RELOAD, handleReload));

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			events.forEach((el) => {
				EventManager.unsubscribe(el);
			});

			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	return (
		<div className="notifications-story">
			<Modal
				className={classes.modal}
				open={open}
				// onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 300,
				}}
			>
				<Fade in={open}>
					<div
						ref={(instance) => {
							if (instance) {
								ref.current = instance;
								notificationReadSize();
							}
						}}
						className={`${classes.paper} notifications-story-content`}
					>
						<div
							ref={(instance) => {
								if (instance) {
									refContentWrap.current = instance;
									getContentWrap();
								}
							}}
							className="notifications-story-content-wrap"
						>
							<div className="notifications-story-header">
								<img className="header-alerts" src={alertsIconDark} alt="" />
								<h3>
									Notifications
									<div className="block-btn">
										{isUnRead ? (
											<span
												className="all-read-btn"
												title="Mark all read"
												onClick={handleReadAllNotifications}
											>
												<DoneAllIcon className="icon-done-all" />
											</span>
										) : (
											''
										)}

										<span className="close-btn" onClick={handleClose}>
											<ButtonClose />
										</span>
									</div>
								</h3>
							</div>
							<div className="block-body">
								{renderNotificationItem(todayItems, 'Today')}
								{renderNotificationItem(weekItems, 'This Week')}
								{renderNotificationItem(earlierItems, 'Older')}
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}

export default NotificationsStory;
