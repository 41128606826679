import { AboutContent } from './AboutContent';
import Modal from '../../components/Modal';
import styles from './AboutUs.module.scss';

type AboutUsType = {
	isModalOpen: boolean;
	setIsModalOpen: (state: boolean) => void;
};

function AboutUs(props: AboutUsType) {
	const { isModalOpen, setIsModalOpen } = props;

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<Modal
			classNameModalContent={styles['modal-content']}
			isOpen={isModalOpen}
			onClose={closeModal}
		>
			<section className={styles['container']}>
				<div className={styles['content']}>
					<header className={styles.header}>
						<h2>{AboutContent.header.title}</h2>
					</header>
					<div className={styles.body}>{AboutContent.body}</div>
				</div>
			</section>
		</Modal>
	);
}

export default AboutUs;
