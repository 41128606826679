import { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { TravellerInfo } from '../Report/components';
import cn from '../../lib/classNames';
import * as htmlToImage from 'html-to-image';
import { CSSTransition } from 'react-transition-group';

import Header from '../Report/components/Header';

import { ApiReportType } from '../../api/types/apiGetReport';
import { Context, Loader } from '../../components';
import { useOrttoActivityCreateReport } from '../../context/ortto/hooks';
import { api } from '../../sagas';
import TravelRiskReport from '../Report/TravelRiskReport';
import { useContextNavigation } from '../../components/ContextNavigation';
import { PAGES } from '../pagesNavigation';

import styles from './ExternalForm.module.scss';

type ExternalFormParamsType = {
	uuid: string;
};

type QueryParametersType = {
	[key: string]: string | null;
};

export default function ExternalForm() {
	const { uuid } = useParams<ExternalFormParamsType>();

	const [queryParameters, setQueryParameters] = useState<QueryParametersType>({
		username: 'Anonymous',
		personCompany: 'Hearst',
	});
	const [isLoader, setIsLoader] = useState(true);
	const [reportData, setReportData] = useState<ApiReportType>();
	const [isLoaderExportImg, setIsLoaderExportImg] = useState(false);

	const { setFromToDetails } = useContext(Context);
	const { activePage } = useContextNavigation();

	const reportRef = useRef<HTMLDivElement | null>(null);

	const reportDataFromToDetails = reportData?.fromToDetails;
	const aiReport = reportData?.aiReport;

	const track = useOrttoActivityCreateReport();

	const exportToImage = () => {
		if (reportRef.current) {
			const accordions = reportRef.current.querySelectorAll(
				'.accordion-custom:not(.accordion-custom-active)',
			);

			const handleViewAccordions = (view = 'closed') => {
				const method = view === 'opened' ? 'add' : 'remove';
				accordions.forEach((e) => {
					e.classList[method]('accordion-custom-active');
				});
			};

			handleViewAccordions('opened');

			if (!isLoaderExportImg) {
				setIsLoaderExportImg(true);

				setTimeout(() => {
					htmlToImage
						.toJpeg(document.getElementById('root')!, { quality: 0.95 })
						.then(function (dataUrl) {
							const link = document.createElement('a');
							link.download = 'download.jpeg';
							link.href = dataUrl;
							link.click();
							setIsLoaderExportImg(false);
							handleViewAccordions('closed');
						})
						.catch(function (error) {
							console.log('::: ExternalForm >> error: ', error);
							setIsLoaderExportImg(false);
							handleViewAccordions('closed');
						});
				}, 500);
			}
		}
	};

	// TODO: Users Id from data-user-id attribute where came request to create report.
	// 1536 - Hearst id
	// 2050 -SE id

	const getURLParameters = () => {
		const getURL = new URLSearchParams(window.location.search);

		for (const key of getURL.keys()) {
			setQueryParameters((prevState) => ({
				...prevState,
				[key]: getURL.get(key),
			}));
		}
	};

	const getHomeDestinationReportForm = () => {
		if (uuid) {
			api
				.getHomeDestinationReportFormHistory(uuid)
				.then((response) => {
					if (response.ok) {
						setReportData(response.data);
						setFromToDetails(response?.data?.fromToDetails);
						setIsLoader(false);
					} else {
						console.log('::: ExternalForm >> Error: ', response.data.message);
					}
				})
				.catch((err) => console.log('::: ExternalForm >> err: ', err));
		}

		if (queryParameters.id) {
			api
				.getHomeDestinationReportForm(queryParameters)
				.then((response) => {
					if (response.ok) {
						const { reportId } = response.data;
						window.history.replaceState(null, '', `/home-destination-report-form/${reportId}`);
						setReportData(response.data);
						setFromToDetails(response?.data?.fromToDetails);
						setIsLoader(false);
					} else {
						console.log('::: ExternalForm >> getHomeDestinationReportForm >> response: ', response);
					}
				})
				.catch((err) => console.log('::: ExternalForm >> err: ', err));
		}
	};

	useEffect(() => {
		getURLParameters();
	}, []);

	useEffect(() => {
		getHomeDestinationReportForm();
	}, [uuid, queryParameters]);

	useEffect(() => {
		if (!uuid && queryParameters.id && reportData) {
			const locationDestination = reportData?.fromToDetails?.destination;
			track(locationDestination, true);
		}
	}, [uuid, queryParameters, reportData]);

	if (isLoader) {
		return <Loader />;
	}

	return (
		<div
			ref={reportRef}
			id="external-form-container"
			className={cn(styles['external-form-container'])}
		>
			<section className={(cn(styles.section), styles['header-wrap'])}>
				<Header exportToPDF={exportToImage} isLoaderExportImg={isLoaderExportImg} />
			</section>
			<CSSTransition
				in={activePage.id === PAGES.riskScoring.id || !!aiReport || aiReport === null}
				appear
				timeout={0}
				classNames={{
					enterDone: styles['traveller-info-container-done-enter'],
				}}
				unmountOnExit
			>
				<div className={styles['traveller-info-container']}>
					<TravellerInfo
						reportId={activePage.id}
						reportTitle={activePage.reportTitle}
						personName=""
						personAgeBucket={reportDataFromToDetails?.personAgeBucket}
						personGender={reportDataFromToDetails?.personGender}
						date={reportDataFromToDetails?.date}
						home={reportDataFromToDetails?.home}
						destination={reportDataFromToDetails?.destination}
					/>
				</div>
			</CSSTransition>
			{reportData && <TravelRiskReport reportData={reportData} renderHeaderFooter />}
		</div>
	);
}
