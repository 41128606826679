import cn from '../../lib/classNames';

import styles from './SectionHeader.module.scss';

type SectionHeaderType = {
	className?: string;
	headingTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	title: string;
	subTitle?: string;
	textAlign?: 'left' | 'center' | 'right';
};

export default function SectionHeader(props: SectionHeaderType) {
	const { className, headingTag = 'h2', title, subTitle, textAlign = 'left' } = props;

	const HeadingTag = headingTag;

	return (
		<div className={cn(styles['section-header-wrap'], styles[`text-${textAlign}`], className)}>
			<HeadingTag className={headingTag}>{title}</HeadingTag>
			{subTitle ? <p className={cn(styles['sub-title'], 'sub-title')}>{subTitle}</p> : null}
		</div>
	);
}
