import cn from '../../lib/classNames';

import WidgetPersonalAcademyTips from './WidgetPersonalAcademyTips/WidgetPersonalAcademyTips';
import HeaderWidgets from './HeaderWidgets';
import WidgetYourFeedback from './WidgetYourFeedback';

import styles from './SliderBlockForDashboard.module.scss';

type SliderBlockForDashboardType = {
	classNameSection: string;
};

export default function SliderBlockForDashboard(props: SliderBlockForDashboardType) {
	const { classNameSection } = props;

	return (
		<div className={styles['dashboard-personal-block-sliders']}>
			<div
				className={cn(
					styles['widget-carousel-improve-your-se'],
					'section-wrap-white',
					classNameSection,
				)}
			>
				<HeaderWidgets title="Safe-esteem Academy" isDownloadBtn={false} isLogo={false} />
				<WidgetPersonalAcademyTips />
			</div>
			<div
				className={cn(
					styles['widget-carousel-more-from-se'],
					'section-wrap-white',
					classNameSection,
				)}
			>
				<WidgetYourFeedback />
			</div>
		</div>
	);
}
