import { ReactNode } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './WelcomeSlider.module.scss';

type ResponsiveSettings = {
	breakpoint: number;
	settings: {
		slidesToShow: number;
		initialSlide?: number;
		centerPadding?: string;
	};
};

type SliderSettings = {
	className?: string;
	dots: boolean;
	infinite: boolean;
	speed: number;
	centerMode: boolean;
	slidesToShow: number;
	slidesToScroll: number;
	initialSlide: number;
	arrows: boolean;
	responsive: ResponsiveSettings[];
};

export type WelcomeSliderType = {
	children: ReactNode;
	settings: SliderSettings;
};

export default function WelcomeSlider(props: WelcomeSliderType) {
	const { children, settings } = props;

	return (
		<div className={styles['welcome-slider']}>
			<Slider {...settings}>{children}</Slider>
		</div>
	);
}
