import styles from './Disclaimer.module.scss';

export const AccordionData = [
	{
		headerContent: 'Limitations of Information:',
		bodyContent: (
			<ol className={styles.list}>
				<li className={styles.item}>
					<strong>Data Sources and Timeliness:</strong> The travel risk ratings, assessments, and
					advice presented in this Brief are compiled using the best available information from
					reputable government sources. However, users should be aware that this information may not
					be exhaustive and is subject to change. Safe-xplore endeavors to update this information
					regularly, but we cannot guarantee its currentness at all times.
				</li>
				<li className={styles.item}>
					<strong>Personal Circumstances and Behavior:</strong> The effectiveness of our travel risk
					assessments is limited by the generality of the information. The Brief does not, and
					cannot, take into account the specific circumstances, health conditions, or behaviors of
					individual users, which can significantly alter the level of risk encountered.
				</li>
				<li className={styles.item}>
					<strong>User Responsibility:</strong> While Safe-xplore provides this information to aid
					in your travel planning, it is not a substitute for personal judgment and responsibility.
					Users should complement this information with personal research and exercise caution and
					common sense while traveling.
				</li>
				<li className={styles.item}>
					<strong>No Warranty:</strong> The information in the AI-generated Travel Risk Brief is
					provided 'as is' without any warranties, express or implied, regarding its accuracy,
					completeness, or reliability. Safe-xplore, its affiliates, and its employees shall not be
					liable for any loss, injury, or damage caused by reliance on this information.
				</li>
				<li className={styles.item}>
					<strong>Emergency Situations:</strong> In case of emergencies, users should always rely on
					local authorities and real-time local information. The listed emergency contacts and
					recommended resources and facilities are for general guidance and may not be the most
					appropriate in every situation.
				</li>
			</ol>
		),
	},
];
