import LoaderIcon from '../../images/loader_1.svg';
import cn from '../lib/classNames';

import styles from './Loader.module.scss';

type LoaderType = {
	className?: string;
	loaderText?: string;
	bgType?: 'bg-black-09';
	size?: string;
};

function Loader(props: LoaderType) {
	const { className, loaderText, bgType, size } = props;
	//= ========The meaning of props =========
	// bg   -   bg-black-09 (background: rgba(0, 0, 0, 0.9))
	// size -   small ( width: 56px; height: 56px))

	return (
		<div className={cn({ [styles[bgType ?? 'bg-black-09']]: !!bgType }, className)}>
			<div className={styles['loader-container']}>
				<div className={styles['loader-content']}>
					<img className={cn(styles['loader-icon'], size)} src={LoaderIcon} alt="" />
					{loaderText && <p className={styles['loader-text']}>{loaderText}</p>}
				</div>
			</div>
		</div>
	);
}

export default Loader;
