import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
	notificationsSetPersonal: ['data'],
	notificationsLogout: null,
});

export const NotificationsTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable({
	personal: {
		list: [],
		page: 0,
		pages: 0,
		total: 0,
	},
});

const adaptPersonalNotifications = (data) => ({
	list: data?.content || [],
	page: data?.pageable?.pageNumber || 0,
	pages: data?.totalPages || 0,
	total: data?.totalElements || 0,
});
const notificationsSetPersonal = (state, action) => {
	const { data } = action;
	return state.merge(
		{
			personal: adaptPersonalNotifications(data),
		},
		{ deep: true },
	);
};

const notificationsLogout = (state, action) => {
	return INITIAL_STATE;
};

export const reducer = createReducer(INITIAL_STATE, {
	[Types.NOTIFICATIONS_SET_PERSONAL]: notificationsSetPersonal,
	[Types.NOTIFICATIONS_LOGOUT]: notificationsLogout,
});
