import cn from '../../../lib/classNames';

import styles from './RiskHighlightsIndicatorGradient.module.scss';

type RiskHighlightsIndicatorGradientType = {
	ratingValue: number | null;
	ratingDescription: string | null;
};

export default function RiskHighlightsIndicatorGradient(
	props: RiskHighlightsIndicatorGradientType,
) {
	const { ratingValue: ratingValueProp, ratingDescription } = props;

	const ratingValue = ratingValueProp || 0;

	const smiles = [
		'emoji_emotions',
		'sentiment_satisfied',
		'sentiment_neutral',
		'sentiment_dissatisfied',
		'mood_bad',
	];

	const extraRatingDescriptios = [
		'Highly supportive',
		'Supportive',
		'Possible/Moderate',
		'Probable/Common',
		'Very Likely/Severe',
	];

	const complexRatingValue = `${ratingValue}/${smiles.length}`;

	return (
		<div className={styles['risk-highlights-indicator-gradient']}>
			<div
				className={cn(
					styles['gradient-background'],
					styles[`gradient-bg-risk-${ratingValue}` as keyof typeof styles],
				)}
			>
				<div className={styles['gradient-content']}>
					<div className={styles['rating-box']}>
						<div className={styles['rating-description']}>
							<span className={cn(styles['rating-smile'], 'material-icons-outlined')}>
								{ratingValue && smiles[ratingValue - 1]}
							</span>
							<span>
								{ratingDescription || (ratingValue && extraRatingDescriptios[ratingValue - 1])}
							</span>
						</div>
						<div className={styles['rating-value']}>{complexRatingValue}</div>
					</div>
				</div>
			</div>
		</div>
	);
}
