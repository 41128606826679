import { CdcNotices, CdcNoticesValueType } from '../../../../api/types/apiGetReport';

import TravelHealthNoticesLevel from './TravelHealthNoticesLevel';
import { LevelsNumberType } from './travelHealthNoticesTypes';

import styles from './TravelHealthNotices.module.scss';

type TravelHealthNoticesType = {
	cdcNotices: CdcNotices;
};

type LevelsType = {
	level: LevelsNumberType;
	value: CdcNoticesValueType[];
};

export default function TravelHealthNotices(props: TravelHealthNoticesType) {
	const { cdcNotices } = props;

	const levels: LevelsType[] = Object.entries(cdcNotices)
		.map(([key, value]) => ({
			level: parseInt(key.replace('LEVEL_', ''), 10) as LevelsNumberType,
			value,
		}))
		.filter(({ value }) => value.length)
		.sort((a, b) => (a.level < b.level ? 1 : -1));

	const render = levels.map(({ level, value }) => (
		<TravelHealthNoticesLevel levelNum={level} levelValue={value} key={level} />
	));

	return <div className={styles['travel-health-notices']}>{render}</div>;
}
