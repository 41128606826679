import { ReactElement } from 'react';

import cn from '../../lib/classNames';

import styles from './Alert.module.scss';

type AlertType = {
	className?: string;
	type?: 'info' | 'warning' | 'errors' | 'success';
	icon?: string;
	title?: string;
	subTitle?: string;
	text: string;
	button?: ReactElement<HTMLButtonElement>;
};

export default function Alert(props: AlertType) {
	const { className, type = 'info', icon, title, subTitle, text, button } = props;

	return (
		<div className={cn(styles.alert, styles[`type-${type}`], className)}>
			{icon && (
				<div className={cn(styles['icon-wrap'], 'icon-wrap')}>
					<span className={cn(styles.icon, 'icon', 'material-icons-round')}>{icon}</span>
				</div>
			)}
			<div className={cn(styles['text-wrap'], 'text-wrap')}>
				{title && <h3 className={cn(styles.title, 'title')}>{title}</h3>}
				{subTitle && <h5 className={cn(styles['sub-title'], 'sub-title')}>{subTitle}</h5>}
				{text && <p className={cn(styles.text, 'text')}>{text}</p>}
			</div>
			{button && <div className={cn(styles['button-wrap'], 'button-wrap')}>{button}</div>}
		</div>
	);
}
