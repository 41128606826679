import { CompanyType } from '../../api/types/apiGetProfile';
import { LocationDataType } from '../../types/LocationData';
import Button from '../../components/Buttons/Button';
import { ApiReportHistoryListType } from '../../api/types/apiGetReportHistoryList';
import { SelectLocation } from '../../components';

type AdditionInputStepType = {
	locationHome: string;
	place?: LocationDataType;
	handleSelectPlace: (_newPlace: LocationDataType) => void;
	company: CompanyType | null;
	viewedLocations: ApiReportHistoryListType[];
	allowedTotalViewedLocations: number;
	isLogged: boolean;
	handleSubmit: () => void;
	handleGoBack: () => void;
	isBuyNow: boolean;
};

export default function AdditionInputStep(props: AdditionInputStepType) {
	const {
		locationHome,
		place,
		handleSelectPlace,
		company,
		viewedLocations,
		allowedTotalViewedLocations,
		isLogged,
		handleSubmit,
		handleGoBack,
		isBuyNow,
	} = props;

	return (
		<>
			<div className="header">
				<h2>{locationHome}</h2>
				<p>Compare to:</p>
			</div>
			<div className="fields-container-pos-center fields-container">
				<SelectLocation place={place} onSelect={handleSelectPlace} />
				{company === null && !isBuyNow && (
					<div className="report-limit-wrap">
						<h3 className="num-of-total">
							{viewedLocations.length}{' '}
							<span className="color-gray">of {allowedTotalViewedLocations}</span>
						</h3>
						<p className="text">free reports available today</p>
					</div>
				)}
			</div>

			<div className="block-btn change-destination">
				<div className="btn btn-next">
					<Button
						variant="filled"
						color="primary"
						disabled={!place}
						onClick={() => {
							if (place && isLogged) {
								handleSubmit();
							}
						}}
					>
						NEXT
					</Button>
				</div>
				<div className="btn btn-cancel">
					<Button variant="outlined" color="primary" onClick={handleGoBack}>
						CANCEL
					</Button>
				</div>
			</div>
		</>
	);
}
