import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { ScoresDataType } from './types';

import styles from './CircleIndicator.module.scss';

type CircleIndicatorType = {
	scoresData: ScoresDataType[];
	riskColors: {
		accidents: string;
		crime: string;
		empty: string;
		health: string;
	};
};

export default function CircleIndicator(props: CircleIndicatorType) {
	const { scoresData, riskColors } = props;

	const maxValue = 300;
	const totalScore = scoresData.reduce((a, b) => Math.round(a) + Math.round(b.value || 0), 0);
	const scoresDataWithEmpty = [
		...scoresData,
		{ name: 'empty', value: maxValue - totalScore, color: riskColors.empty },
	];

	return (
		<div className={styles.container}>
			<ResponsiveContainer>
				<PieChart>
					<Pie
						isAnimationActive={false}
						dataKey="value"
						startAngle={170}
						endAngle={10}
						data={scoresDataWithEmpty}
						cx="50%"
						cy="100%"
						innerRadius="130%"
						outerRadius="165%"
						stroke="none"
					>
						{scoresDataWithEmpty.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={entry.color} />
						))}
					</Pie>
				</PieChart>
			</ResponsiveContainer>
			<div className={styles['detail-block']}>
				<div className={styles['detail-number']}>{totalScore}</div>
				<div className={styles['detail-description']}>YOUR RISK SCORE</div>
				<div className={styles['detail-level']}>
					<span>Low Risk</span>
					<span>High Risk</span>
				</div>
			</div>
		</div>
	);
}
