import { ChangeEvent, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Slider from '@material-ui/core/Slider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Color } from '../theme';

type SliderStylesType = {
	railHeight: number;
	railBorderRadius: number;
	thumbSize: number;
	thumbPositionY: number;
};

type UseStylesType = {
	inputWidthText: number;
	widthWrap?: string | number;
};

type InputSliderType = SliderStylesType &
	UseStylesType & {
		classNameWrap: string;
		classNameSlider: string;
		label: string;
		name: string;
		defaultValue: number;
		onChange: (_name: string, _value: number) => void;
	};

const useStyles = (props: UseStylesType) => {
	const { inputWidthText, widthWrap } = props;

	return makeStyles({
		root: {
			width: widthWrap,
			marginBottom: 28,
		},
		input: {
			width: inputWidthText,
		},
	})();
};

const sliderStyles = (props: SliderStylesType): Parameters<typeof withStyles>[0] => ({
	root: {
		height: props.railHeight,
		color: Color.violet,
		position: 'absolute',
		top: 10,
	},
	track: {
		height: props.railHeight,
		borderRadius: props.railBorderRadius,
	},
	rail: {
		height: props.railHeight,
		color: Color.gray,
		borderRadius: props.railBorderRadius,
	},
	thumb: {
		width: props.thumbSize,
		height: props.thumbSize,
		marginTop: props.thumbPositionY,
		background: Color.white,
		border: '0.5px solid #DEE2E5',
		boxShadow:
			'0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 1px rgba(0, 0, 0, 0.1)',
	},
});

export default function InputSlider(props: InputSliderType) {
	const {
		classNameWrap,
		classNameSlider,
		label,
		name,
		defaultValue,
		widthWrap = '100%',
		inputWidthText,
		railHeight,
		railBorderRadius,
		thumbSize,
		thumbPositionY,
		onChange,
	} = props;

	const CustomSlider = useMemo(
		() =>
			withStyles(sliderStyles({ railHeight, railBorderRadius, thumbSize, thumbPositionY }))(Slider),
		[],
	);
	const CustomInput = useMemo(() => Input, []);

	const classes = useStyles({ inputWidthText, widthWrap });
	const [value, setValue] = useState(defaultValue);

	const handleSliderChange = (event: ChangeEvent<object>, newValue: number | number[]) => {
		onChange(name, newValue as number);
		setValue(newValue as number);
	};

	const handleInputChange = (event: { target: { value: string; name: string } }) => {
		setValue(Number(event.target.value));
		onChange(event.target.name, Number(event.target.value));
	};

	const handleBlur = () => {
		if (value < 0) {
			setValue(0);
		} else if (value > 100) {
			setValue(100);
		}
	};

	return (
		<div className={classes.root}>
			<div className="input-slider-wrap">
				<Typography id="input-slider" className="input-slider-label">
					{label}
				</Typography>
				{/* <Grid container spacing={2} alignItems='center' className='input-slider-container'> */}
				<Grid item className="input-slider-text-wrap">
					<CustomInput
						className={classes.input}
						value={value}
						name={name}
						margin="dense"
						onChange={handleInputChange}
						onBlur={handleBlur}
						inputProps={{
							step: 1,
							min: 0,
							max: 100,
							type: 'number',
							'aria-labelledby': 'input-slider',
						}}
					/>
				</Grid>
				<Grid item xs className={classNameWrap}>
					<CustomSlider
						className={classNameSlider}
						name={name}
						value={typeof value === 'number' ? value : 0}
						onChange={handleSliderChange}
						aria-labelledby="input-slider"
					/>
				</Grid>
				{/* </Grid> */}
			</div>
		</div>
	);
}
