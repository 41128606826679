import { useOrttoActivityPurchaseReport } from '../../context/ortto/hooks';

import styles from './ButtonToDestinationInput.module.scss';

type ButtonToDestinationInputType = {
	buttonText: string;
	buttonNumberOld: string;
	buttonNumber: string;
	onClickBuyNow: () => void;
	destination?: string;
};

export default function ButtonToDestinationInput(props: ButtonToDestinationInputType) {
	const { buttonText, buttonNumberOld, buttonNumber, onClickBuyNow, destination } = props;

	const track = useOrttoActivityPurchaseReport();

	return (
		<button
			className={styles['button-destination-input']}
			onClick={() => {
				onClickBuyNow();
				track(destination);
			}}
			type="button"
		>
			{buttonText}
			{buttonNumberOld && <span className={styles['price-old']}>{buttonNumberOld}</span>}
			{buttonNumber}
		</button>
	);
}
