import { ReactNode } from 'react';

import './RiskHighlightsIndicatorAccordionItem.scss';

type RiskHighlightsIndicatorAccordionItemType = {
	index: number;
	heading: string;
	extraHeading: string;
	activeIndex: number | null;
	setActiveIndex: (_index: number | null) => void;
	children: ReactNode;
	hasVideo?: boolean;
};

function RiskHighlightsIndicatorAccordionItem(props: RiskHighlightsIndicatorAccordionItemType) {
	const {
		index,
		heading,
		extraHeading,
		activeIndex,
		setActiveIndex,
		children,
		hasVideo = false,
	} = props;

	const isActive = index === activeIndex;
	const contentHasVideo = hasVideo ? 'accordion-content-video' : '';
	const toggleClassName = `accordion-item ${isActive ? 'active-accordion' : ''}`;
	const accordionIcon = isActive ? 'do_disturb_on' : 'add_circle_outline';

	return (
		<li className={toggleClassName}>
			<div className="accordion-title" onClick={() => setActiveIndex(isActive ? null : index)}>
				<h6 className="title-text">
					{heading} {extraHeading && <span className="title-text-extra">{extraHeading}</span>}
				</h6>
				<span className="plus-minus-icon material-icons-outlined">{accordionIcon}</span>
			</div>

			<div className={`accordion-content ${contentHasVideo}`}>{children}</div>
		</li>
	);
}

export default RiskHighlightsIndicatorAccordionItem;
