import { ReactNode } from 'react';

import cn from '../../../lib/classNames';
import { ThemeType } from '../../../types/theme';

import RiskRatingChart from './RiskRatingChart';

import styles from './Content.module.scss';

type ContentType = {
	value?: number;
	color: string;
	valueImage: ReactNode;
	locationName?: string;
	extraInfo?: string;
	className?: string;
	theme: ThemeType;
};

export default function Content(props: ContentType) {
	const { value, color, valueImage, locationName, extraInfo, className, theme } = props;

	return (
		<div className={cn(styles.content, className)}>
			<RiskRatingChart theme={theme} value={value} color={color} />
			<div className={styles['block-text-score']}>{valueImage}</div>
			<div className={styles['location-extra-wrap']}>
				<div className={cn(styles['block-text-location'], 'overflow-row-1')} title={locationName}>
					{locationName}
				</div>
				{extraInfo && (
					<div className={cn(styles['block-extra-info'], 'overflow-row-1')}>{extraInfo}</div>
				)}
			</div>
			<div className={styles['block-text-state']}>
				<span>Low Risk</span>
				<span>High Risk</span>
			</div>
		</div>
	);
}
