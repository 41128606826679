import { ReactComponent as BluredLockIcon } from '../../../../images/bluredRisks/lock.svg';
import ButtonByReport from '../../../components/ButtonBuyReport';

import './RiskScoresEstimatesBlured.scss';

type RiskScoresEstimatesBluredType = {
	title: string;
	description: string;
	buttonText: string;
	buttonNumber?: string;
	link?: string | null;
	bluredImage: string;
	destination?: string;
};

function RiskScoresEstimatesBlured(props: RiskScoresEstimatesBluredType) {
	const { title, description, buttonText, buttonNumber, link, bluredImage, destination } = props;

	const bgImageStyle = {
		backgroundImage: `url(${bluredImage})`,
	};

	return (
		<div className="risk-scores-container risk-scores-container-blured" style={bgImageStyle}>
			<div className="blured-container">
				<div className="content-wrapper">
					<h4 className="title">{title}</h4>
					<div className="main-content">
						<div className="description">{description}</div>
						<div className="img-wrapper">
							<BluredLockIcon className="lock-icon" />
						</div>
						<ButtonByReport
							buttonText={buttonText}
							link={link}
							buttonNumber={buttonNumber}
							destination={destination}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RiskScoresEstimatesBlured;
