import styles from './FormHeader.module.scss';

type FormHeaderType = {
	title: string;
	description?: string;
};

export default function FormHeader(props: FormHeaderType) {
	const { title, description } = props;

	return (
		<div className={styles['form-header']}>
			<h2>{title}</h2>
			{description && <p className={styles.description}>{description}</p>}
		</div>
	);
}
