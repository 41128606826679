import { useRef, useState } from 'react';
import { saveAs } from 'file-saver';

import { DistributionsType } from '../../../../api/types/apiGetReport';
import { DataLayer, Download } from '../../../../components';
import TabButton from '../../../../components/TabButton';
import cn from '../../../../lib/classNames';
import html2canvas from '../../../../lib/html2canvas';
import ViolentCrimeRisk from '../../../Dashboard/ViolentCrimeRisk/ViolentCrimeRisk';
import StatisticsDistributionViCRI from '../StatisticsDistributionViCRI/StatisticsDistributionViCRI';
import TooltipVicri from '../TooltipVicri';

import { ReactComponent as DistributionImage } from './images/distribution.svg';
import { ReactComponent as Speedometer } from './images/speedometer.svg';

import styles from './CitiesViCRIBlock.module.scss';

type CitiesViCRIBlockType = {
	dataLayer?: number;
	showDownloadScreenIcon: boolean;
	distributions: DistributionsType;
	isLimitedVersion: boolean;
	homeCode: string;
	destinationCode: string;
	locationHome?: string;
	locationDestination?: string;
	riskValueHome?: number;
	riskValueDestination?: number;
	locationCircumstancesHome?: string[];
	locationCircumstancesDestination?: string[];
	homeCrimeRiskIndexLocations?: string[];
	destinationCrimeRiskIndexLocations?: string[];
};

export default function CitiesViCRIBlock(props: CitiesViCRIBlockType) {
	const {
		dataLayer = -1,
		showDownloadScreenIcon = true,
		distributions,
		isLimitedVersion = false,
		homeCode,
		destinationCode,
		locationHome,
		locationDestination,
		riskValueHome,
		riskValueDestination,
		locationCircumstancesHome,
		locationCircumstancesDestination,
		homeCrimeRiskIndexLocations,
		destinationCrimeRiskIndexLocations,
	} = props;

	const [activeTab, setActiveTab] = useState<'rating' | 'distribution'>('rating');
	const elementToShare = useRef<HTMLDivElement>(null);

	const handleClickShare = () => {
		html2canvas(elementToShare.current).then((canvas) => {
			saveAs(canvas.toDataURL(), 'cities-statistics.png');
		});
	};

	const headerTextOptions = {
		rating: (
			<>
				City-Level Violent Crime Risk Index (ViCRI) <TooltipVicri />
				<p>
					Index Rating and Percentile Rank vs Top 100 Travel Destinations for Homicide, Aggravated
					Assault, Robbery, and Rape.
				</p>
			</>
		),
		distribution: (
			<>
				City-Level Violent Crime Risk Index (ViCRI)
				<p>
					Index Rating and Percentile Rank vs Top 100 Travel Destinations for Homicide, Aggravated
					Assault, Robbery, and Rape.
				</p>
			</>
		),
	};

	const headerText = headerTextOptions[activeTab];

	const existDistributionData =
		distributions && distributions.HR && distributions.HR.distribution.length;

	const showTabButtons = isLimitedVersion || existDistributionData;

	return (
		<div
			className={cn(styles['statistics-container-vicri'], 'section-wrap-white')}
			ref={elementToShare}
		>
			{dataLayer >= 0 && (
				<div className="data-layer-container">
					<DataLayer numData={dataLayer} />
				</div>
			)}
			<div className={styles.header}>
				<h4>{headerText}</h4>
				<div className={styles['block-right']}>
					{showDownloadScreenIcon && (
						<div className="download-icon" onClick={handleClickShare}>
							<Download className="icons-animation" />
						</div>
					)}

					{showTabButtons && (
						<div className={styles.buttons}>
							<TabButton
								text="Rating"
								image={<Speedometer />}
								onClick={() => setActiveTab('rating')}
								isActive={activeTab === 'rating'}
							/>
							<TabButton
								text="Statistics"
								image={<DistributionImage />}
								onClick={() => setActiveTab('distribution')}
								isActive={activeTab === 'distribution'}
							/>
							<div className={styles['tabs-line']} />
						</div>
					)}
				</div>
			</div>

			{activeTab === 'rating' && (
				<ViolentCrimeRisk
					showHeader={false}
					title="The Violent Crime Risk Index for Global Cities"
					subTitle="Your home and destination ranking of homicide, robbery, rape, and aggravated assault risks."
					showTooltipIcon
					showDownloadScreenIcon={false}
					locationHome={locationHome}
					locationDestination={locationDestination}
					riskValueHome={riskValueHome}
					riskValueDestination={riskValueDestination}
					homeCrimeRiskIndexLocations={homeCrimeRiskIndexLocations}
					destinationCrimeRiskIndexLocations={destinationCrimeRiskIndexLocations}
					locationCircumstancesHome={locationCircumstancesHome}
					locationCircumstancesDestination={locationCircumstancesDestination}
					isShowEstimate={false}
				/>
			)}
			{activeTab === 'distribution' && (
				<StatisticsDistributionViCRI
					distributionLevel="city"
					distributions={distributions}
					homeCode={homeCode}
					destinationCode={destinationCode}
					isDemo={isLimitedVersion}
					showLabels
				/>
			)}
		</div>
	);
}
