import cn from '../../lib/classNames';

import img01 from './images/img01.png';
import img02 from './images/img02.png';
import SectionHeader from './SectionHeader';

import styles from './LiveTravel.module.scss';

type LiveTravelType = {
	classPageContent?: string;
};

export default function LiveTravel(props: LiveTravelType) {
	const { classPageContent } = props;

	return (
		<section className={styles['live-travel']}>
			<div className={cn(styles.row, styles['row-1'])}>
				<div className={classPageContent}>
					<SectionHeader
						className={styles['header-wrap']}
						title="Live And Travel With Confidence"
						subTitle="With the power of big data and analytics, along with human and Artificial Intelligence
				(AI) you can now access personalized crime, accidents, and health risk ratings."
					/>
				</div>
			</div>
			<div className={cn(styles.row, styles['row-2'])}>
				<div className={cn(styles.content, classPageContent)}>
					<div className={styles['img-wrap']}>
						<div className={cn(styles.col, styles['col-1'])}>
							<img src={img01} alt="" />
						</div>
						<div className={cn(styles.col, styles['col-2'])}>
							<img src={img02} alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
