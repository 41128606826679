import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import dataCity from '../../images/data_city.svg';
import dataCountry from '../../images/data_country.svg';
import dataRegion from '../../images/data_region.svg';

const LEVEL_DATA = {
	country: {
		img: dataCountry,
		place: 'Country',
	},
	Region: {
		img: dataRegion,
		place: 'State/Region',
	},
	City: {
		img: dataCity,
		place: 'City/County',
	},
};

type DataLayerType = {
	numData?: number;
};

type LevelType = {
	img: string;
	place: string;
};

export default function DataLayer(props: DataLayerType) {
	const { numData } = props;

	const [level, setLevel] = useState<LevelType | null>(null);

	useEffect(() => {
		if (numData === undefined || numData < 0) {
			return;
		}

		if (numData === 0) {
			setLevel(LEVEL_DATA.country);
		} else if (numData === 1) {
			setLevel(LEVEL_DATA.Region);
		} else {
			setLevel(LEVEL_DATA.City);
		}
	}, [numData]);

	if (level === null) return null;

	return (
		<div className="data-layer">
			<img
				data-tooltip-id="data-layer-tooltip"
				className="data-layer-tooltip"
				data-tooltip-content={level.place}
				src={level.img}
				alt=""
			/>
			<Tooltip
				id="data-layer-tooltip"
				className="data-layer-tooltip"
				place="bottom"
				opacity={0.8}
				render={({ content }) => (
					<span style={{ fontSize: '12px' }}>
						Data Accuracy Level: <br /> {content}
					</span>
				)}
			/>
		</div>
	);
}
