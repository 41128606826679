import { combineReducers } from 'redux';

import rootSaga from '../sagas';

import configureStore from './CreateStore';
import { reducer as dashboardReducer } from './DashboardRedux';
import { reducer as networkReducer } from './NetworkRedux';
import { reducer as notificationsReducer } from './NotificationsRedux';
import { reducer as userReducer } from './UserRedux';

export const reducers = combineReducers({
	network: networkReducer,
	dashboard: dashboardReducer,
	user: userReducer,
	notifications: notificationsReducer,
});

export default () => {
	let {
		// eslint-disable-next-line prefer-const
		store,
		sagasManager,
		sagaMiddleware,
	} = configureStore(reducers, rootSaga);

	if (module.hot) {
		module.hot.accept(() => {
			const nextRootReducer = require('.').reducers; // eslint-disable-line global-require, import/no-self-import
			store.replaceReducer(nextRootReducer);

			const newYieldedSagas = require('../sagas').default; // eslint-disable-line global-require
			sagasManager.cancel();
			sagasManager.done.then(() => {
				sagasManager = sagaMiddleware.run(newYieldedSagas);
			});
		});
	}

	return store;
};
