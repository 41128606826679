import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styles from './Modal.module.scss';
import cn from '../lib/classNames';

type ModalType = {
	className?: string;
	classNameModalContent?: string;
	isOpen: boolean;
	onClose: () => void;
	children: ReactNode;
};

export default function Modal(props: ModalType) {
	const { className, classNameModalContent, isOpen, onClose, children } = props;

	let portalRoot: HTMLElement | null = document.getElementById('portal');

	useEffect(() => {
		if (!portalRoot) {
			portalRoot = document.createElement('div');
			portalRoot.setAttribute('id', 'portal');
			document.body.appendChild(portalRoot);
		}

		document.body.style.overflow = isOpen ? 'hidden' : 'unset';

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, [isOpen]);

	return isOpen && portalRoot
		? createPortal(
				<div className={cn(styles['modal-overlay'], className)} onClick={onClose}>
					<div
						className={cn(styles['modal-content'], classNameModalContent, 'scrollbar1')}
						onClick={(e) => e.stopPropagation()}
					>
						<button className={styles['modal-btn-close']} onClick={onClose}>
							<span className={cn(styles['modal-icon-close'], 'material-symbols-outlined')}>
								close
							</span>
						</button>
						{children}
					</div>
				</div>,
				portalRoot,
		  )
		: null;
}
