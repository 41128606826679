import { useContextNavigation } from '../../../components/ContextNavigation';
import cn from '../../../lib/classNames';
import { ReportsType } from '../../pagesNavigation';

import styles from './ReportNavigationMobile.module.scss';

export default function ReportNavigationMobile() {
	const { pageNavigation, activePage, setActivePage } = useContextNavigation();

	const renderPagesNavigation = () => {
		return pageNavigation.map((page: ReportsType) => {
			const { id, icon, supIcon, btnText } = page;

			return (
				<button
					key={id}
					className={styles['nav-item']}
					type="button"
					onClick={() => {
						setActivePage(page);
					}}
				>
					<span
						className={cn(styles['btn-content'], { [styles['nav-active']]: activePage.id === id })}
					>
						{icon && <span className={cn(styles.icon, 'material-symbols-rounded')}>{icon}</span>}
						{btnText && <span className={styles['btn-text']}>{btnText}</span>}
						{supIcon && (
							<span className={cn(styles['sup-icon'], 'material-icons-round')}>{supIcon}</span>
						)}
					</span>
				</button>
			);
		});
	};
	return (
		<nav className={styles['report-navigation-mobile-wrap']}>
			<div className={styles['nav-content']}>{renderPagesNavigation()}</div>
		</nav>
	);
}
