import Loader from './Loader';

type LoadingType = {
	children: JSX.Element;
	isLoading: boolean;
	size?: string;
};

export default function Loading(props: LoadingType) {
	const { children, isLoading, size } = props;

	if (isLoading) {
		return <Loader size={size} />;
	}

	return children;
}
