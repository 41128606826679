import { Link } from 'react-router-dom';

import { ROUTE } from '../../../App';
import cn from '../../../lib/classNames';

import { reportType } from './ReportHistoryDashboard';

import styles from './ReportHistoryGroup.module.scss';

type ReportHistoryGroupType = {
	title: string;
	linkSeeMore: boolean;
	stub: string;
	list: React.ReactNode;
	reportTypeClass: reportType;
};

export default function ReportHistoryGroup(props: ReportHistoryGroupType) {
	const { title, linkSeeMore, stub, list, reportTypeClass } = props;

	return (
		<div className={cn(styles['searched-wrap'], styles[reportTypeClass])}>
			<header className={styles.header}>
				<p className={styles.title}>{title}</p>
			</header>
			{linkSeeMore && (
				<div className={styles['all-history-block']}>
					<div className={styles['description-see-more']}>
						Get back to the reports you generated earlier
					</div>
					<Link className={styles['link-all-history']} to={ROUTE.profile}>
						<span>All history</span>
						<span className={cn(styles['label-icon-arrow'], 'material-icons-round')}>
							arrow_forward
						</span>
					</Link>
				</div>
			)}
			<div className={styles.body}>
				{list ? <ul className={styles.list}>{list}</ul> : <p className={styles.stub}>{stub}</p>}
			</div>
		</div>
	);
}
