import { FormField } from '../../Profile';
import Header from '../Header';
import IndicatorsDots from '../IndicatorsDots';
import { NameValueType } from '../GetStartedSteps';
import Button from '../../../components/Buttons/Button';

type GetStartedStep5Type = {
	step?: number;
	stepsCount: number;
	email: string;
	isEmailError: string | boolean;
	onSaveData: ({ name, value }: NameValueType) => void;
	handleSubmit: () => void;
};

export default function GetStartedStep5(props: GetStartedStep5Type) {
	const { step, stepsCount, email, isEmailError, onSaveData, handleSubmit } = props;
	return (
		<div className="get-started-steps-container">
			<IndicatorsDots currentDot={step} stepsCount={stepsCount} />
			<Header title="What is your email?" description="Please select your email" />
			<div className="fields-container fields-container-pos-center">
				<div className="email-pass-wrap">
					<div className="email">
						<FormField
							placeholder=""
							helpText="Email"
							typeField="text"
							name="email"
							value={email}
							onSaveData={onSaveData}
							isEmailError={isEmailError}
						/>
					</div>
				</div>
			</div>
			<div className="block-btn">
				<Button
					variant={'filled'}
					color={'primary'}
					onClick={() => {
						handleSubmit();
					}}
					disabled={!!(!email || isEmailError)}
				>
					Calculate
				</Button>
			</div>
		</div>
	);
}
