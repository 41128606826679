import cn from '../../../lib/classNames';

import styles from './InfoAction.module.scss';

type InfoActionType = {
	className?: string;
	buyReportLink?: string | null;
};

export default function InfoAction(props: InfoActionType) {
	const { className, buyReportLink } = props;

	return (
		<section className={cn(styles['info-action'], className)}>
			<div className={styles.row}>
				<div className={cn(styles.col, styles['col-1'])}>
					<p>
						Sign in or join Safe-xplore to access personalized risk ratings, safety recommendations,
						and critical information for hundreds of global destinations.
					</p>
				</div>
				{!!buyReportLink && (
					<div className={cn(styles.col, styles['col-2'])}>
						<a
							className={styles['button-buy-report']}
							href={buyReportLink}
							target="_blank"
							rel="noreferrer"
						>
							UNLOCK PRO REPORT
						</a>
					</div>
				)}
			</div>
		</section>
	);
}
