import cn from '../lib/classNames';

import './GraphColor.scss';

type GraphColorType = {
	className?: string;
};

function GraphColor(props: GraphColorType) {
	const { className, ...rest } = props;
	return <div className={cn('graph-color-icon', className)} {...rest} />;
}

export default GraphColor;
