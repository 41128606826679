import { useContextNavigation } from '../../../components/ContextNavigation';
import cn from '../../../lib/classNames';
import { ReportsType } from '../../pagesNavigation';

import styles from './MenuItems.module.scss';

type MenuItemsType = {
	className?: string;
};

export default function MenuItems(props: MenuItemsType) {
	const { className } = props;

	const { pageNavigation, activePage, setActivePage } = useContextNavigation();

	const renderPagesNavigation = () => {
		return pageNavigation.map((page: ReportsType) => {
			const { id, supIcon, btnText } = page;

			return (
				<button
					key={id}
					className={cn(styles['nav-item'], { [styles['nav-item-active']]: activePage.id === id })}
					type="button"
					onClick={() => {
						setActivePage(page);
					}}
				>
					<span className={styles['btn-content']}>
						{btnText}
						{supIcon && (
							<span className={cn(styles['btn-icon'], 'material-icons-round')}>{supIcon}</span>
						)}
					</span>
				</button>
			);
		});
	};

	return <div className={cn(styles['menu-items'], className)}>{renderPagesNavigation()}</div>;
}
