import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { Color } from '../theme';

const tooltipStylesFullWidth = {
	tooltip: {
		backgroundColor: 'rgba(35, 53, 68, 1)',
		color: Color.white,
		fontSize: 11,
		borderRadius: 10,
		padding: 10,
		// maxWidth: 220,
		maxWidth: '100%',
		boxSizing: 'border-box',
		margin: '10px 15px 10px 25px',

		'& .tooltip-btn-default': {
			display: 'flex',
			justifyContent: 'space-between',
		},

		'& .tooltip-link': {
			fontSize: '1.1rem',
			color: Color.cyanLight,
			fontWeight: 400,
			marginTop: 10,
			cursor: 'pointer',

			'& svg': {
				width: '1.5rem',
				height: '1.5rem',
				marginLeft: 0,
			},
		},
	},

	tooltipArrow: {
		backgroundColor: 'rgba(35, 53, 68, 1)',
	},

	arrow: {
		color: 'rgba(35, 53, 68, 1)',
		marginLeft: '-51px !important',

		'&:before': {
			borderRadius: 2,
		},
	},
};

const tooltipStylesWidth220 = (props) => ({
	tooltip: {
		backgroundColor: 'rgba(35, 53, 68, .9)',
		color: Color.white,
		fontSize: 11,
		borderRadius: 3,
		padding: 10,
		maxWidth: props.maxWidth,

		'& .tooltip-btn-default': {
			display: 'flex',
			justifyContent: 'space-between',
		},

		'& .tooltip-link': {
			fontSize: '1.1rem',
			color: Color.cyanLight,
			fontWeight: 400,
			marginTop: 10,
			cursor: 'pointer',

			'& svg': {
				width: '1.5rem',
				height: '1.5rem',
				marginLeft: 0,
			},
		},

		'& .tooltip-link-text': {
			color: Color.cyanLight,

			'&:visited': {
				color: Color.cyanLight,
			},
		},
	},

	popper: {
		zIndex: 10,
		// zIndex: 9999,
	},

	tooltipArrow: {
		backgroundColor: 'rgba(35, 53, 68, .9)',
	},

	arrow: {
		color: 'rgba(35, 53, 68, .9)',

		'&:before': {
			borderRadius: 2,
		},
	},
});

const TooltipFullWidthHtml = withStyles(tooltipStylesFullWidth)(Tooltip);

export function TooltipFullWidth(props) {
	const { children, arrow, placement, title, open, id } = props;

	return (
		<div>
			<TooltipFullWidthHtml
				id={id}
				open={open}
				arrow={arrow}
				placement={placement}
				title={title}
				interactive
			>
				{children}
			</TooltipFullWidthHtml>
		</div>
	);
}

export function TooltipWidth220(props) {
	const { children, arrow, placement, title, open, id, maxWidth = 220 } = props;
	const TooltipWidth220Html = useMemo(
		() => withStyles(tooltipStylesWidth220({ maxWidth }))(Tooltip),
		[],
	);

	return (
		<div>
			<TooltipWidth220Html
				id={id}
				open={open}
				arrow={arrow}
				placement={placement}
				title={title}
				interactive
			>
				{children}
			</TooltipWidth220Html>
		</div>
	);
}

export function TooltipLGBTQI(props) {
	const { children, arrow, placement, title, open, id, maxWidth = 220 } = props;
	const TooltipWidth220Html = useMemo(
		() => withStyles(tooltipStylesWidth220({ maxWidth }))(Tooltip),
		[],
	);

	return (
		<div>
			<TooltipWidth220Html
				id={id}
				open={open}
				arrow={arrow}
				placement={placement}
				title={title}
				PopperProps={{
					disablePortal: true,
				}}
				interactive
			>
				{children}
			</TooltipWidth220Html>
		</div>
	);
}
