import { ChangeEvent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MUSelect from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import styles from './Select.module.scss';

type SelectType = {
	style?: CSSProperties;
	items: {
		value: string;
		label: string;
	}[];
	onSelect: (_value: string) => void;
	placeholder?: string;
	value?: string;
};

const useStyles = (props: { style?: CSSProperties }) => {
	const { style } = props;

	return makeStyles({
		root: {
			width: 240,
			fontSize: 16,
			...style,
		},
		select: {
			'&:focus': {
				background: 'transparent',
			},
		},
	})();
};

const menuItemStyles = makeStyles({
	root: {
		fontSize: 16,
	},
});

export default function Select(props: SelectType) {
	const { style, items, onSelect, placeholder, ...rest } = props;

	const handleChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
		if (onSelect) onSelect(event.target.value as string);
	};

	const classes = useStyles({ style });
	const classesMenuItem = menuItemStyles();

	return (
		<div className={styles['mu-select']}>
			<MUSelect classes={classes} onChange={handleChange} displayEmpty={!!placeholder} {...rest}>
				{placeholder ? (
					<MenuItem classes={classesMenuItem} disabled value="">
						{placeholder}
					</MenuItem>
				) : null}
				{items.map((el, idx) => (
					<MenuItem classes={classesMenuItem} key={idx} value={el.value}>
						{el.label}
					</MenuItem>
				))}
			</MUSelect>
		</div>
	);
}
