import { allReplace } from '../../lib';

const replaceChar = { ' ': '_', '-': '_', '\\...': '' };

export enum ElementType {
	checkbox = 'checkbox',
	text = 'text',
	slider = 'slider',
}

export type FieldsType = {
	label: string;
	name: string;
	type: ElementType;
	text?: string;
	placeholder?: string;
	status?: string;
};

type LinksType = {
	[key: string]: {
		linkUrl: string;
		linkText: string;
	};
};

export type Screen1Type = {
	title: string;
	subTitle: string;
	fields?: FieldsType[];
};

export type Screen2Type = {
	title: string;
	subTitle: string;
	headline?: string;
	subHeadline?: string;
	links?: LinksType;
	note?: string;
};

export type QuestionTextType = {
	[key: string]: {
		screen1: Screen1Type;
		screen2: Screen2Type;
	};
};

export const QuestionText: QuestionTextType = {
	qu1: {
		screen1: {
			title: 'Tell us how you rate your personal risks',
			subTitle: 'What is more likely to cause you serious harm or injury this year?',
		},

		screen2: {
			title: 'Tell us how you rate your personal risks',
			subTitle: 'What is more likely to cause you serious harm or injury this year?',
		},
	},

	qu2: {
		screen1: {
			title: 'What personal risks concern you the most?',
			subTitle: 'Please select your top three',
			fields: [
				{
					label: 'Violent crime',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'COVID-19',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Road or Vehicle Accidents',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'HIV or AIDS',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Other Communicable Diseases',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Extortion or Blackmail',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Sexual Violence',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Heart Disease',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Other health conditions',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Food safety and security',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Water access and safety',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Pollution',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Other...',
					name: '',
					type: ElementType.checkbox,
					text: 'other_value',
				},
				{
					label: 'Other value',
					name: '',
					type: ElementType.text,
					placeholder: 'Please specify',
					status: 'other',
				},
			],
		},

		screen2: {
			title: 'Thanks for your answer!',
			subTitle: 'We really appreciate your help, and your answers were successfully submitted.',
		},
	},

	qu3: {
		screen1: {
			title: 'Where do you get your health & medical information or advice?',
			subTitle: 'Please select your top two sources',
			fields: [
				{
					label: 'Family doctor',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Local clinic or hospital',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Family members and friends',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Community elders',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Internet...',
					name: '',
					type: ElementType.checkbox,
					text: 'internet_value',
				},
				{
					label: 'Internet value',
					name: '',
					type: ElementType.text,
					placeholder: 'Please specify',
					status: 'internet',
				},
				{
					label: 'Other...',
					name: '',
					type: ElementType.checkbox,
					text: 'other_value',
				},
				{
					label: 'Other value',
					name: '',
					type: ElementType.text,
					placeholder: 'Please specify',
					status: 'other',
				},
			],
		},

		screen2: {
			title: 'Thanks for your answer!',
			subTitle: 'We really appreciate your help, and your answers were successfully submitted.',
			headline: 'Did you know?',
			subHeadline:
				'Here are three authoritative and useful sources of health data available to you:',
			links: {
				link1: {
					linkUrl: 'https://www.healthdata.org/ghana',
					linkText: 'IHME - Ghana Country Profile',
				},
				link2: {
					linkUrl: 'https://www.afro.who.int/countries/ghana',
					linkText: 'WHO - Ghana',
				},
				link3: {
					linkUrl: 'https://www.moh.gov.gh/category/news/',
					linkText: 'Ghana Ministry of Health News',
				},
			},
			note: 'Please remember: This app and the information contained in it or on the websites linked from it is for educational purposes only and doe snot guarantee your safety — it is not a substitute for medical advice from a doctor or health care provider. You must never rely on any information obtained using this app for any diagnosis or recommendation for medical treatment.',
		},
	},

	qu4: {
		screen1: {
			title:
				'What risk factors have increased significantly for you and your family in recent years?',
			subTitle: 'Please select only the top two',
			fields: [
				{
					label: 'Severe weather',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Lacking or inadequate infrastructures',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Pollution',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Civil unrest',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Crime',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Corruption',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Police brutality',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Food scarcity',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Anxiety or mental health',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Other...',
					name: '',
					type: ElementType.checkbox,
					text: 'other_value',
				},
				{
					label: 'Other value',
					name: '',
					type: ElementType.text,
					placeholder: 'Please specify',
					status: 'other',
				},
			],
		},

		screen2: {
			title: 'Thanks for your answer!',
			subTitle: 'We really appreciate your help, and your answers were successfully submitted.',
		},
	},

	qu5: {
		screen1: {
			title:
				'How likely are you to report any of the following crimes to the police (if you were the victim)?',
			subTitle:
				'Please enter the percentage with 0% indicating you would never report it, and 100% in case you are completely sure you would report each and every incident.',
			fields: [
				{
					label: 'Robbery',
					name: '',
					type: ElementType.slider,
				},
				{
					label: 'Sexual harassment',
					name: '',
					type: ElementType.slider,
				},
				{
					label: 'Sexual assault or rape',
					name: '',
					type: ElementType.slider,
				},
				{
					label: 'Violent assault',
					name: '',
					type: ElementType.slider,
				},
				{
					label: 'Bribery demand',
					name: '',
					type: ElementType.slider,
				},
				{
					label: 'Burglary',
					name: '',
					type: ElementType.slider,
				},
				{
					label: 'Theft',
					name: '',
					type: ElementType.slider,
				},
			],
		},

		screen2: {
			title: 'Thanks for your answer!',
			subTitle: 'We really appreciate your help, and your answers were successfully submitted.',
		},
	},

	qu6: {
		screen1: {
			title: 'How do you learn of local and national emergencies?',
			subTitle: 'Please select your top two sources',
			fields: [
				{
					label: 'Radio',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Television',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Newspapers',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Social media apps',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Online news websites',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Government websites',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Local community',
					name: '',
					type: ElementType.checkbox,
				},
				{
					label: 'Mobile apps...',
					name: '',
					type: ElementType.checkbox,
					text: 'mobile_apps_value',
				},
				{
					label: 'Mobile apps value',
					name: '',
					type: ElementType.text,
					placeholder: 'Please specify',
					status: 'mobile_apps',
				},
				{
					label: 'Other...',
					name: '',
					type: ElementType.checkbox,
					text: 'other_value',
				},
				{
					label: 'Other value',
					name: '',
					type: ElementType.text,
					placeholder: 'Please specify',
					status: 'other',
				},
			],
		},

		screen2: {
			title: 'Thanks for your answer!',
			subTitle: 'We really appreciate your help, and your answers were successfully submitted.',
			headline: 'Did you know?',
			subHeadline:
				'Here are three authoritative and useful sources of emergency data available to you:',
			links: {
				link1: {
					linkUrl: 'https://www.healthdata.org/ghana',
					linkText: 'IHME - Ghana Country Profile',
				},
				link2: {
					linkUrl: 'https://www.afro.who.int/countries/ghana',
					linkText: 'WHO - Ghana',
				},
				link3: {
					linkUrl: 'https://www.moh.gov.gh/category/news/',
					linkText: 'Ghana Ministry of Health News',
				},
			},
			note: 'Please remember: This app and the information contained in it or on the websites linked from it is for educational purposes only and does not guarantee your safety — it is not a substitute for medical advice from a doctor or health care provider. You must never rely on any information obtained using this app for any diagnosis or recommendation for medical treatment.',
		},
	},

	qu7: {
		screen1: {
			title:
				'Which locations and/or activities cause the highest personal safety concerns for you?',
			subTitle:
				'For example: “my commute to work”, “my workplace”, “when I interact with police”, etc.',
			fields: [
				{
					label: 'answer',
					name: '',
					type: ElementType.text,
					placeholder: 'Please specify',
				},
			],
		},

		screen2: {
			title: 'Thanks for your answer!',
			subTitle: 'We really appreciate your help, and your answers were successfully submitted.',
		},
	},
};

const QuestionTextAdapter = () => {
	Object.keys(QuestionText).map((e) => {
		const el = QuestionText[e];
		el.screen1.fields?.map((x) => {
			if (x.label !== '') {
				x.name = allReplace(x.label, replaceChar).toLowerCase();
			}
		});
	});
};

QuestionTextAdapter();
