import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'get-value';

import { ROUTE } from '../../App';
import { Context, Loader } from '../../components';
import useMediaQuery from '../../hooks/MediaQuery';
import { localStore } from '../../lib';
import cn from '../../lib/classNames';
import { api } from '../../sagas';
import LayoutDashboard from '../Layout/LayoutDashboard';

import TravelTo from './TravelTo/TravelTo';
import WidgetHowSafeXploreWorks from './WidgetHowSafeXploreWorks/WidgetHowSXWorks';
import WidgetPersonalizedRiskScore from './WidgetPersonalizedRiskScore/WidgetPersonalizedRiskScore';
import WidgetVicriRanking from './WidgetVicriRanking/WidgetVicriRanking';
import WidgetYourRecentSearches from './WidgetYourRecentSearches/WidgetYourRecentSearches';
import SliderBlockForDashboard from './SliderBlockForDashboard';

import styles from './DashboardPersonal.module.scss';

export default function DashboardPersonal() {
	const [isLoader, setIsLoader] = useState(false);
	const { googleToken, userProfileData, homeData, setHomeData, firstStepDataExist } =
		useContext(Context);

	const elementToShare = useRef<HTMLDivElement>(null);

	const isDesktop = useMediaQuery('(min-width: 1400px)');

	const userEmail = userProfileData?.email;

	const scores = homeData?.scores;
	const crimeIndexLocation = homeData?.crimeIndexLocation || userProfileData?.name;
	const crimeIndex = homeData?.crimeIndex;
	const locationCircumstancesHome = homeData?.locationCircumstances;
	const riskContributors = homeData?.riskContributors;

	const history = useHistory();

	const loadHomeReport = () => {
		const { ageBucket, gender, id, lat, lng, name } = userProfileData;

		setIsLoader(true);

		api
			.homeReport({ ageBucket, gender, id, lat, lng, name, allRiskFactors: true }, googleToken)
			.then((response) => {
				if (response.ok) {
					const homeRequestId = get(response, 'data.homeRequestId');
					if (homeRequestId) {
						const report = get(response, 'data.report');
						setHomeData({
							homeRequestId,
							...report,
						});
					}
				}
			})
			.catch((err) => console.error('DashboardPersonal. Home report loading error', err))
			.finally(() => setIsLoader(false));
	};

	useEffect(() => {
		if (firstStepDataExist) {
			if (!localStore.getFirstAuth()) {
				const currentDate = new Date();
				localStore.setFirstAuth(userEmail, currentDate);
			}
			loadHomeReport();
		} else {
			history.push(ROUTE.getStarted);
		}
	}, [firstStepDataExist]);

	if (!homeData?.homeRequestId || isLoader) {
		return (
			<div className="main-container">
				<div className="content">
					<Loader />
				</div>
			</div>
		);
	}

	return (
		<LayoutDashboard>
			<div ref={elementToShare} className={styles['page-dashboard-personal']}>
				<TravelTo />
				<div className={styles['dashboard-first-page']}>
					<section
						className={cn(
							styles['dashboard-personal-column'],
							styles['dashboard-personal-column-left'],
						)}
					>
						<div className={styles.widget}>
							<WidgetYourRecentSearches />
						</div>
						<div className={cn(styles.widget, styles['widget-full-height'])}>
							<WidgetHowSafeXploreWorks />
						</div>
					</section>

					<section
						className={cn(
							styles['dashboard-personal-column'],
							styles['dashboard-personal-column-right'],
						)}
					>
						<div className={styles['dashboard-personal-block-rankings']}>
							<div
								className={cn(styles.widget, styles['widget-vicri-ranking'], 'section-wrap-white')}
							>
								<WidgetVicriRanking
									header="VICRI Ranking"
									subLine="City violent crime risk class"
									locationCircumstances={locationCircumstancesHome}
									crimeIndexLocation={crimeIndexLocation}
									crimeIndex={crimeIndex}
									theme="theme-light"
								/>
							</div>
							<div
								className={cn(
									styles.widget,
									styles['widget-personalized-risk-score'],
									'section-wrap-white',
								)}
							>
								<WidgetPersonalizedRiskScore scores={scores} riskContributors={riskContributors} />
							</div>
						</div>
						{isDesktop && <SliderBlockForDashboard classNameSection={styles.widget} />}
					</section>
				</div>
				{!isDesktop && <SliderBlockForDashboard classNameSection={styles.widget} />}
			</div>
		</LayoutDashboard>
	);
}
