import './Locations.scss';

type LocationsType = {
	[key: string]: string;
};

function Locations(props: LocationsType) {
	const { locationHome, locationDestination } = props;
	return (
		<div className="locations locations-content">
			<div className="location location-home">
				<span className="location-icon location-icon-home material-icons-round">home</span>
				<h4 className="location-name location-name-home">{locationHome}</h4>
			</div>
			<div className="spacer" />
			<div className="location location-destination">
				<span className="location-icon location-icon-destination material-icons">location_on</span>
				<h4 className="location-name location-name-destination">{locationDestination}</h4>
			</div>
		</div>
	);
}

export default Locations;
