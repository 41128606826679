import { useState } from 'react';

import RiskHighlightsIndicatorAccordionItem from './RiskHighlightsIndicatorAccordionItem';
import RiskHighlightsIndicatorGradient from './RiskHighlightsIndicatorGradient';
import RiskHighlightsIndicatorVideo from './RiskHighlightsIndicatorVideo';

import './RiskHighlightsIndicatorAccordion.scss';

type RiskHighlightsIndicatorAccordionType = {
	guidance: { [key: string]: string } | null;
	keys: string[] | null;
	videoUrl: string | null;
	adviceTitle: string | null;
	adviceTexts: string[] | null;
	hyperlocalTitle: string | null;
	hyperlocalTexts: string[] | null;
	imageUrl: string | null;
};

function RiskHighlightsIndicatorAccordion(props: RiskHighlightsIndicatorAccordionType) {
	const {
		guidance,
		keys,
		videoUrl,
		adviceTitle,
		adviceTexts,
		hyperlocalTitle,
		hyperlocalTexts,
		imageUrl,
	} = props;

	const [activeAccordion, setActiveAccordion] = useState<number | null>(0);

	// Rating Guidance
	const contentForAccordionRating = (
		<div>
			<ul className="content-text-list clear-list-style">
				{keys?.map((el, idx) => {
					return (
						<li className="content-text-item content-text-item-gradient" key={idx}>
							<div className="gradient-wrapper">
								<RiskHighlightsIndicatorGradient
									ratingValue={Number(el)}
									ratingDescription={null}
								/>
							</div>
							<div className="risc-description">{guidance && guidance[el]}</div>
						</li>
					);
				})}
			</ul>
		</div>
	);

	// Tips & advice TEXT
	const contentForAccordionText = (
		<div>
			{!!adviceTitle && (
				<p className="content-text-main" dangerouslySetInnerHTML={{ __html: adviceTitle }} />
			)}
			<ul className="content-text-list">
				{adviceTexts?.map((el, idx) => {
					return (
						<li className="content-text-item" key={idx}>
							{el}
						</li>
					);
				})}
			</ul>
		</div>
	);

	// Tips & advice VIDEO
	const contentForAccordionVideo = (
		<RiskHighlightsIndicatorVideo imageUrl={imageUrl} videoUrl={videoUrl} />
	);

	// Hyperlocal information
	const contentForAccordionHyperlocal = (
		<div>
			<p className="content-text-main">{hyperlocalTitle}</p>
			<ul className="content-text-list">
				{hyperlocalTexts?.map((el, idx) => {
					return (
						<li className="content-text-item" key={idx}>
							{el}
						</li>
					);
				})}
			</ul>
		</div>
	);

	// general accordion data items:
	const accordionDataItems = [
		{
			accordionHeading: 'Rating Guidance',
			accordionHeadingExtra: '',
			accordionContent: contentForAccordionRating,
			hasVideo: false,
		},
		{
			accordionHeading: 'Tips & advice',
			accordionHeadingExtra: 'TEXT',
			accordionContent: contentForAccordionText,
			hasVideo: false,
		},
		{
			accordionHeading: 'Tips & advice',
			accordionHeadingExtra: 'VIDEO',
			accordionContent: contentForAccordionVideo,
			hasVideo: true,
		},
		{
			accordionHeading: 'Hyperlocal information',
			accordionHeadingExtra: '',
			accordionContent: contentForAccordionHyperlocal,
			hasVideo: false,
		},
	];

	return (
		<ul className="accordion">
			{accordionDataItems.map(
				({ accordionHeading, accordionHeadingExtra, accordionContent, hasVideo }, index) => (
					<RiskHighlightsIndicatorAccordionItem
						key={index}
						index={index}
						heading={accordionHeading}
						extraHeading={accordionHeadingExtra}
						activeIndex={activeAccordion}
						setActiveIndex={setActiveAccordion}
						hasVideo={hasVideo}
					>
						{accordionContent}
					</RiskHighlightsIndicatorAccordionItem>
				),
			)}
		</ul>
	);
}

export default RiskHighlightsIndicatorAccordion;
