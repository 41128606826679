import addIcon from '../../../../images/add_add.svg';
import closeIcon from '../../../../images/close_icon.svg';
import { ButtonLinkedApple, ButtonLinkedGoogle } from '../../../components';
import { CredentialResponse } from '@react-oauth/google';

import ProfileField from './ProfileField';

export type HelpTextType = 'Linked' | 'Not linked';

type BtnType = {
	flag: boolean;
	name: string;
	value: string;
	removable: boolean;
	onSuccess?: (credentialResponse: CredentialResponse) => void;
	handleDelete: () => void;
	disabled?: boolean;
};

type ProfileButtonType = {
	helpText: HelpTextType;
	errorText: string;
	value: string;
	placeholder: string;
	btn: BtnType;
};

const renderButton = (btn: BtnType) => {
	const btnAdd = () => {
		const btnAddIcon = () => <img src={addIcon} alt="btn-add" />;

		if (btn?.name === 'apple') {
			return <ButtonLinkedApple renderBtn={btnAddIcon()} />;
		}
		if (btn?.name === 'google') {
			return <ButtonLinkedGoogle onSuccess={btn.onSuccess!} />;
		}
	};

	if (btn.disabled) {
		return (
			<div className="btn btn-add btn-add-disabled">
				<img src={addIcon} alt="btn-add" />
			</div>
		);
	}

	if (!btn.value) {
		return <div className="btn btn-add">{btnAdd()}</div>;
	}

	return (
		<div className="btn btn-delete" onClick={btn.handleDelete}>
			<img src={closeIcon} alt="btn-delete" />
		</div>
	);
};

export default function ProfileButton(props: ProfileButtonType) {
	const { helpText, errorText, value, placeholder, btn } = props;
	return (
		<ProfileField
			helpText={helpText}
			errorText={errorText}
			value={value}
			placeholder={placeholder}
			innerClassName="btn-padding"
		>
			<div className="btn-link-wrap">
				{btn?.flag && (!btn?.value || btn?.removable) ? renderButton(btn) : null}
			</div>
		</ProfileField>
	);
}
