import QuestionScreen1Form from './QuestionScreen1Form';

import { DataType } from './types';

type Question7Type = {
	screen: number;
	data: (data: DataType) => void;
	questionNumber: number;
};

export default function Question7(props: Question7Type) {
	const { questionNumber, screen = 1, data } = props;

	if (screen !== 1) return null;

	return (
		<div className={`question-content question-${questionNumber}-content`}>
			<QuestionScreen1Form
				screen={screen}
				data={data}
				questionNumber={questionNumber}
				inputClassName="input-root"
				inputDisabled={false}
			/>
		</div>
	);
}
