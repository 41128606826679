import { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as AlertsIcon } from '../../../../images/alerts_icon_gray.svg';
import { ROUTE } from '../../../App';
import { Context, Logotype, useOutsideClickEvent } from '../../../components';
import { useContextNavigation } from '../../../components/ContextNavigation';
import useMediaQuery from '../../../hooks/MediaQuery';
import { DEVICES } from '../../../lib/breakpoints';
import { fakeAgeBucket } from '../../../lib/constats';
import { NotificationsStory } from '..';

import MenuItems from './MenuItems';

import './DashboardHeader.scss';

type DashboardHeaderType = {
	ageBucket: string;
	gender: string;
	picture: string;
	userName: string;
};

export default function DashboardHeader(props: DashboardHeaderType) {
	const { ageBucket, gender, picture, userName } = props;

	const [menuMobileOpen, setMenuMobileOpen] = useState(false);
	const [notificationsStoreOpen, setNotificationsStoreOpen] = useState(false);

	const { isReportMenuVisible } = useContextNavigation();
	const { userProfileData, isLogged, notificationsPersonalNewCount } = useContext(Context);

	const ref = useRef(null);

	const isDesktop = useMediaQuery(DEVICES.isDesktop);
	const isMobile = useMediaQuery(DEVICES.isMobile);

	const userCompany = userProfileData?.company !== null && userProfileData?.company;

	const handleClickOutside = () => {
		setTimeout(() => {
			setMenuMobileOpen(false);
		}, 100);
	};

	useOutsideClickEvent(ref, handleClickOutside);

	const renderAlerts = () => {
		return (
			<div className="notifications-alerts nav-link">
				<div className="notifications-alerts-content">
					<span
						className={notificationsPersonalNewCount ? 'new-notifications' : ''}
						onClick={() => setNotificationsStoreOpen(true)}
					>
						<div>
							<AlertsIcon className="bell-animate icons-animation" />
						</div>
					</span>
				</div>
			</div>
		);
	};

	const renderCompanyLogo = () => {
		if (userCompany) {
			const logoUrl = userCompany.config.logo_url;

			if (userCompany.config.logo_url !== '') {
				return <img className="company-logo" src={logoUrl} alt="" />;
			}
		}

		return null;
	};

	const renderUserInfo = () => {
		const seName = userName ? userName.trim() : '';
		const seGender = gender || '';
		const seAgeBucket = ageBucket ? fakeAgeBucket(ageBucket) : '';

		if (isLogged) {
			if (seName) {
				return (
					<div className="user-info">
						<span className="user-name overflow-row-1" title={seName}>
							{seName}
						</span>
						{(seAgeBucket ? ', ' : '') + seAgeBucket}
					</div>
				);
			}
			return <div className="user-info">{seGender + (seAgeBucket ? ', ' : '') + seAgeBucket}</div>;
		}

		return null;
	};

	const renderBtnProfile = () => {
		return (
			<div className="btn btn-profile">
				<Link to={ROUTE.profile}>
					<div className="menu-user-profile">
						<div className="user-photo" style={{ backgroundImage: `url(${picture})` }} />
						{isDesktop && renderUserInfo()}
						{renderCompanyLogo()}
					</div>
				</Link>
			</div>
		);
	};

	return (
		<header className="dashboard-header header header-top">
			<div className="block-logo">
				<Link to={ROUTE.default}>
					<Logotype className="se-logo" color="dark" small={isMobile} />
				</Link>
			</div>
			<div className="nav-menu block-btn">
				{!isMobile && isReportMenuVisible && (
					<div className="menu-desktop">
						<MenuItems />
					</div>
				)}

				<div className="btn-alerts-wrap">{renderAlerts()}</div>

				<div className="btn-profile-wrap">{renderBtnProfile()}</div>

				<NotificationsStory
					notificationsStoreOpen={notificationsStoreOpen}
					notificationsStoreClose={setNotificationsStoreOpen}
				/>
			</div>
		</header>
	);
}
