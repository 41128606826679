import { useEffect, useState } from 'react';

import { ApiPersonalAcademyTipsType } from '../../../api/types/apiGetPersonalAcademyTips';
import LinkBox from '../../../components/Buttons/LinkBox';
import {
	useOrttoActivityClickAcademyTip,
	useOrttoActivityGotAcademyTip,
} from '../../../context/ortto/hooks';
import cn from '../../../lib/classNames';
import { api } from '../../../sagas';

import AcademySlider from './AcademySlider';

import styles from './WidgetPersonalAcademyTips.module.scss';

const SLIDE_PLACEHOLDER = [
	{
		id: 0,
		notificationClass: 'ACADEMY',
		questionCode: null,
		title: 'Improve your personal resilience',
		message:
			'Our safe-esteem describes our ability to understand and make sound decisions about personal risk and safety - and ultimately lead healthy, safe, and confident lives.  Here you will find insights on how to survive and thrive in the modern risk landscape.',
		url: null,
		imageUrl: 'https://public-src.s3.us-east-2.amazonaws.com/academy/academy_placeholder.svg',
		date: '2023-07-14T08:46:16.702',
		ignoredCnt: null,
		read: true,
		obligatory: true,
	},
];

export default function WidgetPersonalAcademyTips() {
	const [slides, setSlides] = useState<ApiPersonalAcademyTipsType[]>(SLIDE_PLACEHOLDER);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [isRead, setIsRead] = useState(false);
	const [imageLoaded, setImageLoaded] = useState(false);

	const trackClickAcademyTip = useOrttoActivityClickAcademyTip();
	const trackGotAcademyTip = useOrttoActivityGotAcademyTip();

	const handleImageLoad = () => {
		setImageLoaded(true);
	};

	const getPersonalAcademyTips = () => {
		api
			.getPersonalAcademyTips()
			.then((response) => {
				if (response.ok) {
					if (response.data.length) setSlides(response.data);
				}
			})
			.catch((err) => {
				console.log('::: WidgetPersonalAcademyTips >> err: ', err);
			});
	};

	useEffect(() => {
		if (slides[currentSlide]?.id === 0) {
			setIsRead(true);
		} else if (isRead !== slides[currentSlide]?.read) setIsRead(slides[currentSlide]?.read);
	}, [currentSlide, slides]);

	useEffect(() => getPersonalAcademyTips(), []);

	const onClickBtnGotIt = () => {
		const { id, title } = slides[currentSlide];

		trackGotAcademyTip(title);

		api
			.personalAcademyTipOnRead(id)
			.then((response) => {
				if (response.ok) {
					const slideId = response.data.id;
					setSlides((prevSlides) =>
						prevSlides.map((slide) => (slide.id === slideId ? { ...slide, read: true } : slide)),
					);
				}
			})
			.catch((err) => {
				console.log('::: WidgetPersonalAcademyTips >> err: ', err);
			});
	};

	const renderSlider = slides.map((e) => {
		return (
			<div className={cn(styles['slide-content'], 'scrollbar2')} key={e.id} data-slide-id={e.id}>
				<div
					className={cn(styles['block-img'], {
						[styles['loader-active']]: !imageLoaded,
					})}
				>
					<div className={cn(styles['loader-container'])}>
						<div className={styles['loading-bar']} />
					</div>
					<img
						onLoad={handleImageLoad}
						className={cn({ [styles['placeholder-img']]: e.id === 0 })}
						src={e.imageUrl}
						alt=""
					/>
				</div>
				<div className={styles['block-text']}>
					<h4 className={styles.title}>
						{e.title}
						<span className={cn(styles['read-icon'], 'material-icons-round')}>beenhere</span>
					</h4>
					<p className={styles.description}>{e.message}</p>
				</div>

				{e.url && (
					<LinkBox
						href={e.url}
						onClick={() => trackClickAcademyTip(e.title)}
						iconAfter="open_in_new"
					>
						Learn more
					</LinkBox>
				)}
			</div>
		);
	});

	return (
		<div
			className={cn(
				styles['personal-academy-tips'],
				isRead && slides[currentSlide]?.id !== 0 ? styles['slide-read'] : styles['slide-not-read'],
			)}
		>
			<AcademySlider
				isReadSlide={isRead}
				onClickBtnGotIt={onClickBtnGotIt}
				getCurrentSlide={setCurrentSlide}
				isNavigate={slides.length > 1}
			>
				{renderSlider}
			</AcademySlider>
		</div>
	);
}
