import { useEffect, useRef, useState } from 'react';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';

import Button from '../../components/Buttons/Button';
import cn from '../../lib/classNames';
import { Color } from '../../theme';

import styles from './GuessRisk.module.scss';

gsap.registerPlugin(Draggable);

type NodesType = {
	dataValue: number;
	dataTitle: string;
	height: number;
	posY: number;
	class: string;
	y: number;
};

const nodes: NodesType[] = [
	{
		dataValue: 1000,
		dataTitle: '10 times as dangerous or more',
		height: 0,
		posY: 0,
		class: '',
		y: 0,
	},
	{
		dataValue: 600,
		dataTitle: '6 times more dangerous',
		height: 0,
		posY: 0,
		class: '',
		y: 0,
	},
	{
		dataValue: 300,
		dataTitle: '3 times more dangerous',
		height: 0,
		posY: 0,
		class: '',
		y: 0,
	},
	{
		dataValue: 100,
		dataTitle: 'Twice as dangerous',
		height: 0,
		posY: 0,
		class: '',
		y: 0,
	},
	{
		dataValue: 0,
		dataTitle: 'Risk is about the same',
		height: 0,
		posY: 0,
		class: styles['mark-active'],
		y: 0,
	},
	{
		dataValue: -25,
		dataTitle: '25% safer',
		height: 0,
		posY: 0,
		class: '',
		y: 0,
	},
	{
		dataValue: -50,
		dataTitle: 'Half as dangerous',
		height: 0,
		posY: 0,
		class: '',
		y: 0,
	},
	{
		dataValue: -75,
		dataTitle: '75% safer',
		height: 0,
		posY: 0,
		class: '',
		y: 0,
	},
	{
		dataValue: -100,
		dataTitle: 'No risk at all',
		height: 0,
		posY: 0,
		class: '',
		y: 0,
	},
];

type GuessRiskType = {
	home?: string;
	destination?: string;
	step: (_number: number) => void;
	variant?: string;
	guessHandleSubmit?: (_data: number) => void;
};

type SliderPosType = {
	top: number;
	y: number;
};

export default function GuessRisk(props: GuessRiskType) {
	const { home = '', destination = '', step, variant = '', guessHandleSubmit } = props;

	const [scaleData, setScaleData] = useState<DOMRect>();
	const [sliderData, setSliderData] = useState<DOMRect>();
	const [zeroPos, setZeroPos] = useState(0);
	const [homePos, setHomePos] = useState(86);
	const [sliderPos, setSliderPos] = useState<SliderPosType>({ top: 78, y: 0 });
	const [markCurrent, setMarkCurrent] = useState(nodes[4]);
	const [isDrag, setIsDrag] = useState(false);

	const scaleRef = useRef<HTMLDivElement>(null);
	const homeRef = useRef<HTMLDivElement>(null);
	const sliderRef = useRef<HTMLDivElement>(null);

	const resetMarksClass = () => {
		nodes.forEach((e) => {
			e.class = '';
		});
	};

	const getNodeByValue = (value: number) => {
		return nodes.find(({ dataValue }) => value === dataValue) ?? ({} as NodesType);
	};

	const colorMarks = (value: number) => {
		nodes.forEach((e) => {
			if (value < 0 && e.dataValue < 0 && e.dataValue >= value) {
				if (e.class) {
					e.class += ` ${styles['color-green']}`;
				}
				e.class = styles['color-green'];
			} else if (value > 0 && e.dataValue > 0 && e.dataValue <= value) {
				if (e.class) {
					e.class += ` ${styles['color-red']}`;
				}
				e.class = styles['color-red'];
			}
		});
	};

	const findValueByTop = (y: number) => {
		resetMarksClass();

		const found = nodes.find((node) => {
			const top = node.y;
			const y1 = getNodeByValue(0).y + y - 1;
			const y2 = y1 + 2;
			return top >= y1 && top <= y2;
		});

		if (found) {
			found.class = styles['mark-active'];
			colorMarks(found.dataValue);
			setMarkCurrent(found);
			return found.dataValue;
		}

		return 0;
	};

	useEffect(() => {
		resetMarksClass();

		if (scaleRef.current) {
			const bounds = scaleRef.current.getBoundingClientRect();
			setScaleData(bounds);

			Draggable.create(sliderRef.current, {
				type: 'y',
				bounds: '.box-slider',
				throwProps: true,
				dragClickables: true,
				lockAxis: true,
				liveSnap: true,
				onPress() {
					setIsDrag(true);
				},
				onRelease() {
					setIsDrag(false);
				},
				onDrag() {
					findValueByTop(this.y);
				},
				onDragEnd() {
					findValueByTop(this.y);
					setSliderPos((prev) => ({ ...prev, y: this.y }));
				},
				snap: {
					y(endValue) {
						const stepHeight = bounds.height / (nodes.length - 1);
						return Math.round(endValue / stepHeight) * stepHeight;
					},
				},
			});
		}

		if (sliderRef.current) {
			const slider = sliderRef.current.getBoundingClientRect();
			setSliderData(slider);
		}
	}, []);

	const homePosition = () => {
		if (homeRef.current) {
			const homeIcon = homeRef.current;
			const homeIconHeight = homeIcon.getBoundingClientRect().height;
			const homeIconPos = zeroPos - homeIconHeight / 2;
			setHomePos(homeIconPos);
		}
	};

	const markZero = () => {
		if (scaleData) {
			nodes.forEach((e) => {
				if (e.dataValue === 0) {
					e.posY = scaleData.height / 2 - 1;
					e.y = e.posY;
					setZeroPos(e.posY);
				}
			});
		}
	};

	const sliderPosition = () => {
		if (scaleData && sliderData) {
			const top = scaleData.height / 2 - sliderData.height / 2;
			setSliderPos((prev) => ({ ...prev, top }));
		}
	};

	const marksMinus = () => {
		if (scaleData) {
			const arrMarks = nodes.filter((i) => i.dataValue < 0);
			const marksHeight = Math.round((scaleData.height - zeroPos - 2) / arrMarks.length);
			let count = 0;

			nodes.forEach((e) => {
				if (e.dataValue < 0) {
					e.height = marksHeight;
					e.posY = Math.round(zeroPos + marksHeight * count);
					e.y = e.posY + e.height;
					count += 1;
				}
			});
		}
	};

	const marksPlus = () => {
		const arrMarks = nodes.filter((i) => i.dataValue > 0);
		const marksHeight = Math.round(zeroPos / arrMarks.length);
		let count = 0;

		nodes.forEach((e) => {
			if (e.dataValue > 0) {
				e.height = marksHeight;
				e.posY = Math.round(marksHeight * count);
				e.y = e.posY;
				count += 1;
			}
		});
	};

	useEffect(() => {
		markZero();
	}, [scaleData, zeroPos]);

	useEffect(() => {
		homePosition();
		marksPlus();
		marksMinus();
		sliderPosition();
	}, [zeroPos]);

	const handleSubmit = () => {
		if (guessHandleSubmit) {
			guessHandleSubmit(markCurrent.dataValue);
		}
		step(2);
	};

	const renderMarks = () => {
		return nodes.map((e) => {
			if (e.dataValue === 0) {
				return (
					<div
						key={e.dataValue}
						className={cn(styles.marks, e.class)}
						data-value={e.dataValue}
						data-label={e.dataTitle}
						style={{ top: `${e.posY}px` }}
					>
						<div className={styles.label}>{e.dataValue}%</div>
					</div>
				);
			}
			if (e.dataValue > 0) {
				return (
					<div
						key={e.dataValue}
						className={cn(styles.marks, styles['marks-plus'], e.class)}
						data-value={e.dataValue}
						data-label={e.dataTitle}
						style={{ top: `${e.posY}px`, height: `${e.height}px` }}
					>
						<div className={styles.label}>{e.dataValue}%</div>
					</div>
				);
			}
			return (
				<div
					key={e.dataValue}
					className={cn(styles.marks, styles['marks-minus'], e.class)}
					data-value={e.dataValue}
					data-label={e.dataTitle}
					style={{ top: `${e.posY}px`, height: `${e.height}px` }}
				>
					<div className={styles.label}>{e.dataValue}%</div>
				</div>
			);
		});
	};

	const colorsClassName = () => {
		if (markCurrent.dataValue > 0) {
			return styles['border-color-red'];
		}
		if (markCurrent.dataValue < 0) {
			return styles['border-color-green'];
		}

		return styles['border-color-gray'];
	};

	const renderScale = () => {
		return (
			<div className={styles['wrap-guess-crime-risk-picker']}>
				<h3 className={styles['box-mark-description']}>
					<span className={cn(styles['mark-description-variant'], colorsClassName())}>
						{markCurrent.dataTitle}
					</span>
				</h3>
				<div className={cn(styles['box-slider'], 'box-slider')}>
					<div className={styles['block-scale']} ref={scaleRef}>
						<div
							className={cn(styles['wrap-marks'], {
								[styles['marks-active']]: isDrag,
							})}
						>
							{renderMarks()}
						</div>

						<div
							className={cn(styles.destination, colorsClassName())}
							ref={sliderRef}
							style={{ top: `${sliderPos.top}px`, transform: `translateY(${sliderPos.y}px)` }}
						>
							<span className={cn(styles['location-icon'], 'material-icons')}>location_on</span>
						</div>
						<div className={styles.home} ref={homeRef} style={{ top: `${homePos}px` }}>
							<ArrowLeftIcon htmlColor={Color.grayLight} />
							<span className={cn(styles['home-icon'], 'material-icons-round')}>home</span>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderGuessRisk = () => {
		if (variant === 'guess-step2') {
			return (
				<div className={styles['guess-risk-step2']}>
					<div className={cn(styles['risk-choose-wrap'], styles['risk-choose-wrap-step2'])}>
						<div className={styles['risk-scale-wrap']}>{renderScale()}</div>
					</div>
					<div className={styles['btn-wrap']}>
						<Button
							variant="filled"
							color="secondary"
							iconAfter="arrow_forward"
							onClick={() => step(3)}
						>
							SEE ACTUAL RESULTS
						</Button>
					</div>
				</div>
			);
		}
		return (
			<div className={styles['guess-risk-step1']}>
				<div className={styles['risk-choose-wrap']}>{renderScale()}</div>
				<div className={styles['btn-wrap']}>
					<Button variant="outlined" color="primary" onClick={() => step(3)}>
						SKIP
					</Button>
					<Button variant="filled" color="primary" onClick={() => handleSubmit()}>
						Submit
					</Button>
				</div>
			</div>
		);
	};

	return (
		<div className={styles['guess-risk']}>
			<div className={(cn(styles['title-wrap']), 'title-wrap')}>
				<h3 className={cn(styles.location, styles['location-home'], 'overflow-row-1')} title={home}>
					<span className={cn(styles['home-icon'], 'material-icons-round')}>home</span>
					{home}
				</h3>
				<span className={cn(styles['icon-compare'], 'material-icons-round')}>compare_arrows</span>
				<h3
					className={cn(styles.location, styles['location-destination'], 'overflow-row-1')}
					title={destination}
				>
					<span className={cn(styles['location-icon'], 'material-icons')}>location_on</span>
					{destination}
				</h3>
			</div>
			<p className={styles['sub-title']}>
				{variant === 'guess-step2'
					? 'Your estimate'
					: 'While results are loading, try estimating how the destination violent crime risks compare to your home. '}
			</p>
			{renderGuessRisk()}
		</div>
	);
}
