import { FormField } from '../../Profile';
import Header from '../Header';
import IndicatorsDots from '../IndicatorsDots';
import { NameValueType } from '../GetStartedSteps';
import Button from '../../../components/Buttons/Button';

type GetStartedStep4Type = {
	step?: number;
	stepsCount: number;
	email: string;
	isEmailError: string | boolean;
	firstName: string;
	firstNameHelpText: string;
	lastName: string;
	onSaveData: ({ name, value }: NameValueType) => void;
	onCheckFirstName: ({ name, value }: NameValueType) => void;
	handleNextStep: (_num: number) => void;
	handleSubmit: () => void;
};

export default function GetStartedStep4(props: GetStartedStep4Type) {
	const {
		step,
		stepsCount,
		firstName,
		firstNameHelpText,
		lastName,
		email,
		isEmailError,
		onSaveData,
		onCheckFirstName,
		handleNextStep,
		handleSubmit,
	} = props;
	return (
		<div className="get-started-steps-container">
			<IndicatorsDots currentDot={step} stepsCount={stepsCount} />
			<Header title="What is your name?" description="Please enter your name" />
			<div className="fields-container fields-container-pos-center">
				<div className="name-wrap">
					<div className="first-name">
						<FormField
							placeholder=""
							helpText="First name"
							typeField="text"
							name="firstName"
							value={firstName}
							onSaveData={onSaveData}
							onChangeData={onCheckFirstName}
							required
							firstNameHelpText={firstNameHelpText}
						/>
					</div>
					<div className="last-name">
						<FormField
							placeholder=""
							helpText="Last name"
							typeField="text"
							name="lastName"
							value={lastName}
							onSaveData={onSaveData}
						/>
					</div>
				</div>
			</div>
			<div className="block-btn">
				{email && !isEmailError ? (
					<Button
						variant={'filled'}
						color={'primary'}
						onClick={handleSubmit}
						disabled={!firstName || !!firstNameHelpText}
					>
						Calculate
					</Button>
				) : (
					<Button
						variant={'filled'}
						color={'primary'}
						onClick={() => {
							if (firstName && !firstNameHelpText) handleNextStep(5);
						}}
						disabled={!firstName || !!firstNameHelpText}
					>
						NEXT
					</Button>
				)}
			</div>
		</div>
	);
}
