export type ReportsType = {
	id: string;
	btnText: string;
	reportTitle: string;
	active: boolean;
	icon?: string;
	supIcon?: string;
};

export const PAGES = {
	riskScoring: {
		id: 'riskScoring',
		btnText: 'Risk Ratings',
		reportTitle: 'Personal Risk Scoring and Effects of Travel Report',
		active: true,
		icon: 'bar_chart',
	},
	riskBrief: {
		id: 'riskBrief',
		btnText: 'Risk Brief',
		reportTitle: 'Risk Intelligence Brief for:',
		active: false,
		icon: 'trip',
		supIcon: 'auto_awesome',
	},
	personalDashboard: {
		id: 'personalDashboard',
		btnText: 'PERSONAL DASHBOARD',
		reportTitle: '',
		active: false,
		icon: '',
	},
	profile: {
		id: 'profile',
		btnText: 'PROFILE',
		reportTitle: '',
		active: false,
		icon: '',
	},
	conflictMap: {
		id: 'conflictMap',
		btnText: '',
		reportTitle: '',
		active: false,
		icon: '',
	},
	statistics: {
		id: 'statistics',
		btnText: '',
		reportTitle: '',
		active: false,
		icon: '',
	},
};

export const PAGES_NAVIGATION = [PAGES.riskScoring, PAGES.riskBrief];
