import get from 'get-value';
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
	userSetToken: ['token'],
	userUpdateGoogleToken: ['token'],
	userSetGoogleToken: ['token'],
	userSetFacebookLoginStatus: ['data'],
	userSetFacebookData: ['data'],
	userSetFacebookDataLoaded: ['dataLoaded'],
	userSetProfileData: ['data'],
	userSetProfileDataLoaded: ['dataLoaded'],
	userResetFacebookData: null,
	userLogout: null,
	userSetGoogleOAuthData: ['data'],
});

export const UserTypes = Types;
export default Creators;

export const FACEBOOK_LOGIN_STATUS = {
	CONNECTED: 'connected',
	AUTHORIZATION_EXPIRED: 'authorization_expired',
	NOT_AUTHORIZED: 'not_authorized',
	UNKNOWN: 'unknown',
};

export const FACEBOOK_GENDER = {
	MALE: 'male',
	FEMALE: 'female',
};

export const INITIAL_FACEBOOK_DATA = Immutable({
	id: null,
	picture: null,
	email: null,
	name: null,
	firstName: null,
	lastName: null,
	gender: null, // male | female
	location: {
		id: null,
		name: null,
	}, // {id: 109320289087485, name: Sumy}
	birthday: null, // 09/24/1976
});

export const INITIAL_GOOGLE_DATA = Immutable({
	accessToken: null,
	idToken: null,
	id: null,
	name: null,
	firstName: null,
	lastName: null,
	picture: null,
	email: null,
});

export const INITIAL_PROFILE_DATA = Immutable({
	isLogged: null,
	additionalWidgets: null,
	ageBucket: null,
	email: null,
	appleName: null,
	appleRemovable: false,
	fbAvatar: null,
	fbName: null,
	facebookRemovable: true,
	googleAvatar: null,
	googleName: null,
	googleRemovable: true,
	gender: null,
	id: null,
	lat: null,
	lng: null,
	name: null,
	seAvatar: null,
});

export const INITIAL_STATE = Immutable({
	token: null,
	google: {
		token: null,
	},
	googleOAuth: INITIAL_GOOGLE_DATA,
	facebook: {
		loginStatus: FACEBOOK_LOGIN_STATUS.UNKNOWN,
		authResponse: {},
		data: INITIAL_FACEBOOK_DATA,
		dataLoaded: false,
	},
	profile: {
		data: INITIAL_PROFILE_DATA,
		dataLoaded: false,
	},
});

export const userSetToken = (state, action) => {
	const { token } = action;
	return state.merge({ token }, { deep: true });
};

export const userUpdateGoogleToken = (state, action) => {
	const { token } = action;
	return state.merge({ google: { token } }, { deep: true });
};

export const userSetGoogleToken = (state, action) => {
	const { token } = action;
	return state.merge({ google: { token } }, { deep: true });
};

export const userSetFacebookLoginStatus = (state, action) => {
	const { data } = action;
	console.log('=== userSetFacebookLoginStatus', data);
	return state.merge(
		{
			facebook: {
				authResponse: data.authResponse || {},
				loginStatus: data.status,
			},
		},
		{ deep: true },
	);
};

const adaptFacebookData = (data) => {
	return {
		id: data.id,
		picture: get(data, 'picture.data.url'),
		email: data.email,
		name: data.name,
		firstName: data.first_name,
		lastName: data.last_name,
		gender: data.gender || null,
		location: data.location || { id: null, name: null },
		birthday: data.birthday || null,
	};
};
export const userSetFacebookData = (state, action) => {
	const { data } = action;
	return state.merge(
		{
			facebook: {
				data: adaptFacebookData(data),
			},
		},
		{ deep: true },
	);
};

export const userSetFacebookDataLoaded = (state, action) => {
	const { dataLoaded } = action;
	return state.merge({ facebook: { dataLoaded } }, { deep: true });
};

export const userResetFacebookData = (state, action) => {
	const {
		facebook: { resetCounter },
	} = state;
	return state.merge(
		{
			facebook: {
				data: INITIAL_FACEBOOK_DATA,
				resetCounter: resetCounter + 1,
			},
		},
		{ deep: true },
	);
};

export const userSetGoogleOAuthData = (state, action) => {
	const { data } = action;
	return state.merge(
		{
			googleOAuth: data,
		},
		{ deep: true },
	);
};

export const userSetProfileData = (state, action) => {
	const { data } = action;
	return state.merge({ profile: { data } }, { deep: true });
};

export const userSetProfileDataLoaded = (state, action) => {
	const { dataLoaded } = action;
	return state.merge({ profile: { dataLoaded } }, { deep: true });
};

export const userLogout = (state, action) => {
	return state.merge(
		{
			facebook: {
				data: INITIAL_FACEBOOK_DATA,
			},
			googleOAuth: INITIAL_GOOGLE_DATA,
			profile: {
				data: INITIAL_PROFILE_DATA,
			},
		},
		{ deep: true },
	);
};

export const reducer = createReducer(INITIAL_STATE, {
	[Types.USER_SET_TOKEN]: userSetToken,
	[Types.USER_UPDATE_GOOGLE_TOKEN]: userUpdateGoogleToken,
	[Types.USER_SET_GOOGLE_TOKEN]: userSetGoogleToken,
	[Types.USER_SET_FACEBOOK_LOGIN_STATUS]: userSetFacebookLoginStatus,
	[Types.USER_SET_FACEBOOK_DATA]: userSetFacebookData,
	[Types.USER_SET_FACEBOOK_DATA_LOADED]: userSetFacebookDataLoaded,
	[Types.USER_RESET_FACEBOOK_DATA]: userResetFacebookData,
	[Types.USER_SET_PROFILE_DATA]: userSetProfileData,
	[Types.USER_SET_PROFILE_DATA_LOADED]: userSetProfileDataLoaded,
	[Types.USER_SET_GOOGLE_O_AUTH_DATA]: userSetGoogleOAuthData,
	[Types.USER_LOGOUT]: userLogout,
});
