import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE } from '../../../App';
import { Context, SelectLocation } from '../../../components';
import Button from '../../../components/Buttons/Button';
import cn from '../../../lib/classNames';
import { LocationDataType } from '../../../types/LocationData';

import styles from './RiskCalculation.module.scss';

export default function RiskCalculation() {
	const [place, setPlace] = useState<LocationDataType | null>();

	const { dailyViewedLimitedList } = useContext(Context);

	const history = useHistory();

	const handleCompare = (destinationPlace: LocationDataType) => {
		history.push({
			pathname: ROUTE.changeDestination,
			state: {
				inputPlace: destinationPlace,
				inputStep: dailyViewedLimitedList.length < 3 ? 1 : 0,
			},
		});
	};

	const handleClear = () => {
		setPlace(null);
	};

	return (
		<section className={styles['risk-calculation']}>
			<div className={styles['select-block']}>
				<span className={cn(styles['icon-place'], 'material-icons')}>location_on</span>
				{place && (
					<span className={cn(styles['icon-clear'], 'material-icons')} onClick={handleClear}>
						close
					</span>
				)}
				<SelectLocation
					place={place}
					onSelect={setPlace}
					placeholder="Enter destination city"
					inputDestinationForDashboard
				/>
			</div>

			<Button
				className={styles.button}
				disabled={!place}
				variant="filled"
				color="secondary"
				sizeWidth="full"
				onClick={() => place && handleCompare(place)}
			>
				<span className="material-icons">search</span>
			</Button>
		</section>
	);
}
