import { useEffect, useState } from 'react';
import { isEmptyObj } from '../../lib';
import { DataType } from './types';
import Button from '../../components/Buttons/Button';

import { ModalCustom } from '../../components';
import { EVENT, EventManager } from '../../lib';
import { api } from '../../sagas';

import { QuestionText } from './QuestionText';
import { Question1, Question2, Question3, Question4, Question5, Question6, Question7 } from '.';
import { QuestionType } from '../Dashboard/Alerts/Alerts';

import './Questions.scss';

type QuestionsType = {
	question: QuestionType;
	close: (n: null) => void;
};

export default function Questions(props: QuestionsType) {
	const { question, close } = props;

	const [openModal, setOpenModal] = useState(false);
	const [screen, setScreen] = useState(1);
	const [data, setData] = useState<DataType>({});

	const { questionId } = question;

	const questionNumber = question.questionNum;

	const quNum = `qu${question.questionNum}`;

	const screenNum = `screen${screen}` as 'screen1' | 'screen2';

	const { title } = QuestionText[quNum][screenNum];
	const { subTitle } = QuestionText[quNum][screenNum];

	const body = () => {
		if (questionNumber === 1) {
			return <Question1 screen={screen} data={setData} questionNumber={questionNumber} />;
		}
		if (questionNumber === 2) {
			return <Question2 screen={screen} data={setData} questionNumber={questionNumber} />;
		}
		if (questionNumber === 3) {
			return <Question3 screen={screen} data={setData} questionNumber={questionNumber} />;
		}
		if (questionNumber === 4) {
			return <Question4 screen={screen} data={setData} questionNumber={questionNumber} />;
		}
		if (questionNumber === 5) {
			return <Question5 screen={screen} data={setData} questionNumber={questionNumber} />;
		}
		if (questionNumber === 6) {
			return <Question6 screen={screen} data={setData} questionNumber={questionNumber} />;
		}
		if (questionNumber === 7) {
			return <Question7 screen={screen} data={setData} questionNumber={questionNumber} />;
		}
	};

	useEffect(() => {
		questionNumber > 0 ? setOpenModal(true) : setOpenModal(false);
	}, [questionNumber]);

	useEffect(() => {
		isData();
	}, [data]);

	const isData = () => {
		if ((questionNumber === 1 || questionNumber === 5) && !isEmptyObj(data)) {
			return true;
		}

		if (questionNumber === 7 && data.answer) {
			return true;
		}

		const values = Object.values(data);

		return values.includes(true);
	};

	const fields = QuestionText[quNum].screen1?.fields;

	const handleSubmitAnswer = () => {
		if (questionNumber === 1) {
			api.setSlider(data).then((response) => {
				if (response.ok) {
					setScreen(2);
				}
			});
		}

		if (questionNumber > 1) {
			fields?.map((e) => {
				if (Object.keys(e).includes('text') && e.text) {
					data[e.name] = data[e.text];
					delete data[e.text];
				}
			});

			api.quiz(questionId, data).then((response) => {
				if (response.ok) {
					if (response.data.success) {
						EventManager.publish(EVENT.NOTIFICATIONS_STORY_RELOAD);
						setScreen(2);
					}
				}
			});
		}
	};

	const renderBtm = () => {
		return screen === 1 ? (
			<Button
				variant={'filled'}
				color={'secondary'}
				iconAfter="arrow_forward"
				disabled={!isData()}
				onClick={handleSubmitAnswer}
			>
				Submit answer
			</Button>
		) : (
			<Button
				variant={'outlined'}
				color={'primary'}
				iconBefore="close"
				onClick={() => {
					close(null);
					setOpenModal(false);
				}}
			>
				CLOSE
			</Button>
		);
	};

	const renderContent = () => {
		if (questionNumber) {
			return (
				<div className="questions-wrap">
					<div className="header">
						<h3>{title}</h3>
						<p>{subTitle}</p>
					</div>
					<div className="body">{body()}</div>
					<div className="btn-wrap">{renderBtm()}</div>
				</div>
			);
		}
	};

	return (
		<div>
			<ModalCustom open={openModal} stylesModal={{}} stylesPaper={{ maxWidth: 580 }}>
				{renderContent()}
			</ModalCustom>
		</div>
	);
}
