export const userNameValidate = (label, value) => {
	const charactersLongMin = 1;
	const charactersLongMax = 35;
	const nameRegex = new RegExp(
		// 	`^(?=.{${charactersLongMin},${charactersLongMax}}$)[A-Za-z'-]+(?: [A-Za-z'-]+)*$`,
		`^(?=.{${charactersLongMin},${charactersLongMax}}$)(?![0-9])[A-Za-z0-9'-]+(?: [A-Za-z0-9'-]+)*$`,
	);

	value = value.trim();

	let errorMessage = '';

	if (!nameRegex.test(value)) {
		if (value.length < charactersLongMin) {
			errorMessage = `${label} must be at least ${charactersLongMin} characters long.`;
		} else if (value.length > charactersLongMax) {
			errorMessage = `${label} cannot be longer than ${charactersLongMax} characters.`;
		} else {
			errorMessage = `${label} contains invalid characters. Only letters, hyphens, and apostrophes are allowed.`;
		}
	}

	return errorMessage;
};
