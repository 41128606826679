import { ReactNode, useContext } from 'react';

import { Context, Loader } from '../../components';

import { AuthLoading } from '.';

type AuthType = {
	children: ReactNode;
};

function Auth(props: AuthType) {
	const { children } = props;

	const { isLogged, isLoader } = useContext(Context);

	if (!isLoader) {
		if (isLogged) {
			return children;
		}
		return <AuthLoading />;
	}
	return <Loader />;
}

export default Auth;
