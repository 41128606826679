import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
	networkAddPending: ['name'],
	networkDelPending: ['name'],
	networkLogout: null,
});

export const NetworkTypes = Types;
export default Creators;

export const PENDING = {
	// facebookGetMe: 'facebookGetMe',
	getProfile: 'getProfile',
};

export const INITIAL_STATE = Immutable({
	pending: {
		// [PENDING.facebookGetMe]: 0,
		[PENDING.getProfile]: 0,
	},
});

// pending
export const networkAddPending = (state, action) => {
	const { name } = action;
	const newState = state.merge(
		{
			pending: {
				[name]: state.pending[name] + 1,
			},
		},
		{ deep: true },
	);

	console.log('*** NetworkRedux >>> ', { state }, { action }, { newState });
	return newState;
};
export const networkDelPending = (state, action) => {
	const { name } = action;
	const newState = state.merge(
		{
			pending: {
				[name]: state.pending[name] > 0 ? state.pending[name] - 1 : 0,
			},
		},
		{ deep: true },
	);

	return newState;
};

export const networkLogout = (state, action) => {
	return INITIAL_STATE;
};

export const reducer = createReducer(INITIAL_STATE, {
	[Types.NETWORK_ADD_PENDING]: networkAddPending,
	[Types.NETWORK_DEL_PENDING]: networkDelPending,
	[Types.NETWORK_LOGOUT]: networkLogout,
});
