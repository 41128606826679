import styles from './ButtonClose.module.scss';

export default function ButtonClose() {
	return (
		<div className={styles['close-container']}>
			<div className={styles['item-1']} />
			<div className={styles['item-2']} />
		</div>
	);
}
