import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import Config from '../config';

export default (rootReducer, rootSaga) => {
	const middleware = [];
	const enhancers = [];

	const sagaMiddleware = createSagaMiddleware({});
	middleware.push(sagaMiddleware);
	if (Config.debug) middleware.push(logger);

	enhancers.push(applyMiddleware(...middleware));
	const store = createStore(rootReducer, compose(...enhancers));
	// const store = createStore(rootReducer, compose(...enhancers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()));

	// kick off root saga
	const sagasManager = sagaMiddleware.run(rootSaga);

	return {
		store,
		sagasManager,
		sagaMiddleware,
	};
};
