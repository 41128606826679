import { DataType } from './types';
import QuestionScreen1Form from './QuestionScreen1Form';

import externalLink from '../../../images/external_link.svg';

import { QuestionText } from '.';

type Question3Type = {
	screen: number;
	data: (data: DataType) => void;
	questionNumber: number;
};

export default function Question3(props: Question3Type) {
	const { questionNumber, screen = 1, data } = props;

	const quNum = `qu${questionNumber}`;

	const { screen2 } = QuestionText[quNum];

	return (
		<div className={`question-content question-${questionNumber}-content`}>
			{screen === 1 ? (
				<QuestionScreen1Form screen={screen} data={data} questionNumber={questionNumber} />
			) : (
				<div className="screen-2">
					<h4 className="headline">{screen2.headline}</h4>
					<p className="sub-headline">{screen2.subHeadline}</p>
					<div className="links-wrap">
						{Object.values(screen2.links ?? {}).map((e) => {
							return (
								<a className="link bg-violet" href={e.linkUrl} target="_blank" rel="noreferrer">
									{e.linkText}
									<img src={externalLink} alt="" />
								</a>
							);
						})}
					</div>
					<div className="note">{screen2.note}</div>
				</div>
			)}
		</div>
	);
}
