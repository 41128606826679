import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import useMediaQuery from '../../hooks/MediaQuery';
import cn from '../../lib/classNames';

import safetyR1Img01 from './images/safetyR1Img01.png';
import safetyR1Img02 from './images/safetyR1Img02.png';
import safetyR1Img03 from './images/safetyR1Img03.png';
import safetyR1Img04 from './images/safetyR1Img04.png';
import safetyR1Img05 from './images/safetyR1Img05.png';
import safetyR1Img06 from './images/safetyR1Img06.png';
import safetyR2Img01 from './images/safetyR2Img01.png';
import safetyR2Img02 from './images/safetyR2Img02.png';
import safetyR2Img03 from './images/safetyR2Img03.png';
import SectionHeader from './SectionHeader';
import WelcomeSlider, { WelcomeSliderType } from './WelcomeSlider';

import styles from './CriticalSafetyIndicators.module.scss';

gsap.registerPlugin(ScrollTrigger);

type CriticalSafetyIndicatorsType = {
	classPageContent?: string;
};

type CollageImageType = { group: number; image: number; size: 'large' | 'middle' | 'small' };

type WrapperType = { isTablet: boolean } & WelcomeSliderType;

const images = [
	[safetyR1Img01, safetyR1Img02, safetyR1Img03, safetyR1Img04, safetyR1Img05, safetyR1Img06],
	[safetyR2Img01, safetyR2Img02, safetyR2Img03],
];

function CollageImage({ group, image, size }: CollageImageType) {
	return (
		<img
			className={cn(
				styles.img,
				styles[`img-r${group}-${image}`],
				styles[`img-${size}`],
				'collage-item',
			)}
			src={images[group - 1][image - 1]}
			alt=""
		/>
	);
}

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	centerMode: true,
	slidesToShow: 3,
	slidesToScroll: 1,
	initialSlide: 0,
	arrows: false,
	responsive: [
		{
			breakpoint: 640,
			settings: {
				slidesToShow: 1,
				initialSlide: 1,
				centerPadding: '120px',
			},
		},
		{
			breakpoint: 479,
			settings: {
				slidesToShow: 1,
				centerPadding: '90px',
			},
		},

		{
			breakpoint: 389,
			settings: {
				slidesToShow: 1,
				centerPadding: '70px',
			},
		},
	],
};

function Wrapper({ isTablet, ...props }: WrapperType): JSX.Element | null {
	if (!isTablet) {
		return props.children as JSX.Element | null;
	}
	return <WelcomeSlider {...props} />;
}

export default function CriticalSafetyIndicators(props: CriticalSafetyIndicatorsType) {
	const { classPageContent } = props;

	const isTablet = useMediaQuery('(max-width: 1023px)');

	const section = useRef(null);
	const list = useRef<HTMLUListElement | null>(null);
	const collageWrap = useRef<HTMLDivElement | null>(null);

	// TODO: It will be needed for later animation.

	// useEffect(() => {
	// 	const { children: collageChildren } = collageWrap.current || {};

	// 	if (collageChildren) {
	// 		const collageItems = Array.from(collageChildren);
	// 		collageItems.forEach((e, idx) =>
	// 			gsap.from(e, {
	// 				autoAlpha: 0,
	// 				width: gsap.utils.random(10, 100),
	// 				rotate: gsap.utils.random(-90, 280),
	// 				transitionX: gsap.utils.random(-400, idx * 100),
	// 				transitionY: gsap.utils.random(-400, idx * 100),
	// 				scrollTrigger: {
	// 					trigger: section.current,
	// 					start: 'top 80%',
	// 					end: 'top 20%',
	// 					scrub: 1,
	// 					snap: {
	// 						duration: { min: 0.2, max: 2.5 },
	// 					},
	// 					once: true,
	// 				},
	// 			}),
	// 		);
	// 	}

	// 	const { children: listChildren } = list.current || {};

	// 	if (listChildren) {
	// 		gsap.from(listChildren, {
	// 			autoAlpha: 0,
	// 			xPercent: -50,
	// 			stagger: 0.2,
	// 			scrollTrigger: {
	// 				trigger: section.current,
	// 				start: 'top 40%',
	// 				end: 'bottom 110%',
	// 				scrub: 2,
	// 				once: true,
	// 			},
	// 		});
	// 	}
	// }, []);

	return (
		<section ref={section} className={cn(styles['critical-safety-indicators'])}>
			<div className={cn(styles.content, classPageContent)}>
				<div className={styles['col-1']}>
					<SectionHeader
						className={styles.header}
						title="Critical Safety Indicators And Recommendations"
					/>
					<div className={styles['list-wrap']}>
						<p className="text-large">Additional safety and security information include:</p>
						{!isTablet && (
							<ul ref={list} className={cn(styles.list, 'text-large')}>
								<li className="list-item">Street Crime</li>
								<li className="list-item">Carjacking</li>
								<li className="list-item">Female Traveler</li>
								<li className="list-item">Information/Cyber</li>
								<li className="list-item">Social Unrest</li>
								<li className="list-item">LGBTQ+</li>
								<li className="list-item">…etc.</li>
							</ul>
						)}
					</div>
				</div>
				<div className={styles['col-2']}>
					<div ref={collageWrap} className={cn(styles.collage, 'collage-wrap')}>
						<Wrapper isTablet={isTablet} settings={settings}>
							<CollageImage group={1} image={1} size="small" />
							<CollageImage group={1} image={2} size="middle" />
							<CollageImage group={1} image={3} size="small" />
							<CollageImage group={1} image={4} size="small" />
							<CollageImage group={1} image={5} size="middle" />
							<CollageImage group={1} image={6} size="small" />
							<CollageImage group={2} image={1} size="large" />
							<CollageImage group={2} image={2} size="large" />
							<CollageImage group={2} image={3} size="large" />
						</Wrapper>
					</div>
				</div>
			</div>
		</section>
	);
}
