import { DataType } from './types';

import QuestionScreen1Form from './QuestionScreen1Form';

type Question2Type = {
	screen: number;
	data: (data: DataType) => void;
	questionNumber: number;
};

export default function Question2(props: Question2Type) {
	const { questionNumber, screen, data } = props;

	if (screen !== 1) return null;

	return (
		<div className={`question-content question-${questionNumber}-content`}>
			<QuestionScreen1Form screen={screen} data={data} questionNumber={questionNumber} />
		</div>
	);
}
