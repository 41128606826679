import { useEffect, useState } from 'react';

import { InputSlider } from '../../components';
import useMediaQuery from '../../hooks/MediaQuery';

import { DataType } from './types';
import { ElementType, QuestionText } from '.';

type Question5Type = {
	screen: number;
	data: (data: DataType) => void;
	questionNumber: number;
};

export default function Question5(props: Question5Type) {
	const { questionNumber, screen = 1, data } = props;

	const quNum = `qu${questionNumber}`;

	const { fields } = QuestionText[quNum].screen1;

	const defaultValue = 50;

	const [state, setState] = useState<Record<string, boolean | string | number>>(
		fields?.reduce((prev, curr) => {
			return {
				...prev,
				[curr.name]: defaultValue,
			};
		}, {}) ?? {},
	);

	const handleChange = (name: string, value: number) => {
		setState({ ...state, [name]: value });
	};

	useEffect(() => {
		data(state);
	}, [state]);

	const isMobile = useMediaQuery('(max-width: 767px)');

	if (screen !== 1) {
		return null;
	}

	return (
		<div className={`question-content question-${questionNumber}-content`}>
			<div className="form">
				{fields?.map((e) => {
					if (e.type === ElementType.slider) {
						return (
							<InputSlider
								classNameWrap="slider-wrap"
								classNameSlider="slider"
								label={e.label}
								name={e.name}
								inputWidthText={isMobile ? 30 : 48}
								railHeight={5}
								railBorderRadius={5}
								thumbSize={22}
								thumbPositionY={-8.5}
								defaultValue={defaultValue}
								onChange={handleChange}
								key={e.label}
							/>
						);
					}
				})}
			</div>
		</div>
	);
}
