import LayoutDashboard from './Layout/LayoutDashboard';
import { ConfirmedEmail } from './GetStarted';

import styles from './PageTests.module.scss';

export default function PageTests() {
	return (
		<LayoutDashboard>
			<div className={styles['content-wrap']}>
				<ConfirmedEmail />
			</div>
		</LayoutDashboard>
	);
}
