import { PureComponent } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';

import { Color } from '../theme';

const styles1 = (inputDestinationForDashboard) => {
	return {
		control: (provided, state) => ({
			...provided,
			backgroundColor: 'transparent',
			border: 'none',
			borderRadius: 0,
			borderBottom: inputDestinationForDashboard ? 'none' : '2px solid',
			borderBottomColor: Color.gray,
			boxShadow: 'none',
			'&:hover': { borderColor: Color.gray },
			fontSize: '1.6rem',
			maxHeight: 180,
		}),
		valueContainer: (provided, state) => ({
			...provided,
			padding: 0,
			margin: 0,
			fontSize: '1.6rem',
			paddingLeft: inputDestinationForDashboard ? 30 : 0,
			paddingRight: inputDestinationForDashboard ? 75 : 0,
		}),
		placeholder: (provided, state) => ({
			...provided,
			color: Color.gray,
			width: '100%',
			marginLeft: 0,
			marginRight: 0,
			fontSize: '1.6rem',
			fontWeight: 700,
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: Color.gray,
			fontSize: '1.6rem',
		}),
		indicatorSeparator: (provided, state) => ({
			...provided,
			display: 'none',
		}),
		input: (provided, state) => ({
			...provided,
			color: Color.gray,
			fontSize: '1.6rem',
		}),

		indicatorsContainer: (provided, state) => ({
			...provided,
			display: 'none',
		}),

		menu: (provided, state) => ({
			...provided,
			// boxShadow: 'none',
			border: 'none',
		}),

		menuList: (provided, state) => ({
			...provided,
			maxHeight: 180,
		}),
	};
};

const styles2 = (inputDestinationForDashboard) => {
	return {
		...styles1(),
		control: (provided, state) => ({
			...provided,
			backgroundColor: 'transparent',
			border: 'none',
			borderRadius: 0,
			boxShadow: 'none',
			fontSize: '1.1429rem',
			borderBottom: inputDestinationForDashboard ? 'none' : `2px solid ${Color.grayLight}`,
			'&:hover': { borderColor: Color.grayLight },
			paddingLeft: inputDestinationForDashboard ? 30 : 0,
			paddingRight: inputDestinationForDashboard ? 75 : 0,
		}),
	};
};

const styles2withOutBorder = {
	...styles2(),
	control: (provided, state) => ({
		...provided,
		boxShadow: 'none',
		border: 'none',
		'&:hover': { borderColor: 'none' },
	}),
};

const styles1Dark = {
	control: (provided, state) => ({
		...provided,
		backgroundColor: 'transparent',
		border: 'none',
		borderRadius: 0,
		borderBottom: `2px solid ${Color.gray}`,
		boxShadow: 'none',
		'&:hover': { borderColor: Color.gray },
	}),
	valueContainer: (provided, state) => ({
		...provided,
		padding: 0,
		margin: 0,
		fontSize: '1.6rem',
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: Color.grayLight,
		width: '100%',
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: Color.blueDark,
		fontSize: '1.6rem',
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: 'none',
	}),
	input: (provided, state) => ({
		...provided,
		color: Color.blueDark,
		fontSize: '1.6rem',
	}),
};

const styles2Dark = {
	...styles1Dark,
	control: (provided, state) => ({
		...provided,
		backgroundColor: 'transparent',
		border: 'none',
		borderRadius: 0,
		borderBottom: 'none',
		boxShadow: 'none',
		fontSize: '1.1429rem',
		'&:hover': { borderColor: Color.gray },
	}),
};

function DropdownIndicator(props) {
	const {
		selectProps: { menuIsOpen },
	} = props;
	const style = {
		color: menuIsOpen ? Color.white : Color.gray,
	};

	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator {...props}>
				<Icon style={style}>{menuIsOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
			</components.DropdownIndicator>
		)
	);
}

class Autocomplete extends PureComponent {
	static propTypes = {
		label: PropTypes.string,
		placeholder: PropTypes.string,
		value: PropTypes.object,
		isClearable: PropTypes.bool,
		items: PropTypes.array, // [{label, value}]
		error: PropTypes.bool,
		errorMessage: PropTypes.string,
		async: PropTypes.bool,
		fullWidth: PropTypes.bool,
		large: PropTypes.bool,
		withoutBorder: PropTypes.bool,
		onChange: PropTypes.func,
		inputDestinationForDashboard: PropTypes.bool,
	};

	handleChange = (event) => {
		const { onChange } = this.props;
		if (onChange) onChange(event.target.name, event.target.value);
	};

	render() {
		const {
			placeholder,
			value,
			isClearable = false,
			items = [],
			error = false,
			errorMessage = '',
			async = false,
			fullWidth = true,
			large = false,
			withoutBorder,
			dark = false,
			onFocus,
			inputDestinationForDashboard,
			...rest
		} = this.props;

		let styles = large
			? withoutBorder
				? styles2withOutBorder
				: styles2(inputDestinationForDashboard)
			: styles1(inputDestinationForDashboard);
		if (dark) {
			styles = large ? styles2Dark : styles1Dark;
		}

		return (
			<FormControl fullWidth={fullWidth} error={!!error}>
				{async ? (
					<AsyncSelect
						onChange={this.handleChange}
						placeholder={placeholder}
						isClearable={isClearable}
						components={{ DropdownIndicator }}
						styles={styles}
						value={value}
						{...rest}
					/>
				) : (
					<Select
						options={items}
						value={value}
						onChange={this.handleChange}
						placeholder={placeholder}
						isClearable={isClearable}
						components={{ DropdownIndicator }}
						styles={styles}
						{...rest}
					/>
				)}
				{error && <FormHelperText>{errorMessage}</FormHelperText>}
			</FormControl>
		);
	}
}

export default Autocomplete;
