import { ReactNode, useEffect, useRef, useState } from 'react';

import LinkBox from '../../../components/Buttons/LinkBox';
import cn from '../../../lib/classNames';

import styles from './Accordion.module.scss';

type AccordionType = {
	className?: string;
	title?: string;
	description: ReactNode;
	footerClassName?: string;
	footer?: ReactNode;
	heightClosed?: number;
	btnText?: {
		toOpen: string;
		toClose: string;
	};
};

export default function Accordion(props: AccordionType) {
	const {
		className,
		title,
		description,
		footerClassName,
		footer,
		heightClosed = 175,
		btnText = {
			toOpen: 'Read more',
			toClose: 'Minimize',
		},
	} = props;

	const [isOpen, setIsOpen] = useState(false);
	const [contentHeight, setContentHeight] = useState({
		heightOpened: 0,
		heightClosed,
	});

	const infoTextRef = useRef<HTMLDivElement>(null);

	const isAccordionBtn = contentHeight.heightOpened > heightClosed;

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		if (infoTextRef.current) {
			setContentHeight({ ...contentHeight, heightOpened: infoTextRef.current.scrollHeight });
		}
	}, [heightClosed]);

	const descriptionMaxHeight = isOpen ? contentHeight.heightOpened : contentHeight.heightClosed;

	return (
		<div className={cn(styles.accordion, className)}>
			{title && <h4 className={styles.title}>{title}</h4>}
			<div
				ref={infoTextRef}
				className={styles.description}
				style={{ maxHeight: `${descriptionMaxHeight}px` }}
			>
				{description}
				{footer && <footer className={cn(styles.footer, footerClassName)}>{footer}</footer>}
			</div>
			{isAccordionBtn && (
				<LinkBox
					className={styles['link-read-more']}
					variant="text"
					iconBefore={isOpen ? 'remove_circle_outline' : 'add_circle_outline'}
					onClick={toggleAccordion}
					elementType="button"
				>
					{isOpen ? btnText.toClose : btnText.toOpen}
				</LinkBox>
			)}
		</div>
	);
}
