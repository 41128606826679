import cn from '../../lib/classNames';

import SectionHeader from './SectionHeader';
import StepCard from './StepCard';

import styles from './TravelingSoon.module.scss';

type TravelingSoonType = {
	classPageContent?: string;
};

export default function TravelingSoon(props: TravelingSoonType) {
	const { classPageContent } = props;

	return (
		<section className={styles['traveling-soon']}>
			<div className={cn(styles.content, classPageContent)}>
				<div className={styles['row-1']}>
					<span className={cn(styles['icon-airplane'], 'material-icons-outlined')}>flight</span>
				</div>
				<div className={styles['row-2']}>
					<SectionHeader
						className={styles.header}
						title="Traveling soon?"
						subTitle="Make informed decisions by comparing your destination risks to
						your home in less than 30 seconds"
						textAlign="center"
					/>
				</div>
				<div className={styles['row-3']}>
					<StepCard
						num={1}
						title="Create your profile"
						description="Enter your age group, sex at birth, and home city."
					/>
					<StepCard
						num={2}
						title="Select your destination"
						description="Choose among hundreds of U.S. and over 190 global cities."
					/>
					<StepCard
						num={3}
						title="Get detailed risk data"
						description="City and county-lavel violent crime and healty rates and more."
					/>
				</div>
			</div>
		</section>
	);
}
