import { AdditionalRisksType } from '../../../api/types/apiGetReport';

import RiskHighlightsIndicatorAccordion from './RiskHighlightsIndicatorAccordion';
import RiskHighlightsIndicatorDialog from './RiskHighlightsIndicatorDialog';
import RiskHighlightsIndicatorGradient from './RiskHighlightsIndicatorGradient';
import RiskHighlightsIndicatorScale from './RiskHighlightsIndicatorScale';

import './RiskHighlightsIndicatorGuidance.scss';

type RiskHighlightsIndicatorGuidanceType = {
	additionalRisks: AdditionalRisksType[];
	reportHelpOpened: boolean;
	reportHelpIndex: number;
	handleOnCloseReportHelp: () => void;
};

function RiskHighlightsIndicatorGuidance(props: RiskHighlightsIndicatorGuidanceType) {
	const { additionalRisks, reportHelpOpened, reportHelpIndex, handleOnCloseReportHelp } = props;

	const {
		name,
		description,
		rating,
		ratingDescription,
		guidance,
		videoUrl,
		adviceTitle,
		adviceTexts,
		hyperlocalTitle,
		hyperlocalTexts,
		imageUrl,
	} = additionalRisks[reportHelpIndex];

	const keys = guidance && Object.keys(guidance);

	return (
		<RiskHighlightsIndicatorDialog
			title={name}
			open={reportHelpOpened}
			onClose={handleOnCloseReportHelp}
		>
			<RiskHighlightsIndicatorGradient ratingValue={rating} ratingDescription={ratingDescription} />
			<RiskHighlightsIndicatorScale ratingValue={rating} />
			<p className="rating-general-text">{description}</p>
			<RiskHighlightsIndicatorAccordion
				guidance={guidance}
				keys={keys}
				adviceTitle={adviceTitle}
				adviceTexts={adviceTexts}
				hyperlocalTitle={hyperlocalTitle}
				hyperlocalTexts={hyperlocalTexts}
				videoUrl={videoUrl}
				imageUrl={imageUrl}
			/>
		</RiskHighlightsIndicatorDialog>
	);
}
export default RiskHighlightsIndicatorGuidance;
