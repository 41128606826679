import { ReactComponent as PreloaderGraph } from './images/PreloaderGraph.svg';

import styles from './PreloaderViolentCrimeRiskGraph.module.scss';

export default function PreloaderViolentCrimeRiskGraph() {
	return (
		<div className={styles.wrapper}>
			<PreloaderGraph className={styles.graph} />
		</div>
	);
}
