import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
	dashboardSetPersonal: ['data'],
	dashboardSetHome: ['data'],
	dashboardSetDestination: ['data'],
	dashboardLogout: null,
});

export const DashboardTypes = Types;
export default Creators;

export const AGE_BUCKETS = [
	'0-19',
	'20-24',
	'25-29',
	'30-34',
	'35-39',
	'40-44',
	'45-49',
	'50-54',
	'55-59',
	'60-64',
	'65-69',
	'70-74',
	'75-79',
	'80+',
];
export const AGE_BUCKET = AGE_BUCKETS.map((el) => ({ value: el, label: el }));

export const GENDERS = ['Male', 'Female'];
export const GENDER = {
	[GENDERS[0]]: 'M',
	[GENDERS[1]]: 'F',
};
export const GENDERS_FOR_SELECT = GENDERS.map((el, idx) => ({ value: idx, label: el }));
export const GENDER_REVERSED = {
	M: 0,
	male: 0,
	F: 1,
	female: 1,
};

export const VACCINATED = ['Yes', 'No'];

const INITIAL_STATE = Immutable({
	personal: {
		ageBucket: '',
		gender: '',
		location: null, // {label: "Kyiv, Ukraine", value: {id: "region.6496195031590180", latitude: 50.25, longitude: 30.5, name: "Kyiv, Ukraine", type: "region"}}
		destination: null,
		email: '',
		vaccinated: null,
		seLogin: '',
		seName: '',
		seAvatar: '',
		seFirstName: '',
		seLastName: '',
		passwordSet: null,
	},
	home: {
		homeRequestId: null,
		riskContributors: {
			health: null,
			crime: null,
			accidents: null,
		},
		domainsDistribution: {
			health: null,
			crime: null,
			accidents: null,
		},
		covidBucket: null,
		covidDeathTrend: null,
		covidRankingGroup: null,
		covidRankingLocation: null,
		covidTrendHistory: [], // {"date": "2020-08-02","avg_cases": 67111.75,"avg_deaths": 1297.75}
		riskRateChangeAll: {
			lower: null,
			upper: null,
			value: null,
		},
		riskRateChangeHealth: {
			lower: null,
			upper: null,
			value: null,
		},
		riskRateChangeCommunicableHealth: {
			lower: null,
			upper: null,
			value: null,
		},
		covidCasesTrend: null,
		countryCode: null,
		covidLocation: null,
	},
	destination: {
		homeReport: null,
		covidBucket: null,
		covidDeathTrend: null,
		covidRankingGroup: null,
		covidRankingLocation: null,
		crimeRateChange: null,
		covidTrendHistory: [],
		covidGeography: [], // {"location": "AW","covidDeathBucket": 5}
		covidCasesTrend: null,
		countryCode: null,
		riskRateChangeAll: {
			lower: null,
			upper: null,
			value: null,
		},
		riskRateChangeHealth: {
			lower: null,
			upper: null,
			value: null,
		},
		riskRateChangeCommunicableHealth: {
			lower: null,
			upper: null,
			value: null,
		},
	},
});

const dashboardSetPersonal = (state, action) => {
	const { data } = action;
	return state.merge(
		{
			personal: data,
		},
		{ deep: true },
	);
};

const dashboardSetHome = (state, action) => {
	const { data } = action;
	return state.merge(
		{
			home: data,
		},
		{ deep: true },
	);
};

const dashboardSetDestination = (state, action) => {
	const { data } = action;
	return state.merge(
		{
			destination: data,
		},
		{ deep: true },
	);
};

const dashboardLogout = (state, action) => {
	return INITIAL_STATE;
};

export const reducer = createReducer(INITIAL_STATE, {
	[Types.DASHBOARD_SET_PERSONAL]: dashboardSetPersonal,
	[Types.DASHBOARD_SET_HOME]: dashboardSetHome,
	[Types.DASHBOARD_SET_DESTINATION]: dashboardSetDestination,
	[Types.DASHBOARD_LOGOUT]: dashboardLogout,
});
