import React from 'react';
import { createRoot } from 'react-dom/client';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable'; // IE11 fix
import 'core-js/modules/es.object.assign'; // IE11 fix
import 'regenerator-runtime/runtime'; // IE11 fix
import 'core-js/features/string';

import { App } from './app';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
