import nodataIcon from '../../images/nodata_icon_v1.svg';
import warIcon from '../../images/war_icon_v1.svg';
import { SimilarVariant } from '../features/Dashboard/ViolentCrimeRisk/RiskRating';
import cn from '../lib/classNames';

import styles from './SimilarCitiesConflictOrNoData.module.scss';

type SimilarCitiesConflictOrNoDataType = {
	variant: SimilarVariant;
	location?: string;
	locationTitle: string;
};

export default function SimilarCitiesConflictOrNoData(props: SimilarCitiesConflictOrNoDataType) {
	const { location, locationTitle, variant } = props;

	return (
		<div
			className={cn(styles['similar-cities-conflict-or-nodata'], {
				[styles['similar-cities-conflict']]: variant === SimilarVariant.conflict,
			})}
		>
			<div className={styles['content-wrap']}>
				<div className={styles['left-block']}>
					<span className={locationTitle === 'Home' ? 'material-icons-round' : 'material-icons'}>
						{locationTitle === 'Home' ? 'home' : 'location_on'}
					</span>
					<div className={styles['text-wrap']}>
						<div className={styles.location} title={location}>
							{location}
						</div>
						<div className={styles['location-title']}>{locationTitle}</div>
					</div>
				</div>
				<div className={styles['right-block']}>
					<div className={styles.icon}>
						<img src={variant === SimilarVariant.conflict ? warIcon : nodataIcon} alt="" />
					</div>
				</div>
			</div>
		</div>
	);
}
