import { SimilarCities } from '../../../components';
import SimilarCitiesConflictOrNoData from '../../../components/SimilarCitiesConflictOrNoData';

export enum SimilarVariant {
	conflict = 'conflict',
	nodata = 'nodata',
}

type RiskRatingType = {
	location?: string;
	locationTitle: string;
	riskValue: number;
	isConflict: boolean;
	isNoData: boolean;
};

export default function RiskRating(props: RiskRatingType): JSX.Element {
	const { location, locationTitle, riskValue, isConflict, isNoData } = props;

	if (isConflict) {
		return (
			<SimilarCitiesConflictOrNoData
				location={location}
				locationTitle={locationTitle}
				variant={SimilarVariant.conflict}
			/>
		);
	}
	if (isNoData) {
		return (
			<SimilarCitiesConflictOrNoData
				location={location}
				locationTitle={locationTitle}
				variant={SimilarVariant.nodata}
			/>
		);
	}
	return <SimilarCities location={location} locationTitle={locationTitle} riskValue={riskValue} />;
}
