import useMediaQuery from '../../hooks/MediaQuery';
import cn from '../../lib/classNames';

import violentImg from './images/violentImg.jpg';
import violentImgMobile from './images/violentImgMobile.jpg';
import SectionHeader from './SectionHeader';

import styles from './ViolentCrimeRiskIndex.module.scss';

type ViolentCrimeRiskIndexType = {
	classPageContent?: string;
};

export default function ViolentCrimeRiskIndex(props: ViolentCrimeRiskIndexType) {
	const isDesktop = useMediaQuery('(min-width: 1024px)');

	const { classPageContent } = props;
	return (
		<section className={styles['violent-crime-risk-index']}>
			<div className={cn(styles.content, classPageContent)}>
				<div className={styles['col-1']}>
					<SectionHeader
						className={styles.header}
						title="The Violent Crime Risk Index For Global Cities"
						subTitle="With full access to the ViCRI index, Safe-xplore users can find the serious crime risk ratings of hundreds of U.S. and global cities."
					/>
				</div>
				<div className={styles['col-2']}>
					{isDesktop ? (
						<img className="welcome-shadow welcom-border-radius" src={violentImg} alt="" />
					) : (
						<img
							className={cn(styles['img-mobile'], 'welcome-shadow', 'welcom-border-radius')}
							src={violentImgMobile}
							alt=""
						/>
					)}
				</div>
			</div>
		</section>
	);
}
