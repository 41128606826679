import { useState } from 'react';

import { AdditionalRisksType, ApiReportType } from '../../../api/types/apiGetReport';

import RiskHighlightsIndicator from './RiskHighlightsIndicator';
import RiskHighlightsIndicatorGuidance from './RiskHighlightsIndicatorGuidance';
import RiskHighlightsIndicatorMoreAvailables from './RiskHighlightsIndicatorMoreAvailables';

import './RiskHighlightsIndicatorContainer.scss';

function backNamesCompare(a: AdditionalRisksType, b: AdditionalRisksType) {
	if (a.name < b.name) {
		return 1;
	}
	if (a.name > b.name) {
		return -1;
	}
	return 0;
}

function ratingNamesCompare(a: AdditionalRisksType, b: AdditionalRisksType) {
	if (a.rating === b.rating) {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	}

	return (b.rating || 0) - (a.rating || 0);
}

type RiskHighlightsIndicatorContainerType = {
	data?: ApiReportType;
	isLimitedVersion?: boolean;
	buyReportLink?: string | null;
};

export default function RiskHighlightsIndicatorContainer(
	props: RiskHighlightsIndicatorContainerType,
) {
	const { data, isLimitedVersion, buyReportLink = null } = props;

	const [reportHelpOpened, setReportHelpOpened] = useState(false);
	const [reportHelpIndex, setReportHelpIndex] = useState(0);

	const additionalRisks = data?.additionalRisks;

	const handleOnClickReportHelp = (idx: number) => {
		setReportHelpOpened(true);
		setReportHelpIndex(idx);
		return false;
	};

	const handleOnCloseReportHelp = () => {
		setReportHelpOpened(false);
		return false;
	};

	const additionalRisksForRenderUnsorted = additionalRisks || [];
	const additionalRisksForRenderSorted = [...additionalRisksForRenderUnsorted].sort(
		ratingNamesCompare,
	);
	let additionalRisksForRender = additionalRisksForRenderSorted;

	const freeRisksNameArray = ['Street Crime', 'Female Traveler'];
	let notFreeIndicators = [];
	let moreAvailablesItem = null;

	if (isLimitedVersion) {
		additionalRisksForRender = additionalRisksForRenderUnsorted.filter((indicator) =>
			freeRisksNameArray.includes(indicator.name),
		);
		additionalRisksForRender.sort(backNamesCompare);

		notFreeIndicators = additionalRisksForRenderUnsorted.filter(
			(indicator) => !freeRisksNameArray.includes(indicator.name),
		);

		moreAvailablesItem = (
			<RiskHighlightsIndicatorMoreAvailables
				moreAvaiablesList={notFreeIndicators}
				title="Access 7 more risk domains on Pro report"
				buttonText="UNLOCK PRO REPORT"
				buyReportLink={buyReportLink}
				destination={data?.fromToDetails?.destination}
			/>
		);
	}

	return (
		<>
			<div className="risk-highlights-indicator-container">
				{additionalRisksForRender.map((el, idx) => {
					return (
						<RiskHighlightsIndicator
							key={idx}
							name={el.name}
							rating={el.rating}
							description={el.ratingDescription}
							idx={idx}
							handleOnClickReportHelp={handleOnClickReportHelp}
							bgImage={el.imageUrl}
						/>
					);
				})}
				{moreAvailablesItem}
			</div>
			<RiskHighlightsIndicatorGuidance
				additionalRisks={additionalRisksForRender}
				reportHelpOpened={reportHelpOpened}
				reportHelpIndex={reportHelpIndex}
				handleOnCloseReportHelp={handleOnCloseReportHelp}
			/>
		</>
	);
}
