import { ReactComponent as ConfirmEmailIcon } from '../../../images/mail_link.svg';
import { HeaderNotAuth } from '../../components';
import { Footer } from '../Dashboard';

import cn from '../../lib/classNames';

import styles from './ConfirmEmail.module.scss';

export default function ConfirmEmail() {
	return (
		<section className={cn(styles['page-confirm-email'], 'page')}>
			<div className={cn(styles.content, 'content')}>
				<HeaderNotAuth />
				<section className={styles.body}>
					<div className={styles['block-img']}>
						<ConfirmEmailIcon />
					</div>
					<div className={styles['block-text']}>
						<h1 className={styles.title}>Thank you for signing up!</h1>
						<p className={styles.description}>
							To complete your registration, please check your email inbox and click on the
							confirmation link in it.
						</p>
						<div className={cn(styles.alert, 'bg-gray-light')}>
							<span className={cn(styles['alert-icon'], 'material-icons-outlined')}>info</span>
							<span className={styles['alert-text']}>
								If you didn't receive our email message, please check your spam folder or reach us
								via support.
							</span>
						</div>
					</div>
				</section>
				<footer className={styles.footer}>
					<Footer />
				</footer>
			</div>
		</section>
	);
}
