import { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { ROUTE } from '../../App';
import { Context, GoogleReCaptcha, Loader } from '../../components';
import { validateEmail, validatePassword } from '../../lib';
import { fakeAgeBucket } from '../../lib/constats';
import { userNameValidate } from '../../lib/userValidate';
import { api } from '../../sagas';
import { LocationDataType } from '../../types/LocationData';
import LayoutStarted from '../Layout/LayoutStarted';

import GetStartedStep0 from './Steps/GetStartedStep0';
import GetStartedStep1 from './Steps/GetStartedStep1';
import GetStartedStep2 from './Steps/GetStartedStep2';
import GetStartedStep3 from './Steps/GetStartedStep3';
import GetStartedStep4 from './Steps/GetStartedStep4';
import GetStartedStep5 from './Steps/GetStartedStep5';
import GetStartedStep6 from './Steps/GetStartedStep6';
import Forgot from './Forgot';
import SignIn from './SignIn';

import './GetStartedSteps.scss';

export type NameValueType = {
	name: string;
	value: string;
};

function GetStartedSteps() {
	const [step, setStep] = useState<number | undefined>(); // default value = undefined!
	const [ageBucket, setAgeBucket] = useState('');
	const [gender, setGender] = useState(-1);
	const [place, setPlace] = useState<LocationDataType>();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [gotoDashboard, setGotoDashboard] = useState(false);
	const [isForgotPassword, setIsForgotPassword] = useState(false);
	const [isEmailError, setIsEmailError] = useState<string | boolean>(false);
	const [isPasswordError, setIsPasswordError] = useState(false);
	const [firstNameHelpText, setFirstNameHelpText] = useState('');
	const [stepsCount, setStepsCount] = useState(0);

	const {
		userProfileData,
		setUserProfileData,
		googleToken,
		setGoogleToken,
		isLogged,
		userLocation,
		firstStepDataExist,
		isLoader,
	} = useContext(Context);

	const checkingSteps = () => {
		const { ageBucket, gender, name, seFirstName, email } = userProfileData;

		if (isLogged && firstStepDataExist) setGotoDashboard(true);

		if (!isLogged) {
			handleNextStep(0);
		} else if (!ageBucket) {
			handleNextStep(1);
		} else if (gender === '' || gender === undefined || gender === null) {
			handleNextStep(2);
		} else if (!name) {
			handleNextStep(3);
		} else if (!seFirstName) {
			handleNextStep(4);
		} else if (!email) {
			handleNextStep(5);
		} else {
			handleNextStep(6);
		}
	};

	useEffect(() => {
		setAgeBucket(userProfileData.ageBucket ? userProfileData.ageBucket : '');
		setGender(userProfileData.gender ? userProfileData.gender : -1);
		setPlace(userLocation);
		setFirstName(userProfileData.seFirstName ? userProfileData.seFirstName : '');
		setLastName(userProfileData.seLastName ? userProfileData.seLastName : '');
		setEmail(userProfileData.email ? userProfileData.email : '');
	}, [userProfileData, userLocation]);

	useEffect(() => {
		checkingSteps();
	}, [isLogged, userProfileData]);

	const emailVerification = (email: string) => {
		const isError = validateEmail.validate({ email }).error;
		setIsEmailError(isError);
		return !isError;
	};

	const passwordVerification = (password: string) => {
		const isError = validatePassword.validate({ password }).error;
		setIsPasswordError(isError);
		return !isError;
	};

	const handleNextStep = useCallback((nextStep: number) => {
		setStep(nextStep);
	}, []);

	const handleSelectAge = useCallback((value: string) => {
		setAgeBucket(value);
	}, []);

	const handleSelectGender = useCallback((value: number) => {
		setGender(value);
	}, []);

	const handleSelectPlace = useCallback((newPlace: LocationDataType) => {
		setPlace(newPlace);
	}, []);

	const onCheckFirstName = ({ name, value }: NameValueType) => {
		if (name === 'firstName') {
			setFirstNameHelpText('');
			const fieldValidation = userNameValidate('First name', value);

			if (!fieldValidation) {
				setFirstName(value);
			} else {
				setFirstNameHelpText(fieldValidation);
			}
		}
	};

	const onSaveData = ({ name, value }: NameValueType) => {
		if (name === 'lastName') {
			setLastName(value);
		}
		if (name === 'email') {
			if (emailVerification(value)) {
				setEmail(value);
			} else {
				setIsEmailError(true);
			}
		} else if (name === 'password') {
			if (passwordVerification(value)) {
				setPassword(value);
			} else {
				setIsPasswordError(true);
			}
		}
	};

	const handleCaptchaVerify = useCallback((newToken: string) => {
		setGoogleToken(newToken);
	}, []);

	const handleSubmit = useCallback(() => {
		if (!isEmailError) {
			api
				.updateProfile(
					{
						ageBucket: fakeAgeBucket(ageBucket),
						gender,
						id: place?.value.id,
						lat: place?.value.latitude,
						lng: place?.value.longitude,
						name: place?.label,
						seFirstName: firstName,
						seLastName: lastName,
						email,
						allRiskFactors: true,
					},
					googleToken,
					false,
				)
				.then((response) => {
					if (response.ok) {
						setUserProfileData(response.data);
						setGotoDashboard(true);
					} else {
						setIsEmailError(response.data?.message);
					}
				})
				.catch((error) => {
					console.log('::: GetStartedSteps >> error: ', error);
				});
		}
	}, [
		userProfileData,
		googleToken,
		setUserProfileData,
		ageBucket,
		gender,
		place,
		firstName,
		lastName,
		email,
		isEmailError,
		isPasswordError,
	]);

	const onForgot = (isForgot: boolean) => {
		setIsForgotPassword(isForgot);
	};

	const steps = [
		<GetStartedStep0
			isForgotPassword={isForgotPassword}
			renderSignIn={<SignIn onForgot={onForgot} />}
			renderForgot={<Forgot onForgot={onForgot} isForgotPassword={isForgotPassword} />}
		/>,
		<GetStartedStep1
			step={step}
			stepsCount={stepsCount}
			ageBucket={ageBucket}
			handleSelectAge={handleSelectAge}
			handleNextStep={handleNextStep}
		/>,
		<GetStartedStep2
			step={step}
			stepsCount={stepsCount}
			gender={gender}
			handleSelectGender={handleSelectGender}
			handleNextStep={handleNextStep}
		/>,
		<GetStartedStep3
			step={step}
			stepsCount={stepsCount}
			place={place}
			email={email}
			isEmailError={isEmailError}
			firstName={firstName}
			handleSelectPlace={handleSelectPlace}
			handleNextStep={handleNextStep}
			handleSubmit={handleSubmit}
		/>,
		<GetStartedStep4
			step={step}
			stepsCount={stepsCount}
			firstName={firstName}
			firstNameHelpText={firstNameHelpText}
			lastName={lastName}
			email={email}
			isEmailError={isEmailError}
			onSaveData={onSaveData}
			onCheckFirstName={onCheckFirstName}
			handleSubmit={handleSubmit}
			handleNextStep={handleNextStep}
		/>,
		<GetStartedStep5
			step={step}
			stepsCount={stepsCount}
			email={email}
			isEmailError={isEmailError}
			onSaveData={onSaveData}
			handleSubmit={handleSubmit}
		/>,
		<GetStartedStep6 />,
	];

	useEffect(() => {
		const body = document.getElementById('body');
		if (body) body.className = 'bg-blue-medium-dark';

		return () => {
			if (body) body.className = '';
		};
	}, []);

	useEffect(() => {
		// Total steps except the last step - the loader step.
		const totalSteps = steps.length - 1;
		// Calculate stepsCount for rendering indicators
		if (!!email && !isEmailError && (!!firstName || !!lastName)) {
			setStepsCount(totalSteps - 2);
		} else if (email && !isEmailError) {
			setStepsCount(totalSteps - 1);
		} else {
			setStepsCount(totalSteps);
		}
	}, [email, isEmailError]);

	if (gotoDashboard) return <Redirect to={ROUTE.dashboard} />;

	if (isLoader) return <Loader />;

	return (
		<LayoutStarted displayStoreButtons>
			{!googleToken && <GoogleReCaptcha onVerify={handleCaptchaVerify} />}
			{step !== undefined && <div className="intro-container">{steps[step]}</div>}
		</LayoutStarted>
	);
}

export default GetStartedSteps;
