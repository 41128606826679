export const AboutContent = {
	header: {
		title: 'About Safe-xplore AI',
	},
	body: (
		<>
			<p>
				Safe-xplore AI is a groundbreaking personal and travel risk rating application empowering
				its users with data and insights that can dramatically improve the quality of their personal
				risk awareness, judgment, and decision-making.
			</p>

			<p>
				Safe-xplore uses big data, analytics, along with human and Artificial Intelligence (AI) to
				deliver personalized and location-specific crime, accidents, and health risk rates. In
				addition to helping you better understand how the relative likelihood of three domains
				impacting your life safety, Safe-xplore informs you of your destination’s violent crime risk
				in relative terms to your home.
			</p>

			<p>
				Other/traditional travel risk and safety applications present qualitative risk ratings (with
				little or no statistical validity) for entire countries (where a city like Beverly Hills is
				in the same risk class as Baltimore) and without consideration of the travelers’
				demographics (as if the risk of accidents, medical emergencies, or victimization were the
				same for a 22YO male and a 65YO female traveler.)
			</p>

			<p>
				Want to make smarter, safer decisions for you and your loved ones? Most people have very
				foggy or inaccurate perceptions about the risks they face and what is likely to cause them
				harm - leading to fear, anxiety, and poor everyday decisions.
			</p>

			<p>
				Unlike most travel risk apps that have no value beyond once your journey ends, Safe-xplore
				is designed to help you ‘calibrate’ your risk judgement, and overcome the bias and
				disinformation about your personal safety and that of your loved ones every day, in your
				home town.
			</p>

			<p>
				Safe-xplore AI Edition leverages the same data and information developed for the{' '}
				<a href="https://www.safe-esteem.com/xplore" target="_blank" rel="noopener noreferrer">
					world’s most advanced personal and travel risk rating platform
				</a>{' '}
				used by protective intelligence teams for the world’s top CEOs and Ultra High Net Worth
				individuals.
			</p>

			<ul>
				<li>See personalized and hyperlocal risk scores </li>
				<li>Receive critical safety alerts, tips, and best practices</li>
				<li>
					Learn which crime, accidents, and health risks are more likely to impact your life, based
					on your age, sex, and location
				</li>
				<li>Compare your travel destination crime risks to your home</li>
				<li>Access the most current and personalized COVID-19 risk metrics available anywhere</li>
			</ul>

			<p>
				This app uses only accredited global data sources along with proprietary analyses and both
				human and Artificial Intelligence (AI) to present the risk in accurate, meaningful, and
				personalized forms.
			</p>

			<ul>
				<li>
					The World Health Organization{' '}
					<a href="https://www.who.int" target="_blank" rel="noopener noreferrer">
						https://www.who.int
					</a>
				</li>
				<li>
					The United Nations Statistical Commission{' '}
					<a href="https://unstats.un.org" target="_blank" rel="noopener noreferrer">
						https://unstats.un.org
					</a>
				</li>
				<li>
					Eurostat{' '}
					<a href="https://ec.europa.eu/eurostat" target="_blank" rel="noopener noreferrer">
						https://ec.europa.eu/eurostat
					</a>
				</li>
				<li>
					The Office for Economic Co-operation and Development (OECD){' '}
					<a href="https://www.oecd.org" target="_blank" rel="noopener noreferrer">
						https://www.oecd.org
					</a>
				</li>
				<li>
					The Institute for Health Metrics and Evaluation{' '}
					<a href="http://www.healthdata.org" target="_blank" rel="noopener noreferrer">
						http://www.healthdata.org
					</a>
				</li>
				<li>
					The Centers for Disease Control and Prevention{' '}
					<a href="https://www.cdc.gov" target="_blank" rel="noopener noreferrer">
						https://www.cdc.gov
					</a>
				</li>
				<li>
					United States Department of Health & Human Services{' '}
					<a href="https://healthdata.gov" target="_blank" rel="noopener noreferrer">
						https://healthdata.gov
					</a>
				</li>
				<li>
					The National Incident-Based Reporting System FBI-NIBRS (US){' '}
					<a
						href="https://www.fbi.gov/services/cjis/ucr/nibrs"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://www.fbi.gov/services/cjis/ucr/nibrs
					</a>
				</li>
				<li>
					The National Archive of Criminal Justice Data (US){' '}
					<a
						href="https://www.icpsr.umich.edu/web/pages/NACJD/index.html"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://www.icpsr.umich.edu/web/pages/NACJD/index.html
					</a>
				</li>
				<li>
					The UK Office of National Statistics:{' '}
					<a
						href="https://www.ons.gov.uk/peoplepopulationandcommunity/crimeandjustice"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://www.ons.gov.uk/peoplepopulationandcommunity/crimeandjustice
					</a>
				</li>
				<li>
					...Plus hundreds of national and subnational government agencies as well as proprietary
					Safe-esteem, Inc data and estimates.
				</li>
			</ul>

			<p>
				MEDICAL DISCLAIMER: THIS APP AND THE INFORMATION CONTAINED IN IT OR ON THE WEBSITES LINKED
				FROM IT IS FOR EDUCATIONAL PURPOSES ONLY AND DOES NOT GUARANTEE YOUR SAFETY - IT IS NOT A
				SUBSTITUTE FOR MEDICAL ADVICE FROM A DOCTOR OR HEALTH CARE PROVIDER. YOU MUST NEVER RELY ON
				ANY INFORMATION OBTAINED USING THIS APP FOR ANY DIAGNOSIS OR RECOMMENDATION FOR MEDICAL
				TREATMENT.
			</p>
		</>
	),
};
