export const BREAKPOINTS = {
	mobile: 320,
	phablet: 480,
	tablet: 768,
	desktop: 1024,
	bigdesktop: 1440,
};

export const DEVICES = {
	isDesktop: `(min-width: ${BREAKPOINTS.desktop}px)`,
	isMobile: `(max-width: ${BREAKPOINTS.tablet - 1}px)`,
};
