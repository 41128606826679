import get from 'get-value';
import moment from 'moment';

import { AGE_BUCKET, GENDER, GENDER_REVERSED, GENDERS } from '../store/DashboardRedux';

export const adaptDataForHomeRiskApi = (data) => {
	const {
		ageBucket,
		gender,
		location: {
			value: { latitude: lat, longitude: lng },
		},
		allRiskFactors,
	} = data;
	return {
		ageBucket,
		gender: GENDER[GENDERS[gender]],
		lat,
		lng,
		allRiskFactors,
	};
};

export const adaptDataForDestinationRiskApi = (data) => {
	const { ageBucket, gender, homeLocation, destinationLocation, email } = data;
	return {
		ageBucket,
		gender: GENDER[GENDERS[gender]],
		homeLat: homeLocation.value.latitude,
		homeLng: homeLocation.value.longitude,
		destLat: destinationLocation.value.latitude,
		destLng: destinationLocation.value.longitude,
		email,
	};
};

export const adaptDataForDestinationReportApi = (data) => {
	return data;
};

export const fakeAgeBucket = (group) => {
	if (group === '0-19') {
		return '15-19';
	}
	if (group === '15-19') {
		return '0-19';
	}
	return group;
};

export const adaptDataForProfileApi = (data) => {
	const email = get(data, 'email');
	const seFirstName = get(data, 'seFirstName');
	const seLastName = get(data, 'seLastName');
	const fbName = get(data, 'fbName');
	const fbAvatar = get(data, 'fbAvatar');
	const googleName = get(data, 'googleName');
	const googleAvatar = get(data, 'googleAvatar');
	const ageBucket = get(data, 'ageBucket');
	const gender = get(data, 'gender');
	const id = get(data, 'location.value.id');
	const name = get(data, 'location.value.name');
	const lat = get(data, 'location.value.latitude');
	const lng = get(data, 'location.value.longitude');
	const adapted = {};
	if (email) adapted.email = email;
	if (seFirstName) adapted.seFirstName = seFirstName;
	if (seLastName) adapted.seLastName = seLastName;
	if (fbName) adapted.fbName = fbName;
	if (fbAvatar) adapted.fbAvatar = fbAvatar;
	if (googleName) adapted.googleName = googleName;
	if (googleAvatar) adapted.googleAvatar = googleAvatar;
	if (ageBucket) adapted.ageBucket = fakeAgeBucket(ageBucket);
	if (gender >= 0) adapted.gender = GENDER[GENDERS[gender]];
	if (id) adapted.id = id;
	if (name) adapted.name = name;
	if (lat) adapted.lat = lat;
	if (lng) adapted.lng = lng;

	return adapted;
};

export const adaptFromProfileApi = (data) => {
	// const { id, name, email = '', gender, ageBucket, lng, lat, seLogin = '', seName = '', seAvatar = '', seFirstName = '', seLastName = '', passwordSet} = data;

	const {
		additionalWidgets,
		ageBucket,
		appleAvatar,
		appleId,
		appleName,
		appleRemovable,
		company,
		email = '',
		facebookRemovable,
		fbAvatar,
		fbId,
		fbName,
		gender,
		googleAvatar,
		googleId,
		googleName,
		googleRemovable,
		id,
		lat,
		lng,
		name,
		password,
		passwordSet,
		seAvatar,
		seFirstName = '',
		seLastName = '',
		seLogin = '',
		seName = '',
		unconfirmedEmail,
		userId,
	} = data;

	console.log('*** adapter >> adaptFromProfileApi >> data >>>', data);

	const adapted = { ...data };
	if (email) adapted.email = email;
	if (ageBucket) adapted.ageBucket = ageBucket;
	if (gender) adapted.gender = GENDER_REVERSED[gender];
	if (seLogin) adapted.seLogin = seLogin;
	adapted.seName = seName;
	if (seAvatar) adapted.seAvatar = seAvatar;
	adapted.seFirstName = seFirstName || '';
	adapted.seLastName = seLastName || '';
	adapted.passwordSet = passwordSet || '';
	if (lng || lat) {
		adapted.location = { value: {}, label: name };
		if (id) adapted.location.value.id = id;
		if (name) adapted.location.value.name = name;
		if (lng) adapted.location.value.longitude = lng;
		if (lat) adapted.location.value.latitude = lat;
	}

	console.log('*** adapter >> adaptFromProfileApi >>adapted >>>', adapted);
	return adapted;
};

export const adaptDataForHomeReportApi = (data) => {
	const {
		ageBucket,
		gender,
		location: {
			value: { id, name, latitude: lat, longitude: lng },
		},
		allRiskFactors,
	} = data;
	return {
		id,
		name,
		ageBucket: fakeAgeBucket(ageBucket),
		gender: GENDER[GENDERS[gender]],
		lat,
		lng,
		allRiskFactors,
	};
};

export const adaptDataFromHomeCovidTrendHistoryApi = (data) => {
	const newData = data.map((el) => ({
		date: el.date,
		case: el.avg_cases,
		death: el.avg_deaths,
	}));

	return newData;
};

export const adaptPersonalDataFromDestinationReportApi = (data) => {
	const { homeRequest, destinationRequest } = data;
	return {
		ageBucket: homeRequest.ageBucket,
		gender: GENDER_REVERSED[homeRequest.gender],
		location: {
			label: homeRequest.name,
			value: {
				id: homeRequest.id,
				latitude: homeRequest.lat,
				longitude: homeRequest.lng,
				name: homeRequest.name,
			},
		},
		destination: {
			label: destinationRequest.name,
			value: {
				id: destinationRequest.id,
				latitude: destinationRequest.lat,
				longitude: destinationRequest.lng,
				name: destinationRequest.name,
			},
		},
		email: destinationRequest.email,
	};
};

export const getAgeBucketFromBirthday = (birthday) => {
	if (birthday) {
		const years = parseInt(moment().diff(moment(birthday, ['MM/DD/YYYY']), 'years'), 10);
		for (let i = 0; i < AGE_BUCKET.length; i++) {
			const el = AGE_BUCKET[i];
			const [first, last] = el.split('-');
			if (years >= parseInt(first, 10) && years <= parseInt(last, 10)) {
				return el;
			}
		}
	}
	return '';
};
