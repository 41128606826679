import AppleLogin, { AppleLoginProps } from 'react-apple-login';

import Config from '../config';
import { localStore } from '../lib';

type ButtonLinkedAppleType = {
	renderBtn: JSX.Element;
};

export default function ButtonLinkedApple(props: ButtonLinkedAppleType) {
	const { renderBtn } = props;

	const renderAppleBtn: AppleLoginProps['render'] = ({ onClick }) => {
		return <div onClick={onClick}>{renderBtn}</div>;
	};

	const userToken = encodeURIComponent(`{"token": "${localStore.getToken()}"}`);

	return (
		<AppleLogin
			clientId={Config.appleAuthId}
			redirectURI={Config.appleRedirectURI}
			scope={Config.appleScope}
			state={userToken}
			responseType="code+id_token"
			responseMode="form_post"
			render={renderAppleBtn}
		/>
	);
}
