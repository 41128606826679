import { ROUTE } from '../../App';
import config from '../../config';

import { useOrtto } from '.';

const isPurchaseFromHearst = () => {
	const routeHearstReport = ROUTE.reportHomeDestinationForm;
	const currentUrl = window.location.pathname;
	const parts = currentUrl.split('/');
	const currentRoute = `/${parts?.[1]}/` || '';

	if (currentRoute && routeHearstReport.includes(currentRoute)) {
		return true;
	}
	return false;
};

export const useOrttoActivityClickAcademyTip = () => {
	const { track } = useOrtto();
	const activity = config?.ortto?.clickAcademyTip;
	return (description) => track(activity, '', description);
};

export const useOrttoActivityGotAcademyTip = () => {
	const { track } = useOrtto();
	const activity = config?.ortto?.gotAcademyTip;
	return (description) => track(activity, '', description);
};

export const useOrttoActivityCreateReport = () => {
	const { track } = useOrtto();
	const activity = config?.ortto?.createReport;
	return (userDestination, isHearst) => track(activity, userDestination, '', isHearst);
};

export const useOrttoActivityPurchaseReport = () => {
	const { track } = useOrtto();
	const activity = config?.ortto?.purchaseReport;
	const isHearst = isPurchaseFromHearst();
	return (userDestination) => track(activity, userDestination, '', isHearst);
};
