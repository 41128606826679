import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import cn from '../../lib/classNames';
import FormHeader from './FormHeader';

import Button from '../../components/Buttons/Button';

import { ROUTE } from '../../App';
import { Context, TextField } from '../../components';
import { localStore, validateEmail, validatePassword } from '../../lib';
import { api } from '../../sagas';

import styles from './LogInSignUpAuth.module.scss';

enum FormBtnText {
	getStarted = 'Get Started',
	logIn = 'LOG IN',
	createAccount = 'Create Account',
}

type LogInSignUpAuthType = {
	onForgot: (isForgot: boolean) => void;
	displayForgot?: boolean;
};

export default function LogInSignUpAuth(props: LogInSignUpAuthType) {
	const { onForgot, displayForgot } = props;

	const [emailValue, setEmailValue] = useState('');
	const [passwordValue, setPasswordValue] = useState('');
	const [isApiEmail, setIsApiEmail] = useState(false);
	const [isEmailError, setIsEmailError] = useState(false);
	const [isPasswordError, setIsPasswordError] = useState(false);
	const [passwordHelpText, setPasswordHelpText] = useState({ typeText: 'field-error', text: '' });
	const [isEmptyEmail, setIsEmptyEmail] = useState(true);
	const [renderDisplay, setRenderDisplay] = useState('');
	const [isCheckingApiEmail, setIsCheckingApiEmail] = useState(false);

	const refEmail = useRef<HTMLInputElement>();

	const refPassword = useRef<HTMLInputElement>();

	const history = useHistory();

	const { setUserProfileData, googleToken } = useContext(Context);

	const APIauthToken = () => {
		api.authToken({ name: emailValue, password: passwordValue }).then((response) => {
			if (!response.ok) {
				setPasswordHelpText((passwordHelpText) => ({
					...passwordHelpText,
					text: response.data.message,
				}));
			}

			if (response.data.unconfirmedEmail) {
				history.push(ROUTE.confirmEmail);
			}

			if (response.headers) {
				const apiToken = response.headers.authorization;
				api.apiInstance.setHeader('Authorization', apiToken);
				localStore.setToken(apiToken);
				localStore.setLoggedAs('SE');

				api.getProfile(googleToken).then((profileResponse) => {
					if (profileResponse.ok) {
						setUserProfileData({ ...profileResponse.data });
					}
				});
			}
		});
	};

	const checkApiEmail = () => {
		setIsCheckingApiEmail(true);
		api
			.checkEmail(encodeURIComponent(emailValue), googleToken)
			.then((response) => {
				if (response.ok) {
					const result = response.data.success;
					setIsApiEmail(result);
				}
				setIsCheckingApiEmail(false);
			})
			.catch(() => setIsCheckingApiEmail(false));
	};

	const emailVerification = () => {
		if (refEmail.current) {
			const email = refEmail.current.value;
			const result = validateEmail.validate({ email }).error;
			setEmailValue(email);
			setIsEmailError(!!result);
		}

		return null;
	};

	useEffect(() => {
		if (!isEmailError && emailValue) {
			checkApiEmail();
		}
	}, [emailValue, isEmptyEmail]);

	const passwordVerification = () => {
		const result = validatePassword.validate({ password: passwordValue }).error;
		setIsPasswordError(!!result);
		return !result;
	};

	const getStarted = () => {
		APIauthToken();
	};

	const createAccount = () => {
		APIauthToken();
	};

	const handleSendForgot = (data: string) => {
		emailVerification();
		if (!isEmailError) {
			api.passwordForgot(data).then((response) => {
				if (response.ok) {
					setRenderDisplay('renderThank');
				} else {
					setRenderDisplay('renderErrorEmail');
				}
			});
		}
	};

	const renderPasswordHelpText = () => {
		setPasswordHelpText((passwordHelpText) => ({ ...passwordHelpText, text: '' }));

		if (!isEmptyEmail) {
			if (isApiEmail) {
				if (passwordValue && isPasswordError) {
					setPasswordHelpText((passwordHelpText) => ({
						...passwordHelpText,
						typeText: 'field-error',
						text: 'Enter a valid password',
					}));
				} else {
					setPasswordHelpText((passwordHelpText) => ({ ...passwordHelpText, text: '' }));
				}
			} else if (passwordValue && isPasswordError) {
				setPasswordHelpText((passwordHelpText) => ({
					...passwordHelpText,
					typeText: 'field-error',
					text: 'Enter a valid password',
				}));
			} else {
				setPasswordHelpText((passwordHelpText) => ({
					...passwordHelpText,
					typeText: 'field-help',
					text: '6 characters min. Must contain capital and regular letters and a number.',
				}));
			}
		}
	};

	const handleSubmit = () => {
		if (!passwordValue) {
			setPasswordHelpText((passwordHelpText) => ({
				...passwordHelpText,
				typeText: 'field-error',
				text: 'Enter password',
			}));
		} else if (passwordVerification()) {
			if (emailValue && !isEmailError) {
				isApiEmail ? getStarted() : createAccount();
			} else {
				emailVerification();
				passwordVerification();
			}
		}
	};

	useEffect(() => {
		renderPasswordHelpText();
	}, [isEmptyEmail, isApiEmail, isPasswordError, passwordValue]);

	const handleKeyPress = (event: { key: string }) => {
		if (event.key === 'Enter' && !isEmptyEmail) {
			handleSubmit();
		}
	};

	const renderFormBtn = () => {
		if (isEmptyEmail) return FormBtnText.getStarted;

		if (isApiEmail) return FormBtnText.logIn;

		return FormBtnText.createAccount;
	};

	const renderAuth = () => {
		return (
			<div className={styles['form-inner']}>
				<div className={styles['field-wrap']}>
					<TextField
						inputRef={refEmail}
						label="Your email"
						onBlur={() => {
							emailVerification();
							setIsEmptyEmail(!refEmail.current?.value);
						}}
						onChange={() => {
							setIsEmailError(false);
						}}
					/>
					{isEmailError && <p className={styles['field-error']}>Enter a valid email</p>}
				</div>
				<div className={cn(styles['field-wrap'], styles['field-wrap-password'])}>
					<TextField
						inputRef={refPassword}
						label="Password"
						type="password"
						value={passwordValue}
						onChange={() => {
							setIsPasswordError(false);
							setPasswordHelpText((passwordHelpText) => ({ ...passwordHelpText, text: '' }));
							setPasswordValue(refPassword.current ? refPassword.current.value : '');
						}}
						onFocus={() => renderPasswordHelpText()}
						onKeyPress={handleKeyPress}
					/>

					{passwordHelpText.text && (
						<p className={cn(styles['field-help-text'], styles[passwordHelpText.typeText])}>
							{passwordHelpText.text}
						</p>
					)}

					{isApiEmail && (
						<div className={cn(styles['field-wrap'], styles['field-wrap-link-forgot'])}>
							<span onClick={() => onForgot(true)}>Forgot Password?</span>
						</div>
					)}
				</div>
				<div className={cn(styles['field-wrap'], styles['field-wrap-btn'])}>
					<Button
						disabled={isEmptyEmail || isCheckingApiEmail}
						variant="filled"
						color="secondary"
						onClick={() => {
							handleSubmit();
						}}
					>
						{isCheckingApiEmail ? (
							<span className={styles['pulse-text']}>Loading...</span>
						) : (
							renderFormBtn()
						)}
					</Button>
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (refEmail.current) {
			setEmailValue(refEmail.current.value);
			setIsEmptyEmail(!refEmail.current.value);
			refEmail.current.value !== '' && emailVerification();
		}
	}, [refEmail.current?.value]);

	const renderForgot = () => {
		if (renderDisplay === 'renderThank') {
			return renderThank();
		}
		if (renderDisplay === 'renderErrorEmail') {
			return renderErrorEmail();
		}

		return (
			<div className={styles['form-inner']}>
				<FormHeader
					title="Forgot Password"
					description="Please enter your email and we'll send you a temporary password. You can use it to enter	your account."
				/>
				<div className={styles['form-container']}>
					<div className={styles['field-wrap']}>
						<TextField
							inputRef={refEmail}
							label="Your email"
							onBlur={() => emailVerification()}
							onChange={() => {
								setIsEmailError(false);
								setIsEmptyEmail(!refEmail.current?.value);
							}}
						/>
						{isEmailError && <p className={styles['field-error']}>Enter a valid email</p>}
					</div>

					<div className={cn(styles['field-wrap'], styles['field-wrap-btn'])}>
						<Button
							disabled={isEmptyEmail}
							variant="filled"
							color="secondary"
							onClick={() => {
								handleSendForgot(emailValue);
							}}
						>
							SEND PASSWORD
						</Button>
						<Button
							className={styles['btn-cancel']}
							variant={'text'}
							color={'primary'}
							onClick={() => onForgot(false)}
						>
							CANCEL
						</Button>
					</div>
				</div>
			</div>
		);
	};

	const renderThank = () => {
		return (
			<div className={styles['form-inner']}>
				<FormHeader
					title="Success"
					description="We've sent the temporary password to your e-mail. We recommend you to go to your profile
					settings and set your new private password."
				/>
				<div className={cn(styles['field-wrap'], styles['field-wrap-btn'])}>
					<Button
						variant="filled"
						color="secondary"
						onClick={() => {
							onForgot(false);
						}}
					>
						BACK TO LOG IN
					</Button>
				</div>
			</div>
		);
	};

	const renderErrorEmail = () => {
		return (
			<div className={styles['form-inner']}>
				<FormHeader
					title="Ooops!"
					description="We couldn't find this email in our data base. Please check if you entered a correct
					email."
				/>
				<div className={cn(styles['field-wrap'], styles['field-wrap-btn'])}>
					<Button
						variant="filled"
						color="secondary"
						onClick={() => {
							setRenderDisplay('');
						}}
					>
						TRY AGAIN
					</Button>

					<Button
						className={styles['btn-cancel']}
						variant={'text'}
						color={'primary'}
						onClick={() => onForgot(false)}
					>
						CANCEL
					</Button>
				</div>
			</div>
		);
	};

	const renderForm = () => {
		if (displayForgot) {
			return renderForgot();
		}
		return renderAuth();
	};

	return renderForm();
}
