import cn from '../lib/classNames';

import styles from './SimilarCities.module.scss';

type SimilarCitiesType = {
	location?: string;
	locationTitle: string;
	riskValue: number;
};

export default function SimilarCities(props: SimilarCitiesType) {
	const { location, locationTitle, riskValue } = props;

	const classRiskColor = riskValue < 10 ? `heatmap-0${riskValue}` : `heatmap-${riskValue}`;

	return (
		<div className={cn(styles['similar-cities'], 'similar-cities', classRiskColor)}>
			<div className={styles['content-wrap']}>
				<div className={styles['left-block']}>
					<span className={locationTitle === 'Home' ? 'material-icons-round' : 'material-icons'}>
						{locationTitle === 'Home' ? 'home' : 'location_on'}
					</span>
				</div>
				<div className={styles['right-block']}>
					<div className={styles['risk-rating']}>
						<div className={styles['risk-rating-data']}>
							{riskValue > 0 ? riskValue : 'N.A.'}
							{riskValue > 0 ? <span>/11</span> : ''}
						</div>
						<div className={styles['risk-rating-title']}>Risk Rating</div>
					</div>
				</div>
			</div>
			<div className={styles.footer}>
				<div className={styles.location} title={location}>
					{location}
				</div>
				<div className={styles['location-title']}>{locationTitle}</div>
			</div>
		</div>
	);
}
