import get from 'get-value';

import { ScoresItemType } from '../../../api/types/apiGetReport';
import DataLayer from '../../../components/DataLayer';
import { getRoundingByCondition } from '../../../lib';
import cn from '../../../lib/classNames';

import ContributorLine from './ContributorLine';
import RiskScoreDinamics from './RiskScoreDinamics';
import RiskScoreLine from './RiskScoreLine';
import { destinationIconPath, homeIconPath } from './SvgFactory';

import styles from './RiskScoresEstimates.module.scss';

export const RISK_COLOR = {
	CRIME: 'rgb(255, 0, 0)',
	ACCIDENTS: 'rgb(126, 242, 243)',
	HEALTH: '#FFCB00',
};

type RiskScoresEstimatesType = {
	data?: ScoresItemType | null;
	risk: string;
	fullView?: boolean;
};

export default function RiskScoresEstimates(props: RiskScoresEstimatesType) {
	const { data, risk, fullView = false } = props;

	function getRiskColor(riskName: string) {
		if (riskName === 'crime') return RISK_COLOR.CRIME;
		if (riskName === 'accidents') return RISK_COLOR.ACCIDENTS;
		if (riskName === 'health') return RISK_COLOR.HEALTH;
		return null;
	}

	const renderRiskScore = () => {
		if (data && risk) {
			const { diff, homeScore, destinationScore, destinationContributors } = data;

			const homeScoreRound = getRoundingByCondition(homeScore);
			const destinationScoreRound = getRoundingByCondition(destinationScore);

			const nonCommunicableDestinationContributors = get(
				data,
				'nonCommunicableDestinationContributors',
				{ default: [] },
			);

			let contributorsTitle = '';
			let contributorsTitle1 = '';
			let contributorsTitle2 = '';
			let title = '';

			if (risk === 'crime') {
				contributorsTitle = 'Crime Risk Drivers';
				title = 'Violent Crime Risks';
			} else if (risk === 'accidents') {
				contributorsTitle = 'Accidents Risk Drivers';
				title = 'Accidents Risk';
			} else if (risk === 'health') {
				contributorsTitle = 'Health Risk Drivers';
				contributorsTitle1 = 'Non-communicable Drivers';
				contributorsTitle2 = 'Communicable Drivers';
				title = 'Health Risk';
			}

			let scroreDiff = null;
			const diffAbs = Math.abs(diff);
			if (diffAbs < 5)
				scroreDiff = (
					<RiskScoreDinamics
						className="no-significant-change"
						value={getRoundingByCondition(diff)}
						label="Similar"
					/>
				);
			else if (diff >= 0)
				scroreDiff = (
					<RiskScoreDinamics
						className="increase"
						value={getRoundingByCondition(diff)}
						label="Increase"
					/>
				);
			else
				scroreDiff = (
					<RiskScoreDinamics
						className="decrease"
						value={getRoundingByCondition(diff)}
						label="Decrease"
					/>
				);

			const contributorsLabels = Object.keys(destinationContributors).splice(0, 3);
			const nonCommunicableContributorsLabels = Object.keys(
				nonCommunicableDestinationContributors,
			).splice(0, 3);

			if (fullView) {
				return [
					<div className={styles['risks-data-top']} key="RiskScoreLine">
						<h4 className={styles.title}>{title}</h4>
						<div className={styles['row-1']}>
							<div key="diff" className={cn(styles['increase-decrease'], styles['col-1'])}>
								{scroreDiff}
							</div>
							<div
								key="score-line-contributors1"
								className={cn(styles['risk-score-lines'], styles['score-line-contributors'])}
							>
								<div className={styles['risk-score-line-wrap']}>
									<RiskScoreLine
										value={homeScoreRound}
										label="Residence"
										centerIcon={homeIconPath}
										color={getRiskColor(risk)}
									/>
								</div>
								<div className={styles['risk-score-line-wrap']}>
									<RiskScoreLine
										value={destinationScoreRound}
										label="Destination"
										centerIcon={destinationIconPath}
										color={getRiskColor(risk)}
									/>
								</div>
							</div>
						</div>
					</div>,
					<div
						className={cn(styles.drivers, styles['communicable-drivers'])}
						key="nonCommunicableContributorsLabels"
					>
						<div className={styles.col}>
							<p className={styles['risk-drivers-title']}>{contributorsTitle1}</p>
							{nonCommunicableContributorsLabels.map((el) => (
								<div className={styles['risk-drivers-data-narrow']} key={el}>
									<ContributorLine
										value={nonCommunicableDestinationContributors[el]}
										width={130}
										label={`${nonCommunicableDestinationContributors[el]}% ${el}`}
									/>
								</div>
							))}
						</div>

						<div key="score-line-contributors2" className={styles.col}>
							<div>
								<p className={styles['risk-drivers-title']}>{contributorsTitle2}</p>
								{contributorsLabels.map((el) => (
									<div className={styles['risk-drivers-data-narrow']} key={el}>
										<ContributorLine
											value={destinationContributors[el]}
											width={130}
											label={`${destinationContributors[el]}% ${el}`}
										/>
									</div>
								))}
							</div>
						</div>
					</div>,
				];
			}
			return [
				<div className={styles['risks-data-top']} key="RiskScore">
					<h4 className={styles.title}>{title}</h4>
					<div className={styles['row-1']}>
						<div key="diff" className={cn(styles['increase-decrease'], styles['col-1'])}>
							{scroreDiff}
						</div>
						<div key="scoreLines" className={styles['risk-score-lines']}>
							<div className={styles['risk-score-line-wrap']}>
								<RiskScoreLine
									value={homeScoreRound}
									label="Residence"
									centerIcon={homeIconPath}
									color={getRiskColor(risk)}
								/>
							</div>
							<div className={styles['risk-score-line-wrap']}>
								<RiskScoreLine
									value={destinationScoreRound}
									label="Destination"
									centerIcon={destinationIconPath}
									color={getRiskColor(risk)}
								/>
							</div>
						</div>
					</div>
				</div>,
				<div key="contributors" className={styles.drivers}>
					<p className={styles['risk-drivers-title']}>{contributorsTitle}</p>
					{contributorsLabels.map((el) => (
						<div className={styles['risk-drivers-data']} key={el}>
							<ContributorLine
								value={destinationContributors[el]}
								label={`${destinationContributors[el]}% ${el}`}
							/>
						</div>
					))}
				</div>,
			];
		}
		return null;
	};

	return (
		<div className={styles['risk-scores-container']}>
			<div className="data-layer-container">
				<DataLayer numData={data?.maxLevel} />
			</div>
			{renderRiskScore()}
		</div>
	);
}
