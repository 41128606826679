type DownloadType = {
	className?: string;
	width?: string;
	height?: string;
	color?: string;
};

function Download(props: DownloadType) {
	const { className = '', width = '24', height = '24', color = '#7D8C97' } = props;
	return (
		<svg
			className={`download ${className}`}
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.0004 3.12402L12.0004 16.1775"
				stroke={color}
				strokeWidth="1.64"
				strokeLinecap="round"
			/>
			<path
				d="M12 16.1773L7.20453 11.3818"
				stroke={color}
				strokeWidth="1.64"
				strokeLinecap="round"
			/>
			<path
				d="M12 16.1773L16.7955 11.3818"
				stroke={color}
				strokeWidth="1.64"
				strokeLinecap="round"
			/>
			<path
				d="M20.7998 16.7998C20.7998 16.9784 20.7998 17.9035 20.7998 18.8214C20.7998 19.926 19.9044 20.8214 18.7998 20.8214L5.19981 20.8214C4.09524 20.8214 3.19981 19.9262 3.19981 18.8216C3.19981 18.1448 3.19981 17.4073 3.19981 16.7998"
				stroke={color}
				strokeWidth="1.64"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Download;
