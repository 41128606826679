import { useState } from 'react';
import ReactPlayer from 'react-player';

import { Loader } from '../../../components';
import cn from '../../../lib/classNames';

import styles from './RiskHighlightsIndicatorVideo.module.scss';

type RiskHighlightsIndicatorVideo = {
	videoUrl: string | null;
	imageUrl: string | null;
};

function RiskHighlightsIndicatorVideo(props: RiskHighlightsIndicatorVideo) {
	const { videoUrl = '', imageUrl } = props;

	const [isLoadVideo, setIsLoadVideo] = useState(true);
	const [isPlay, setIsPlay] = useState(false);

	const playButton = isLoadVideo ? (
		<Loader size="small" />
	) : (
		<button className={styles['custom-play-button']} onClick={() => setIsPlay(true)} type="button">
			<span className={cn(styles['play-arrow'], 'material-icons-outlined')}>play_arrow</span>
		</button>
	);

	const renderPreviewVideo = () => {
		return (
			<div
				className={cn(styles['react-player-preview-video'], {
					[styles['react-player-no-video']]: !videoUrl,
				})}
				style={{ backgroundImage: `url(${imageUrl}` }}
			>
				{videoUrl && playButton}
			</div>
		);
	};

	const renderVideo = () => {
		return (
			!!videoUrl && (
				<ReactPlayer
					url={videoUrl}
					playing={isPlay}
					controls
					width="100%"
					height="100%"
					playIcon={playButton}
					onReady={() => setIsLoadVideo(false)}
				/>
			)
		);
	};

	return (
		<div className={styles['react-player-wrapper']}>
			{!isPlay && renderPreviewVideo()}
			{renderVideo()}
		</div>
	);
}

export default RiskHighlightsIndicatorVideo;
