/**
 * Compose className depending on input data
 * The function is a simple analogue of `classnames` or `clsx` packages
 * @param  {...(string|string[]|Record<string,boolean>|null|undefined)} args
 * @returns {string}
 */
export default function cn(...args) {
	let result = [];

	args.forEach((arg) => {
		if (!arg) {
			return;
		}

		if (typeof arg === 'string') {
			result.push(arg);
			return;
		}

		if (Array.isArray(arg)) {
			result = result.concat(arg);
			return;
		}

		const entries = Object.entries(arg);
		if (entries.length) {
			entries.forEach(([className, flag]) => {
				if (flag) {
					result.push(className);
				}
			});
		}
	});

	return result.join(' ');
}
