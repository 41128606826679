import { useState } from 'react';

import { AiReportType } from '../../../../api/types/apiGetReport';
import cn from '../../../../lib/classNames';
import { DataPlaceholderType } from '../../RiskSafetySummaryDemo/RiskSafetySummaryDemo';
import { MAP_GROUP } from '../RiskSafetySummary';
import TabButton from '../TabButton';

import HighRisk from './HighRisk';
import Hospitals from './Hospitals';
import LocationsDemo from './LocationsDemo';
import SafestPlaces from './SafestPlaces';

import styles from './Locations.module.scss';

export type reportVariants = 'demo';

export type LocationsType = {
	className?: string;
	data?: AiReportType;
	dataDemo?: AiReportType;
	dataPlaceholder?: DataPlaceholderType;
	variant?: reportVariants;
};

export default function Locations(props: LocationsType) {
	const { className, data, dataDemo, dataPlaceholder, variant } = props;

	const [tab, setTab] = useState(MAP_GROUP[1].field);

	const renderTabButton = MAP_GROUP.map((e) => {
		if (e.field !== 'all') {
			return (
				<TabButton
					key={e.btnText}
					className={cn(styles['tab-button'], `btn-${e.field}`)}
					iconBefore={
						e.icon ? (
							<span className={`material-symbols-rounded btn-icon icon-${e.icon}`}>{e.icon}</span>
						) : undefined
					}
					onClick={() => setTab(e.field)}
					active={tab === e.field}
				>
					{e.btnText}
				</TabButton>
			);
		}
		return null;
	});

	const renderHospitals =
		variant === 'demo' ? (
			<LocationsDemo
				headerDescription="Recommended hospitals that are more likely to offer 24-hour emergency/critical care and English-speaking staff. It is strongly advised to confirm these options prior to or immediately upon arrival with your local hosts or embassy:"
				icon="emergency"
				dataDemo={dataDemo?.hospitals.slice(0, 2)}
				dataPlaceholder={dataPlaceholder?.hospitals}
			/>
		) : (
			data && <Hospitals data={MAP_GROUP[1].getList(data)} />
		);

	const renderSafestPlaces =
		variant === 'demo' ? (
			<LocationsDemo
				headerDescription="Areas that are generally considered safe, relative to the area, and have lower reported incidents of crime:"
				icon="encrypted"
				dataDemo={dataDemo?.areas.safest_areas.slice(0, 2)}
				dataPlaceholder={dataPlaceholder?.safestPlaces}
			/>
		) : (
			data && (
				<SafestPlaces
					data={MAP_GROUP[2].getList(data)}
					helpMessage={MAP_GROUP[2].helpMessage(data)}
				/>
			)
		);

	const renderHighRisk =
		variant === 'demo' ? (
			<LocationsDemo
				headerDescription="Areas that are known to be of higher risk and may require additional caution:"
				icon="dangerous"
				dataDemo={dataDemo?.areas.high_risk_locations.slice(0, 2)}
				dataPlaceholder={dataPlaceholder?.highRisk}
			/>
		) : (
			data && (
				<HighRisk data={MAP_GROUP[3].getList(data)} helpMessage={MAP_GROUP[3].helpMessage(data)} />
			)
		);

	return (
		<section className={cn(styles.locations, className)}>
			<header className={styles.header}>
				<div className={styles['btn-wrap']}>{renderTabButton}</div>
			</header>
			<div className={styles['content-wrap']}>
				{tab === MAP_GROUP[1].field && renderHospitals}
				{tab === MAP_GROUP[2].field && renderSafestPlaces}
				{tab === MAP_GROUP[3].field && renderHighRisk}
			</div>
		</section>
	);
}
