import { useEffect, useRef, useState } from 'react';

import { PersonalAlertsType } from '../../../api/types/apiPersonalAlerts';
import { EVENT, EventManager, notificationsClasses } from '../../../lib';
import { api } from '../../../sagas';
import { Questions } from '../../Questionnaire';
import Tips from '../Tips';

// TODO: This line is needed for testing.
// import { alertNotificationsMock } from './alertNotificationsMock';
import './Alerts.scss';

type AlertsType = {
	googleToken?: string;
	refreshComponent?: Date;
};

export type QuestionType = {
	questionCode: string;
	questionNum: number;
	questionId: number;
};

function Alerts(props: AlertsType) {
	const { googleToken, refreshComponent } = props;

	const [notifications, setNotifications] = useState<PersonalAlertsType[]>([]);
	const [notificationsFetching, setNotificationsfetching] = useState(false);
	const [apiNotificationsSuccessful, setApiNotificationsSuccessful] = useState(false);
	const [question, setQuestion] = useState<QuestionType | null>();

	const loadNotification = () => {
		if (!notificationsFetching) {
			setNotificationsfetching(true);
			api.getPersonalAlerts(googleToken).then((response) => {
				if (response.ok) {
					setNotifications(response.data.reverse());
					setApiNotificationsSuccessful(true);
				}
				setNotificationsfetching(false);
			});
		}
		// TODO: This line is needed for testing.
		// setNotifications(alertNotificationsMock);
	};

	const stackedCards = useRef<HTMLDivElement>(null);

	const positionAlerts = () => {
		if (stackedCards?.current?.children) {
			if (stackedCards.current.children.length > 1) {
				stackedCards.current.style.cssText = 'height: auto;';
			} else if (stackedCards.current.children.length === 1) {
				if (stackedCards.current.lastChild instanceof HTMLElement) {
					const currentAlertHeight = stackedCards.current.lastChild.clientHeight;
					stackedCards.current.style.cssText = `height: ${currentAlertHeight}px; transition: .3s;`;
				}
			} else if (apiNotificationsSuccessful && stackedCards.current.children.length === 0) {
				stackedCards.current.style.cssText = `height: 1px; margin-top: -2rem; transition: .3s;`;
			}
		}
	};

	useEffect(() => {
		if (googleToken) {
			loadNotification();
		}
	}, [googleToken, refreshComponent]);

	useEffect(() => {
		setTimeout(() => {
			positionAlerts();
		}, 300);
	}, [notifications, apiNotificationsSuccessful]);

	useEffect(() => {
		window.addEventListener('resize', positionAlerts);

		return () => {
			window.removeEventListener('resize', positionAlerts);
		};
	}, []);

	const onClickRead = (id: number, activeCard: HTMLElement) => {
		positionAlerts();

		activeCard.classList.remove('card--added');
		setTimeout(() => {
			requestAnimationFrame(() => {
				activeCard.remove();
			});
			setNotifications((prev) => prev.filter((e) => id !== e.id));
		}, 400);

		api.readNotification(id, googleToken).then((response) => {
			if (response.ok) {
				EventManager.publish(EVENT.NOTIFICATIONS_QUIZ_QUESTION, {});
				EventManager.publish(EVENT.NOTIFICATIONS_STORY_RELOAD);
			}
		});
	};

	const onClickQuestion = (id: number, questionCode: string) => {
		const match = questionCode.match(/\.([^']+)-/);
		if (match) {
			const questionNum = +match[1];
			setQuestion({ questionCode, questionNum, questionId: id });
		}
	};

	const onClickRemind = (id: number, questionCode: string) => {
		api.quiz(id, {}).then((response) => {
			if (response.ok) {
				if (response.data.success)
					console.log('*** Remind: ', id, questionCode, response.data.success);
			}
		});
	};

	const onClickNotificationQuestion = (params: { id: number; questionCode: string }) => {
		onClickQuestion(params.id, params.questionCode);
	};

	useEffect(() => {
		const eventNoteQuiz = EventManager.subscribe(
			EVENT.NOTIFICATIONS_QUIZ_QUESTION,
			onClickNotificationQuestion,
		);

		return () => {
			EventManager.unsubscribe(eventNoteQuiz);
		};
	}, []);

	const handleRequireAnswer = () => {
		for (let i = 0; i < notifications.length; i++) {
			if (
				notifications[i].notificationClass === notificationsClasses.quizQuestion &&
				notifications[i].obligatory
			) {
				setTimeout(() => {
					onClickQuestion(notifications[i].id, notifications[i].questionCode);
				}, 1000);
				break;
			}
		}
	};

	useEffect(() => {
		handleRequireAnswer();
	}, [notifications]);

	const notificationsFiltered = notifications.filter((e) => !e.obligatory);

	const renderNotification =
		!!notificationsFiltered.length &&
		notificationsFiltered.map((e) => {
			return (
				<Tips
					key={e.id}
					variant="notification"
					typeIcon="alertIcon"
					id={e.id}
					title={e.title}
					message={e.message}
					url={e.url}
					notificationClass={e.notificationClass}
					questionCode={e.questionCode}
					button
					onClickRead={onClickRead}
					onClickQuestion={onClickQuestion}
					onClickRemind={onClickRemind}
				/>
			);
		});

	return (
		<>
			<div
				ref={stackedCards}
				className={`stacked-cards ${!notifications.length ? 'stacked-cards-empty' : ''}`}
			>
				{renderNotification}
			</div>
			{!!question?.questionCode && <Questions question={question} close={setQuestion} />}
		</>
	);
}

export default Alerts;
