import LinkBox from '../../../components/Buttons/LinkBox';

import styles from './Footer.module.scss';

export default function Footer(props: { className: string }) {
	const { className } = props;

	return (
		<div className={className}>
			<div className={styles['footer-info-title']}>About VICRI</div>
			<p className={styles['footer-info-text']}>
				This rating and the ViCRI index represent the most accurate estimation efforts available
				today about the specific risk of serious violent crime at the urban level on an
				international scale (measures homicide, aggravated assault, robbery, and rape risks.)
			</p>

			<LinkBox href="https://violenceriskindex.org/" iconBefore="open_in_new">
				Learn about the ViCRI for global cities
			</LinkBox>
		</div>
	);
}
