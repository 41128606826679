import { SelectLocation } from '../../../components';
import Button from '../../../components/Buttons/Button';
import { LocationDataType } from '../../../types/LocationData';
import Header from '../Header';
import IndicatorsDots from '../IndicatorsDots';

type GetStartedStep3Type = {
	step?: number;
	stepsCount: number;
	place?: LocationDataType;
	email: string;
	isEmailError: string | boolean;
	firstName: string;
	handleSelectPlace: (place: LocationDataType) => void;
	handleNextStep: (_num: number) => void;
	handleSubmit: () => void;
};

export default function GetStartedStep3(props: GetStartedStep3Type) {
	const {
		step,
		stepsCount,
		place,
		email,
		isEmailError,
		firstName,
		handleSelectPlace,
		handleNextStep,
		handleSubmit,
	} = props;

	return (
		<div className="get-started-steps-container">
			<IndicatorsDots currentDot={step} stepsCount={stepsCount} />
			<Header
				title="What is your current location?"
				description="Please select your current location to continue"
			/>
			<div className="fields-container-pos-center fields-container">
				<SelectLocation types="place,locality" place={place} onSelect={handleSelectPlace} />
			</div>
			<div className="block-btn">
				{email && !isEmailError && firstName ? (
					<Button variant="filled" color="primary" onClick={handleSubmit} disabled={!place}>
						Calculate
					</Button>
				) : (
					<Button
						variant="filled"
						color="primary"
						onClick={() => {
							if (place) handleNextStep(4);
						}}
						disabled={!place}
					>
						NEXT
					</Button>
				)}
			</div>
		</div>
	);
}
