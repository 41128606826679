type IndicatorsDotsType = {
	currentDot?: number;
	stepsCount: number;
};

export default function IndicatorsDots(props: IndicatorsDotsType) {
	const { currentDot, stepsCount } = props;
	return (
		<ul className="indicators-dots">
			{[...Array(stepsCount)].map((_, i) => (
				<li key={i} className={currentDot === i ? 'active' : ''} />
			))}
		</ul>
	);
}
