import { useState } from 'react';

import { AccordionCustom } from '../../../components';

import styles from './KeyConcept.module.scss';

const AccordionData = [
	{
		headerContent: 'Safe-xplore Analysis Introduction',
		bodyContent: (
			<>
				<p className="description">
					Safe-xplore is the world’s first Travel Risk Intelligence (TRI) platform offering a highly
					personalized and quantified analysis of a traveler’s risks at any destination around the
					globe, accounting for the full spectrum of life and limb risks. By contrast, legacy TRI
					products apply qualitative and subjective methodologies, focusing on recent events without
					reference to the particulars of the traveler. Safe-xplore is different. It combines big
					data and analytics with world-class subject matter expertise and human intelligence to
					quantify the likelihood of incidents to the traveler. Safe-xplore generates a relative
					risk comparison between the traveler’s routine environment (home) and the planned
					destination - anywhere in the world. As such, Safe-xplore delivers foundational
					decision-support information for travel planners and executive protection analysts and
					agents which can augment existing legacy services. Key takeaways:
				</p>
				<ul className={styles.list}>
					<li className={styles.item}>
						Risk scores are transformed from actual underlying data to a 0 to 100 scale versus
						averaged, nominal or ordinal scales used by legacy products. (See this for the dangers
						of relying on ordinal scales.)
					</li>
					<li className={styles.item}>
						Risk score calculations account for the effects of being a visitor versus being a local
						at a specific geography.
					</li>
					<li className={styles.item}>
						Reports include the full spectrum of personal risks aggregated into three domains,
						described more fully below.
					</li>
					<li className={styles.item}>
						Data is constantly being added and updated, with city-level accuracy for nearly 200 U.S.
						and 180 international destinations.
					</li>
				</ul>
			</>
		),
	},

	{
		headerContent: 'Risk Scoring Methodology',
		bodyContent: (
			<>
				<p className="description">
					Using proprietary algorithms, Safe-xplore accounts for local risk data including injuries,
					victimization, morbidity and mortality. The traveler’s personal attributes and behaviors
					are further incorporated to assess the extent of their real risks. Key takeaways include:
				</p>
				<ul className={styles.list}>
					<li className={styles.item}>
						Scores are generated on a logarithmic scale, similar to the Richter scale used to
						measure the magnitude of earthquakes. Consider, for example, that a score increase from
						20 to 24 indicates a 45% increase in the underlying risk rates. Each of our reports
						includes a reference chart to facilitate the interpretation of the data.
					</li>
					<li className={styles.item}>
						Scores are transformed back to underlying risk rate estimates to generate a comparison
						of likelihood of incidents at destination and at home.
					</li>
					<li className={styles.item}>
						Rates per 100,000 relative to scores should be interpreted as indicative rather than
						literal, as they include weighted averaging of mortality (i.e. homicide) and morbidity
						(i.e. victimization).
					</li>
					<li className={styles.item}>
						Relative risk weighting allows the traveler or the protective security analyst to
						calibrate their judgment about the likelihood of incidents by type of risk, and
						prioritize mitigation efforts accordingly.
					</li>
				</ul>
			</>
		),
	},

	{
		headerContent: 'Relative Risk Weighting',
		bodyContent: (
			<p className="description">
				In addition to measuring the change in risks across all three risk domains, Safe-xplore
				provides insight into the relative importance of the risk domains at home and at the travel
				destination. In this way, the report provides insight into which risk is the most
				significant when the traveler is at his destination.
			</p>
		),
	},

	{
		headerContent: 'Disclaimer, Limitations of Liability',
		bodyContent: (
			<>
				<p className="description">
					A. Release from Claims. User releases SAFE-ESTEEM from claims, demands, and damages of
					every kind and nature arising out of or in any way connected with information provided at
					this Website.
				</p>
				<p className="description">
					B. Disclaimer of Warranties. SAFE-ESTEEM provides this Website 'AS IS.' SAFE-ESTEEM MAKES
					NO REPRESENTATION OR WARRANTY OF ANY KIND RELATING IN ANY WAY TO THE WEBSITE, INCLUDING
					ANY USER IDENTITY OR CONTENT. SAFE-ESTEEM DISCLAIMS TO THE MAXIMUM EXTENT PERMITTED BY
					LAW, ANY AND ALL SUCH REPRESENTATIONS AND WARRANTIES. SAFE-ESTEEM SPECIFICALLY DISCLAIMS
					TO THE MAXIMUM EXTENT PERMITTED BY LAW ANY AND ALL (i) WARRANTIES OF MERCHANTABILITY OR
					FITNESS FOR A PARTICULAR PURPOSE, (ii) WARRANTIES AGAINST INFRINGEMENT OF ANY THIRD PARTY
					INTELLECTUAL PROPERTY OR PROPRIETARY RIGHTS, (iii) WARRANTIES RELATING TO DELAYS,
					INTERRUPTIONS, ERRORS, OR OMISSIONS IN THE WEBSITE, OR ANY PART THEREOF, (iv) WARRANTIES
					RELATING TO THE TRANSMISSION OR DELIVERY OF THE WEBSITE, AND (v) WARRANTIES OTHERWISE
					RELATING TO DATA, INFORMATION, PERFORMANCE, NONPERFORMANCE, OR OTHER ACTS OR OMISSIONS BY
					SAFE-ESTEEM OR ANY THIRD PARTY. FURTHER, SAFE-ESTEEM DOES NOT WARRANT THAT THE WEBSITE OR
					THE TECHNOLOGY OR INTELLECTUAL PROPERTY DESCRIBED IN THE WEBSITE WILL MEET USERS NEEDS OR
					REQUIREMENTS OR THE NEEDS OR REQUIREMENTS OF ANY OTHER PERSON OR ENTITY.
				</p>
				<p className="description">
					C. Limitation of Remedy. NEITHER SAFE-ESTEEM NOR ANY THIRD PARTY SHALL BE LIABLE TO USERS
					OR ANY OTHER PERSON FOR ANY REMEDY, LIABILITY, DAMAGES, COSTS, OR LOSSES RELATING TO THE
					WEBSITE OR ANY CONTENT, WHETHER ARISING UNDER THESE TERMS OR ANY APPLICABLE LAW.
				</p>
				<p className="description">
					D. Exclusion of Consequential Damages. IN NO EVENT SHALL SAFE-ESTEEM OR ANY THIRD PARTY BE
					LIABLE TO USERS OR ANY OTHER PERSON FOR CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY,
					PUNITIVE, OR INDIRECT DAMAGES (INCLUDING LOST PROFITS, LOSS OF BUSINESS OR DATA, BUSINESS
					INTERRUPTION, TRADING LOSSES, AND DAMAGES THAT RESULT FROM INACCURACY OF THE INFORMATION
					OR INCONVENIENCE, DELAY, OR LOSS OF THE USE OF THE SERVICE) ARISING UNDER OR IN ANY WAY
					RELATED TO THE WEBSITE OR ANY OTHER CONTENT, EVEN IF SAFE-ESTEEM OR ANY THIRD PARTY HAS
					BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES.
				</p>
			</>
		),
	},

	{
		headerContent: 'Indemnification',
		bodyContent: (
			<p className="description">
				Users agree to defend, indemnify and hold harmless SAFE-ESTEEM and its subsidiaries,
				affiliates, officers, agents, partners and employees from all liabilities or claims of any
				third party arising out of Users violation of this Agreement.
			</p>
		),
	},
];

function KeyConcept() {
	const [expanded, setExpanded] = useState('panel-1');

	const renderAccordions = () => {
		return AccordionData.map((e, idx) => {
			const num = idx + 1;
			const panel = `panel-${num}`;
			return (
				<div className={styles['accordion-item']} key={panel}>
					<AccordionCustom
						panel={panel}
						panelContent={`panel-content-${num}`}
						expanded={expanded}
						headerText={e.headerContent}
						setExpanded={setExpanded}
					>
						{e.bodyContent}
					</AccordionCustom>
				</div>
			);
		});
	};

	return <div className={styles['key-concept-wrap-inner']}>{renderAccordions()}</div>;
}

export default KeyConcept;
