import { useEffect, useRef, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import alertsIconAlert from '../../../images/alerts_icon_alert.svg';
import alertsIconInfo from '../../../images/alerts_icon_info.svg';
import alertsIconInfoYellow from '../../../images/alerts_icon_info_yellow.svg';
import alertsIconQuiz from '../../../images/alerts_icon_question_violet.svg';
import alertsIconViolet from '../../../images/alerts_icon_violet.svg';
import useMediaQuery from '../../hooks/MediaQuery';
import { formaterDate, notificationsClasses } from '../../lib';

import './NotificationStoryItem.scss';

function NotificationStoryItem(props) {
	const {
		id,
		date,
		title,
		message,
		notificationClass,
		onRef,
		read,
		url,
		questionCode,
		getNotificationDeleteIds,
		onClickQuestion,
		close,
	} = props;

	const ref = useRef();

	const [overflow, setOverflow] = useState(true);

	const notificationTypeArr = [
		{
			icon: alertsIconViolet,
			color: 'bg-violet',
		},
		{
			icon: alertsIconInfo,
			color: 'bg-green',
		},
		{
			icon: alertsIconAlert,
			color: 'bg-red',
		},
		{
			icon: alertsIconInfoYellow,
			color: 'bg-yellow',
		},
		{
			icon: alertsIconQuiz,
			color: 'bg-violet',
		},
	];

	const isMobile = useMediaQuery('(max-width: 767px)');

	const notificationType = (notificationClass) => {
		if (notificationClass === notificationsClasses.advise) {
			return notificationTypeArr[3];
		}
		if (notificationClass === notificationsClasses.alert) {
			return notificationTypeArr[2];
		}
		if (notificationClass === notificationsClasses.quizQuestion) {
			return notificationTypeArr[4];
		}
		return notificationTypeArr[0];
	};

	useEffect(() => {
		if (onRef) onRef({ id, ref: ref.current });
	}, [read]);

	return (
		<div
			ref={ref}
			onClick={() => setOverflow(!overflow)}
			className={`notification-story-item-container ${notificationType(notificationClass).color} ${
				read ? 'notification-read' : 'notification-unread'
			}`}
		>
			<div className="item-pre-header">
				<div className="item-notification-type-icon">
					<img src={notificationType(notificationClass).icon} alt="" />
				</div>
				{notificationClass !== notificationsClasses.quizQuestion ? (
					<div className="item-close-btn">
						<DeleteForeverOutlinedIcon
							titleAccess="Delete"
							fontSize="inherit"
							color="inherit"
							onClick={() => {
								ref.current.parentElement.classList.add('notification-item-delete');
								getNotificationDeleteIds(id);
							}}
						/>
					</div>
				) : (
					''
				)}
			</div>
			<div className={`header title ${overflow && isMobile ? 'overflow-row-1' : ''}`}>{title}</div>
			<div className="body">
				<div className={`body-message ${overflow && isMobile ? 'overflow-row-3' : ''}`}>
					{message}
				</div>
			</div>
			<div className="footer">
				{url ? (
					<div className="body-url">
						<a href={url} target="_blank" rel="noreferrer">
							Read more...{' '}
							<span className="url-icon">
								<OpenInNewIcon />
							</span>
						</a>
					</div>
				) : null}
				{notificationClass === notificationsClasses.quizQuestion && !!questionCode ? (
					<button
						className="btn bg-violet"
						onClick={() => {
							onClickQuestion(id, questionCode);
							close();
						}}
					>
						<span className="btn-icon">
							<CheckIcon fontSize="inherit" />
						</span>
						<span className="btn-text">Start survey</span>
					</button>
				) : (
					''
				)}
				<div className="notification-date">{formaterDate(date, 'YYYY-MM-DD')}</div>
			</div>
		</div>
	);
}

export default NotificationStoryItem;
