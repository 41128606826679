import React, { Component } from 'react';
import * as d3 from 'd3';
import moment from 'moment';

class RiskTrends extends Component {
	chart = null;

	chartInited = false;

	svg = null;

	initChart() {
		const { data } = this.props;
		if (this.chart && !this.chartInited && data && data.length) {
			this.chartInited = true;
			const { width, height } = this.props;

			const svg = d3.select(this.chart);
			svg.attr('viewBox', `0 0 ${width} ${height}`);
			this.svg = svg;

			this.initDefs();
			this.addXAxis();
			this.addLines();
		}
	}

	initDefs() {
		const { svg } = this;
		if (svg) {
			// const defs = svg.append("defs");
		}
	}

	convertDate(date) {
		return new Date(date).getTime();
	}

	shortDate(date) {
		return moment(date, 'YYYY-MM-DD').format('DD MMM');
	}

	getMinX() {
		const { data } = this.props;
		return this.convertDate(data[0].date);
	}

	getMaxX() {
		const { data } = this.props;
		return this.convertDate(data[data.length - 1].date);
	}

	getCaseMinY() {
		const { data } = this.props;
		return Math.min.apply(
			Math,
			data.map((el) => el.case),
		);
	}

	getCaseMaxY() {
		const { data } = this.props;
		return Math.max.apply(
			Math,
			data.map((el) => el.case),
		);
	}

	getDeathMinY() {
		const { data } = this.props;
		return Math.min.apply(
			Math,
			data.map((el) => el.death),
		);
	}

	getDeathMaxY() {
		const { data } = this.props;
		return Math.max.apply(
			Math,
			data.map((el) => el.death),
		);
	}

	xScale() {
		const { width, paddingLeft, paddingRight } = this.props;
		return d3
			.scaleLinear()
			.domain([this.getMinX(), this.getMaxX()])
			.range([0, width - paddingLeft - paddingRight]);
	}

	addLines() {
		const { svg } = this;
		if (svg) {
			const { data, color, height, paddingTop, paddingLeft, paddingBottom } = this.props;
			const h = (height - paddingTop - paddingBottom) / 2;

			const xScale = this.xScale();

			const yScaleCase = d3
				.scaleLinear()
				.domain([this.getCaseMinY(), this.getCaseMaxY()])
				.range([h, 0]);

			const yScaleDeath = d3
				.scaleLinear()
				.domain([this.getDeathMinY(), this.getDeathMaxY()])
				.range([h, 0]);

			const lineCase = d3
				.line()
				.x((d) => xScale(this.convertDate(d.date)))
				.y((d) => yScaleCase(d.case))
				.curve(d3.curveMonotoneX);

			const lineDeath = d3
				.line()
				.x((d) => xScale(this.convertDate(d.date)))
				.y((d) => yScaleDeath(d.death))
				.curve(d3.curveMonotoneX);

			const line1 = svg
				.append('g')
				.attr('class', 'risk-trend-lines')
				.attr('transform', `translate(${paddingLeft}, ${paddingTop})`);
			line1
				.append('path')
				.datum(data)
				.attr('fill', 'none')
				.attr('stroke', color.case)
				.attr('stroke-width', 3)
				.attr('stroke-linejoin', 'round')
				.attr('stroke-linecap', 'round')
				.attr('d', lineCase);

			const line2 = svg
				.append('g')
				.attr('class', 'risk-trend-lines')
				.attr('transform', `translate(${paddingLeft}, ${paddingTop + h})`);
			line2
				.append('path')
				.datum(data)
				.attr('fill', 'none')
				.attr('stroke', color.death)
				.attr('stroke-width', 3)
				.attr('stroke-linejoin', 'round')
				.attr('stroke-linecap', 'round')
				.attr('d', lineDeath);
		}
	}

	addXAxis() {
		const { svg } = this;
		if (svg) {
			const { data, height, paddingLeft, xAxisFontSize, zAxisColor } = this.props;
			const xScale = this.xScale();
			const y = height;

			const xAxis = svg.append('g').attr('transform', `translate(${paddingLeft}, 0)`);
			data.forEach((el) => {
				const x1 = xScale(this.convertDate(el.date));
				xAxis
					.append('svg:line')
					.attr('class', 'today')
					.attr('x1', x1)
					.attr('y1', height)
					.attr('x2', xScale(this.convertDate(el.date)))
					.attr('y2', 0)
					.attr('fill', 'none')
					.attr('stroke', 'rgba(35, 53, 68, 0.1)')
					.attr('stroke-width', 1);
				xAxis
					.append('text')
					.attr('x', x1 + 1)
					.attr('y', y - 2)
					.text(this.shortDate(el.date))
					.attr('font-size', `${xAxisFontSize}px`)
					.attr('fill', zAxisColor);
			});
		}
	}

	render() {
		return (
			<svg
				className="risk-trends-chart"
				ref={(ref) => {
					this.chart = ref;
					this.initChart();
				}}
			/>
		);
	}
}

RiskTrends.defaultProps = {
	width: 328,
	height: 110,
	paddingTop: 3,
	paddingLeft: 3,
	paddingBottom: 15,
	paddingRight: 40,
	xAxisFontSize: 9,
	zAxisColor: 'rgba(35, 53, 68, 0.25)',

	color: {
		case: '#FFCB00',
		death: '#9356F8',
	},
	data: [
		// points
		{
			date: '2020-08-02',
			case: 10,
			death: 0,
		},
		{
			date: '2020-08-04',
			case: 20,
			death: 3,
		},
		{
			date: '2020-08-06',
			case: 30,
			death: 5,
		},
		{
			date: '2020-08-08',
			case: 26,
			death: 4,
		},
		{
			date: '2020-08-10',
			case: 40,
			death: 8,
		},
		{
			date: '2020-08-12',
			case: 30,
			death: 12,
		},
	],
};

export default RiskTrends;
