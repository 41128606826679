import { useContext } from 'react';

import { Context, Loader } from '../../components';

import GetStartedSteps from './GetStartedSteps';

function GetStartedStepsLoader(props: JSX.IntrinsicAttributes) {
	const { isLoader } = useContext(Context);

	if (isLoader) {
		return <Loader />;
	}
	return <GetStartedSteps {...props} />;
}

export default GetStartedStepsLoader;
