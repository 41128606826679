import { ReactComponent as ConfirmedEmailIcon } from '../../../images/mail_confirm.svg';
import { ROUTE } from '../../App';
import { HeaderNotAuth } from '../../components';
import Button from '../../components/Buttons/Button';
import cn from '../../lib/classNames';
import { Footer } from '../Dashboard';

import styles from './ConfirmedEmail.module.scss';

export default function ConfirmedEmail() {
	return (
		<section className={cn(styles['page-confirmed-email'], 'page')}>
			<div className={cn(styles.content, 'content')}>
				<HeaderNotAuth />
				<section className={styles.body}>
					<div className={styles['block-img']}>
						<ConfirmedEmailIcon />
					</div>
					<div className={styles['block-text']}>
						<h1 className={styles.title}>Email confirmed!</h1>
						<p className={styles.description}>
							Successful validation! Please sign-in and complete your account setup.
						</p>
						<div className={styles['btn-wrap']}>
							<Button
								variant="outlined"
								color="primary"
								elementType="navigate"
								iconBefore="person"
								href={ROUTE.getStarted}
							>
								SIGN IN
							</Button>
						</div>
					</div>
				</section>
				<footer className={styles.footer}>
					<Footer />
				</footer>
			</div>
		</section>
	);
}
