import { googleLogout } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';

import profileIcon from '../../images/Profile.svg';
import { adaptDataForProfileApi } from '../api/adapter';
import { EVENT, EventManager, formaterDate, localStore } from '../lib';
import { GENDER_ADAPTER_FROM_SHORT_TO_FULL } from '../lib/constats';
import { api } from '../sagas';

export const firstStepDataExist = (userProfileData) => {
	return (
		userProfileData.ageBucket !== '' &&
		userProfileData.gender !== '' &&
		userProfileData.id !== '' &&
		userProfileData.name !== null &&
		userProfileData.email !== '' &&
		userProfileData.seName !== ''
	);
};

export const userLogout = (setUserProfileData) => {
	localStore.setToken('');
	setUserProfileData({});
	googleLogout();
};

export const userName = (userProfileData) => {
	if (userProfileData.seName) return userProfileData.seName;
	if (userProfileData.fbName) return userProfileData.fbName;
	if (userProfileData.googleName) return userProfileData.googleName;
	if (userProfileData.appleName) return userProfileData.appleName;
	return '';
};

export const userGender = (gender) => {
	return {
		genderShortLabel: gender,
		genderFullLabel: GENDER_ADAPTER_FROM_SHORT_TO_FULL[gender],
	};
};

export const userLocationAdapter = (userProfileData) => {
	const { lng, lat, id, name } = userProfileData;

	const adapted = {};

	if (lng || lat) {
		adapted.location = { value: {}, label: name };
		if (id) adapted.location.value.id = id;
		if (name) adapted.location.value.name = name;
		if (lng) adapted.location.value.longitude = lng;
		if (lat) adapted.location.value.latitude = lat;
	}

	return adapted.location;
};

export const userPicture = (userProfileData) => {
	const p = userProfileData;
	if (p.seAvatar) return p.seAvatar;
	if (p.fbAvatar) return p.fbAvatar;
	if (p.googleAvatar) return p.googleAvatar;
	return profileIcon;
};

export const setLoaderIncrement = (setIsLoader) => {
	setIsLoader((prev) => prev + 1);
};

export const setLoaderDecrement = (setIsLoader) => {
	setIsLoader((prev) => (prev === 0 ? 0 : prev - 1));
};

export const adaptPersonalNotifications = (data) => ({
	list: data?.content || [],
	page: data?.pageable?.pageNumber || 0,
	pages: data?.totalPages || 0,
	total: data?.totalElements || 0,
});

export const notificationsPersonalNewCount = (personalNotifications) => {
	try {
		return personalNotifications.list.reduce((prev, curr) => {
			return !curr.read ? prev + 1 : prev;
		}, 0);
	} catch (e) {
		return 0;
	}
};

export const notificationsPeriods = (personalNotifications) => {
	const list = personalNotifications.list || [];
	const calcDate = (correctionDay) => {
		const getCurrentDate = new Date();
		return formaterDate(getCurrentDate.setDate(getCurrentDate.getDate() - correctionDay));
	};

	const today = calcDate(0);
	const weekEnd = calcDate(7);

	const todayItems = [];
	const weekItems = [];
	const earlierItems = [];

	list.forEach((e) => {
		const dateMessage = formaterDate(e.date);

		if (dateMessage === today) {
			todayItems.push(e);
		} else if (dateMessage >= weekEnd && dateMessage < today) {
			weekItems.push(e);
		} else {
			earlierItems.push(e);
		}
	});

	return { todayItems, weekItems, earlierItems };
};

export const getGoogleUserDataFromCredential = (response) => {
	const userObject = jwt_decode(response.credential);
	const data = {
		idToken: response.credential,
		id: userObject.sub,
		email: userObject.email,
		name: userObject.name,
		firstName: userObject.given_name,
		lastName: userObject.family_name,
		picture: userObject.picture,
	};

	return data;
};

export async function getApiToken(api, data) {
	const response = await api.authToken(data);
	if (response.ok) {
		const apiToken = response.headers.authorization;
		api.apiInstance.setHeader('Authorization', apiToken);
		localStore.setToken(apiToken);
		return apiToken;
	}
	return null;
}

export async function userSetGoogleOAuthData(googleToken, data, setUserProfileData) {
	const idToken = data?.idToken;

	if (googleToken && idToken) {
		EventManager.publish(EVENT.FCM_SEND_TOKEN);
		await getApiToken(api, {
			googleToken: idToken,
		});
		localStore.setLoggedAs('GOOGLE');

		await api.updateProfile(
			adaptDataForProfileApi({
				email: data?.email,
				googleName: `${data?.firstName} ${data?.lastName}`,
				googleAvatar: data?.picture,
			}),
			googleToken,
		);

		const response = await api.getProfile(googleToken);

		if (response.ok && response.data) {
			setUserProfileData(response.data);
		}
	}
}
