import cn from '../lib/classNames';

import img404 from '../../images/404.png';
import imgMap from '../../images/map.png';
import { ROUTE } from '../App';
import { HeaderNotAuth } from '../components';

import Button from '../components/Buttons/Button';

import { Footer } from './Dashboard';

import styles from './404.module.scss';

export default function Page404() {
	return (
		<div className={styles['page-404']}>
			<div className="content">
				<HeaderNotAuth />
				<section className={styles.body}>
					<div className={styles['bg-wrap']}>
						<img className={cn(styles['bg-img'], styles['img-map'])} src={imgMap} alt="" />
						<img className={cn(styles['bg-img'], styles['img-404'])} src={img404} alt="" />
					</div>
					<div className={styles['block-text']}>
						<h1>Page not found</h1>
						<p>Your personal safety barometer doesn't work here</p>
						<div className={styles['btn-back']}>
							<Button
								variant={'outlined'}
								color={'primary'}
								elementType="navigate"
								iconBefore="arrow_back_ios"
								href={ROUTE.default}
							>
								Back to main page
							</Button>
						</div>
					</div>
				</section>
				<footer className={styles.footer}>
					<Footer />
				</footer>
			</div>
		</div>
	);
}
