export const TYPE = {
	POI: 'poi',
	PLACE: 'place',
	PLACE_OUR: 'place_our',
	REGION: 'region',
	COUNTRY: 'country',
};

const reduceType = (list) => {
	return list.reduce((acc, t) => {
		if (!acc) return t;
		if (t === TYPE.PLACE && acc === TYPE.POI) return acc;
		if (t === TYPE.REGION && (acc === TYPE.POI || acc === TYPE.PLACE)) return acc;
		if (t === TYPE.COUNTRY && (acc === TYPE.POI || acc === TYPE.PLACE || acc === TYPE.REGION))
			return acc;
		return t;
	});
};

export const adaptSearchPlacesResponse = (data) => {
	if (data && 'features' in data && data.features.length) {
		return data.features.map((el, idx) => ({
			id: el.id,
			name: el.place_name,
			longitude: el.center[0],
			latitude: el.center[1],
			type: reduceType(el.place_type),
		}));
	}
	return [];
};
