import { ChangeEvent, useLayoutEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import cn from '../lib/classNames';
import { Color } from '../theme';

import Slider from './Slider';
import SliderLine from './SliderLine';

import styles from './RiskSlider.module.scss';

const useStyles = makeStyles({
	root: {
		maxWidth: 600,
		color: Color.white,
		zIndex: 1,
		padding: 0,
		display: 'flex',
		alignItems: 'center',
	},
	rail: {
		height: 5,
		backgroundColor: 'transparent',
		borderRadius: 5,
	},
	track: {
		height: 5,
		backgroundColor: Color.cyanLight,
		borderRadius: 5,
	},
	thumb: {
		marginTop: 0,
		marginLeft: -10,
		width: 22,
		height: 22,
		background: Color.white,
		border: '0.5px solid #DEE2E5',
		boxShadow:
			'0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 1px rgba(0, 0, 0, 0.1)',

		'&:after': {
			width: 1,
			height: 88,
			background: Color.white,
			opacity: 0.1,
			position: 'absolute',
			top: 'calc(50% - 88px/2)',
			left: 'calc(50% - 1px)',
			zIndex: -1,
		},
	},
});

type CurrentDataType = {
	crime: number;
	accidents: number;
	health: number;
};

type RiskSliderType = {
	value: number[];
	currentData: (_: CurrentDataType) => void;
};

export default function RiskSlider(props: RiskSliderType) {
	const { value: initialValue, currentData, ...rest } = props;
	const classes = useStyles();

	const [value, setValue] = useState<number[]>(initialValue);
	const [sliderWidth, setSliderWidth] = useState(0);

	const handleChange = (event: ChangeEvent<object>, newValue: number | number[]) => {
		currentData({
			crime: value[0],
			accidents: value[1] - value[0],
			health: 100 - value[1],
		});

		setValue(newValue as number[]);
	};

	const getSecondLineStyle = () => ({
		width: (sliderWidth * value[0]) / 100,
	});

	const getThirdLineStyle = () => ({
		left: (sliderWidth * value[1]) / 100,
		width: sliderWidth - (sliderWidth * value[1]) / 100,
	});

	useLayoutEffect(() => {
		const updateSize = () => {
			const riskSlider = document.getElementById('risk-slider');
			if (riskSlider) setSliderWidth(riskSlider.clientWidth);
		};

		window.addEventListener('resize', updateSize);

		return () => window.removeEventListener('resize', updateSize);
	}, []);

	return (
		<div
			id="risk-slider"
			className={cn(styles['risk-slider'])}
			ref={(instance) => {
				if (instance) {
					setSliderWidth(instance.clientWidth);
				}
			}}
		>
			<div className={styles['risk-value-label']}>
				<div className={styles['risk-val']}>
					{value[0]}
					<span>%</span>
				</div>
				<div className={styles['risk-label']}>
					<span>Crime</span>
				</div>
			</div>
			<div className={styles['risk-value-label']} style={{ left: `${value[0]}%` }}>
				<div className={styles['risk-val']}>
					{value[1] - value[0]}
					<span>%</span>
				</div>
				<div className={styles['risk-label']}>
					<span>Accidents</span>
				</div>
			</div>
			<div className={styles['risk-value-label']} style={{ left: `${value[1]}%` }}>
				<div className={styles['risk-val']}>
					{100 - value[1]}
					<span>%</span>
				</div>
				<div className={styles['risk-label']}>
					<span>Health</span>
				</div>
			</div>
			<SliderLine className="slider-line-first" style={getSecondLineStyle()} />
			<Slider
				classes={classes}
				onChange={handleChange}
				value={value}
				{...rest}
				valueLabelDisplay="off"
			/>
			<SliderLine className="slider-line slider-line-last" style={getThirdLineStyle()} />
		</div>
	);
}
