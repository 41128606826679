import { useEffect } from 'react';

import { slide_03 } from '../../../images';
import Button from '../../components/Buttons/Button';

import styles from './WidgetYourFeedback.module.scss';

export default function WidgetYourFeedback() {
	useEffect(() => {
		let qs;
		let js;
		let q;
		let s;
		const d = document;
		const gi = d.getElementById;
		const ce = d.createElement;
		const gt = d.getElementsByTagName;
		const id = 'typef_orm_share';
		const b = 'https://embed.typeform.com/';
		if (!gi.call(d, id)) {
			js = ce.call(d, 'script') as HTMLScriptElement;
			js.id = id;
			js.src = `${b}embed.js`;
			q = gt.call(d, 'script')[0];
			if (q.parentNode) {
				q.parentNode.insertBefore(js, q);
			}
		}
	});

	return (
		<div className={styles.block}>
			<div className={styles['block-img']}>
				<img className={styles.image} src={slide_03} alt="" />
			</div>

			<div className={styles['block-text-button']}>
				<div className={styles['block-text']}>
					<h4 className={styles['text-description']}>Your Feedback in 90 Secs</h4>
					<p className={styles['text-detail']}>
						Take a quick survey and help us improve this tool for you and your loved ones.
					</p>
				</div>
				<Button
					elementType="link"
					variant="outlined"
					color="primary"
					href="https://form.typeform.com/to/hDuo0Elh"
					target="_blank"
					data-mode="popup"
					data-submit-close-delay="2"
					sizeWidth="full"
					iconBefore="open_in_new"
				>
					GIVE FEEDBACK
				</Button>
			</div>
		</div>
	);
}
