import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';

import { ButtonWhite, Context, GoogleReCaptcha, ModalCustom } from '../../components';
import Button from '../../components/Buttons/Button';
import cn from '../../lib/classNames';
import { api } from '../../sagas';
import Layout from '../Layout/LayoutDashboard';
import ReportHistory from '../Report/ReportHistory/ReportHistory';
import { fakeAgeBucket } from '../../lib/constats';

import { AccountDelete, PersonalData, ProfilePersonalData, ProfilePreview, UserProfile } from '.';

import './Profile.scss';

type LocationStateType = {
	linkedError: string;
	state: {
		linkedError: string;
	};
};

function Profile() {
	const [logout, setLogout] = useState(false);
	const [edit, setEdit] = useState('');
	const [modalMaxWidth, setModalMaxWidth] = useState(676);

	const { setGoogleToken, userLocation, userGender, userProfileData } = useContext(Context);

	const token = useRef('');

	const location = useLocation<LocationStateType>();

	const { linkedError } = location.state ?? {};

	const { profileModificationAvailable } = userProfileData;

	const handleCaptchaVerify = useCallback((newToken: string) => {
		token.current = newToken;
		setGoogleToken(newToken);
	}, []);

	const onSave = () => {
		setEdit('');

		setTimeout(() => {
			setModalMaxWidth(676);
		}, 500);
	};

	const handleAccountDelete = () => {
		api.accountDeactivate().then((response) => {
			if (response.ok) {
				if (response.data.success) setLogout(true);
			}
		});
	};

	const renderContentModal = () => {
		if (edit === 'userProfile') {
			return <UserProfile onSave={onSave} setLogout={setLogout} />;
		}
		if (edit === 'personalData') {
			return <PersonalData onSave={onSave} />;
		}
		if (edit === 'accountDelete') {
			return <AccountDelete onSave={onSave} handleAccountDelete={handleAccountDelete} />;
		}

		return undefined;
	};

	const renderModal = (children?: JSX.Element) => {
		return (
			<ModalCustom
				open={!!edit}
				stylesModal={{ alignItems: 'center' }}
				stylesPaper={{ maxWidth: modalMaxWidth }}
			>
				{children}
				{edit !== 'accountDelete' && (
					<div className="modal-personal-btn-cancel">
						<Button
							onClick={() => {
								setEdit('');
							}}
							variant="text"
							color="primary"
							sizeWidth="full"
						>
							CANCEL
						</Button>
					</div>
				)}
			</ModalCustom>
		);
	};

	const setHeightColumn = () => {
		const getColumns = document.querySelectorAll<HTMLInputElement>(
			'.profile-personal-data-page .col',
		);
		const userProfileContainer = document.querySelector('.user-profile-container');

		const maxColumnHeight = userProfileContainer?.getBoundingClientRect().height;

		getColumns?.forEach((e) => {
			e.style.maxHeight = `${maxColumnHeight}px`;
		});
	};

	useEffect(() => {
		setHeightColumn();
	}, []);

	return (
		<Layout isLogout={logout}>
			{!token.current && <GoogleReCaptcha onVerify={handleCaptchaVerify} />}
			<div className="profile-personal-data-page row">
				<div className="col col-1">
					<div className="user-report-history-container section-wrap-white">
						<ReportHistory title="Travel Reports History" />
					</div>
				</div>
				<div className="col col-2">
					<div className="user-profile-container section-wrap-white">
						<ProfilePreview linkedError={linkedError} />
						<div
							className="btn btn-edit"
							onClick={() => {
								setEdit('userProfile');
							}}
						>
							<EditTwoToneIcon className="edit-icon" />
							Edit Data
						</div>
					</div>
				</div>
				<div className="col col-3">
					<div className="user-personal-data-container section-wrap-white">
						<ProfilePersonalData
							gender={userGender.genderFullLabel}
							label={userLocation.label}
							fakeAgeBucket={fakeAgeBucket(userProfileData.ageBucket)}
						/>

						<div
							className={cn('btn btn-edit', {
								'btn-edit-disable': !profileModificationAvailable,
							})}
							onClick={() => {
								if (profileModificationAvailable) setEdit('personalData');
							}}
						>
							{profileModificationAvailable ? (
								<>
									<EditTwoToneIcon className="edit-icon" />
									Edit Data
								</>
							) : (
								<>
									<div className="btn-edit-tip-wrap" data-tooltip-id="btn-edit-tip">
										<EditTwoToneIcon className="edit-icon" />
										Edit Data
									</div>
									<Tooltip
										className="btn-edit-tip"
										id="btn-edit-tip"
										openOnClick
										clickable
										place="bottom"
									>
										Ouch! It looks like you modified your profile too many times. You will be able
										to edit your information again in 30 days or you can contact us at{' '}
										<a className="btn-edit-tip-link" href="mailto: support@safe-esteem.com">
											support@safe-esteem.com
										</a>
									</Tooltip>
								</>
							)}
						</div>
					</div>
					<div className="btn-wrap">
						<ButtonWhite onClick={() => setLogout(true)}>LOG OUT</ButtonWhite>
						<div
							className="btn btn-account-delete"
							onClick={() => {
								setModalMaxWidth(440);
								setEdit('accountDelete');
							}}
						>
							Delete My Account/Data
						</div>
					</div>
				</div>
				{renderModal(renderContentModal())}
			</div>
		</Layout>
	);
}

export default Profile;
