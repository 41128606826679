import { Link } from 'react-router-dom';

import { ROUTE } from '../../App';
import { HeaderNotAuth } from '../../components';
import cn from '../../lib/classNames';
import { Footer } from '../Dashboard';

import styles from './ReportExpired.module.scss';

export default function ReportExpired() {
	return (
		<section className={styles['page-report-expired']}>
			<div className={cn(styles.content, 'content')}>
				<HeaderNotAuth />
				<section className={styles['block-text']}>
					<h1 className={styles.title}>Sorry, but this URL is expired.</h1>
					<p className={styles.description}>
						<Link className={styles['link-return']} to={ROUTE.getStarted}>
							Click here
						</Link>{' '}
						to return the COVID-19 Personal Risk Explorer and perform a new comparison with current
						data.
					</p>
				</section>
				<footer className={styles.footer}>
					<Footer />
				</footer>
			</div>
		</section>
	);
}
