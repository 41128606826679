import { useRef } from 'react';
import alertsIconAlert from '../../../images/alerts_icon_alert.svg';
import alertsIconQuestion from '../../../images/alerts_icon_question_violet.svg';
import alertsIcon from '../../../images/alerts_icon_violet.svg';
import unionIcon from '../../../images/Union.svg';
import { notificationsClasses } from '../../lib';
import cn from '../../lib/classNames';

import styles from './Tips.module.scss';

type TipsType = {
	variant: 'notification';
	typeIcon?: string;
	id: number;
	title: string;
	message: string;
	url: string;
	notificationClass: string;
	questionCode: string;
	button: boolean;
	onClickRead: (id: number, activeCard: HTMLElement) => void;
	onClickQuestion: (id: number, questionCode: string) => void;
	onClickRemind: (id: number, questionCode: string) => void;
};

export default function Tips(props: TipsType) {
	const {
		variant,
		typeIcon,
		id,
		title,
		message,
		notificationClass,
		url,
		questionCode,
		button,
		onClickRead,
		onClickQuestion,
		onClickRemind,
	} = props;

	const ref = useRef<HTMLDivElement>(null);

	const isAlert = notificationClass === notificationsClasses.alert;
	const isQuizQuestion = notificationClass === notificationsClasses.quizQuestion;

	const renderIcon = () => {
		if (isAlert) {
			return <img src={alertsIconAlert} alt="" />;
		}
		if (isQuizQuestion) {
			return <img src={alertsIconQuestion} alt="" />;
		}
		return <img src={typeIcon === 'alertIcon' ? alertsIcon : unionIcon} alt="" />;
	};

	const renderBtn = () => {
		if (isQuizQuestion) {
			return (
				<div className={styles['block-btn']}>
					<button
						className="bg-violet"
						onClick={() => {
							onClickQuestion(id, questionCode);
							if (ref.current) onClickRead(id, ref.current);
						}}
					>
						<span className={cn(styles['btn-icon'], 'material-symbols-outlined')}>check</span>
						<span className={styles['btn-text']}>Start survey</span>
					</button>
					<button
						className="bg-transparent"
						onClick={() => {
							onClickRemind(id, questionCode);
							if (ref.current) onClickRead(id, ref.current);
						}}
					>
						<span className={styles['btn-text']}>Remind me later</span>
					</button>
				</div>
			);
		}

		return (
			<div className={styles['block-btn']}>
				<button
					className={isAlert ? 'bg-red' : 'bg-violet'}
					onClick={() => {
						if (ref.current) onClickRead(id, ref.current);
					}}
				>
					<span className={cn(styles['btn-icon'], 'material-symbols-outlined')}>close</span>
					<span className={cn(styles['btn-text'], 'mob-hide')}>Got it</span>
				</button>
			</div>
		);
	};

	return (
		<div
			ref={ref}
			className={cn(styles['tips-container'], 'card', 'card--added', {
				[styles['tips-container-variant-2']]: variant === 'notification',
				[styles['tips-container-question']]: isQuizQuestion,
			})}
		>
			<div
				className={cn(
					styles['box-tips'],
					{
						[styles['box-tips-alert']]: isAlert,
					},
					isAlert ? 'bg-red' : 'bg-violet',
				)}
			>
				<div className={styles['content-wrap']}>
					<div className={styles['block-icon']}>{renderIcon()}</div>
					<div className={styles['block-text']}>
						{title && <h3>{title}</h3>}
						<span>
							{message}
							{url && (
								<a
									className={styles['notification-url']}
									href={url}
									target="_blank"
									rel="noopener noreferrer"
								>
									<span>Read more</span>
								</a>
							)}
						</span>
					</div>
				</div>
				{button && renderBtn()}
			</div>
		</div>
	);
}
