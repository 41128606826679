import { useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';

import styles from './SelectWithButtons.module.scss';

type SelectWithButtonsType = {
	items: string[];
	selectedIndex: number;
	onSelect: (_gender: number) => void;
};

export default function SelectWithButtons(props: SelectWithButtonsType) {
	const { selectedIndex, items, onSelect } = props;
	const [selected, setSelected] = useState(selectedIndex);

	const handleItemClick = (index: number) => {
		setSelected(index);
		if (onSelect) onSelect(index);
	};

	const renderItem = (el: string, index: number) => {
		return (
			<li
				key={index}
				className={index === selected ? styles.selected : ''}
				onClick={() => {
					handleItemClick(index);
				}}
			>
				{el} <CheckIcon className={styles['check-icon']} />
			</li>
		);
	};

	return (
		<ul className={styles['select-with-buttons']}>
			{items.map((el, index) => renderItem(el, index))}
		</ul>
	);
}
