import { createContext, useState } from 'react';

import { EVENT, EventManager } from '../lib';

import {
	adaptPersonalNotifications,
	firstStepDataExist,
	notificationsPeriods,
	notificationsPersonalNewCount,
	setLoaderDecrement,
	setLoaderIncrement,
	userGender,
	userLocationAdapter,
	userLogout,
	userName,
	userPicture,
} from './ContextProvider.helper';

export const Context = createContext();

function ContextProvider(props) {
	const [isLoader, setIsLoader] = useState(1);
	const [homeData, setHomeData] = useState({});
	const [reportData, setReportData] = useState({});
	const [unconfirmedEmail, setUnconfirmedEmail] = useState(false);
	const [googleToken, setGoogleToken] = useState('');
	const [userProfileData, setUserProfileData] = useState({});
	const [homeRequest, setHomeRequest] = useState({});
	const [destinationRequest, setDestinationRequest] = useState({});
	const [personalNotifications, setPersonalNotifications] = useState(adaptPersonalNotifications());
	const [googleAuth, setGoogleAuth] = useState({});
	const [dailyViewedLimitedList, setDailyViewedLimitedList] = useState([]);
	const [fromToDetails, setFromToDetails] = useState({});

	const value = {
		// Show/hide loader
		isLoader,
		setLoaderIncrement: () => setLoaderIncrement(setIsLoader),
		setLoaderDecrement: () => setLoaderDecrement(setIsLoader),

		// Data for redirect to ROUTE.confirmEmail
		unconfirmedEmail,
		setUnconfirmedEmail,

		// Google token
		googleToken,
		setGoogleToken,

		// User profile data
		userProfileData,
		setUserProfileData: (data) => {
			setUserProfileData(data);
			EventManager.publish(EVENT.FCM_SEND_TOKEN);
		},

		// User location
		userLocation: userLocationAdapter(userProfileData),

		// User name
		userName: userName(userProfileData),

		// User gender
		userGender: userGender(userProfileData.gender),

		// Is logged user
		isLogged: !!userProfileData.userId,

		// first step data exist
		firstStepDataExist: firstStepDataExist(userProfileData),

		// User Logaut
		userLogout: () => {
			userLogout(setUserProfileData);
		},

		// Data for Home location (Dashboard Personal)
		homeData,
		setHomeData,

		// Data for the report (Compare)
		reportData,
		setReportData,

		// Report Requests
		homeRequest,
		setHomeRequest,

		destinationRequest,
		setDestinationRequest,

		// Personal Notifications
		personalNotifications,
		setPersonalNotifications: (data) => setPersonalNotifications(adaptPersonalNotifications(data)),

		notificationsPersonalNewCount: notificationsPersonalNewCount(personalNotifications),

		notificationsPersonalPeriods: notificationsPeriods(personalNotifications),

		userAvatar: userPicture(userProfileData),

		// Google auth
		googleAuth,
		setGoogleAuth,

		// Hystory free of report
		dailyViewedLimitedList,
		setDailyViewedLimitedList,

		fromToDetails,
		setFromToDetails,
	};

	console.log('*** ContextProvider >> googleToken >>>', googleToken);

	return <Context.Provider {...props} value={value} />;
}

export default ContextProvider;
