import externalLink from '../../../images/external_link.svg';

import { DataType } from './types';

import { QuestionText } from '.';
import QuestionScreen1Form from './QuestionScreen1Form';

type Question6Type = {
	screen: number;
	data: (data: DataType) => void;
	questionNumber: number;
};

export default function Question6(props: Question6Type) {
	const { questionNumber, screen = 1, data } = props;

	const quNum = `qu${questionNumber}`;

	const { screen2 } = QuestionText[quNum];

	return (
		<div className={`question-content question-${questionNumber}-content`}>
			{screen === 1 ? (
				<QuestionScreen1Form screen={screen} data={data} questionNumber={questionNumber} />
			) : (
				<div className="screen-2">
					<h4 className="headline">{screen2.headline}</h4>
					<p className="sub-headline">{screen2.subHeadline}</p>
					<div className="links-wrap">
						{Object.values(screen2.links ?? {}).map((e) => {
							return (
								<a
									className="link bg-violet"
									key={e.linkText}
									href={e.linkUrl}
									target="_blank"
									rel="noreferrer"
								>
									{e.linkText}
									<img src={externalLink} alt="" />
								</a>
							);
						})}
					</div>
					<div className="note">{screen2.note}</div>
				</div>
			)}
		</div>
	);
}
