import { useEffect, useRef, useState } from 'react';
import { TextField } from '../../components';

import './FormField.scss';
import { NameValueType } from '../GetStarted/GetStartedSteps';

type FormFieldType = {
	placeholder: string;
	helpText: string;
	typeField: string;
	name: string;
	value: string;
	onSaveData: ({ name, value }: { name: string; value: string }) => void;
	isEmailError?: boolean | string;
	isPasswordError?: boolean;
	onChange?: (_name: string, _inputValue: string) => void;
	APIErrorText?: string;
	required?: boolean;
	onChangeData?: ({ name, value }: NameValueType) => void;
	firstNameHelpText?: string;
};

export default function FormField(props: FormFieldType) {
	const {
		placeholder,
		helpText,
		typeField,
		name,
		value,
		onSaveData,
		isEmailError,
		isPasswordError,
		onChange,
		APIErrorText,
		required = false,
		onChangeData,
		firstNameHelpText,
	} = props;

	const [inputValue, setInputValue] = useState(value);
	const [inputFocus, setInputFocus] = useState(false);
	const [inputBlur, setInputBlur] = useState(true);

	const inputRef = useRef<HTMLInputElement>(null);

	const handleClickField = () => {
		if (inputRef.current) {
			const field = inputRef.current;
			field.focus();
		}
	};

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const renderHelpText = () => {
		if (firstNameHelpText) {
			console.log('::: FormField >> firstNameHelpText: ', firstNameHelpText);
			return <span className="help-text field-error">{firstNameHelpText}</span>;
		}
		if (isEmailError) {
			if (typeof isEmailError === 'string')
				return <span className="help-text field-error">{isEmailError}</span>;
			return <span className="help-text field-error">Enter a valid email</span>;
		}
		if (isPasswordError && inputValue !== '******') {
			return <span className="help-text field-error">Enter a valid password</span>;
		}
		if (APIErrorText) {
			return <span className="help-text field-error">{APIErrorText}</span>;
		}

		return <span className="help-text">{helpText}</span>;
	};

	return (
		<div className="form-field-wrap" onClick={() => handleClickField()}>
			<div className={`field-inner ${inputFocus ? 'field-inner-focus' : ''}`}>
				{renderHelpText()}
				<div className="input-wrap">
					<TextField
						required={required}
						inputRef={inputRef}
						placeholder={placeholder}
						name={name}
						border={false}
						withOutMargin
						withOutPadding
						value={inputValue}
						type={typeField === 'password' ? 'password' : 'text'}
						inputProps={{
							onChange: (e: { target: { value: string } }) => {
								const valueTrimStart = e.target.value.trimStart();
								setInputValue(valueTrimStart);
								if (onChange) onChange(name, valueTrimStart);
								if (onChangeData) onChangeData({ name, value: valueTrimStart });
							},
							onFocus: () => {
								setInputFocus(true);
							},
							onBlur: () => {
								setInputBlur(false);
								setInputFocus(false);
								if (onSaveData) onSaveData({ name, value: inputValue.trimEnd() });
							},
						}}
					/>
				</div>
			</div>
			{name === 'password' && (
				<div className="help-text help-text-password">
					6 characters min. Must contain capital and regular letters and a number.
				</div>
			)}
		</div>
	);
}
