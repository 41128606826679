import { useState } from 'react';
import AboutUs from '../About/AboutUs';

import './Footer.scss';

type FooterType = {
	className?: string;
};

function Footer(props: FooterType) {
	const { className = '' } = props;

	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<div className={`page-footer-container ${className}`}>
				<hr />
				<div className="footer-content">
					<div className="footer-block-left">
						<span className="copyright">
							© Safe-esteem <span className="mob-hide">| </span>
						</span>
						<br className="hide-desktop" />
						<span
							onClick={() => {
								setIsModalOpen(true);
							}}
							className="link-about"
						>
							About&nbsp;Safe&#8209;xplore&nbsp;Personal&nbsp;Edition
						</span>
					</div>
					<div className="footer-block-right">
						<a href="https://www.safe-esteem.com/privacy" target="_blank" rel="noreferrer">
							Privacy Policy
						</a>
						<a href="https://www.safe-esteem.com/terms-of-use" target="_blank" rel="noreferrer">
							Terms of Use
						</a>
						<a
							className="typeform-share button"
							href="https://form.typeform.com/to/gVcVZNzC?typeform-medium=embed-snippet"
							data-mode="drawer_left"
							data-submit-close-delay="3"
							target="_blank"
							rel="noreferrer"
						>
							Contact Us
						</a>
					</div>
				</div>
				<AboutUs isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
			</div>
		</>
	);
}

export default Footer;
