// @flow
import React, { Component } from 'react';
import * as d3 from 'd3';

import { appendLinearGradient } from './SvgFactory';

type PropsType = {
	width: number;
	height: number;
	value: number;
	labelHeight: number;
	label: string;
	onComplete?: Function;
};

class ContributorLine extends Component<PropsType> {
	static defaultProps = {
		width: 280,
		height: 8,
		labelHeight: 20,
	};

	chart: SVGSVGElement | HTMLElement | null = null;

	chartInited = false;

	svg: d3.Selection<HTMLElement, unknown, null, undefined> | null = null;

	svgWidth = 0;

	getSvgWidth(): number {
		if (this.chart) {
			return this.chart.getBoundingClientRect().width;
		}
		return 0;
	}

	componentDidMount() {
		this.svgWidth = this.getSvgWidth();
		if (this.svg) {
			this.svg.selectAll('*').remove();
		}
		this.chartInited = false;
		this.initChart();
	}

	componentDidUpdate() {
		if (this.svgWidth !== this.getSvgWidth()) {
			this.svgWidth = this.getSvgWidth();
			if (this.svg) {
				this.svg.selectAll('*').remove();
			}
			this.chartInited = false;
			this.initChart();
		}
	}

	initChart() {
		if (this.chart && !this.chartInited) {
			this.chartInited = true;
			const { height, labelHeight, onComplete } = this.props;

			const svg = d3.select(this.chart as HTMLElement);
			svg.attr('width', '100%').attr('height', height + labelHeight);

			this.svg = svg;

			this.initDefs();
			this.initLine();
			this.initValue();

			if (onComplete)
				onComplete({
					svg: svg.html(),
					// width,
					height: height + labelHeight,
				});
		}
	}

	initDefs() {
		const { svg } = this;
		if (svg) {
			const defs = svg.append('defs');
			appendLinearGradient({
				defs,
				id: 'linear-gradient-contributor',
				color1: '#233544',
				color1Opacity: 0.25,
				color2: '#233544',
				color2Opacity: 0.7,
			});
		}
	}

	initLine() {
		const { svg } = this;
		if (svg) {
			const { value, labelHeight, height } = this.props;
			const g = svg
				.append('g')
				.attr('class', 'contributor-line')
				.attr('transform', `translate(0,${labelHeight})`);

			const r = height / 2;
			const x1 = r;
			const x2 = x1 + this.svgWidth - r * 2;
			const y = r;
			const valueX = x1 + ((x2 - x1) * value) / 100;

			g.append('line')
				.attr('x1', x1)
				.attr('y1', y)
				.attr('x2', x2)
				.attr('y2', y)
				.attr('stroke', 'rgba(210, 210, 210, 0.4)')
				.attr('stroke-width', height)
				.attr('stroke-linecap', 'round');
			g.append('line')
				.attr('x1', x1)
				.attr('y1', y)
				.attr('x2', valueX)
				.attr('y2', y)
				.attr('stroke', 'url(#linear-gradient-contributor)')
				.attr('stroke-width', height)
				.attr('stroke-linecap', 'round');
		}
	}

	initValue() {
		const { svg } = this;
		if (svg) {
			const { label } = this.props;

			const fs = 12;
			const x = 0;
			svg
				.append('text')
				.attr('x', x)
				.attr('y', fs)
				.text(label)
				.attr('font-size', `${fs}px`)
				.attr('fill', '#233544');
		}
	}

	render(): React.ReactNode {
		return (
			<svg
				className="risk-score-chart"
				ref={(ref) => {
					this.chart = ref;
					this.initChart();
				}}
			/>
		);
	}
}

export default ContributorLine;
