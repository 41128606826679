import { PureComponent } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';

import { debounce } from '../lib';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const COLOR = {
	USUAL: '#777',
	CRIME: '#ff8080',
	USUAL_BORDER: '#000',
	CRIME_BORDER: '#000',
};

class Map extends PureComponent {
	constructor(props) {
		super(props);

		this.map = null;
		this.onMoveEndDebounced = debounce(this.handleOnMoveEnd, 1000);
		this.markers = [];
	}

	static propTypes = {
		accessToken: PropTypes.string.isRequired,
		mapStyle: PropTypes.string.isRequired,
		onInit: PropTypes.func,
		onMoveEnd: PropTypes.func,
		onDragEnd: PropTypes.func,
		onZoomEnd: PropTypes.func,
		onClick: PropTypes.func,
	};

	state = {
		hoveredStateId: null,
	};

	componentDidMount() {
		this.init();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.markers !== this.props.markers) {
			if (this.props.markers) {
				this.addMarkers(this.props.markers);
			}
		}
	}

	componentWillUnmount() {
		if (this.map) this.map.remove();
	}

	init() {
		const { accessToken, mapStyle, searchEnabled, center, marker, markers, zoom = 7 } = this.props;

		mapboxgl.accessToken = accessToken;

		this.map = new mapboxgl.Map({
			container: this.mapContainer,
			style: mapStyle,
			center,
			zoom,
			preserveDrawingBuffer: true,
		});
		if (searchEnabled) {
			const geocoder = new MapboxGeocoder({
				accessToken: mapboxgl.accessToken,
				placeholder: 'Enter search',
				mapboxgl,
			});
			this.map.addControl(geocoder);
		}

		if (marker) {
			new mapboxgl.Marker({ color: '#9357f8' }).setLngLat(marker).addTo(this.map);
		}

		if (markers.length) {
			this.addMarkers(markers);
			this.fitBounds();
		}

		this.map.on('load', this.handleOnLoad);
	}

	addMarkers = (features) => {
		const self = this;

		if (this.markers.length) {
			this.markers.forEach((marker) => marker.remove());
		}

		this.markers = [];

		features.forEach((marker) => {
			const el = document.createElement('div');
			el.className = 'marker';
			el.style.backgroundImage = `url('${marker.backgroundImage}')`;
			el.style.width = '40px';
			el.style.height = '40px';
			el.style.backgroundSize = 'contain';
			el.style.backgroundRepeat = 'no-repeat';

			// Создаем маркер и добавляем его в массив
			const newMarker = new mapboxgl.Marker({ element: el })
				.setLngLat([parseFloat(marker.long), parseFloat(marker.lat)])
				.setPopup(
					new mapboxgl.Popup({
						className: 'marker-popup-info',
						offset: 25,
					}).setHTML(`<h4>${marker.name}</h4><p>${marker.note || marker.notes}</p>`),
				)
				.addTo(self.map);

			self.markers.push(newMarker);
		});
	};

	fitBounds = () => {
		const bounds = this.props.markers.reduce(
			(bounds, marker) => bounds.extend([parseFloat(marker.long), parseFloat(marker.lat)]),
			new mapboxgl.LngLatBounds(),
		);

		this.map.fitBounds(bounds, {
			padding: 40,
		});
	};

	handleMouseMove = (e) => {
		const { hoveredStateId } = this.state;
		if (e.features.length > 0) {
			const feature = e.features[0];
			const { id } = feature;
			if (hoveredStateId && hoveredStateId !== id) {
				this.map.setFeatureState({ source: 'world', id: hoveredStateId }, { hover: false });
				this.map.setFeatureState({ source: 'world-crime', id: hoveredStateId }, { hover: false });
			}
			this.setState({
				hoveredStateId: id,
			});
			this.map.setFeatureState({ source: 'world', id }, { hover: true });
			this.map.setFeatureState({ source: 'world-crime', id }, { hover: true });
		}
	};

	handleMouseLeave = () => {
		const { hoveredStateId } = this.state;
		if (hoveredStateId) {
			this.map.setFeatureState({ source: 'world', id: hoveredStateId }, { hover: false });
			this.map.setFeatureState({ source: 'world-crime', id: hoveredStateId }, { hover: false });
		}
		this.setState({
			hoveredStateId: null,
		});
	};

	handleClick = (e) => {
		const { onClick } = this.props;
		if (onClick)
			onClick({
				map: this.map,
				featureId: e.features[0].id,
				featureName: e.features[0].properties.name,
				lngLat: e.lngLat,
			});
	};

	handleOnLoad = () => {
		const { onInit } = this.props;
		this.addSources();
		this.addLayers();

		this.map.on('mousemove', 'world-fills', (e) => {
			this.handleMouseMove(e);
		});
		this.map.on('mouseleave', 'world-fills', (e) => {
			this.handleMouseLeave(e);
		});
		this.map.on('click', 'world-fills', (e) => {
			this.handleClick(e);
		});

		this.map.on('mousemove', 'world-crime-fills', (e) => {
			this.handleMouseMove(e);
		});
		this.map.on('mouseleave', 'world-crime-fills', (e) => {
			this.handleMouseLeave(e);
		});
		this.map.on('click', 'world-crime-fills', (e) => {
			this.handleClick(e);
		});

		this.map.on('moveend', (e) => {
			this.onMoveEndDebounced();
		});

		this.map.on('dragend', (e) => {
			this.handleOnDragEnd();
		});

		this.map.on('zoomend', (e) => {
			this.handleOnZoomEnd();
		});

		if (onInit) onInit(this.map);
	};

	handleOnMoveEnd = () => {
		const { onMoveEnd } = this.props;
		if (onMoveEnd) onMoveEnd(this.map);
	};

	handleOnDragEnd = () => {
		const { onDragEnd } = this.props;
		if (onDragEnd) onDragEnd(this.map);
	};

	handleOnZoomEnd = () => {
		const { onZoomEnd } = this.props;
		if (onZoomEnd) onZoomEnd(this.map);
	};

	addSources() {
		this.map.addSource('single-point', {
			type: 'geojson',
			data: {
				type: 'FeatureCollection',
				features: [],
			},
		});

		this.map.addSource('world', {
			type: 'geojson',
			data: {
				type: 'FeatureCollection',
				features: [],
			},
		});

		this.map.addSource('world-crime', {
			type: 'geojson',
			data: {
				type: 'FeatureCollection',
				features: [],
			},
		});
	}

	addLayers() {
		this.map.addLayer({
			id: 'point',
			source: 'single-point',
			type: 'circle',
			paint: {
				'circle-radius': 10,
				'circle-color': '#336699',
			},
		});

		this.map.addLayer({
			id: 'world-fills',
			type: 'fill',
			source: 'world',
			layout: {},
			paint: {
				'fill-color': COLOR.USUAL,
				'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.7, 0.2],
			},
		});

		this.map.addLayer({
			id: 'world-borders',
			type: 'line',
			source: 'world',
			layout: {},
			paint: {
				'line-color': COLOR.USUAL_BORDER,
				'line-width': 2,
			},
		});

		this.map.addLayer({
			id: 'world-crime-fills',
			type: 'fill',
			source: 'world-crime',
			layout: {},
			paint: {
				'fill-color': COLOR.USUAL,
				'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.7, 0.2],
			},
		});

		this.map.addLayer({
			id: 'world-crime-borders',
			type: 'line',
			source: 'world-crime',
			layout: {},
			paint: {
				'line-color': COLOR.USUAL_BORDER,
				'line-width': 2,
			},
		});
	}

	render() {
		const { style, className } = this.props;
		return (
			<div className={`map, ${className}`} style={style} ref={(el) => (this.mapContainer = el)} />
		);
	}
}

Map.defaultProps = {
	center: [-87.676352, 41.839661],
	marker: [-87.676352, 41.839661],
	markers: [],
	searchEnabled: false,
	style: { width: '100%', height: 160 },
};

export default Map;
