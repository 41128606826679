import { ROUTE } from '../../App';
import Button from '../../components/Buttons/Button';
import useMediaQuery from '../../hooks/MediaQuery';
import cn from '../../lib/classNames';

import travelRisk01 from './images/travelRisk01.jpg';
import travelRisk02 from './images/travelRisk02.jpg';
import travelRisk03 from './images/travelRisk03.jpg';
import SectionHeader from './SectionHeader';
import WelcomeSlider from './WelcomeSlider';

import styles from './TravelRiskRatings.module.scss';

type TravelRiskRatingsType = {
	classPageContent?: string;
};

const IMAGES = [travelRisk01, travelRisk02, travelRisk03];

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	centerMode: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	initialSlide: 0,
	arrows: false,
	centerPadding: '25%',
	responsive: [
		{
			breakpoint: 599,
			settings: {
				slidesToShow: 1,
				centerPadding: '23%',
			},
		},
		{
			breakpoint: 390,
			settings: {
				slidesToShow: 1,
				centerPadding: '20%',
			},
		},
	],
};

export default function TravelRiskRatings(props: TravelRiskRatingsType) {
	const { classPageContent } = props;

	const isMobile = useMediaQuery('(max-width: 767px)');

	const renderImg = IMAGES.map((i) => {
		return (
			<img
				key={i}
				className={cn(styles.img, 'welcome-shadow', 'welcom-border-radius')}
				src={i}
				alt=""
			/>
		);
	});

	return (
		<section className={styles['travel-risk-ratings']}>
			<div className={cn(styles.row, styles['row-1'])}>
				<div className={cn(styles.content, classPageContent)}>
					<SectionHeader
						className={styles.header}
						title="The World’s Most Advanced Travel Risk Ratings"
						subTitle="You are unique, and so are your risks. See health, accidents, and violent crime risk estimates based on your demographic profile for hundreds of U.S. and global cities."
					/>
				</div>
			</div>
			<div className={cn(styles.row, styles['row-2'])}>
				<div className={cn(styles.content, classPageContent)}>
					<div className={styles['img-wrap']}>
						{isMobile ? <WelcomeSlider settings={settings}>{renderImg}</WelcomeSlider> : renderImg}
					</div>
				</div>
			</div>
			<div className={cn(styles.row, styles['row-3'])}>
				<div className={cn(styles.content, classPageContent)}>
					<div className={styles['btn-wrap']}>
						<Button
							elementType="navigate"
							variant="filled"
							color="secondary"
							href={ROUTE.getStarted}
							sizeWidth="full"
							size="large"
						>
							Start now
						</Button>
					</div>
				</div>
			</div>
		</section>
	);
}
