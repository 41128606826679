import cn from '../../lib/classNames';

import styles from './StepCard.module.scss';

type StepCardType = {
	num: number;
	title: string;
	description: string;
};

export default function StepCard(props: StepCardType) {
	const { num, title, description } = props;

	return (
		<div className={cn(styles['step-card'], 'welcome-shadow')}>
			<div className={styles.header}>
				<div className={styles.num}>{num}</div>
				<p className={cn(styles.title, 'text-large')}>{title}</p>
			</div>
			<p className={styles.description}>{description}</p>
		</div>
	);
}
