import { ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { ROUTE } from '../../App';
import { Context } from '../../components';
import { useMatchPath } from '../../lib';
import cn from '../../lib/classNames';
import { DashboardHeaderWithActions, Footer } from '../Dashboard';
import Alerts from '../Dashboard/Alerts/Alerts';

import styles from './LayoutDashboard.module.scss';

type LayoutDashboardType = {
	children: ReactNode;
	isLogout?: boolean;
};

export default function LayoutDashboard(props: LayoutDashboardType) {
	const { children, isLogout } = props;

	const [refreshComponent, setRefreshComponent] = useState<Date>();

	const elementToShare = useRef<HTMLDivElement>(null);

	const { googleToken } = useContext(Context);

	useEffect(() => {
		const { classList } = document.getElementById('body') as HTMLElement;
		classList.add('dashboard');
		return () => {
			classList.remove('dashboard');
		};
	}, []);

	useEffect(() => {
		document.addEventListener('visibilitychange', () => {
			if (document.visibilityState === 'visible') {
				setRefreshComponent(new Date());
			}
		});

		return () =>
			document.removeEventListener('visibilitychange', () => {
				if (document.visibilityState === 'visible') {
					setRefreshComponent(new Date());
				}
			});
	}, []);

	const isDashboard = useMatchPath(ROUTE.dashboard);
	const isReportHomeDestination = useMatchPath(ROUTE.reportHomeDestination);
	const isProfile = useMatchPath(ROUTE.profile);
	const isAlerts = isDashboard || isReportHomeDestination || isProfile;

	return (
		<div ref={elementToShare} className={styles['layout-dashboard']}>
			<div className={cn(styles.content, 'content', 'mob-pt-15')}>
				<DashboardHeaderWithActions isLogout={isLogout} />
				{isAlerts && <Alerts googleToken={googleToken} refreshComponent={refreshComponent} />}
				{children}
				<div className={styles['page-footer']}>
					<Footer className="footer-desk" />
				</div>
				<Footer className="footer-mob" />
			</div>
		</div>
	);
}
