import { call, put, select } from 'redux-saga/effects';

import {
	adaptDataForProfileApi,
	adaptFromProfileApi,
	getAgeBucketFromBirthday,
} from '../api/adapter';
import Config from '../config';
import { EVENT, EventManager, localStore } from '../lib';
import { adaptSearchPlacesResponse } from '../lib/geoCoding';
import DashboardActions, { GENDER_REVERSED } from '../store/DashboardRedux';
import NetworkActions, { PENDING } from '../store/NetworkRedux';
import {
	userFacebookData,
	userFacebookIsLogged,
	userFacebookToken,
	userFacebookUserId,
	userGoogleOAuth,
	userGoogleOAuthIdToken,
	userGoogleToken,
} from '../store/selectors';
import UserActions from '../store/UserRedux';

export function* userSetFacebookLoginStatus(api, action) {
	const state = yield select();
	const logged = userFacebookIsLogged(state);
	console.log('=== Sagas::userSetFacebookLoginStatus', { logged });
	if (logged) {
		// yield put(NetworkActions.networkAddPending(PENDING.facebookGetMe));
		yield put(UserActions.userSetFacebookDataLoaded(false));
		const data = yield call(() => {
			const p = new Promise((accept, reject) => {
				window.FB.api('/me', 'GET', { fields: Config.facebookAppFields }, (response) => {
					accept(response);
					console.log('=== Sagas::userSetFacebookLoginStatus /me', { response });
				});
			});
			return p;
		});
		// yield put(NetworkActions.networkDelPending(PENDING.facebookGetMe));
		yield put(UserActions.userSetFacebookDataLoaded(true));
		// console.log('=== *userSetFacebookLoginStatus::api.me', data);
		yield put(UserActions.userSetFacebookData(data));
		const personalData = {};
		if (data.email) personalData.email = data.email;
		if (data.gender) personalData.gender = GENDER_REVERSED[data.gender];
		if (data.ageBucket) personalData.ageBucket = getAgeBucketFromBirthday(data.birthday);
		yield put(DashboardActions.dashboardSetPersonal(personalData));
	} else {
		yield put(UserActions.userResetFacebookData());
		yield put(UserActions.userSetFacebookDataLoaded(true));
		// yield put(UserActions.userSetProfileDataLoaded(true));
	}
	yield true;
}

async function getApiToken(api, data) {
	const response = await api.authToken(data);
	if (response.ok) {
		const apiToken = response.headers.authorization;
		api.apiInstance.setHeader('Authorization', apiToken);
		localStore.setToken(apiToken);
		return apiToken;
	}
	return null;
}

export function* userSetFacebookData(api, action) {
	const state = yield select();
	const fb = userFacebookData(state);
	if (fb.location.id) {
		const data = yield call(() => {
			const p = new Promise((resolve) => {
				api.searchPlaces(fb.location.name).then((response) => {
					const items = adaptSearchPlacesResponse(response.data);
					const list = items.map((el) => ({
						label: el.name,
						value: el,
					}));
					resolve(list);
				});
			});
			return p;
		});
		yield put(
			DashboardActions.dashboardSetPersonal({
				location: data[0],
			}),
		);
	}
	const fbId = userFacebookUserId(state);
	const fbToken = userFacebookToken(state);
	const googleToken = userGoogleToken(state);
	console.log('=== Sagas::userSetFacebookData', { fbId, fbToken, googleToken });
	if (fbId && googleToken && fbToken) {
		const apiToken = yield call(() =>
			getApiToken(api, {
				fbEmail: fb.email,
				fbToken,
			}),
		);
		yield put(UserActions.userSetToken(apiToken));

		yield put(NetworkActions.networkAddPending(PENDING.getProfile));
		yield put(UserActions.userSetProfileDataLoaded(false));

		localStore.setLoggedAs('FB');

		yield api.updateProfile(
			adaptDataForProfileApi({
				email: fb.email,
				fbName: `${fb.firstName} ${fb.lastName}`,
				fbAvatar: fb.picture,
			}),
			googleToken,
		);

		const response = yield call(() => api.getProfile(googleToken));
		if (response.ok && response.data) {
			yield put(DashboardActions.dashboardSetPersonal(adaptFromProfileApi(response.data)));
			yield put(UserActions.userSetProfileData({ ...response.data, isLogged: true }));
		} else {
			yield put(UserActions.userSetProfileData({ isLogged: false }));
		}
		yield put(NetworkActions.networkDelPending(PENDING.getProfile));
		yield put(UserActions.userSetProfileDataLoaded(true));
	}
	yield true;
}

export function* userSetGoogleOAuthData(api, action) {
	const state = yield select();
	const googleToken = userGoogleToken(state);
	const idToken = userGoogleOAuthIdToken(state);
	if (googleToken && idToken) {
		const google = userGoogleOAuth(state);
		yield call(() => {
			EventManager.publish(EVENT.FCM_SEND_TOKEN);
		});
		const apiToken = yield call(() =>
			getApiToken(api, {
				googleToken: idToken,
			}),
		);
		yield put(UserActions.userSetToken(apiToken));

		yield put(NetworkActions.networkAddPending(PENDING.getProfile));
		yield put(UserActions.userSetProfileDataLoaded(false));

		localStore.setLoggedAs('GOOGLE');

		yield api.updateProfile(
			adaptDataForProfileApi({
				email: google.email,
				googleName: `${google.firstName} ${google.lastName}`,
				googleAvatar: google.picture,
			}),
			googleToken,
		);

		const response = yield call(() => api.getProfile(googleToken));
		console.log('=== Sagas::userSetGoogleOAuthData getProfile', { response });
		if (response.ok && response.data) {
			yield put(DashboardActions.dashboardSetPersonal(adaptFromProfileApi(response.data)));
			yield put(UserActions.userSetProfileData({ ...response.data, isLogged: true }));
		} else {
			yield put(UserActions.userSetProfileData({ isLogged: false }));
		}
		yield put(NetworkActions.networkDelPending(PENDING.getProfile));
		yield put(UserActions.userSetProfileDataLoaded(true));
	}
	yield true;
}

export function* userLogout() {
	yield put(DashboardActions.dashboardLogout());
	yield put(NetworkActions.networkLogout());
	localStore.setToken('');
}

export function* userSetProfileData() {
	yield call(() => {
		EventManager.publish(EVENT.FCM_SEND_TOKEN);
	});
}
