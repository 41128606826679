import { useCallback, useContext, useEffect, useState } from 'react';

import { Context } from '../../components';
import Button from '../../components/Buttons/Button';
import { validateEmail, validatePassword } from '../../lib';
import { userNameValidate } from '../../lib/userValidate';
import { api } from '../../sagas';
import cn from '../../lib/classNames';

import { FormField } from '.';
import styles from './UserProfile.module.scss';

type UserProfileType = {
	onSave: () => void;
	setLogout: (isLogout: boolean) => void;
};

export default function UserProfile(props: UserProfileType) {
	const { onSave, setLogout } = props;

	const [namesError, setNamesError] = useState('');
	const [isEmailError, setIsEmailError] = useState(false);
	const [isPasswordError, setIsPasswordError] = useState(false);

	const [APIErrorText, setAPIErrorText] = useState('');

	const [data, setData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		password: '',
	});

	const { setUnconfirmedEmail, userProfileData, setUserProfileData } = useContext(Context);

	useEffect(() => {
		setData({
			firstName: userProfileData.seFirstName,
			lastName: userProfileData.seLastName,
			email: userProfileData.seLogin,
			password: '',
		});
	}, []);

	const oldData = {
		seFirstName: userProfileData.seFirstName,
		seLastName: userProfileData.seLastName,
		seLogin: userProfileData.seLogin,
		password: '',
	};

	const onCheckFirstName = (name: string, value: string) => {
		if (name === 'firstName') {
			setNamesError('');
			const fieldValidation = userNameValidate('First name', value);

			if (fieldValidation) {
				setNamesError(fieldValidation);
			}
		}
	};

	const emailVerification = (email: string) => {
		const isError = validateEmail.validate({ email }).error;
		setIsEmailError(isError);
		return !isError;
	};

	const passwordVerification = (password: string) => {
		const isError = validatePassword.validate({ password }).error;
		setIsPasswordError(isError);
		return !isError;
	};

	const onChange = (name: string, inputValue: string) => {
		onCheckFirstName(name, inputValue);

		if (name === 'email') {
			setIsEmailError(false);
			setAPIErrorText('');
		}

		if (name === 'password') {
			setIsPasswordError(false);
		}
	};

	const onSaveData = ({ name, value }: { name: string; value: string }) => {
		if (name === 'email' && !emailVerification(value)) {
			setIsEmailError(true);
			return;
		}

		if (name === 'password' && !passwordVerification(value)) {
			setIsPasswordError(true);
			return;
		}

		if (name === 'firstName' || name === 'lastName' || name === 'email' || name === 'password') {
			setData({ ...data, [name]: value });
		}
	};

	const handleSubmit = useCallback(() => {
		if (!namesError && !isEmailError && !isPasswordError) {
			api.updateAccountInformation(data).then((response) => {
				if (response.ok) {
					setUserProfileData(response.data);

					if (response.data.unconfirmedEmail) {
						setUnconfirmedEmail(true);
					}

					if (oldData?.seLogin !== response?.data?.seLogin) {
						setLogout(true);
					}

					setUserProfileData({ ...response.data });
					onSave();
				} else {
					setAPIErrorText(response.data.message);
					console.log('::: UserProfile >> message: ', response.data.message);
				}
			});
		}
	}, [data, isEmailError, isPasswordError]);

	return (
		<div className={styles['user-profile-wrap']}>
			<div>
				<h2 className={cn(styles['user-title'], 'text-center')}>Update Account Information</h2>
				<div className={styles['field-error']}>{APIErrorText}</div>
			</div>
			<div className={styles['user-body']}>
				<div className={cn(styles['field-container'], styles['field-container-name'])}>
					<div className={styles['first-name']}>
						{FormField({
							placeholder: '',
							helpText: 'First name',
							typeField: 'text',
							name: 'firstName',
							value: userProfileData.seFirstName,
							onSaveData,
							onChange,
						})}
					</div>
					<div className={styles['last-name']}>
						{FormField({
							placeholder: '',
							helpText: 'Last name',
							typeField: 'text',
							name: 'lastName',
							value: userProfileData.seLastName,
							onSaveData,
							onChange,
						})}
					</div>
					{namesError && (
						<div className={cn(styles['field-error-text'], styles['field-error-text-name'])}>
							{namesError}
						</div>
					)}
				</div>
				<div className={cn(styles['field-container'], styles['field-container-email'])}>
					{FormField({
						placeholder: '',
						helpText: 'Email',
						typeField: 'text',
						name: 'email',
						value: userProfileData.seLogin,
						onSaveData,
						isEmailError,
						onChange,
						APIErrorText,
					})}
				</div>
				<div className={cn(styles['field-container'], styles['field-container-password'])}>
					{FormField({
						placeholder: userProfileData.passwordSet ? '' : 'Create Your Safe-Xplore Password',
						helpText: 'Password',
						typeField: 'password',
						name: 'password',
						value: userProfileData.passwordSet ? '******' : '',
						onSaveData,
						isPasswordError,
						onChange,
					})}
				</div>

				<div className={styles['submit-edit']}>
					<Button
						onClick={handleSubmit}
						disabled={!!(namesError || isEmailError || isPasswordError)}
						variant="filled"
						color="primary"
						sizeWidth="full"
					>
						SAVE
					</Button>
				</div>
			</div>
		</div>
	);
}
