import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE } from '../../App';
import { Context } from '../../components';
import useMediaQuery from '../../hooks/MediaQuery';
import Layout from '../Layout/LayoutDashboard';

import './ConflictMap.scss';

function ConflictMap() {
	const { userProfileData } = useContext(Context);

	const history = useHistory();
	const isDesktop = useMediaQuery('(min-width: 1024px)');

	const { additionalWidgets } = userProfileData;

	const handleMapHeight = () => {
		const { body } = document;
		const bodyHeight = body.offsetHeight;
		const content = document.querySelectorAll('.content');
		const contentStyle = content[0].currentStyle || window.getComputedStyle(content[0]);
		const iframeMapContainer = document.querySelectorAll('.iframe-map-container');
		const mapContainerTop = iframeMapContainer[0].getBoundingClientRect();
		const footerDesk = document.querySelectorAll('.footer-desk');
		const footerMob = document.querySelectorAll('.footer-mob');
		const footerHeight =
			footerDesk[0].offsetHeight > 0 ? footerDesk[0].offsetHeight : footerMob[0].offsetHeight;

		const mapContainerCalcHeight =
			bodyHeight - mapContainerTop.y - footerHeight - (parseInt(contentStyle.paddingBottom) + 2);

		isDesktop
			? (iframeMapContainer[0].style.height = `${mapContainerCalcHeight}px`)
			: (iframeMapContainer[0].style.height = '100vh');
	};

	useEffect(() => {
		handleMapHeight();
	}, [isDesktop]);

	const handleRedirect = () => {
		if (additionalWidgets == null || !additionalWidgets?.includes('UKR_WAR'))
			history.push(ROUTE.dashboard);
	};

	useEffect(() => {
		handleRedirect();
	}, []);

	return (
		<Layout>
			<div className="page-conflict-map">
				<main className="main main__conflict-map">
					<section className="body">
						<iframe
							className="iframe-map-container"
							width="100%"
							height="300"
							src="https://ukraine.safe-xplore.com/views/map"
							frameBorder="0"
							allowFullScreen
						/>
					</section>
				</main>
			</div>
		</Layout>
	);
}

export default ConflictMap;
