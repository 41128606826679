import { useState } from 'react';
import ReactPlayer from 'react-player';

import useMediaQuery from '../../../hooks/MediaQuery';
import { DEVICES } from '../../../lib/breakpoints';
import cn from '../../../lib/classNames';
import HeaderWidgets from '../HeaderWidgets';

import AboutSXReport from './AboutSXReport';

import styles from './WidgetHowSXWorks.module.scss';

const videoUrl = 'https://public-src.s3.us-east-2.amazonaws.com/sxpe/video/SafeXploreAbout.mp4';
const previewImage = 'https://public-src.s3.us-east-2.amazonaws.com/sxpe/image/SafeXploreAbout.png';

export default function WidgetHowSafeXploreWorks() {
	const isMobile = useMediaQuery(DEVICES.isMobile);

	const [onSound, setOnSound] = useState(isMobile);

	return (
		<div className={cn(styles['how-sx-works'], 'section-wrap-white')}>
			<div className={styles['wrapper-top']}>
				<div className={styles['header-wrap']}>
					<HeaderWidgets
						title="How safe-xplore works"
						subLine="Take a tour of our app by watching this brief guide."
						isDownloadBtn={false}
						isLogo={false}
					/>

					{!isMobile && (
						<div className={styles.soundBtnWrap} onClick={() => setOnSound(!onSound)}>
							<span
								className={cn(
									styles['icon-sound'],
									'material-icons-round',
									'bell-animate',
									'icons-animation',
								)}
							>
								{onSound ? 'volume_up' : 'volume_off'}
							</span>
						</div>
					)}
				</div>
				<div className={styles['video-wrap']}>
					<ReactPlayer
						className={styles['video-player']}
						url={videoUrl}
						light={isMobile ? previewImage : false}
						playing
						controls={isMobile}
						width="100%"
						height="100%"
						muted={!onSound}
						loop
					/>
				</div>
			</div>
			<div>
				<AboutSXReport />
			</div>
		</div>
	);
}
