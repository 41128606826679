const config = {
	useFixtures: false,
	debug: false,
	log: true,
	apiBaseURL: process.env.REACT_APP_API_ENDPOINT || 'https://dev.safe-xplore.com',
	// apiBaseURL: 'https://safe-xplore.com',
	mapbox: {
		accessToken:
			'pk.eyJ1IjoiZmlsaXBwbzMwNSIsImEiOiJjanRlbTVwNjMxam02NDNzenBxc3ByZzA4In0.P9lseqfFwQzcRzdAOgYi_A',
		geocoding: 'https://api.mapbox.com/geocoding/v5',
		style: 'mapbox://styles/filippo305/cju42q7t9074a1ft605hlegpa',
		accessTokenReport:
			'pk.eyJ1IjoibWFydGluc2Vhbmh1bnQiLCJhIjoiY2xvb21pa2prMDIzcjJrbHEzdHl5ajE5NSJ9.zf_yj5vtlxim_cXq6T0HxA',
		styleReport: 'mapbox://styles/mapbox/streets-v11',
		// ViCRI Report map
		accessTokenRatingMap:
			'pk.eyJ1IjoiZmlsaXBwbzMwNSIsImEiOiJjanN1aXVjd20xMm4zNDNvMTY1b2QyZ3dlIn0.yBNNjVibr4MmuddYcGtD0A',
		styleRatingMap: 'mapbox://styles/filippo305/clv4zq96c00i201oc83c15gt0',
		tilesetCountryLevel: 'mapbox://filippo305.7887f2r3',
		layerCountryLevel: 'se_level0_tolerance0-5knzy8',
		// TODO: testing tileset versions
		// Tileset Regions v1
		// tilesetRegionLevel: 'mapbox://filippo305.9c6f26k5',
		// layerRegionLevel: 'se_level1_tolerance0_005-55ydmg',
		// Tileset Regions v2 (03.05.2024)
		tilesetRegionLevel: 'mapbox://filippo305.d9l995yf',
		layerRegionLevel: 'se_level1_2_tolerance_0_01-05pvr2',
	},
	reCaptchaKey: process.env.REACT_APP_RECAPTCHAKEY || '6LfP4HcbAAAAALo1bwrjTtvWXuazWoTMpUCOL-Rs',
	googleAnalytics: 'G-98GQ3QTJZN',
	urlShare: 'https://safe-xplore.com',
	imgShare: 'https://safe-xplore.com/1200x630.jpg',

	googleAuthId: '169751093205-ahkvpnovvle28264minbh955otq8o46o.apps.googleusercontent.com',
	appleAuthId: 'com.safeesteem.personal-edition.login',
	appleRedirectURI: 'https://safe-xplore.com/common/apple/login-notification',
	appleScope: 'name+email',
	firebase: {
		apiKey: 'AIzaSyDQRhiHYIe-zTP_Ytjhn4UvQGch29vndsA',
		authDomain: 'safe-xplorecom.firebaseapp.com',
		projectId: 'safe-xplorecom',
		storageBucket: 'safe-xplorecom.appspot.com',
		messagingSenderId: '962310526059',
		appId: '1:962310526059:web:7919376ef150266cf10d2e',
		measurementId: 'G-FP3YLR1MNZ',
	},
	ortto: {
		generalKey: 'ZJwvjdMG84ljhJTcc2FmZWVzdGVlbQ',
		clickAcademyTip: {
			id: 'act:cm:clickedacademytip',
			key: 'PRV-safeesteem-_07e7lc6HE8g7dk_DVDPneRtHps3R8WOygZJVuSVgNg',
		},
		createReport: {
			id: 'act:cm:createdreport',
			key: 'PRV-safeesteem-_07e7lc6HE8g7dk_DVDPneRtHps3R8WOygZJVuSVgNg',
		},
		purchaseReport: {
			id: 'act:cm:purchasedreport',
			key: 'PRV-safeesteem-_07e7lc6HE8g7dk_DVDPneRtHps3R8WOygZJVuSVgNg',
		},
		gotAcademyTip: {
			id: 'act:cm:gotacademytip',
			key: 'PRV-safeesteem-_07e7lc6HE8g7dk_DVDPneRtHps3R8WOygZJVuSVgNg',
		},
	},
};

export default config;
