import { useContext, useEffect, useState } from 'react';

import { ApiReportHistoryListType } from '../../../api/types/apiGetReportHistoryList';
import { Context, Loader } from '../../../components';
import { formaterDate } from '../../../lib';
import cn from '../../../lib/classNames';
import { api } from '../../../sagas';

import ReportHistoryItem from './ReportHistoryItem';

import styles from './ReportHistory.module.scss';

type ReportHistory = {
	title: string;
};

function ReportHistory(props: ReportHistory) {
	const { title = '' } = props;

	const [historyData, setHistoryData] = useState<ApiReportHistoryListType[]>([]);
	const [isLoader, setIsLoader] = useState(true);

	const { userProfileData } = useContext(Context);

	const isLimitedUser = userProfileData.company === null || userProfileData.company?.billableReport;

	const getReportHistory = () => {
		api
			.getHomeDestinationReportsHistoryList(30)
			.then((response) => {
				if (response.ok) {
					setHistoryData(response.data);
					setIsLoader(false);
				}
			})
			.catch((err) => {
				console.log('*** ReportHistory > getReportHistory', err);
			});
	};

	useEffect(() => {
		getReportHistory();
	}, []);

	const renderReportItem = historyData.map((e) => {
		return (
			<ReportHistoryItem
				hashLink={`/home-destination-report/${e.hashLink}`}
				createdAt={formaterDate(e.createdAt, 'MMMM D, yyyy H:mm a')}
				expiredAt={formaterDate(e.expiredAt, 'MMMM D, yyyy')}
				mbLocName={e.mbLocName}
				paid={e.paid}
				isPro={!isLimitedUser || e.paid}
				key={e.hashLink}
			/>
		);
	});

	const renderReportHistory = () => {
		if (isLoader) {
			return <Loader size="small" />;
		}

		if (historyData.length > 0) {
			return <ul className={styles['history-list']}>{renderReportItem}</ul>;
		}

		return (
			<div className={styles['history-stub-wrap']}>
				<h4 className={styles['history-text-stub']}>You haven't purchased any reports yet.</h4>
			</div>
		);
	};

	useEffect(() => {
		renderReportHistory();
	}, [historyData]);

	return (
		<div
			className={cn(
				styles['report-history'],
				{ [styles.loading]: isLoader, [styles['with-scroll']]: historyData.length > 0 },
				'scrollbar-custom',
			)}
		>
			<header className={styles.header}>
				<h3 className={styles.title}>{title}</h3>
			</header>
			<div className={styles.body}>{renderReportHistory()}</div>
		</div>
	);
}

export default ReportHistory;
