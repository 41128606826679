import { ReactNode } from 'react';

import cn from '../../../lib/classNames';

import styles from './TabButton.module.scss';

type TabButtonType = {
	className?: string;
	iconBefore?: JSX.Element;
	iconAfter?: JSX.Element;
	children: ReactNode;
	onClick: () => void;
	disabled?: boolean;
	active: boolean;
};

export default function TabButton(props: TabButtonType) {
	const { className, iconBefore, iconAfter, children, onClick, disabled = false, active } = props;

	const renderIconBefore = (
		<span className={cn(styles['btn-icon'], 'btn-icon', 'btn-icon-before')}>{iconBefore}</span>
	);

	const renderIconAfter = (
		<span className={cn(styles['btn-icon'], 'btn-icon', 'btn-icon-after')}>{iconAfter}</span>
	);

	return (
		<button
			className={cn(
				styles.button,
				{
					[styles.disabled]: disabled,
					[styles.active]: active,
				},
				className,
			)}
			onClick={onClick}
			disabled={disabled}
			type="button"
		>
			{iconBefore && renderIconBefore}
			<span className={cn(styles['btn-text'], 'btn-text')}>{children}</span>
			{iconAfter && renderIconAfter}
		</button>
	);
}
