import { ChangeEvent } from 'react';
import { Input as MUTextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Color } from '../theme';

const useStyles = makeStyles({
	root: {
		width: 240,
		fontSize: '1.6rem',

		'&:before, &:after': {
			borderBottom: `2px solid ${Color.grayLight}`,
		},
	},

	focused: {
		'&:before, &:after': {
			borderColor: `${Color.gray}!important`,
		},
	},
});

type InputType = {
	onChange: (_value: boolean | string) => void;
};

export default function Input(props: InputType) {
	const { onChange, ...rest } = props;

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (onChange) onChange(e.target.value);
	};

	const classes = useStyles();

	return (
		<MUTextField
			placeholder="Enter your email"
			classes={classes}
			onChange={handleChange}
			{...rest}
		/>
	);
}
