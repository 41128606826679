import { ReactNode, useState } from 'react';

import ImageUploading, { ImageListType } from 'react-images-uploading';

import styles from './UploadFile.module.scss';

type UploadFileType = {
	children: ReactNode;
	acceptType: string[];
	onSelect: (imageList: ImageListType) => void;
	maxFileSize: number;
};

function UploadFile(props: UploadFileType) {
	const { children, acceptType, onSelect, maxFileSize } = props;

	const [images, setImages] = useState<ImageListType>([]);
	const maxNumber = 3;

	const onChange = (imageList: ImageListType) => {
		onSelect(imageList);
		setImages(imageList);
	};

	return (
		<ImageUploading
			multiple={false}
			value={images}
			onChange={onChange}
			maxNumber={maxNumber}
			dataURLKey="data_url"
			acceptType={acceptType}
			maxFileSize={maxFileSize}
		>
			{({ onImageUpload, isDragging, dragProps, errors }) => (
				<div className={styles['upload-image-wrap']}>
					<div className={styles['avatar-box']}>
						<button
							style={isDragging ? { color: 'red' } : undefined}
							onClick={onImageUpload}
							{...dragProps}
						>
							{children}
						</button>
						<div className={styles['errors-wrap']}>
							{errors?.acceptType && (
								<div>
									We do not support this type of image. Please try to upload JPG/JPEG or PNG images.
								</div>
							)}
							{errors?.maxFileSize && (
								<div>
									The image you've been trying to upload is too big. Please try to use another
									image, its size should be less than 2 Megabytes.
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</ImageUploading>
	);
}

export default UploadFile;
