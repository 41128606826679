import { CSSProperties } from 'react';

import cn from '../lib/classNames';

import styles from './SliderLine.module.scss';

type SliderLineType = {
	className: string;
	[key: string]: CSSProperties | string;
};

export default function SliderLine(props: SliderLineType) {
	const { className, ...rest } = props;

	return <div className={cn(styles['slider-line'], className)} {...rest} />;
}
