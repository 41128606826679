import cn from '../../lib/classNames';

import appStore from './images/app_store_icon.svg';
import googlePlay from './images/google_play_icon_v1.svg';

import styles from './MarketsBtn.module.scss';

type MarketsBtnType = {
	className?: string;
	classBtn?: string;
};

export default function MarketsBtn(props: MarketsBtnType) {
	const { className, classBtn } = props;

	return (
		<div className={cn(styles['btn-wrap'], className)}>
			<div className={cn(styles['app-store'], styles['btn-store'], classBtn)}>
				<a href="https://apple.co/3pQ5dya">
					<img src={appStore} alt="Download on the App Store" />
				</a>
			</div>
			<div className={cn(styles['google-play'], styles['btn-store'], classBtn)}>
				<a href="https://play.google.com/store/apps/details?id=com.safexplore">
					<img src={googlePlay} alt="" />
				</a>
			</div>
		</div>
	);
}
