import { useOrttoActivityPurchaseReport } from '../context/ortto/hooks';
import cn from '../lib/classNames';

import styles from './ButtonBuyReport.module.scss';

type ButtonBuyReportType = {
	link?: string | null;
	buttonText: string;
	buttonNumberOld?: string;
	buttonNumber?: string;
	extraClass?: string;
	destination?: string;
};

export default function ButtonBuyReport(props: ButtonBuyReportType) {
	const { link, buttonText, buttonNumberOld, buttonNumber, extraClass, destination } = props;
	const track = useOrttoActivityPurchaseReport();

	return (
		<a
			className={cn(styles['button-buy-report'], styles['button-buy-report-weight'], extraClass)}
			href={link || ''}
			onClick={() => track(destination)}
		>
			{buttonText}{' '}
			{buttonNumberOld && <span className={styles['price-old']}>{buttonNumberOld}</span>}{' '}
			{buttonNumber}
		</a>
	);
}
