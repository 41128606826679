import { createContext, useContext, useEffect } from 'react';

import { Context } from '../../components';
import config from '../../config';
import { api } from '../../sagas';

const HEARST_FAKE_EMAIL = 'anonymous_hearst_user@test.mail';
const isTrackingOn = !!Number(process.env.REACT_APP_ORTTO_TRACKING);

export const OrttoContext = createContext();

export default function OrttoProvider({ children }) {
	const { userProfileData, fromToDetails } = useContext(Context);
	const { email } = userProfileData;

	const getUserData = (isHearst) => {
		if (isHearst) {
			return {
				userFirstName: fromToDetails?.personName,
				userLastName: '',
				userEmail: HEARST_FAKE_EMAIL,
				userHome: fromToDetails?.home,
				userAgeGroup: fromToDetails?.personAgeBucket,
				userGender: fromToDetails?.personGender,
				userCompany: fromToDetails?.personCompany,
			};
		}
		return {
			userFirstName: userProfileData?.seFirstName,
			userLastName: userProfileData?.seLastName,
			userEmail: email,
			userHome: userProfileData?.name,
			userAgeGroup: userProfileData?.ageBucket,
			userGender: userProfileData?.gender,
			userCompany: userProfileData?.company?.name || '',
		};
	};

	const init = () => {
		const APIkeySE = config?.ortto?.generalKey;

		if (!APIkeySE) return;

		window.ap3c = window.ap3c || {};
		const { ap3c } = window;
		ap3c.cmd = ap3c.cmd || [];
		ap3c.cmd.push(function () {
			ap3c.init(APIkeySE, 'https://capture-api.autopilotapp.com/', {
				cookieDomains: ['safe-esteem.com', 'safe-xplore.com'],
				link: 'g',
			});
			// TODO: delete
			// ap3c.init(APIkeySE, 'https://capture-api.autopilotapp.com/', {
			//	cookieDomains: ['safe-esteem.com', 'safe-xplore.com', 'localhost:3000'],
			//	link: 'g',
			// });
			ap3c.track({ v: 0, email });
		});
		ap3c.activity = function (act) {
			ap3c.act = ap3c.act || [];
			ap3c.act.push(act);
		};
		let s;
		let t;
		s = document.createElement('script');
		s.type = 'text/javascript';
		s.src = 'https://cdn2l.ink/app.js';
		t = document.getElementsByTagName('script')[0];
		t.parentNode.insertBefore(s, t);
	};

	useEffect(() => {
		if (isTrackingOn) {
			init();
		}
	}, [isTrackingOn, email]);

	const track = (activity, userDestination = '', description = '', isHearst = false) => {
		const { id: activityId, key: activityKey } = activity;

		if (!activityId || !isTrackingOn) return;

		const userData = getUserData(isHearst);

		const {
			userFirstName,
			userLastName,
			userEmail,
			userHome,
			userAgeGroup,
			userGender,
			userCompany,
		} = userData;

		// description we use for type of Academy Tip and for Hearst mark
		if (!description && isHearst) {
			description = 'Hearst';
		}

		const data = {
			activities: [
				{
					activity_id: activityId,
					attributes: {
						'str:cm:report-destination': userDestination,
						'str:cm:description': description,
					},
					fields: {
						'str::email': userEmail,
						'str::first': userFirstName,
						'str::last': userLastName,
						'str:cm:home': userHome,
						'str:cm:organization': userCompany,
						'str:cm:gender': userGender,
						'str:cm:age-group': userAgeGroup,
					},
				},
			],
			merge_by: ['str::email'],
		};

		api
			.customOrttoActivity(data, activityKey)
			.then((response) => {
				if (response.ok) {
					// TODO: temp for debug
					console.log('### Ortto', activityId, data);
				}
			})
			.catch((error) => {
				// TODO: temp for debug
				console.error('### Ortto error', error.message);
			});
	};

	return <OrttoContext.Provider value={{ track }}>{children}</OrttoContext.Provider>;
}

export const useOrtto = () => useContext(OrttoContext);
