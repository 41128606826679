import { Link } from 'react-router-dom';

import cn from '../../../lib/classNames';

import styles from './ReportHistoryItem.module.scss';

type ReportHistoryItemType = {
	hashLink: string;
	createdAt: string;
	expiredAt?: string;
	mbLocName: string;
	paid: boolean;
	isPro: boolean;
};

export default function ReportHistoryItem(props: ReportHistoryItemType) {
	const { hashLink, createdAt, expiredAt, mbLocName, paid, isPro = true } = props;

	return (
		<li className={styles['report-history-item']}>
			<Link to={hashLink}>
				<div className={styles.header}>
					<div className={styles['time-wrap']}>
						<time className={cn(styles.time, styles.create)} dateTime={createdAt}>
							<span className={styles['time-title']}>Created at: </span>
							{createdAt}
						</time>
						{expiredAt && (
							<time className={cn(styles.time, styles.expires)} dateTime={expiredAt}>
								<span className={styles['time-title']}>Expires on: </span>
								{expiredAt}
							</time>
						)}
					</div>
					{isPro && <span className={styles['label-pro']}>PRO</span>}
				</div>
				<span className={styles['label-wrap']}>
					<span className={cn(styles['label-icon-location'], 'material-icons-round')}>
						location_on
					</span>
					<span className={cn(styles['label-text'], 'overflow-row-1')} title={mbLocName}>
						{mbLocName}
					</span>
					<span className={cn(styles['label-icon-arrow'], 'material-icons-round')}>
						arrow_forward
					</span>
				</span>
			</Link>
		</li>
	);
}
