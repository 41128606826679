import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import cn from '../../lib/classNames';

import styles from './Button.module.scss';

type ButtunTarget = '_blank' | '_self' | '_parent' | '_top' | 'framename';
type ButtonVariant = 'filled' | 'outlined' | 'text';

type ButtonType = {
	elementType?: 'button' | 'link' | 'navigate';
	iconBefore?: string;
	children: ReactNode;
	iconAfter?: string;
	className?: string;
	onClick?: () => void;
	disabled?: boolean;
	variant: ButtonVariant;
	color: 'primary' | 'secondary';
	sizeWidth?: 'content' | 'full';
	href?: string;
	target?: ButtunTarget;
	size?: 'normal' | 'large';
};

export default function Button(props: ButtonType) {
	const {
		elementType,
		iconBefore,
		children,
		iconAfter,
		className,
		onClick,
		disabled = false,
		variant = 'filled',
		color = 'primary',
		sizeWidth = 'content',
		href = '',
		target,
		size = 'normal',
		...restProps
	} = props;

	const renderIconBefore = (
		<span
			className={cn(styles['btn-icon'], 'btn-icon', 'btn-icon-before', 'material-icons-outlined')}
		>
			{iconBefore}
		</span>
	);

	const renderIconAfter = (
		<span
			className={cn(styles['btn-icon'], 'btn-icon', 'btn-icon-after', 'material-icons-outlined')}
		>
			{iconAfter}
		</span>
	);

	if (elementType === 'link') {
		return (
			<a
				className={cn(
					styles.button,
					styles[`variant-${variant}`],
					styles[`color-${color}`],
					styles[`size-${size}`],
					{
						[styles['size-width-full']]: sizeWidth === 'full',
					},
					className,
				)}
				href={href}
				target={target}
				{...restProps}
			>
				{iconBefore && renderIconBefore}
				<span className={styles['btn-text']}>{children}</span>
				{iconAfter && renderIconAfter}
			</a>
		);
	}
	if (elementType === 'navigate') {
		return (
			<Link
				className={cn(
					styles.button,
					styles[`variant-${variant}`],
					styles[`color-${color}`],
					styles[`size-${size}`],
					{
						[styles['size-width-full']]: sizeWidth === 'full',
					},
					className,
				)}
				to={href}
				{...restProps}
			>
				{iconBefore && renderIconBefore}
				<span className={styles['btn-text']}>{children}</span>
				{iconAfter && renderIconAfter}
			</Link>
		);
	}

	return (
		<button
			className={cn(
				styles.button,
				styles[`variant-${variant}`],
				styles[`color-${color}`],
				styles[`size-${size}`],
				{ [styles.disabled]: disabled, [styles['size-width-full']]: sizeWidth === 'full' },
				className,
			)}
			type="button"
			onClick={onClick}
			disabled={disabled}
		>
			{iconBefore && renderIconBefore}
			<span className={styles['btn-text']}>{children}</span>
			{iconAfter && renderIconAfter}
		</button>
	);
}
