import * as d3 from 'd3';

type DataType = {
	health?: number;
	crime?: number;
	accidents?: number;
};

type ColorType = {
	health: string;
	crime: string;
	accidents: string;
	cover: string;
};

type RelativeRiskChartV2Type = {
	data?: DataType;
	pieOuterRadius?: number;
	pieInnerRadius?: number;
	color?: ColorType;
	width?: number;
	height?: number;
};

export default function RelativeRiskChartV2(props: RelativeRiskChartV2Type) {
	const {
		data = {
			health: 53,
			crime: 24,
			accidents: 23,
		},
		pieOuterRadius = 70,
		pieInnerRadius = 35,
		color = {
			health: 'rgb(255, 203, 0)',
			crime: 'rgb(255, 0, 0)',
			accidents: 'rgb(126, 242, 243)',
			cover: '#fff',
		},
		width = 208,
		height = 208,
	} = props;

	let chart: SVGSVGElement | null = null;

	let chartInited = false;

	let svg: d3.Selection<SVGSVGElement, unknown, null, undefined> | null = null;

	const initDefs = () => {
		if (svg) {
			const defs = svg.append('defs');
			const maskDestination = defs.append('mask').attr('id', 'mask-destination');
			maskDestination
				.append('rect')
				.attr('x', -50)
				.attr('y', -50)
				.attr('width', 100)
				.attr('height', 200)
				.attr('fill', 'white');
			maskDestination
				.append('circle')
				.attr('cx', 9.5)
				.attr('cy', 9)
				.attr('r', 4)
				.attr('fill', 'black');
		}
	};

	const initPie = () => {
		if (svg) {
			const path = d3.arc().outerRadius(pieOuterRadius).innerRadius(pieInnerRadius);
			const pie = svg
				.append('g')
				.attr('class', 'relative-risk-pie')
				.attr('transform', `translate(70, 70)`);
			const dataAdapted = [data.crime!, data.accidents!, data.health!];

			const pieAdapter = d3
				.pie<number>()
				.value((d) => d)
				.sort(null)
				.endAngle(Math.PI * 2);
			const colors = [color.crime, color.accidents, color.health];

			pie
				.append('circle')
				.attr('cx', 0)
				.attr('cy', 0)
				.attr('r', pieOuterRadius * 0.8)
				.attr('fill', color.cover);

			pie
				.selectAll('path')
				.data(pieAdapter(dataAdapted))
				.enter()
				.append('path')
				.attr('d', (d: any) => path(d)!)
				.attr('fill', (d, i) => colors[i]);
		}
	};

	const initChart = () => {
		if (chart && !chartInited) {
			chartInited = true;

			const svgD3 = d3.select(chart);
			svgD3.attr('width', width).attr('height', height);
			svg = svgD3;

			initDefs();
			initPie();
		}
	};

	return (
		<svg
			className="pie-chart"
			viewBox="0 0 140 140"
			ref={(ref) => {
				chart = ref;
				initChart();
			}}
		/>
	);
}
