import { SelectWithButtons } from '../../../components';
import Button from '../../../components/Buttons/Button';
import { GENDERS } from '../../../lib/constats';
import Header from '../Header';
import IndicatorsDots from '../IndicatorsDots';

type GetStartedStep2Type = {
	step?: number;
	stepsCount: number;
	gender: number;
	handleSelectGender: (_gender: number) => void;
	handleNextStep: (_num: number) => void;
};

export default function GetStartedStep2(props: GetStartedStep2Type) {
	const { step, stepsCount, gender, handleSelectGender, handleNextStep } = props;

	return (
		<div className="get-started-steps-container">
			<IndicatorsDots currentDot={step} stepsCount={stepsCount} />
			<Header
				title="Select your sex at birth"
				description="Please select your gender to continue"
				displayLGBTQI
			/>
			<div className="fields-container fields-container-pos-center">
				<SelectWithButtons items={GENDERS} selectedIndex={gender} onSelect={handleSelectGender} />
			</div>
			<div className="block-btn">
				<Button
					variant="filled"
					color="primary"
					onClick={() => {
						if (gender >= 0) handleNextStep(3);
					}}
					disabled={gender < 0}
				>
					NEXT
				</Button>
			</div>
		</div>
	);
}
