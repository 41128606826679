import locationDestinationIcon from '../../../../images/location_destination_icon_dark.svg';
import homeIcon from '../../../../images/location_home_icon_dark.svg';
import { Loader } from '../../../components';

type AdditionStep3Type = {
	locationHome: string;
	locationDestination?: string;
};

export default function AdditionStep3(props: AdditionStep3Type) {
	const { locationHome, locationDestination } = props;

	return (
		<div className="main-container main-container-step3">
			<div className="content">
				<div className="title-wrap">
					<h3 className="location location-home overflow-row-1" title={locationHome}>
						<img className="icon" src={homeIcon} alt="" />
						{locationHome}
					</h3>
					<span className="material-icons icon-compare">compare_arrows</span>
					<h3 className="location location-destination overflow-row-1" title={locationDestination}>
						<img className="icon" src={locationDestinationIcon} alt="" />
						{locationDestination}
					</h3>
				</div>
				<Loader size="small" />
			</div>
		</div>
	);
}
