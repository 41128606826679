import { AreasItemType } from '../../../../api/types/apiGetReport';
import cn from '../../../../lib/classNames';
import Accordion from '../Accordion';

import TabInfo from './TabInfo';

import styles from './HighRisk.module.scss';

type HighRiskType = {
	data: AreasItemType[];
	helpMessage?: string;
};

export default function HighRisk(props: HighRiskType) {
	const { data, helpMessage } = props;
	const showHelpMessage = !data.length;
	return (
		<div className={styles['high-risk']}>
			{showHelpMessage ? (
				helpMessage
			) : (
				<>
					<p className={cn(styles.description, styles['header-description'])}>
						Areas that are known to be of higher risk and may require additional caution:
					</p>
					<Accordion
						className={styles['contacts-wrap']}
						description={<TabInfo data={data} icon="dangerous" />}
					/>
				</>
			)}
		</div>
	);
}
