import { GraphColor } from '../../../components';

import './SimilarClassRiskCities.scss';

type SimilarCitiesType = {
	riskValue?: number;
	crimeRiskIndexLocations?: string[];
	classRiskColor: string;
	isConflict: boolean;
	isNoData: boolean;
};

export default function SimilarClassRiskCities(props: SimilarCitiesType) {
	const { riskValue, crimeRiskIndexLocations, classRiskColor, isConflict, isNoData } = props;

	const isDisplayData = !isConflict && !isNoData && !!riskValue;

	const locations = crimeRiskIndexLocations?.map((location): JSX.Element => {
		return (
			<p className="overflow-row-1" key={location} title={location}>
				{location}
			</p>
		);
	});

	const renderLocations = (): JSX.Element[] | JSX.Element => {
		if (isConflict || isNoData) {
			return [];
		}
		if (crimeRiskIndexLocations?.length && locations) {
			return locations;
		}
		if (!riskValue) {
			return (
				<p className="no-data-info">
					City-level data for this location is not available yet. See county/state or national-level
					ratings below.
				</p>
			);
		}
		return [];
	};

	return (
		<div className="similar-cities-right similar-cities-info">
			<div className="similar-cities-info-header">
				{isDisplayData ? <GraphColor className={classRiskColor} /> : ''}
				<div className="similar-cities-info-text">
					{isDisplayData ? <h3>Similar Class {riskValue} Cities</h3> : ''}
					{renderLocations()}
				</div>
			</div>
		</div>
	);
}
