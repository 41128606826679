import cn from '../../../lib/classNames';

import styles from './SectionTitle.module.scss';

type SectionTitleType = {
	className?: string;
	title: string;
	subTitle?: string;
};

export default function SectionTitle(props: SectionTitleType) {
	const { className, title, subTitle } = props;

	return (
		<section className={cn(className, styles['section-title'])}>
			<h3>{title}</h3>
			{subTitle && <p className={styles['sub-title']}>{subTitle}</p>}
		</section>
	);
}
