import { ButtonAppleAuth, ButtonGoogleAuth } from '../../components';

import Header from './Header';
import LogInSignUpAuth from './LogInSignUpAuth';

type SignInType = {
	onForgot: (isForgot: boolean) => void;
};

export default function SignIn(props: SignInType) {
	const { onForgot } = props;
	return (
		<div className="form-sign-in">
			<Header title="Log-in or Create Account" />
			<div className="form-container form-auth-container">
				<LogInSignUpAuth onForgot={onForgot} />
			</div>
			<p className="text-auth">Or, you can use your Social Media credentials instead.</p>
			<div className="btn-auth-container">
				<ButtonAppleAuth />
				<ButtonGoogleAuth />
			</div>
			<p className="text-terms">
				By signing in, you agree our{' '}
				<a
					href="https://www.safe-esteem.com/terms-of-use"
					target="_blank"
					rel="noopener noreferrer"
				>
					Terms and Conditions
				</a>
			</p>
		</div>
	);
}
