import { useState } from 'react';

import LinkBox from '../../../components/Buttons/LinkBox';
import AboutUs from '../../About/AboutUs';

import styles from './AboutSXReport.module.scss';

export default function TravelRiskAbout() {
	const [isModalOpen, setIsModalOpen] = useState(false);
	return (
		<section className={styles['travel-risk-about']}>
			<div>
				<h2 className={styles['info-title']}>About Safe-xplore Reports</h2>
				<p className={styles['info-text']}>
					Live and travel with confidence by understanding your risks at home or when traveling.
				</p>
			</div>
			<LinkBox elementType="button" onClick={() => setIsModalOpen(true)} iconAfter="open_in_new">
				Learn more
			</LinkBox>
			<AboutUs isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
		</section>
	);
}
