import { useState, useEffect } from 'react';

import { RiskSlider } from '../../components';

export type Question1DataType = {
	[key: string]: string | number | boolean;
};

type Question1Type = {
	screen: number;
	data: (data: Question1DataType) => void;
	questionNumber?: number;
};

export default function Question1(props: Question1Type) {
	const { screen, data } = props;

	const [value, setValue] = useState([33, 67]);
	const [currentData, setCurrentData] = useState({
		crime: value[0],
		accidents: value[1] - value[0],
		health: 100 - value[1],
	});

	useEffect(() => {
		data(currentData);
	}, [currentData]);

	if (screen !== 1) return null;

	return <RiskSlider value={value} currentData={setCurrentData} aria-labelledby="range-slider" />;
}
