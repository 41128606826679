import { useCallback, useContext, useEffect, useState } from 'react';
import get from 'get-value';

import { Context, Select, SelectLocation } from '../../components';
import Button from '../../components/Buttons/Button';
import InfoLGBTQI from '../../components/InfoLGBTQI';
import { AGE_BUCKET, fakeAgeBucket, GENDERS_FOR_SELECT } from '../../lib/constats';
import { api } from '../../sagas';

import { LocationDataType } from '../../types/LocationData';

import './PersonalData.scss';

type PersonalDataType = {
	onSave: () => void;
};

function PersonalData(props: PersonalDataType) {
	const { onSave } = props;

	const [ageBucket, setAgeBucket] = useState('');
	const [gender, setGender] = useState('');
	const [place, setPlace] = useState<LocationDataType>();

	const [APIErrorText, setAPIErrorText] = useState('');

	const { googleToken, userProfileData, userLocation, setUserProfileData, setHomeData } =
		useContext(Context);

	useEffect(() => {
		setAgeBucket(fakeAgeBucket(userProfileData.ageBucket));
		setGender(userProfileData.gender);
		setPlace(userLocation);
	}, [userProfileData, userProfileData.ageBucket, userProfileData.gender, userLocation]);

	const handleSelectAge = useCallback((value: string) => {
		setAgeBucket(value);
	}, []);

	const handleSelectGender = useCallback((value: string) => {
		setGender(value);
	}, []);

	const handleSelectPlace = useCallback((newPlace: LocationDataType) => {
		setPlace(newPlace);
	}, []);

	const isUpdateData = () => {
		if (
			userProfileData &&
			userProfileData.ageBucket === ageBucket &&
			userProfileData.gender === gender &&
			userProfileData.name === place?.label
		) {
			return false;
		}
		return true;
	};

	useEffect(() => {
		isUpdateData();
	}, [ageBucket, gender, place]);

	const handleSubmit = useCallback(() => {
		const payloadData = {
			ageBucket: fakeAgeBucket(ageBucket),
			gender,
			id: place?.value?.id,
			lat: place?.value?.latitude,
			lng: place?.value?.longitude,
			name: place?.value?.name,
			allRiskFactors: true,
		};

		api.updateProfile(payloadData, googleToken).then((response) => {
			if (response.ok) {
				setUserProfileData(response.data);
				onSave();
				api.homeReport(payloadData, googleToken).then((response) => {
					if (response.ok) {
						const homeRequestId = get(response, 'data.homeRequestId');
						if (homeRequestId) {
							const report = get(response, 'data.report');
							setHomeData({
								homeRequestId,
								...report,
							});
						}
					}
				});
			} else {
				setAPIErrorText(response.data.message);
			}
		});
	}, [ageBucket, gender, place, googleToken]);

	return (
		<div className="user-personal-data-wrap">
			<div className="user-personal-data-header">
				<h2 className="user-personal-data-title text-center">Update Profile Preferences</h2>
				<div className="field-error">{APIErrorText}</div>
			</div>
			<div className="user-personal-data-body">
				<div className="field-wrap field-wrap-personal field-wrap-location">
					<div className="field-inner">
						<div className="label">Home city</div>
						<SelectLocation place={place} onSelect={handleSelectPlace} withoutBorder />
					</div>
				</div>

				<div className="field-wrap field-wrap-personal field-wrap-gender">
					<div className="field-inner">
						<div className="label">
							Gender <InfoLGBTQI smallIcon />
						</div>
						<Select
							style={{ width: '100%' }}
							value={gender}
							items={GENDERS_FOR_SELECT}
							onSelect={handleSelectGender}
						/>
					</div>
				</div>

				<div className="field-wrap field-wrap-personal field-wrap-age">
					<div className="field-inner">
						<div className="label">Age group</div>
						<Select
							style={{ width: '100%' }}
							value={ageBucket}
							items={AGE_BUCKET}
							onSelect={handleSelectAge}
						/>
					</div>
				</div>
				<div className="btn submit-edit">
					<Button
						onClick={handleSubmit}
						disabled={!isUpdateData()}
						variant="filled"
						color="primary"
						sizeWidth="full"
					>
						SAVE
					</Button>
				</div>
			</div>
		</div>
	);
}

export default PersonalData;
