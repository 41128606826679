import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE } from '../../../App';
import { Context } from '../../../components';
import { LocationDataType } from '../../../types/LocationData';

import styles from './MostPopularDestinations.module.scss';

const examplesData: LocationDataType[] = [
	{
		label: 'Atlanta',
		value: {
			id: 'place.13633772',
			latitude: 33.748547,
			longitude: -84.39153,
			name: 'Atlanta, Georgia, United States',
			type: 'Feature',
		},
	},
	{
		label: 'Cancún',
		value: {
			id: 'place.4024479',
			latitude: 21.16188,
			longitude: -86.85176,
			name: 'Cancún, Quintana Roo, Mexico',
			type: 'Feature',
		},
	},
	{
		label: 'Paris',
		value: {
			id: 'place.894029',
			latitude: 48.8534951,
			longitude: 2.3483915,
			name: 'Paris, France',
			type: 'Feature',
		},
	},
	{
		label: 'Rio de Janeiro',
		value: {
			id: 'place.34498592',
			latitude: -22.909534,
			longitude: -43.209934,
			name: 'Rio de Janeiro, Rio de Janeiro, Brazil',
			type: 'Feature',
		},
	},
];

export default function MostPopularDestinations() {
	const history = useHistory();
	const { dailyViewedLimitedList } = useContext(Context);

	const handleChangeDestination = (destinationPlace: LocationDataType) => {
		history.push({
			pathname: ROUTE.changeDestination,
			state: {
				inputPlace: destinationPlace,
				inputStep: dailyViewedLimitedList.length < 3 ? 1 : 0,
			},
		});
	};

	return (
		<section className={styles['most-popular-destinations']}>
			<div className={styles.description}>Or try the most popular destinations:{'\u00A0'}</div>
			{examplesData.map((example, index) => (
				<div
					className={styles.examples}
					key={example.value.id}
					onClick={() => handleChangeDestination(example)}
				>
					<div className={styles.example}>{example.label}</div>
					{index < examplesData.length - 1 && ',\u00A0'}
				</div>
			))}
		</section>
	);
}
