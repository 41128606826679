import { ReactNode } from 'react';
import { Tooltip } from 'react-tooltip';

import styles from './TooltipBody.module.scss';

type TooltipBodyType = {
	tooltipId: string;
	tooltipContent: ReactNode;
};

export default function TooltipBody(props: TooltipBodyType) {
	const { tooltipId, tooltipContent } = props;

	if (!tooltipId || !tooltipContent) return null;

	return (
		<Tooltip className={styles.body} id={tooltipId} openOnClick clickable>
			{tooltipContent}
		</Tooltip>
	);
}
