import cn from '../../lib/classNames';

import academy from './images/academy.jpg';
import SectionHeader from './SectionHeader';

import styles from './Academy.module.scss';

type AcademyType = {
	classPageContent?: string;
};

export default function Academy(props: AcademyType) {
	const { classPageContent } = props;

	return (
		<section className={styles['personalized-risk-score']}>
			<div className={cn(styles.content, classPageContent)}>
				<div className={styles['col-1']}>
					<img className="welcome-shadow welcom-border-radius" src={academy} alt="" />
				</div>
				<div className={styles['col-2']}>
					<SectionHeader
						className={styles.header}
						title="The Safe-Esteem Academy"
						subTitle="Personal health, safety, and security knowledge, education, and tools designed to elevate your awareness, judgment, and decision-making."
					/>
				</div>
			</div>
		</section>
	);
}
