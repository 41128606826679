import { DestinationCompanyCircumstanceType } from '../../../api/types/apiGetReport';
import cn from '../../../lib/classNames';

import LinkButton from './LinkButton';

import styles from './Banner.module.scss';

type BannerType = {
	bannerType?: 'danger' | 'warning';
	content: DestinationCompanyCircumstanceType;
	iconName: string;
};

export default function Banner(props: BannerType) {
	const {
		bannerType = 'warning',
		content: { title, description, url },
		iconName,
	} = props;
	return (
		<div className={cn(styles.banner, styles[bannerType])}>
			<span className={cn(styles.icon, 'material-icons-round')}>{iconName}</span>
			<div>
				<div className={styles['text-wrap']}>
					<h4 className={styles.headline}>{title}</h4>
					<p className={styles.description}>{description}</p>
				</div>
				{url && (
					<div className={styles['btn-wrap']}>
						<LinkButton link={url} iconBefore="open_in_new">
							Learn more
						</LinkButton>
					</div>
				)}
			</div>
		</div>
	);
}
