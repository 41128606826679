import { useEffect, useState } from 'react';

import cn from '../../../lib/classNames';

import RiskHighlightsIndicatorGradient from './RiskHighlightsIndicatorGradient';
import RiskHighlightsIndicatorScale from './RiskHighlightsIndicatorScale';

import styles from './RiskHighlightsIndicator.module.scss';

const toBase64 = (file: Blob) =>
	new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});

type RiskHighlightsIndicatorType = {
	name: string;
	rating: number | null;
	description: string | null;
	idx: number;
	handleOnClickReportHelp: (_idx: number) => void;
	bgImage: string | null;
};

export default function RiskHighlightsIndicator(props: RiskHighlightsIndicatorType) {
	const { name, rating, description, idx, handleOnClickReportHelp, bgImage } = props;
	const levelsAmount = 5;

	const [bgImage64, setBgImage64] = useState('');

	useEffect(() => {
		async function convertImageToBase64() {
			try {
				if (bgImage) {
					const response = await fetch(bgImage, {
						method: 'GET',
						mode: 'cors',
						cache: 'no-cache',
						credentials: 'same-origin',
						headers: { 'Content-Type': 'application/json' },
					});
					const blob = await response.blob();
					const base64String = await toBase64(blob);
					setBgImage64(base64String);
				} else {
					console.error('bgImage is null');
				}
			} catch (error) {
				console.error(error);
			}
		}
		convertImageToBase64();
	}, [bgImage]);

	return (
		<div className={styles['risk-highlights-indicator-wrapper']}>
			<div className={styles['risk-highlights-indicator']}>
				<div className={styles['rating-text-info']}>
					<h4 className={cn(styles['rating-name'], 'overflow-row-1')} title={name}>
						{name}
					</h4>
				</div>

				<RiskHighlightsIndicatorGradient ratingValue={rating} ratingDescription={description} />
				<RiskHighlightsIndicatorScale ratingValue={rating} levelsAmount={levelsAmount} />

				<div className={styles['detail-preview']}>
					<img className={styles['detail-preview-img']} src={bgImage64} alt="prewiew" />
					<button
						className={styles['detail-preview-btn']}
						onClick={() => handleOnClickReportHelp(idx)}
						type="button"
					>
						<span className={styles['btn-extra-text']}>MORE INFORMATION</span>
						<span className={cn(styles['plus-icon'], 'material-icons-outlined')}>add</span>
					</button>
				</div>
			</div>
		</div>
	);
}
