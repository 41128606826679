import { CredentialResponse, GoogleLogin } from '@react-oauth/google';

type ButtonLinkedGoogleType = {
	onSuccess: (credentialResponse: CredentialResponse) => void;
};

function ButtonLinkedGoogle(props: ButtonLinkedGoogleType) {
	const { onSuccess } = props;

	return (
		<div className="google-auth-btn">
			<GoogleLogin
				onSuccess={onSuccess}
				onError={() => {
					console.log('::: ButtonGoogleAuth >> : Login Failed');
				}}
			/>
		</div>
	);
}

export default ButtonLinkedGoogle;
