import cn from '../../../lib/classNames';
import { PAGES } from '../../pagesNavigation';
import { CSSTransition } from 'react-transition-group';

import styles from './TravellerInfo.module.scss';

type TravellerInfoType = {
	reportId: string;
	reportTitle: string;
	personName?: string;
	personAgeBucket?: string;
	personGender?: string;
	date?: string;
	home?: string;
	destination?: string;
};

type RowType = {
	label: string;
	value?: string;
};

function Row({ label, value }: RowType) {
	if (!value) return null;
	return (
		<div className={styles['traveller-person-data-wrap']}>
			<p className={styles['traveller-label']}>{label}</p>
			<p className={styles['traveller-data']}>{value}</p>
		</div>
	);
}

export default function TravellerInfo(props: TravellerInfoType) {
	const {
		reportId,
		reportTitle,
		personName,
		personAgeBucket,
		personGender,
		date,
		home,
		destination,
	} = props;

	const userName = personName || 'anonymous';

	const isPreloader = !home || !destination;

	const LOCATION_DATA = {
		home: home || 'Home',
		destination: destination || 'Destination',
	};

	return (
		<section className={styles['traveller-info']}>
			<div className={styles['row-1']}>
				<CSSTransition
					key={reportId}
					in={true}
					appear
					timeout={100}
					classNames={{
						enterDone: styles['col-1-done-enter'],
					}}
				>
					<div className={styles['col-1']}>
						<h3 className={cn(styles.title, 'title')}>{reportTitle}</h3>
						<div className={styles.locations}>
							{reportId === PAGES.riskScoring.id && (
								<div className={styles.location}>
									<span className={cn(styles['location-icon'], 'material-icons-round')}>home</span>
									<h4
										className={cn(styles['location-name'], 'overflow-row-1', {
											[styles['location-name-preloader']]: isPreloader,
											'preloader-pulse': isPreloader,
										})}
									>
										{LOCATION_DATA.home}
									</h4>
								</div>
							)}
							<div className="spacer" />
							<div className={styles.location}>
								<span className={cn(styles['location-icon'], 'material-icons')}>location_on</span>
								<h4
									className={cn(
										styles['location-name'],
										{
											[styles['risk-brief-location-name']]: reportId === PAGES.riskBrief.id,
											[styles['location-name-preloader']]: isPreloader,
											'preloader-pulse': isPreloader,
										},
										'overflow-row-1',
									)}
								>
									{LOCATION_DATA.destination}
								</h4>
							</div>
						</div>

						<p className={styles.description}>
							{isPreloader
								? 'Processing...'
								: `Produced by Safe-xplore exclusively for ${userName} on ${date}`}
						</p>
					</div>
				</CSSTransition>
				<div className={styles['col-2']}>
					<div>
						<div className={styles['traveller-person-header']}>
							<span className={cn(styles['traveller-person-icon'], 'material-icons')}>
								person_outline
							</span>
							<h4 className={styles['traveller-person-title']}>Traveler information</h4>
						</div>
						<div className={styles['traveller-person-body']}>
							<Row label="Name" value={personName} />
							<Row label="Age Group" value={`${personAgeBucket} years old`} />
							<Row label="Male/Female" value={personGender} />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
