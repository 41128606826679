import { createContext, useContext, useState } from 'react';

import { PAGES, PAGES_NAVIGATION } from '../features/pagesNavigation';

export const ContextNavigation = createContext();

export default function ContextNavigationProvider(props) {
	const [isReportMenuVisible, setIsReportMenuVisible] = useState(false);
	const [pageNavigation, setPageNavigation] = useState(PAGES_NAVIGATION);
	const [activePage, setActivePage] = useState(PAGES.riskScoring);

	const value = {
		isReportMenuVisible,
		setIsReportMenuVisible,
		pageNavigation,
		setPageNavigation,
		activePage,
		setActivePage,
	};

	return <ContextNavigation.Provider {...props} value={value} />;
}

export const useContextNavigation = () => useContext(ContextNavigation);
