import { ReactComponent as ConfirmPassIcon } from '../../../images/password_confirmed.svg';
import { ROUTE } from '../../App';
import { HeaderNotAuth } from '../../components';
import Button from '../../components/Buttons/Button';
import cn from '../../lib/classNames';
import { Footer } from '../Dashboard';

import styles from './ConfirmNewPass.module.scss';

export default function ConfirmNewPass() {
	return (
		<section className={cn(styles['page-confirm-new-password'], 'page')}>
			<div className={cn(styles.content, 'content')}>
				<HeaderNotAuth />
				<section className={styles.body}>
					<div className={styles['block-img']}>
						<ConfirmPassIcon />
					</div>
					<div className={styles['block-text']}>
						<h1 className={styles.title}>E-mail Confirmed</h1>
						<p className={styles.description}>
							Your can log into the app right now with your temporary password you’ve received on
							e-mail
						</p>
						<div className={cn(styles.alert, 'bg-yellow')}>
							<span className={cn(styles['alert-icon'], 'material-icons-round')}>
								warning_amber
							</span>
							<span className={styles['alert-text']}>
								It is strongly recommended to change this password once you get into the app
							</span>
						</div>
						<div className={styles['btn-wrap']}>
							<Button
								variant="outlined"
								color="primary"
								elementType="navigate"
								iconBefore="person"
								href={ROUTE.getStarted}
							>
								LOG IN
							</Button>
						</div>
					</div>
				</section>
				<footer className={styles.footer}>
					<Footer />
				</footer>
			</div>
		</section>
	);
}
