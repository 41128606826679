import TooltipBody from '../../Tooltip/TooltipBody';
import TooltipIcon from '../../Tooltip/TooltipIcon';

import styles from './TooltipVicri.module.scss';

export default function TooltipVicri() {
	const tooltipContent = (
		<>
			This rating and the ViCRI index represent the most accurate estimation efforts available today
			about the specific risk of serious violent crime at the national and urban level on a global
			scale (measures homicide, aggravated assault, robbery, and rape risks). Visit{' '}
			<a
				className="tooltip-link-text"
				href="https://violenceriskindex.org/"
				target="_blank"
				rel="noreferrer"
			>
				violenceriskindex.org
			</a>{' '}
			for additional information.
		</>
	);

	const tooltipId = 'vicri-tooltip';

	return (
		<>
			<span className={styles.icon} data-tooltip-id={tooltipId}>
				<TooltipIcon />
			</span>
			<TooltipBody tooltipId={tooltipId} tooltipContent={tooltipContent} />
		</>
	);
}
