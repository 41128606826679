export const GENDERS = ['Male', 'Female'];

export const GENDER = {
	[GENDERS[0]]: 'M',
	[GENDERS[1]]: 'F',
};

export const GENDER_ADAPTER_FROM_SHORT_TO_FULL = {
	M: 'Male',
	F: 'Female',
};

export const GENDERS_FOR_SELECT = Object.entries(GENDER_ADAPTER_FROM_SHORT_TO_FULL).map((el) => ({
	value: el[0],
	label: el[1],
}));

export const AGE_BUCKETS = [
	'0-19',
	'20-24',
	'25-29',
	'30-34',
	'35-39',
	'40-44',
	'45-49',
	'50-54',
	'55-59',
	'60-64',
	'65-69',
	'70-74',
	'75-79',
	'80+',
];
export const AGE_BUCKET = AGE_BUCKETS.map((el) => ({ value: el, label: el }));

export const fakeAgeBucket = (ageBucket) => {
	if (ageBucket === '0-19') {
		return '15-19';
	}
	if (ageBucket === '15-19') {
		return '0-19';
	}
	return ageBucket;
};

export const PRICES = {
	priceOld: '$29.99',
	priceNew: '$19.99',
};
