import { ChangeEvent, useEffect, useState } from 'react';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import { StyledComponentProps, withStyles } from '@material-ui/core/styles';

import { Color } from '../../theme';

import { DataType } from './types';
import { ElementType, QuestionText } from '.';

type QuestionScreen1FormType = {
	screen: number;
	data: (data: DataType) => void;
	questionNumber: number;
	inputClassName?: string;
	inputDisabled?: boolean;
};

type CustomFormControlLabelProps = StyledComponentProps<'label'> & {
	control: JSX.Element;
	label: string;
	key: string;
};

type CustomCheckboxProps = CheckboxProps & {
	checked: boolean;
};

export default function QuestionScreen1Form(props: QuestionScreen1FormType) {
	const { questionNumber, screen, data, inputClassName, inputDisabled } = props;

	const quNum = `qu${questionNumber}`;

	const { fields } = QuestionText[quNum].screen1;

	const [state, setState] = useState<Record<string, boolean | string>>(
		fields?.reduce((prev, curr) => {
			return {
				...prev,
				[curr.name]: curr.type === ElementType.checkbox ? false : '',
			};
		}, {}) ?? {},
	);

	const CustomCheckbox = withStyles({
		root: {
			color: Color.gray,
			padding: '0 7px 0 0',
			'&$checked': {
				color: Color.blueMediumDark,
			},
			'& svg': {
				width: 24,
				height: 24,
			},
		},
		checked: {},
	})((props: CustomCheckboxProps) => <Checkbox color="default" {...props} />);

	const CustomFormControlLabel = withStyles({
		label: {
			fontSize: 16,
			color: Color.blueDark,
			lineHeight: 1.4,
		},
	})((props: CustomFormControlLabelProps) => <FormControlLabel {...props} />);

	const handleChangeType = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.type === ElementType.checkbox) {
			return event.target.checked;
		}
		if (event.target.type === ElementType.text) {
			return event.target.value;
		}
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		let textFild = {};
		fields?.map((e) => {
			if (e.status === event.target.name && !event.target.checked) {
				textFild = { [e.name]: '' };
			}
		});
		setState({ ...state, ...textFild, [event.target.name]: handleChangeType(event) });
	};

	useEffect(() => {
		data(state);
	}, [state]);

	return (
		<div className="form">
			{fields?.map((e) => {
				if (e.type === ElementType.checkbox) {
					return (
						<CustomFormControlLabel
							control={
								<CustomCheckbox checked={!!state[e.name]} onChange={handleChange} name={e.name} />
							}
							label={e.label}
							key={e.name}
						/>
					);
				}
				if (e.type === ElementType.text) {
					const inputStateDefault =
						inputDisabled !== undefined ? inputDisabled : !(e.status && state[e.status]);

					return (
						<div className="input-text-wrap" key={e.name}>
							<Input
								className={inputClassName}
								placeholder="Please specify"
								name={e.name}
								onChange={handleChange}
								value={state[e.name]}
								disabled={inputStateDefault}
							/>
						</div>
					);
				}
			})}
		</div>
	);
}
