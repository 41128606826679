import './Footer.scss';

const CURRENT_YEAR = new Date().getFullYear();

function Footer() {
	return (
		<div className="footer-content">
			<p className="footer-text">
				Any rights not expressly granted herein are reserved by SAFE-ESTEEM. <br />© Safe-esteem{' '}
				{CURRENT_YEAR}.
			</p>
		</div>
	);
}

export default Footer;
