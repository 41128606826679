import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Loading from '../components/Loading';
import WidgetVicriRanking from '../features/Dashboard/WidgetVicriRanking/WidgetVicriRanking';
import cn from '../lib/classNames';
import { api } from '../sagas';

import styles from './VicriWidgetExternal.module.scss';

type dataType = {
	locationName: string;
	index: number;
	type: string | null;
	circumstances: string[];
};

export default function VicriWidgetExternal() {
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState<dataType>();

	const location = useLocation();

	const query = new URLSearchParams(location.search);

	const queryParams = {
		x: query.get('lng'),
		y: query.get('lat'),
		theme: query.get('theme'),
	};

	const theme = queryParams.theme === 'theme-dark' ? queryParams.theme : 'theme-light';

	const getVicri = () => {
		if (queryParams.x && queryParams.y) {
			api.getVicri(queryParams).then((response) => {
				if (response.ok) {
					setData(response.data);
					setIsLoading(false);
				}
			});
		}
	};

	const vicriContainerPropsForIframe = () => {
		const container = document.querySelector(`.${styles['vicri-container-external']}`);

		const { height, width } = container?.getBoundingClientRect() ?? {};

		parent.postMessage({ width, height }, '*');
	};

	useEffect(() => {
		getVicri();
	}, []);

	useEffect(() => {
		if (!isLoading) vicriContainerPropsForIframe();
	}, [isLoading]);

	return (
		<div className={cn(styles['vicri-container-external'], theme)}>
			<Loading isLoading={isLoading}>
				<WidgetVicriRanking
					header="VICRI Ranking"
					subLine="City violent crime risk class"
					crimeIndex={data?.index}
					crimeIndexLocation={data?.locationName}
					locationCircumstances={data?.circumstances || []}
					theme={theme}
					isDownloadBtn={false}
					isLogo
				/>
			</Loading>
		</div>
	);
}
