import cn from '../../lib/classNames';

import Academy from './Academy';
import AIPoweredRiskIntelligenceBrief from './AIPoweredRiskIntelligenceBrief';
import CriticalSafetyIndicators from './CriticalSafetyIndicators';
import Footer from './Footer';
import Header from './Header';
import ImproveSafeEsteem from './ImproveSafeEsteem';
import InfoCards from './InfoCards';
import LiveTravel from './LiveTravel';
import PersonalizedRiskScore from './PersonalizedRiskScore';
import TravelingSoon from './TravelingSoon';
import TravelRiskRatings from './TravelRiskRatings';
import ViolentCrimeRiskIndex from './ViolentCrimeRiskIndex';

import styles from './Welcome.module.scss';

export default function Welcome() {
	return (
		<div className={cn(styles['welcome-page'])}>
			<div className={styles['page-content']}>
				<Header classPageContent={styles.content} />
				<LiveTravel classPageContent={styles.content} />
				<InfoCards classPageContent={styles.content} />
				<TravelRiskRatings classPageContent={styles.content} />
				<CriticalSafetyIndicators classPageContent={styles.content} />
				<AIPoweredRiskIntelligenceBrief classPageContent={styles.content} />
				<PersonalizedRiskScore classPageContent={styles.content} />
				<ViolentCrimeRiskIndex classPageContent={styles.content} />
				<Academy classPageContent={styles.content} />
				<TravelingSoon classPageContent={styles.content} />
				<ImproveSafeEsteem classPageContent={styles.content} />
				<Footer classPageContent={styles.content} />
			</div>
		</div>
	);
}
