import { useCallback, useEffect } from 'react';
import {
	// GoogleReCaptchaProvider,
	useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

function GoogleReCaptcha(props) {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { onVerify, onError, action } = props;

	// Create an event handler so you can call the verification on button click event or form submit
	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			return;
		}

		const token = await executeRecaptcha(action);
		if (token && onVerify) {
			onVerify(token);
		}
		// Do whatever you want with the token
	}, [executeRecaptcha]);

	// You can use useEffect to trigger the verification as soon as the component being loaded
	useEffect(() => {
		handleReCaptchaVerify();
	}, [handleReCaptchaVerify]);

	return null;
}

export default GoogleReCaptcha;
