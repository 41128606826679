import cn from '../../../lib/classNames';
import ReportHistoryDashboard from '../../Report/ReportHistory/ReportHistoryDashboard';

import styles from './WidgetYourRecentSearches.module.scss';

export default function WidgetYourRecentSearches() {
	return (
		<div className={cn(styles['your-recent-searches'], 'section-wrap-white')}>
			<ReportHistoryDashboard />
		</div>
	);
}
