import { AdditionalRisksType } from '../../../api/types/apiGetReport';
import ButtonByReport from '../../../components/ButtonBuyReport';
import cn from '../../../lib/classNames';

import styles from './RiskHighlightsIndicatorMoreAvailables.module.scss';

type RiskHighlightsIndicatorMoreAvailablesType = {
	moreAvaiablesList: AdditionalRisksType[];
	title: string;
	buttonText: string;
	buyReportLink: string | null;
	destination?: string;
};

function RiskHighlightsIndicatorMoreAvailables(props: RiskHighlightsIndicatorMoreAvailablesType) {
	const { moreAvaiablesList = [], title, buttonText, buyReportLink, destination } = props;

	return (
		<div className={styles['risk-highlights-indicator-wrapper-more']}>
			<div className={styles.title}>{title}</div>
			<div className={styles['main-content']}>
				<ul className={styles['more-avaiables-list']}>
					{moreAvaiablesList.map((el, idx) => {
						return (
							<li className={styles['more-avaiable-item']} key={idx}>
								<span className={cn(styles['lock-icon'], 'material-icons')}>lock</span>
								<span>{el.name}</span>
							</li>
						);
					})}
				</ul>
			</div>
			<div className={styles['button-wrapper']}>
				<ButtonByReport buttonText={buttonText} link={buyReportLink} destination={destination} />
			</div>
		</div>
	);
}

export default RiskHighlightsIndicatorMoreAvailables;
