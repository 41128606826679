import LogInSignUpAuth from './LogInSignUpAuth';

type ForgotType = {
	onForgot: (isForgot: boolean) => void;
	isForgotPassword: boolean;
};

export default function Forgot(props: ForgotType) {
	const { onForgot, isForgotPassword } = props;
	return (
		<div className="form-forgot-container">
			<LogInSignUpAuth onForgot={onForgot} displayForgot={isForgotPassword} />
		</div>
	);
}
