import logotypeDark from '../../images/logo_black_3.svg';
import logotypeLigth from '../../images/logo_white_3.svg';
import logotypeSmall from '../../images/logotype_small.svg';

type LogotypeType = {
	className?: string;
	width?: number | 'auto';
	height?: number | 'auto';
	color?: 'light' | 'dark';
	small?: boolean;
};

export default function Logotype(props: LogotypeType) {
	const { className, width = 'auto', height = 40, color = 'light', small = false } = props;

	const imgSrc = () => {
		if (small) {
			return logotypeSmall;
		}

		if (color === 'dark') {
			return logotypeDark;
		}

		return logotypeLigth;
	};

	return (
		<img
			className={className}
			width={width === 'auto' ? 'auto' : width}
			height={height === 'auto' ? 'auto' : height}
			src={imgSrc()}
			alt="SE Logotype"
		/>
	);
}
