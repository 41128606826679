// const countriesFlags = {
// 	af: require('../../images/countriesFlags/af.png'),
// 	ax: require('../../images/countriesFlags/ax.png'),
// 	al: require('../../images/countriesFlags/al.png'),
// 	dz: require('../../images/countriesFlags/dz.png'),
// 	as: require('../../images/countriesFlags/as.png'),
// 	ad: require('../../images/countriesFlags/ad.png'),
// 	ao: require('../../images/countriesFlags/ao.png'),
// 	ai: require('../../images/countriesFlags/ai.png'),
// 	aq: require('../../images/countriesFlags/aq.png'),
// 	ag: require('../../images/countriesFlags/ag.png'),
// 	ar: require('../../images/countriesFlags/ar.png'),
// 	am: require('../../images/countriesFlags/am.png'),
// 	aw: require('../../images/countriesFlags/aw.png'),
// 	au: require('../../images/countriesFlags/au.png'),
// 	at: require('../../images/countriesFlags/at.png'),
// 	az: require('../../images/countriesFlags/az.png'),
// 	bs: require('../../images/countriesFlags/bs.png'),
// 	bh: require('../../images/countriesFlags/bh.png'),
// 	bd: require('../../images/countriesFlags/bd.png'),
// 	bb: require('../../images/countriesFlags/bb.png'),
// 	by: require('../../images/countriesFlags/by.png'),
// 	be: require('../../images/countriesFlags/be.png'),
// 	bz: require('../../images/countriesFlags/bz.png'),
// 	bj: require('../../images/countriesFlags/bj.png'),
// 	bm: require('../../images/countriesFlags/bm.png'),
// 	bt: require('../../images/countriesFlags/bt.png'),
// 	bo: require('../../images/countriesFlags/bo.png'),
// 	bq: require('../../images/countriesFlags/bq.png'),
// 	ba: require('../../images/countriesFlags/ba.png'),
// 	bw: require('../../images/countriesFlags/bw.png'),
// 	bv: require('../../images/countriesFlags/bv.png'),
// 	br: require('../../images/countriesFlags/br.png'),
// 	io: require('../../images/countriesFlags/io.png'),
// 	vg: require('../../images/countriesFlags/vg.png'),
// 	bn: require('../../images/countriesFlags/bn.png'),
// 	bg: require('../../images/countriesFlags/bg.png'),
// 	bf: require('../../images/countriesFlags/bf.png'),
// 	bi: require('../../images/countriesFlags/bi.png'),
// 	kh: require('../../images/countriesFlags/kh.png'),
// 	cm: require('../../images/countriesFlags/cm.png'),
// 	ca: require('../../images/countriesFlags/ca.png'),
// 	cv: require('../../images/countriesFlags/cv.png'),
// 	cf: require('../../images/countriesFlags/cf.png'),
// 	td: require('../../images/countriesFlags/td.png'),
// 	cl: require('../../images/countriesFlags/cl.png'),
// 	cn: require('../../images/countriesFlags/cn.png'),
// 	cx: require('../../images/countriesFlags/cx.png'),
// 	cc: require('../../images/countriesFlags/cc.png'),
// 	co: require('../../images/countriesFlags/co.png'),
// 	km: require('../../images/countriesFlags/km.png'),
// 	ck: require('../../images/countriesFlags/ck.png'),
// 	cr: require('../../images/countriesFlags/cr.png'),
// 	ci: require('../../images/countriesFlags/ci.png'),
// 	hr: require('../../images/countriesFlags/hr.png'),
// 	cu: require('../../images/countriesFlags/cu.png'),
// 	cw: require('../../images/countriesFlags/cw.png'),
// 	cy: require('../../images/countriesFlags/cy.png'),
// 	cz: require('../../images/countriesFlags/cz.png'),
// 	cd: require('../../images/countriesFlags/cd.png'),
// 	dk: require('../../images/countriesFlags/dk.png'),
// 	dj: require('../../images/countriesFlags/dj.png'),
// 	dm: require('../../images/countriesFlags/dm.png'),
// 	do: require('../../images/countriesFlags/do.png'),
// 	ec: require('../../images/countriesFlags/ec.png'),
// 	eg: require('../../images/countriesFlags/eg.png'),
// 	sv: require('../../images/countriesFlags/sv.png'),
// 	gq: require('../../images/countriesFlags/gq.png'),
// 	er: require('../../images/countriesFlags/er.png'),
// 	ee: require('../../images/countriesFlags/ee.png'),
// 	et: require('../../images/countriesFlags/et.png'),
// 	fk: require('../../images/countriesFlags/fk.png'),
// 	fo: require('../../images/countriesFlags/fo.png'),
// 	fj: require('../../images/countriesFlags/fj.png'),
// 	fi: require('../../images/countriesFlags/fi.png'),
// 	fr: require('../../images/countriesFlags/fr.png'),
// 	gf: require('../../images/countriesFlags/gf.png'),
// 	pf: require('../../images/countriesFlags/pf.png'),
// 	tf: require('../../images/countriesFlags/tf.png'),
// 	ga: require('../../images/countriesFlags/ga.png'),
// 	gm: require('../../images/countriesFlags/gm.png'),
// 	ge: require('../../images/countriesFlags/ge.png'),
// 	de: require('../../images/countriesFlags/de.png'),
// 	gh: require('../../images/countriesFlags/gh.png'),
// 	gi: require('../../images/countriesFlags/gi.png'),
// 	gr: require('../../images/countriesFlags/gr.png'),
// 	gl: require('../../images/countriesFlags/gl.png'),
// 	gd: require('../../images/countriesFlags/gd.png'),
// 	gp: require('../../images/countriesFlags/gp.png'),
// 	gu: require('../../images/countriesFlags/gu.png'),
// 	gt: require('../../images/countriesFlags/gt.png'),
// 	gg: require('../../images/countriesFlags/gg.png'),
// 	gn: require('../../images/countriesFlags/gn.png'),
// 	gw: require('../../images/countriesFlags/gw.png'),
// 	gy: require('../../images/countriesFlags/gy.png'),
// 	ht: require('../../images/countriesFlags/ht.png'),
// 	hm: require('../../images/countriesFlags/hm.png'),
// 	hn: require('../../images/countriesFlags/hn.png'),
// 	hu: require('../../images/countriesFlags/hu.png'),
// 	is: require('../../images/countriesFlags/is.png'),
// 	in: require('../../images/countriesFlags/in.png'),
// 	id: require('../../images/countriesFlags/id.png'),
// 	ir: require('../../images/countriesFlags/ir.png'),
// 	iq: require('../../images/countriesFlags/iq.png'),
// 	ie: require('../../images/countriesFlags/ie.png'),
// 	im: require('../../images/countriesFlags/im.png'),
// 	il: require('../../images/countriesFlags/il.png'),
// 	it: require('../../images/countriesFlags/it.png'),
// 	jm: require('../../images/countriesFlags/jm.png'),
// 	jp: require('../../images/countriesFlags/jp.png'),
// 	je: require('../../images/countriesFlags/je.png'),
// 	jo: require('../../images/countriesFlags/jo.png'),
// 	kz: require('../../images/countriesFlags/kz.png'),
// 	ke: require('../../images/countriesFlags/ke.png'),
// 	ki: require('../../images/countriesFlags/ki.png'),
// 	kw: require('../../images/countriesFlags/kw.png'),
// 	kg: require('../../images/countriesFlags/kg.png'),
// 	la: require('../../images/countriesFlags/la.png'),
// 	lv: require('../../images/countriesFlags/lv.png'),
// 	lb: require('../../images/countriesFlags/lb.png'),
// 	ls: require('../../images/countriesFlags/ls.png'),
// 	lr: require('../../images/countriesFlags/lr.png'),
// 	ly: require('../../images/countriesFlags/ly.png'),
// 	li: require('../../images/countriesFlags/li.png'),
// 	lt: require('../../images/countriesFlags/lt.png'),
// 	lu: require('../../images/countriesFlags/lu.png'),
// 	mo: require('../../images/countriesFlags/mo.png'),
// 	mk: require('../../images/countriesFlags/mk.png'),
// 	mg: require('../../images/countriesFlags/mg.png'),
// 	mw: require('../../images/countriesFlags/mw.png'),
// 	my: require('../../images/countriesFlags/my.png'),
// 	mv: require('../../images/countriesFlags/mv.png'),
// 	ml: require('../../images/countriesFlags/ml.png'),
// 	mt: require('../../images/countriesFlags/mt.png'),
// 	mh: require('../../images/countriesFlags/mh.png'),
// 	mq: require('../../images/countriesFlags/mq.png'),
// 	mr: require('../../images/countriesFlags/mr.png'),
// 	mu: require('../../images/countriesFlags/mu.png'),
// 	yt: require('../../images/countriesFlags/yt.png'),
// 	mx: require('../../images/countriesFlags/mx.png'),
// 	fm: require('../../images/countriesFlags/fm.png'),
// 	md: require('../../images/countriesFlags/md.png'),
// 	mc: require('../../images/countriesFlags/mc.png'),
// 	mn: require('../../images/countriesFlags/mn.png'),
// 	me: require('../../images/countriesFlags/me.png'),
// 	ms: require('../../images/countriesFlags/ms.png'),
// 	ma: require('../../images/countriesFlags/ma.png'),
// 	mz: require('../../images/countriesFlags/mz.png'),
// 	mm: require('../../images/countriesFlags/mm.png'),
// 	na: require('../../images/countriesFlags/na.png'),
// 	nr: require('../../images/countriesFlags/nr.png'),
// 	np: require('../../images/countriesFlags/np.png'),
// 	nl: require('../../images/countriesFlags/nl.png'),
// 	nc: require('../../images/countriesFlags/nc.png'),
// 	nz: require('../../images/countriesFlags/nz.png'),
// 	ni: require('../../images/countriesFlags/ni.png'),
// 	ne: require('../../images/countriesFlags/ne.png'),
// 	ng: require('../../images/countriesFlags/ng.png'),
// 	nu: require('../../images/countriesFlags/nu.png'),
// 	nf: require('../../images/countriesFlags/nf.png'),
// 	mp: require('../../images/countriesFlags/mp.png'),
// 	kp: require('../../images/countriesFlags/kp.png'),
// 	no: require('../../images/countriesFlags/no.png'),
// 	om: require('../../images/countriesFlags/om.png'),
// 	pk: require('../../images/countriesFlags/pk.png'),
// 	pw: require('../../images/countriesFlags/pw.png'),
// 	ps: require('../../images/countriesFlags/ps.png'),
// 	pa: require('../../images/countriesFlags/pa.png'),
// 	pg: require('../../images/countriesFlags/pg.png'),
// 	py: require('../../images/countriesFlags/py.png'),
// 	pe: require('../../images/countriesFlags/pe.png'),
// 	ph: require('../../images/countriesFlags/ph.png'),
// 	pn: require('../../images/countriesFlags/pn.png'),
// 	pl: require('../../images/countriesFlags/pl.png'),
// 	pt: require('../../images/countriesFlags/pt.png'),
// 	qa: require('../../images/countriesFlags/qa.png'),
// 	cg: require('../../images/countriesFlags/cg.png'),
// 	re: require('../../images/countriesFlags/re.png'),
// 	ro: require('../../images/countriesFlags/ro.png'),
// 	ru: require('../../images/countriesFlags/ru.png'),
// 	rw: require('../../images/countriesFlags/rw.png'),
// 	bl: require('../../images/countriesFlags/bl.png'),
// 	sh: require('../../images/countriesFlags/sh.png'),
// 	kn: require('../../images/countriesFlags/kn.png'),
// 	lc: require('../../images/countriesFlags/lc.png'),
// 	mf: require('../../images/countriesFlags/mf.png'),
// 	pm: require('../../images/countriesFlags/pm.png'),
// 	vc: require('../../images/countriesFlags/vc.png'),
// 	ws: require('../../images/countriesFlags/ws.png'),
// 	sm: require('../../images/countriesFlags/sm.png'),
// 	st: require('../../images/countriesFlags/st.png'),
// 	sa: require('../../images/countriesFlags/sa.png'),
// 	sn: require('../../images/countriesFlags/sn.png'),
// 	rs: require('../../images/countriesFlags/rs.png'),
// 	sc: require('../../images/countriesFlags/sc.png'),
// 	sl: require('../../images/countriesFlags/sl.png'),
// 	sg: require('../../images/countriesFlags/sg.png'),
// 	sx: require('../../images/countriesFlags/sx.png'),
// 	sk: require('../../images/countriesFlags/sk.png'),
// 	si: require('../../images/countriesFlags/si.png'),
// 	sb: require('../../images/countriesFlags/sb.png'),
// 	so: require('../../images/countriesFlags/so.png'),
// 	za: require('../../images/countriesFlags/za.png'),
// 	gs: require('../../images/countriesFlags/gs.png'),
// 	kr: require('../../images/countriesFlags/kr.png'),
// 	ss: require('../../images/countriesFlags/ss.png'),
// 	es: require('../../images/countriesFlags/es.png'),
// 	lk: require('../../images/countriesFlags/lk.png'),
// 	sd: require('../../images/countriesFlags/sd.png'),
// 	sr: require('../../images/countriesFlags/sr.png'),
// 	sj: require('../../images/countriesFlags/sj.png'),
// 	sz: require('../../images/countriesFlags/sz.png'),
// 	se: require('../../images/countriesFlags/se.png'),
// 	ch: require('../../images/countriesFlags/ch.png'),
// 	sy: require('../../images/countriesFlags/sy.png'),
// 	tw: require('../../images/countriesFlags/tw.png'),
// 	tj: require('../../images/countriesFlags/tj.png'),
// 	tz: require('../../images/countriesFlags/tz.png'),
// 	th: require('../../images/countriesFlags/th.png'),
// 	tl: require('../../images/countriesFlags/tl.png'),
// 	tg: require('../../images/countriesFlags/tg.png'),
// 	tk: require('../../images/countriesFlags/tk.png'),
// 	to: require('../../images/countriesFlags/to.png'),
// 	tt: require('../../images/countriesFlags/tt.png'),
// 	tn: require('../../images/countriesFlags/tn.png'),
// 	tr: require('../../images/countriesFlags/tr.png'),
// 	tm: require('../../images/countriesFlags/tm.png'),
// 	tc: require('../../images/countriesFlags/tc.png'),
// 	tv: require('../../images/countriesFlags/tv.png'),
// 	ug: require('../../images/countriesFlags/ug.png'),
// 	ua: require('../../images/countriesFlags/ua.png'),
// 	ae: require('../../images/countriesFlags/ae.png'),
// 	gb: require('../../images/countriesFlags/gb.png'),
// 	us: require('../../images/countriesFlags/us.png'),
// 	um: require('../../images/countriesFlags/um.png'),
// 	uy: require('../../images/countriesFlags/uy.png'),
// 	uz: require('../../images/countriesFlags/uz.png'),
// 	vu: require('../../images/countriesFlags/vu.png'),
// 	va: require('../../images/countriesFlags/va.png'),
// 	ve: require('../../images/countriesFlags/ve.png'),
// 	vn: require('../../images/countriesFlags/vn.png'),
// 	vi: require('../../images/countriesFlags/vi.png'),
// 	wf: require('../../images/countriesFlags/wf.png'),
// 	eh: require('../../images/countriesFlags/eh.png'),
// 	ye: require('../../images/countriesFlags/ye.png'),
// 	zm: require('../../images/countriesFlags/zm.png'),
// 	zw: require('../../images/countriesFlags/zw.png'),
// }

const coutriesCodes = [
	'af',
	'ax',
	'al',
	'dz',
	'as',
	'ad',
	'ao',
	'ai',
	'aq',
	'ag',
	'ar',
	'am',
	'aw',
	'au',
	'at',
	'az',
	'bs',
	'bh',
	'bd',
	'bb',
	'by',
	'be',
	'bz',
	'bj',
	'bm',
	'bt',
	'bo',
	'bq',
	'ba',
	'bw',
	'bv',
	'br',
	'io',
	'vg',
	'bn',
	'bg',
	'bf',
	'bi',
	'kh',
	'cm',
	'ca',
	'cv',
	'cf',
	'td',
	'cl',
	'cn',
	'cx',
	'cc',
	'co',
	'km',
	'ck',
	'cr',
	'ci',
	'hr',
	'cu',
	'cw',
	'cy',
	'cz',
	'cd',
	'dk',
	'dj',
	'dm',
	'do',
	'ec',
	'eg',
	'sv',
	'gq',
	'er',
	'ee',
	'et',
	'fk',
	'fo',
	'fj',
	'fi',
	'fr',
	'gf',
	'pf',
	'tf',
	'ga',
	'gm',
	'ge',
	'de',
	'gh',
	'gi',
	'gr',
	'gl',
	'gd',
	'gp',
	'gu',
	'gt',
	'gg',
	'gn',
	'gw',
	'gy',
	'ht',
	'hm',
	'hn',
	'hu',
	'is',
	'in',
	'id',
	'ir',
	'iq',
	'ie',
	'im',
	'il',
	'it',
	'jm',
	'jp',
	'je',
	'jo',
	'kz',
	'ke',
	'ki',
	'kw',
	'kg',
	'la',
	'lv',
	'lb',
	'ls',
	'lr',
	'ly',
	'li',
	'lt',
	'lu',
	'mo',
	'mk',
	'mg',
	'mw',
	'my',
	'mv',
	'ml',
	'mt',
	'mh',
	'mq',
	'mr',
	'mu',
	'yt',
	'mx',
	'fm',
	'md',
	'mc',
	'mn',
	'me',
	'ms',
	'ma',
	'mz',
	'mm',
	'na',
	'nr',
	'np',
	'nl',
	'nc',
	'nz',
	'ni',
	'ne',
	'ng',
	'nu',
	'nf',
	'mp',
	'kp',
	'no',
	'om',
	'pk',
	'pw',
	'ps',
	'pa',
	'pg',
	'py',
	'pe',
	'ph',
	'pn',
	'pl',
	'pt',
	'qa',
	'cg',
	're',
	'ro',
	'ru',
	'rw',
	'bl',
	'sh',
	'kn',
	'lc',
	'mf',
	'pm',
	'vc',
	'ws',
	'sm',
	'st',
	'sa',
	'sn',
	'rs',
	'sc',
	'sl',
	'sg',
	'sx',
	'sk',
	'si',
	'sb',
	'so',
	'za',
	'gs',
	'kr',
	'ss',
	'es',
	'lk',
	'sd',
	'sr',
	'sj',
	'sz',
	'se',
	'ch',
	'sy',
	'tw',
	'tj',
	'tz',
	'th',
	'tl',
	'tg',
	'tk',
	'to',
	'tt',
	'tn',
	'tr',
	'tm',
	'tc',
	'tv',
	'ug',
	'ua',
	'ae',
	'gb',
	'us',
	'um',
	'uy',
	'uz',
	'vu',
	'va',
	've',
	'vn',
	'vi',
	'wf',
	'eh',
	'ye',
	'zm',
	'zw',
];

export const fileFlagUrl = (countryCode) => {
	const countryCodeLowerCase = countryCode.toLowerCase();
	const isCode = coutriesCodes.filter((e) => e === countryCodeLowerCase);
	return isCode.length ? require(`../../images/countriesFlags/${countryCode}.png`) : null;
};
