import ProfileField from './ProfilePreview/ProfileField';

import styles from './ProfilePersonalData.module.scss';

type ProfilePersonalDataType = {
	gender: string;
	label: string;
	fakeAgeBucket: string;
};

export default function ProfilePersonalData(props: ProfilePersonalDataType) {
	const { gender, label, fakeAgeBucket } = props;

	return (
		<div className={styles['profile-personal-data-wrap']}>
			<div>
				<h3 className={styles.title}>Personal Data</h3>
				<p className={styles.description}>
					We use this data to calculate your personalized risk estimates.
				</p>
			</div>
			<div className={styles.body}>
				<ProfileField helpText="Home city" value={label} />
				<ProfileField helpText="Gender" value={gender} />
				<ProfileField helpText="Age group" value={fakeAgeBucket} />
			</div>
		</div>
	);
}
