import { Player } from '@lottiefiles/react-lottie-player';

import cn from '../../../lib/classNames';

import loader from './loader.json';

import styles from './Loader.module.scss';

type LoaderType = {
	className?: string;
};

export default function Loader(props: LoaderType) {
	const { className } = props;

	return (
		<section className={cn(styles.loader, className)}>
			<div className={styles['text-wrap']}>
				Gathering info may take some time. We’ll send you an email when the Risk Brief is ready. Or
				you can simply update this page in several minutes.
			</div>
			<div className={styles['img-wrap']}>
				<Player autoplay loop src={loader} style={{ height: '300px', width: '300px' }} />
			</div>
		</section>
	);
}
