import { useRef, useState } from 'react';
import { saveAs } from 'file-saver';

import { RiskContributorsType, ScoresType } from '../../../api/types/apiGetHomeReport';
import cn from '../../../lib/classNames';
import html2canvas from '../../../lib/html2canvas';
import HeaderWidgets from '../HeaderWidgets';

import CircleIndicator from './CircleIndicator';
import LinearIndicator from './LinearIndicator';

import styles from './WidgetPersonalizedRiskScore.module.scss';

type WidgetPersonalizedRiskScoreType = {
	scores: ScoresType;
	riskContributors: RiskContributorsType;
};

const RISK_COLORS = {
	empty: '#DEE2E5',
	crime: '#FF0000',
	accidents: '#7EF2F3',
	health: '#FFCB00',
};

export default function WidgetPersonalizedRiskScore(props: WidgetPersonalizedRiskScoreType) {
	const { scores, riskContributors } = props;

	const [tipActive, setTipActive] = useState(0);

	const elementToShareViolentCrimeRisk = useRef<HTMLDivElement>(null);

	const scoresData = [
		{
			name: 'Violent crime',
			value: scores.crime,
			color: RISK_COLORS.crime,
			info: riskContributors?.crime,
		},
		{
			name: 'Accidents',
			value: scores.accidents,
			color: RISK_COLORS.accidents,
			info: riskContributors?.accidents,
		},
		{
			name: 'Health issues',
			value: scores.health,
			color: RISK_COLORS.health,
			info: riskContributors?.health,
		},
	];

	const handleClickShare = () => {
		html2canvas(elementToShareViolentCrimeRisk.current).then((canvas) => {
			saveAs(canvas.toDataURL(), 'personalized-risk-score.png');
		});
	};

	return (
		<div
			ref={elementToShareViolentCrimeRisk}
			className={cn(styles['personalized-risk-score'], 'section-wrap-white')}
		>
			<div className={styles['personalized-risk-score-top']}>
				<HeaderWidgets
					title="Personalized Risk Score"
					subLine="Cumulative score based on your profile"
					handleClickShare={handleClickShare}
					isDownloadBtn
				/>
				<CircleIndicator scoresData={scoresData} riskColors={RISK_COLORS} />
			</div>

			<div className={styles['personalized-risk-score-bottom']}>
				<div className={styles['risk-score-title']}>Scores by risk types</div>
				{scoresData.map((riscScore, index) => (
					<LinearIndicator
						key={riscScore.name}
						tipIndex={index + 1}
						tipActive={tipActive}
						setTipActive={setTipActive}
						riscScore={riscScore}
						emptyColor={RISK_COLORS.empty}
					/>
				))}
			</div>
		</div>
	);
}
