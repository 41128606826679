import { ReactNode, useEffect, useRef, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { Color } from '../theme';

type CSSPropertiesType = {
	stylesModal?: CSSProperties;
	stylesPaper?: CSSProperties;
};

type UseStylesType = CSSPropertiesType & {
	isMobile: boolean;
};

const useStyles = (props: UseStylesType) => {
	const { stylesModal, stylesPaper, isMobile } = props;

	return makeStyles({
		modal: {
			backgroundColor: 'rgba(35, 53, 68, .9)',
			overflowX: 'hidden',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			...stylesModal,
		},

		paper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			background: Color.white,
			boxShadow: '0px 12px 30px rgba(0, 26, 33, 0.3)',
			borderRadius: 12,
			padding: '4rem',
			...stylesPaper,
			alignSelf: isMobile ? 'flex-start' : 'center',
		},
	})();
};

type ModalCustomType = {
	open: boolean;
	children: ReactNode;
	stylesModal?: CSSProperties;
	stylesPaper?: CSSProperties;
};

export default function ModalCustom(props: ModalCustomType) {
	const { open, children, stylesModal, stylesPaper } = props;

	const refContainer = useRef<HTMLDivElement>(null);
	const refContent = useRef<HTMLDivElement>(null);

	const [isMobile, setIsMobile] = useState(false);

	const resize = () => {
		if (!refContainer.current || !refContent.current) {
			return;
		}

		const containerHeight = refContainer.current.clientHeight;
		const contentHeight = refContent.current.clientHeight;

		setIsMobile(containerHeight <= contentHeight);
	};

	useEffect(() => {
		resize();
	}, [refContainer.current?.clientHeight, refContent.current?.clientHeight, children]);

	useEffect(() => {
		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	}, []);

	const classes = useStyles({ stylesModal, stylesPaper, isMobile });

	return (
		<Modal
			className={classes.modal}
			open={open}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 300,
			}}
			ref={refContainer}
		>
			<Fade in={open}>
				<div ref={refContent} className={`${classes.paper} modal-custom`}>
					{children}
				</div>
			</Fade>
		</Modal>
	);
}
