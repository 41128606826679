import { CompanyType } from '../../../api/types/apiGetProfile';
import { ApiReportHistoryListType } from '../../../api/types/apiGetReportHistoryList';
import { Loader } from '../../../components';
import { LocationDataType } from '../../../types/LocationData';
import AdditionInputStep from '../AdditionInputStep';
import ViewLimit from '../ViewLimit';

type AdditionStep0Type = {
	loaderShow: boolean;
	isBuyNow: boolean;
	viewedLocations: ApiReportHistoryListType[];
	allowedTotalViewedLocations: number;
	handleGoToDestinationInput: (_buyNow: boolean) => void;
	locationHome: string;
	place?: LocationDataType;
	handleSelectPlace: (_newPlace: LocationDataType) => void;
	company: CompanyType;
	isLogged: boolean;
	handleSubmit: () => void;
	handleGoBack: () => void;
};

export default function AdditionStep0(props: AdditionStep0Type) {
	const {
		loaderShow,
		isBuyNow,
		viewedLocations,
		allowedTotalViewedLocations,
		handleGoToDestinationInput,
		locationHome,
		place,
		handleSelectPlace,
		company,
		isLogged,
		handleSubmit,
		handleGoBack,
	} = props;

	if (loaderShow) {
		return <Loader />;
	}

	if (!isBuyNow && viewedLocations.length >= allowedTotalViewedLocations) {
		return (
			<ViewLimit
				viewedLocations={viewedLocations}
				onClickBuyNow={handleGoToDestinationInput}
				destination={place?.label}
			/>
		);
	}

	return (
		<div className="get-started-steps-container addition-steps">
			<AdditionInputStep
				locationHome={locationHome}
				place={place}
				handleSelectPlace={handleSelectPlace}
				company={company}
				allowedTotalViewedLocations={allowedTotalViewedLocations}
				isLogged={isLogged}
				handleSubmit={handleSubmit}
				handleGoBack={handleGoBack}
				isBuyNow={isBuyNow}
				viewedLocations={viewedLocations}
			/>
		</div>
	);
}
