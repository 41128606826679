import { ReactComponent as PreloaderList } from './images/PreloaderList.svg';

import styles from './PreloaderSimilarCities.module.scss';

export default function PreloaderSimilarCities() {
	return (
		<>
			<div className={styles.block} />
			<div className={styles['list-wrapper']}>
				<PreloaderList />
			</div>
		</>
	);
}
