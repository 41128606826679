import cn from '../../../lib/classNames';

import styles from './RiskHighlightsIndicatorScale.module.scss';

type RiskHighlightsIndicatorScaleType = {
	ratingValue: number | null;
	levelsAmount?: number;
};

export default function RiskHighlightsIndicatorScale(props: RiskHighlightsIndicatorScaleType) {
	const { ratingValue, levelsAmount = 5 } = props;
	return (
		<div className={styles['risk-highlights-indicator-scale']}>
			{[...Array(levelsAmount)].map((_, i) => {
				const extraClass =
					ratingValue && ratingValue > i
						? styles[`bg-color-risk-${ratingValue}` as keyof typeof styles]
						: '';
				return <div className={cn(styles['scale-item'], extraClass)} key={i} />;
			})}
		</div>
	);
}
