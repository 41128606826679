import { ReactNode } from 'react';

import cn from '../../../lib/classNames';

import styles from './LinkButton.module.scss';

type LinkButtonType = {
	link?: string;
	children: ReactNode;
	iconBefore?: string;
	iconAfter?: string;
	onClick?: () => void;
};

export default function LinkButton(props: LinkButtonType) {
	const { link = '', children, iconBefore, iconAfter, onClick } = props;

	return (
		<div onClick={onClick} className={styles['link-button']}>
			<a
				href={link}
				target="_blank"
				rel="noopener noreferrer"
				className={cn(styles['label-link'], 'bg-yellow')}
			>
				<span className={styles['label-wrap']}>
					<span className="label-icon label-icon-before material-icons-round">{iconBefore}</span>
					<span className={cn(styles['label-content'], 'label-content')}>{children}</span>
					<span className="label-icon label-icon-after material-icons-round">{iconAfter}</span>
				</span>
			</a>
		</div>
	);
}
