import { ReactComponent as Logo } from '../../../images/logo_text.svg';
import { Download } from '../../components';
import cn from '../../lib/classNames';

import styles from './HeaderWidgets.module.scss';

type HeaderWidgetsType = {
	title: string;
	handleClickShare?: () => void;
	subLine?: string;
	className?: string;
	isDownloadBtn?: boolean;
	isLogo?: boolean;
};

export default function HeaderWidgets(props: HeaderWidgetsType) {
	const {
		title,
		handleClickShare,
		subLine,
		className,
		isDownloadBtn = true,
		isLogo = false,
	} = props;

	return (
		<div className={cn(styles.header, className)}>
			<div className={styles['row-1']}>
				<h3>
					<span>{title}</span>
				</h3>
				{isDownloadBtn && (
					<div className="download-icon" onClick={handleClickShare}>
						<Download className="icons-animation" />
					</div>
				)}
				{isLogo && (
					<div className={styles['logo-wrap']}>
						<p className={styles.text}>Powered by</p>
						<a
							className={styles.link}
							href="https://xplore.safe-esteem.com/"
							target="_blank"
							rel="noreferrer"
						>
							<Logo className={styles.logo} />
						</a>
					</div>
				)}
			</div>
			{subLine && (
				<div>
					<span className={styles['sub-line']}>{subLine}</span>
				</div>
			)}
		</div>
	);
}
