import { ReactNode, useRef } from 'react';
import { saveAs } from 'file-saver';

import cn from '../../../lib/classNames';
import html2canvas from '../../../lib/html2canvas';
import { ThemeType } from '../../../types/theme';
import HeaderWidgets from '../HeaderWidgets';

import { ReactComponent as ActiveConflictAreaSvg } from './images/activeConflictArea.svg';
import { ReactComponent as PoorDataAreaSvg } from './images/poorDataArea.svg';
import Content from './Content';
import Footer from './Footer';

import styles from './WidgetVicriRanking.module.scss';

type WidgetVicriRankingType = {
	isShadow?: boolean;
	isHeader?: boolean;
	header?: string;
	subLine?: string;
	crimeIndex?: number;
	crimeIndexLocation?: string;
	locationCircumstances?: string[];
	className?: string;
	theme: ThemeType;
	isDownloadBtn?: boolean;
	isLogo?: boolean;
	isFooter?: boolean;
	description?: string;
};

export default function WidgetVicriRanking(props: WidgetVicriRankingType) {
	const {
		isShadow = true,
		isHeader = true,
		header = '',
		subLine = '',
		crimeIndex,
		crimeIndexLocation,
		locationCircumstances,
		className,
		theme,
		isDownloadBtn,
		isLogo,
		description,
		isFooter = true,
	} = props;
	const elementToShareViolentCrimeRisk = useRef<HTMLDivElement>(null);

	let extraInfo = '';
	let color = '#DEE2E5';
	let value = crimeIndex;
	let valueImage: ReactNode = crimeIndex || <PoorDataAreaSvg />;

	if (locationCircumstances?.includes('MILITARY_CONFLICT')) {
		extraInfo = 'ACTIVE CONFLICT AREA';
		color = theme === 'theme-dark' ? '#DEE2E5' : '#390505';
		value = 0;
		valueImage = (
			<ActiveConflictAreaSvg className={cn(styles['active-conflict-area-icon'], theme)} />
		);
	} else if (crimeIndex === null || locationCircumstances?.includes('NO_DATA')) {
		extraInfo = 'POOR DATA AREA';
		color = '#DEE2E5';
		value = 0;
		valueImage = <PoorDataAreaSvg />;
	}

	const handleClickShare = () => {
		html2canvas(elementToShareViolentCrimeRisk.current).then((canvas) => {
			saveAs(canvas.toDataURL(), 'violent-crime-risk-index-ranking.png');
		});
	};

	return (
		<div
			ref={elementToShareViolentCrimeRisk}
			className={cn(styles['vicri-ranking'], { 'section-wrap-white': isShadow }, className)}
		>
			{isHeader && (
				<HeaderWidgets
					className={cn(styles['header-container'], 'vicri-header-container')}
					title={header}
					handleClickShare={handleClickShare}
					subLine={subLine}
					isDownloadBtn={isDownloadBtn}
					isLogo={isLogo}
				/>
			)}

			<Content
				className={styles['content-container']}
				value={value}
				color={color}
				valueImage={valueImage}
				locationName={crimeIndexLocation}
				extraInfo={extraInfo}
				theme={theme}
			/>
			{description && <div className={styles.description}>{description}</div>}
			{isFooter && <Footer className={cn(styles['footer-container'], 'vicri-footer-container')} />}
		</div>
	);
}
