import { Color } from '../theme';

const colorsArr = [
	Color.heatmap1,
	Color.heatmap2,
	Color.heatmap3,
	Color.heatmap4,
	Color.heatmap5,
	Color.heatmap6,
	Color.heatmap7,
	Color.heatmap8,
	Color.heatmap9,
	Color.heatmap10,
];

const colorsBlueArr = [
	Color.blueHeatmap1,
	Color.blueHeatmap2,
	Color.blueHeatmap3,
	Color.blueHeatmap4,
	Color.blueHeatmap5,
	Color.blueHeatmap6,
	Color.blueHeatmap7,
	Color.blueHeatmap8,
	Color.blueHeatmap9,
	Color.blueHeatmap10,
];

type RiskRatingChartType = {
	value?: number;
	valueFontSize?: number;
	valueVerticalPosition?: string;
	text?: string;
	colors: string;
};

export default function RiskRatingChart(props: RiskRatingChartType) {
	const {
		value = 2.1,
		valueFontSize = 35,
		valueVerticalPosition = '85',
		text = 'Counties Ranking',
		colors = '',
	} = props;

	const padding = (v: number) => -17 * (0.1 * v - 1);

	const dasharray = (v: number) => {
		const l = Math.round((232 * v) / 10) - padding(v);
		const ll = 493 - l;
		return `${l} ${ll}`;
	};

	const colorsPaletteArr = colors === 'blue' ? { ...colorsBlueArr } : { ...colorsArr };

	const valueColor = colorsPaletteArr[value - 1];

	return (
		<svg viewBox="0 0 220 113" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M185 103.858C185 70.3579 150.078 32.8579 107 32.8579C63.9218 32.8579 29 70.3579 29 103.858"
				stroke="#DEE2E5"
				strokeWidth="17"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<circle
				cx="106.8"
				cy="111"
				r="78.5"
				stroke={valueColor}
				strokeDasharray={dasharray(value)}
				strokeDashoffset="239.4"
				strokeWidth="17"
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<path
				d="M14.344 87.6658C12.833 93.9676 11.9515 100.509 11.7685 107.219"
				stroke={colorsPaletteArr[0]}
				strokeWidth="6"
				strokeLinecap="round"
			/>
			<path
				d="M26.5051 60.2941C23.1207 65.8205 20.2609 71.7697 18.0133 78.0946"
				stroke={colorsPaletteArr[1]}
				strokeWidth="6"
				strokeLinecap="round"
			/>
			<path
				d="M46.5291 38.0202C41.6027 42.2304 37.0444 47.0046 32.9523 52.3255"
				stroke={colorsPaletteArr[2]}
				strokeWidth="6"
				strokeLinecap="round"
			/>
			<path
				d="M72.4563 23.0239C66.47 25.5056 60.6595 28.6376 55.1235 32.4335"
				stroke={colorsPaletteArr[3]}
				strokeWidth="6"
				strokeLinecap="round"
			/>
			<path
				d="M101.749 16.7741C95.2884 17.2845 88.7944 18.4676 82.3563 20.367"
				stroke={colorsPaletteArr[4]}
				strokeWidth="6"
				strokeLinecap="round"
			/>
			<path
				d="M131.539 19.8818C125.237 18.3709 118.695 17.4894 111.985 17.3063"
				stroke={colorsPaletteArr[5]}
				strokeWidth="6"
				strokeLinecap="round"
			/>
			<path
				d="M158.91 32.0426C153.384 28.6582 147.435 25.7983 141.11 23.5508"
				stroke={colorsPaletteArr[6]}
				strokeWidth="6"
				strokeLinecap="round"
			/>
			<path
				d="M181.184 52.0666C176.974 47.1401 172.2 42.5818 166.879 38.4898"
				stroke={colorsPaletteArr[7]}
				strokeWidth="6"
				strokeLinecap="round"
			/>
			<path
				d="M196.18 77.9935C193.699 72.0071 190.567 66.1966 186.771 60.6606"
				stroke={colorsPaletteArr[8]}
				strokeWidth="6"
				strokeLinecap="round"
			/>
			<path
				d="M202.431 107.286C201.92 100.826 200.737 94.3318 198.838 87.8937"
				stroke={colorsPaletteArr[9]}
				strokeWidth="6"
				strokeLinecap="round"
			/>
			<path
				d="M0.837891 99V98.1782H2.76611V92.356L1.05811 93.5752V92.6621L2.84668 91.4321H3.73828V98.1782H5.58057V99H0.837891Z"
				fill="#7D8C97"
			/>
			<path
				d="M10.5532 68V67.3179C10.7358 66.8989 10.9578 66.5301 11.2192 66.2114C11.4842 65.8892 11.7617 65.5991 12.0518 65.3413C12.3418 65.0799 12.6283 64.8382 12.9111 64.6162C13.1976 64.3942 13.4554 64.1722 13.6846 63.9502C13.9137 63.7282 14.0981 63.4954 14.2378 63.252C14.381 63.0085 14.4526 62.7327 14.4526 62.4248C14.4526 62.0094 14.3309 61.6872 14.0874 61.458C13.8439 61.2288 13.5055 61.1143 13.0723 61.1143C12.6605 61.1143 12.3203 61.2271 12.0518 61.4526C11.7868 61.6746 11.631 61.988 11.5845 62.3926L10.5962 62.3013C10.6678 61.6961 10.9238 61.2145 11.3643 60.8564C11.8083 60.4984 12.3776 60.3193 13.0723 60.3193C13.835 60.3193 14.4204 60.5002 14.8286 60.8618C15.2404 61.2199 15.4463 61.7301 15.4463 62.3926C15.4463 62.6862 15.3783 62.978 15.2422 63.2681C15.1097 63.5581 14.911 63.8481 14.646 64.1382C14.381 64.4282 13.8743 64.8776 13.126 65.4863C12.7142 65.8229 12.3866 66.1273 12.1431 66.3994C11.8996 66.668 11.7241 66.9276 11.6167 67.1782H15.5645V68H10.5532Z"
				fill="#7D8C97"
			/>
			<path
				d="M34.6343 36.9106C34.6343 37.6089 34.4123 38.1496 33.9683 38.5327C33.5243 38.9159 32.8905 39.1074 32.0669 39.1074C31.3006 39.1074 30.6883 38.9355 30.23 38.5918C29.7752 38.2445 29.5049 37.7324 29.4189 37.0557L30.418 36.9644C30.5469 37.8595 31.0965 38.3071 32.0669 38.3071C32.5539 38.3071 32.9352 38.1872 33.2109 37.9473C33.4902 37.7074 33.6299 37.3511 33.6299 36.8784C33.6299 36.4666 33.4705 36.1462 33.1519 35.917C32.8368 35.6842 32.3802 35.5679 31.7822 35.5679H31.2344V34.73H31.7607C32.2907 34.73 32.7007 34.6154 32.9907 34.3862C33.2843 34.1535 33.4312 33.833 33.4312 33.4248C33.4312 33.0202 33.3112 32.7015 33.0713 32.4688C32.835 32.2324 32.4823 32.1143 32.0132 32.1143C31.5871 32.1143 31.2415 32.2235 30.9766 32.4419C30.7152 32.6603 30.563 32.9683 30.52 33.3657L29.5479 33.2905C29.6195 32.6711 29.8755 32.1877 30.3159 31.8403C30.7599 31.493 31.3293 31.3193 32.0239 31.3193C32.783 31.3193 33.3721 31.4966 33.791 31.8511C34.2135 32.202 34.4248 32.6925 34.4248 33.3228C34.4248 33.8062 34.2887 34.2 34.0166 34.5044C33.748 34.8052 33.356 35.0093 32.8403 35.1167V35.1382C33.4061 35.1991 33.8465 35.3888 34.1616 35.7075C34.4767 36.0262 34.6343 36.4272 34.6343 36.9106Z"
				fill="#7D8C97"
			/>
			<path
				d="M60.7319 20.2866V22H59.8188V20.2866H56.2524V19.5347L59.7168 14.4321H60.7319V19.5239H61.7954V20.2866H60.7319ZM59.8188 15.5225C59.8117 15.5439 59.7616 15.6388 59.6685 15.8071C59.5754 15.9754 59.5055 16.0936 59.459 16.1616L57.52 19.019L57.23 19.4165L57.144 19.5239H59.8188V15.5225Z"
				fill="#7D8C97"
			/>
			<path
				d="M91.6558 8.53467C91.6558 9.33317 91.4176 9.96159 90.9414 10.4199C90.4688 10.8783 89.8117 11.1074 88.9702 11.1074C88.2648 11.1074 87.6955 10.9535 87.2622 10.6455C86.8289 10.3376 86.555 9.89176 86.4404 9.30811L87.418 9.19531C87.6221 9.94368 88.1466 10.3179 88.9917 10.3179C89.5109 10.3179 89.9173 10.1621 90.2109 9.85059C90.5046 9.53548 90.6514 9.104 90.6514 8.55615C90.6514 8.07992 90.5028 7.69499 90.2056 7.40137C89.9119 7.10775 89.5145 6.96094 89.0132 6.96094C88.7518 6.96094 88.5083 7.00212 88.2827 7.08447C88.0571 7.16683 87.8315 7.30648 87.606 7.50342H86.6606L86.9131 3.43213H91.2153V4.25391H87.7939L87.6489 6.65479C88.0679 6.33252 88.5889 6.17139 89.2119 6.17139C89.9567 6.17139 90.5493 6.38981 90.9897 6.82666C91.4338 7.26351 91.6558 7.83285 91.6558 8.53467Z"
				fill="#7D8C97"
			/>
			<path
				d="M124.634 8.52393C124.634 9.32243 124.418 9.95264 123.984 10.4146C123.551 10.8765 122.953 11.1074 122.19 11.1074C121.338 11.1074 120.687 10.7905 120.235 10.1567C119.784 9.52295 119.559 8.60091 119.559 7.39062C119.559 6.08008 119.793 5.07389 120.262 4.37207C120.731 3.67025 121.399 3.31934 122.266 3.31934C123.408 3.31934 124.128 3.83317 124.425 4.86084L123.501 5.02734C123.311 4.41146 122.896 4.10352 122.255 4.10352C121.703 4.10352 121.276 4.36133 120.971 4.87695C120.67 5.389 120.52 6.132 120.52 7.10596C120.695 6.78011 120.943 6.53304 121.261 6.36475C121.58 6.19287 121.945 6.10693 122.357 6.10693C123.055 6.10693 123.608 6.32536 124.017 6.76221C124.428 7.19906 124.634 7.7863 124.634 8.52393ZM123.651 8.56689C123.651 8.01904 123.517 7.59652 123.249 7.29932C122.98 7.00212 122.606 6.85352 122.126 6.85352C121.675 6.85352 121.31 6.986 121.03 7.25098C120.755 7.51237 120.617 7.87402 120.617 8.33594C120.617 8.9196 120.76 9.39762 121.046 9.77002C121.336 10.1424 121.707 10.3286 122.158 10.3286C122.624 10.3286 122.989 10.1729 123.254 9.86133C123.519 9.54622 123.651 9.11475 123.651 8.56689Z"
				fill="#7D8C97"
			/>
			<path
				d="M155.564 15.2163C154.791 16.3979 154.245 17.3236 153.926 17.9932C153.608 18.6628 153.368 19.3234 153.207 19.9751C153.049 20.6268 152.97 21.3018 152.97 22H151.96C151.96 21.0332 152.165 20.0163 152.573 18.9492C152.985 17.8786 153.67 16.6468 154.63 15.2539H150.564V14.4321H155.564V15.2163Z"
				fill="#7D8C97"
			/>
			<path
				d="M181.64 36.8892C181.64 37.5874 181.418 38.1317 180.974 38.522C180.53 38.9123 179.892 39.1074 179.062 39.1074C178.252 39.1074 177.618 38.9159 177.16 38.5327C176.705 38.1496 176.478 37.6053 176.478 36.8999C176.478 36.4058 176.619 35.9904 176.902 35.6538C177.185 35.3172 177.547 35.1131 177.987 35.0415V35.02C177.576 34.9233 177.25 34.7139 177.01 34.3916C176.773 34.0693 176.655 33.6916 176.655 33.2583C176.655 32.6818 176.87 32.2145 177.3 31.8564C177.733 31.4984 178.313 31.3193 179.04 31.3193C179.785 31.3193 180.372 31.4948 180.802 31.8457C181.235 32.1966 181.452 32.6711 181.452 33.269C181.452 33.7023 181.332 34.0801 181.092 34.4023C180.852 34.7246 180.524 34.9269 180.109 35.0093V35.0308C180.592 35.1095 180.968 35.3154 181.237 35.6484C181.505 35.9779 181.64 36.3914 181.64 36.8892ZM180.447 33.3228C180.447 32.467 179.978 32.0391 179.04 32.0391C178.585 32.0391 178.238 32.1465 177.998 32.3613C177.762 32.5762 177.644 32.8966 177.644 33.3228C177.644 33.756 177.765 34.0872 178.009 34.3164C178.256 34.542 178.603 34.6548 179.051 34.6548C179.506 34.6548 179.851 34.5509 180.087 34.3433C180.327 34.132 180.447 33.7918 180.447 33.3228ZM180.635 36.7979C180.635 36.3288 180.496 35.9761 180.216 35.7397C179.937 35.4998 179.545 35.3799 179.04 35.3799C178.549 35.3799 178.166 35.5088 177.891 35.7666C177.615 36.0208 177.477 36.3717 177.477 36.8193C177.477 37.8613 178.009 38.3823 179.072 38.3823C179.599 38.3823 179.991 38.257 180.249 38.0063C180.506 37.7521 180.635 37.3493 180.635 36.7979Z"
				fill="#7D8C97"
			/>
			<path
				d="M202.597 64.063C202.597 65.3628 202.359 66.3618 201.882 67.0601C201.41 67.7583 200.735 68.1074 199.857 68.1074C199.267 68.1074 198.792 67.9839 198.434 67.7368C198.08 67.4862 197.825 67.0833 197.671 66.5283L198.595 66.3833C198.789 67.0135 199.215 67.3286 199.874 67.3286C200.429 67.3286 200.858 67.0708 201.163 66.5552C201.467 66.0396 201.626 65.3037 201.641 64.3477C201.497 64.6699 201.252 64.9295 200.905 65.1265C200.557 65.3198 200.176 65.4165 199.761 65.4165C199.08 65.4165 198.536 65.1838 198.128 64.7183C197.72 64.2528 197.516 63.6351 197.516 62.8652C197.516 62.0739 197.738 61.4526 198.182 61.0015C198.626 60.5467 199.243 60.3193 200.035 60.3193C200.876 60.3193 201.514 60.6309 201.947 61.2539C202.38 61.877 202.597 62.8133 202.597 64.063ZM201.544 63.1284C201.544 62.5197 201.404 62.0309 201.125 61.6621C200.846 61.2897 200.472 61.1035 200.002 61.1035C199.537 61.1035 199.17 61.2629 198.901 61.5815C198.633 61.8966 198.499 62.3245 198.499 62.8652C198.499 63.4167 198.633 63.8535 198.901 64.1758C199.17 64.4945 199.533 64.6538 199.992 64.6538C200.271 64.6538 200.531 64.5911 200.771 64.4658C201.01 64.3369 201.198 64.1561 201.334 63.9233C201.474 63.6906 201.544 63.4256 201.544 63.1284Z"
				fill="#7D8C97"
			/>
			<path
				d="M207.838 99V98.1782H209.766V92.356L208.058 93.5752V92.6621L209.847 91.4321H210.738V98.1782H212.581V99H207.838ZM218.811 95.2134C218.811 96.4774 218.587 97.4424 218.14 98.1084C217.696 98.7744 217.039 99.1074 216.168 99.1074C215.298 99.1074 214.645 98.7762 214.208 98.1138C213.771 97.4513 213.553 96.4845 213.553 95.2134C213.553 93.9136 213.764 92.9396 214.187 92.2915C214.613 91.6434 215.284 91.3193 216.201 91.3193C217.092 91.3193 217.749 91.647 218.172 92.3022C218.598 92.9575 218.811 93.9279 218.811 95.2134ZM217.828 95.2134C217.828 94.1213 217.701 93.3299 217.447 92.8394C217.196 92.3488 216.781 92.1035 216.201 92.1035C215.606 92.1035 215.178 92.3452 214.917 92.8286C214.659 93.312 214.53 94.1069 214.53 95.2134C214.53 96.2876 214.661 97.0736 214.922 97.5713C215.187 98.069 215.606 98.3179 216.179 98.3179C216.749 98.3179 217.166 98.0636 217.431 97.5552C217.696 97.0467 217.828 96.2661 217.828 95.2134Z"
				fill="#7D8C97"
			/>
			<text
				x="107"
				y={valueVerticalPosition}
				fontSize={`${valueFontSize}px`}
				fill="#233544"
				textAnchor="middle"
			>
				{value}
			</text>
			<text x="107" y="105" fontSize="13px" fill="#7D8C97" textAnchor="middle">
				{text}
			</text>
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="29"
					y1="111.5"
					x2="47"
					y2="57"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#6AD400" />
					<stop offset="1" stopColor="#97D101" />
				</linearGradient>
			</defs>
		</svg>
	);
}
