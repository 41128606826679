import cn from '../../lib/classNames';

import personalizedRiskScore from './images/personalizedRiskScore.jpg';
import SectionHeader from './SectionHeader';

import styles from './PersonalizedRiskScore.module.scss';

type PersonalizedRiskScoreType = {
	classPageContent?: string;
};

export default function PersonalizedRiskScore(props: PersonalizedRiskScoreType) {
	const { classPageContent } = props;

	return (
		<section className={styles['personalized-risk-score']}>
			<div className={cn(styles.content, classPageContent)}>
				<div className={styles['col-1']}>
					<SectionHeader
						className={styles.header}
						title="Personalized Risk Score"
						subTitle="Personalized health, accidents, and crime risk estimates for hundreds of global cities."
					/>
				</div>
				<div className={styles['col-2']}>
					<img className="welcome-shadow welcom-border-radius" src={personalizedRiskScore} alt="" />
				</div>
			</div>
		</section>
	);
}
