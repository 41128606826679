import { Select } from '../../../components';
import { AGE_BUCKET } from '../../../lib/constats';
import Header from '../Header';
import IndicatorsDots from '../IndicatorsDots';

import Button from '../../../components/Buttons/Button';

type GetStartedStep1Type = {
	step?: number;
	stepsCount: number;
	ageBucket: string;
	handleSelectAge: (age: string) => void;
	handleNextStep: (_num: number) => void;
};

export default function GetStartedStep1(props: GetStartedStep1Type) {
	const { step, stepsCount, ageBucket, handleSelectAge, handleNextStep } = props;
	return (
		<div className="get-started-steps-container">
			<IndicatorsDots currentDot={step} stepsCount={stepsCount} />
			<Header
				title="What is your age group?"
				description="Please select your age group to continue"
			/>
			<div className="fields-container fields-container-pos-center">
				<Select
					placeholder="Select your age group"
					value={ageBucket}
					items={AGE_BUCKET}
					onSelect={handleSelectAge}
				/>
			</div>
			<div className="block-btn">
				<Button
					variant={'filled'}
					color={'primary'}
					onClick={() => {
						if (ageBucket !== '') handleNextStep(2);
					}}
					disabled={ageBucket === ''}
				>
					NEXT
				</Button>
			</div>
		</div>
	);
}
