import plane from './images/plane.svg';
import planeMobile from './images/planeMobile.svg';
import MostPopularDestinations from './MostPopularDestinations';
import RiskCalculation from './RiskCalculation';

import styles from './TravelTo.module.scss';

export default function TravelTo() {
	return (
		<div className={styles['travel-to']}>
			<section className={styles.header}>
				<div className={styles.image}>
					<img src={plane} alt="plane" />
				</div>
				<div className={styles['image-mobile']}>
					<img src={planeMobile} alt="plane" />
				</div>
				<h1 className={styles.title}>Explore your destination</h1>
			</section>
			<section className={styles.description}>
				Traveling soon? Make informed decisions by comparing <br className="mob-hide" /> your
				destination risks to your home.
			</section>
			<RiskCalculation />
			<MostPopularDestinations />
		</div>
	);
}
