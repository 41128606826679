import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE } from '../../App';
import { Loader } from '../../components';
import { EVENT, EventManager, localStore } from '../../lib';
import { api } from '../../sagas';

function AuthAplleLoading() {
	const history = useHistory();

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const appleToken = urlParams.get('appleToken');
		if (appleToken) {
			api.authToken({ appleToken }).then((response) => {
				if (response.ok && response.headers) {
					const apiToken = response.headers.authorization;
					console.log('=== AuthAplleLoading', { apiToken });
					api.apiInstance.setHeader('Authorization', apiToken);
					localStore.setToken(apiToken);
					localStore.setLoggedAs('APPLE');
					EventManager.publish(EVENT.FCM_SEND_TOKEN);
					document.location = '/';
				} else history.push(ROUTE.default);
			});
		} else history.push(ROUTE.default);
	}, []);

	return (
		<div className="main-container">
			<div className="content">
				<Loader />
			</div>
		</div>
	);
}

export default AuthAplleLoading;
