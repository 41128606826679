import { ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import './RiskHighlightsIndicatorDialog.scss';

type RiskHighlightsIndicatorDialogType = {
	children: ReactNode;
	onClose: () => void;
	title?: string;
	open?: boolean;
};

export default function RiskHighlightsIndicatorDialog(props: RiskHighlightsIndicatorDialogType) {
	const { children, onClose, title = '', open = false } = props;

	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			className="dialog-wrapper"
			classes={{
				paper: 'dialog-paper',
			}}
		>
			<MuiDialogTitle disableTypography className="dialog-title">
				<Typography className="dialog-title-text" variant="h4">
					{title}
				</Typography>
				{onClose ? (
					<IconButton aria-label="Close" className="close-button" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				) : null}
			</MuiDialogTitle>
			<MuiDialogContent className="report-help-popap">{children}</MuiDialogContent>
		</Dialog>
	);
}
