import { ReactNode } from 'react';
import Slider from 'react-slick';

import useMediaQuery from '../../../../hooks/MediaQuery';
import cn from '../../../../lib/classNames';

import AIPoweredRisk1 from './images/AIPoweredRisk1.jpg';
import AIPoweredRisk2 from './images/AIPoweredRisk2.jpg';
import AIPoweredRisk3 from './images/AIPoweredRisk3.jpg';
import AIPoweredRisk4 from './images/AIPoweredRisk4.jpg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './AiPlaceholder.module.scss';

type ImgType = {
	imgs: string[];
};

type SliderSettings = {
	arrows: boolean;
	autoplay: boolean;
	autoplaySpeed: number;
	dots: boolean;
	swipe: boolean;
	infinite: boolean;
	speed: number;
	slidesToShow: number;
	slidesToScroll: number;
	pauseOnHover: boolean;
};

type WrapperType = {
	className?: string;
	children: ReactNode;
	isMobile: boolean;
	settings: SliderSettings;
};

type AiPlaceholderType = {
	title?: string;
	description?: string;
};

function Img(props: ImgType) {
	const { imgs } = props;

	const getImg = imgs?.map((e) => {
		return <img key={e} className={styles.img} src={e} alt="" />;
	});

	return <div className={styles['img-wrap']}>{getImg}</div>;
}

function Wrapper(props: WrapperType) {
	const { className, children, isMobile = false, settings } = props;
	return isMobile ? (
		<Slider className={cn(styles.slider, className)} {...settings}>
			{children}
		</Slider>
	) : (
		<div className={cn(styles['images-wrap'], className)}>{children}</div>
	);
}

export default function AiPlaceholder(props: AiPlaceholderType) {
	const { title, description } = props;

	const isMobile = useMediaQuery('(max-width: 767px)');

	const settings = {
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		swipe: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover: true,
	};

	return (
		<div className={styles['aireport-placeholder']}>
			{title && <h1>{title}</h1>}
			{description && <p className={styles.description}>{description}</p>}
			<Wrapper isMobile={isMobile} settings={settings}>
				<Img imgs={[AIPoweredRisk1]} />
				<Img imgs={[AIPoweredRisk2]} />
				<Img imgs={[AIPoweredRisk3, AIPoweredRisk4]} />
			</Wrapper>
		</div>
	);
}
