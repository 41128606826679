import { AreasItemType } from '../../../../api/types/apiGetReport';
import cn from '../../../../lib/classNames';

import styles from './TabInfo.module.scss';

type RowType = {
	label: string;
	value?: string;
};

type TabInfoType = {
	className?: string;
	data: AreasItemType[];
	icon: string;
};

function Row({ label, value }: RowType) {
	if (!value) return null;
	return (
		<div className={styles.row}>
			<p className={styles.label}>{label}</p>
			<p className={styles.value}>{value}</p>
		</div>
	);
}

export default function TabInfo(props: TabInfoType) {
	const { className, data, icon } = props;

	if (!data || data.length <= 0) {
		return null;
	}

	return (
		<>
			{data.map((e, idx) => {
				const index = idx;
				return (
					<div className={cn(styles['item-wrap'], className)} key={e.name + index}>
						<div className={styles['name-wrap']}>
							<span className={cn(styles['name-icon'], styles[icon], 'material-symbols-rounded')}>
								{icon}
							</span>
							<p className={styles.name}>{e.name}</p>
						</div>
						<div className={styles['content-wrap']}>
							<Row label="Address:" value={e.address} />
							<Row label="Phone Number:" value={e.number} />
							<Row label="Note:" value={e.notes} />
							<Row label="Confidence Level:" value={e.confidence} />
						</div>
					</div>
				);
			})}
		</>
	);
}
