import provider from 'pubsub.js';

export const EVENT = {
	FCM_SEND_TOKEN: 'fcm/send/token',
	NOTIFICATIONS_STORY_RELOAD: 'notifications_story/reload',
	NOTIFICATIONS_QUIZ_QUESTION: 'notifications_quiz/question',
};

const factory = () => {
	const subscribe = (event, handler) => {
		return provider.subscribe(event, handler);
	};

	const publish = (event, params = {}) => {
		return provider.publish(event, [params]);
	};

	const unsubscribe = (instance) => {
		return provider.unsubscribe(instance);
	};

	return {
		subscribe,
		publish,
		unsubscribe,
	};
};

export const EventManager = factory();
