import { useEffect } from 'react';

import useMediaQuery from '../hooks/MediaQuery';
import { adaptSearchPlacesResponse } from '../lib/geoCoding';
import { api } from '../sagas';
import { LocationDataType } from '../types/LocationData';

import Autocomplete from './Autocomplete';

type SelectLocationType = {
	place?: LocationDataType | null;
	placeholder?: string;
	onSelect: (_place: LocationDataType) => void;
	withoutBorder?: boolean;
	types?: string;
	inputDestinationForDashboard?: boolean;
};

type PlaceItemType = Record<string, string | number>;

export default function SelectLocation(props: SelectLocationType) {
	const {
		place,
		placeholder = 'City',
		onSelect,
		withoutBorder,
		types,
		inputDestinationForDashboard,
	} = props;

	const isMobile = useMediaQuery('(max-width: 479px)');

	const handleLoadPlaces = (query: LocationDataType) => {
		return new Promise((resolve) => {
			api.searchPlaces(query, types).then((response) => {
				const items = adaptSearchPlacesResponse(response.data);
				const list = items.map((el: PlaceItemType) => ({
					label: el.name,
					value: el,
				}));
				resolve([{ label: '', options: list }]);
			});
		});
	};

	const handleChange = (newPlace: LocationDataType) => {
		if (onSelect) onSelect(newPlace);
	};

	const handleIntroScroll = () => {
		const introContainer = document.querySelector<HTMLElement>('.intro-container');

		if (introContainer) {
			const positionIntroContainer = introContainer.offsetTop;

			if (isMobile) {
				window.scrollTo({ top: positionIntroContainer, behavior: 'smooth' });
			}
		}
	};

	useEffect(() => {
		handleIntroScroll();

		window.addEventListener('resize', handleIntroScroll);
		return () => window.removeEventListener('resize', handleIntroScroll);
	}, [isMobile]);

	return (
		<Autocomplete
			async
			defaultValue={place}
			value={place}
			placeholder={placeholder}
			loadOptions={handleLoadPlaces}
			cacheOptions
			onChange={handleChange}
			large
			withoutBorder={withoutBorder}
			inputDestinationForDashboard={inputDestinationForDashboard}
		/>
	);
}
