import { Link } from 'react-router-dom';

import { ReactComponent as UserIcon } from '../../images/Profile.svg';
import { ROUTE } from '../App';
import useMediaQuery from '../hooks/MediaQuery';
import { DEVICES } from '../lib/breakpoints';

import Logotype from './Logotype';

import './HeaderNotAuth.scss';

function HeaderNotAuth() {
	const isMobile = useMediaQuery(DEVICES.isMobile);

	return (
		<header className="header-not-auth">
			<Link to={ROUTE.default}>
				<Logotype color="dark" small={isMobile} />
			</Link>

			<div className="btn btn-auth">
				<Link to={ROUTE.getStarted}>
					<UserIcon className="btn-icon" />
					<span className="btn-text mob-hide">SIGN UP / LOG IN</span>
				</Link>
			</div>
		</header>
	);
}

export default HeaderNotAuth;
