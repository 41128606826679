import { useEffect, useState } from 'react';

const useMediaQuery = (query) => {
	const [matches, setMatches] = useState(false);

	useEffect(() => {
		const media = window.matchMedia(query);

		if (media.matches !== matches) {
			setMatches(media.matches);
		}
		const listener = () => setMatches(media.matches);
		window.addEventListener('resize', listener);
		return () => window.removeEventListener('resize', listener);
	}, [matches, query]);

	return matches;
};

export default useMediaQuery;

// import useMediaQuery from "../hooks/MediaQuery";
// const isDesktop = useMediaQuery(DEVICES.isDesktop);
// const isMobile = useMediaQuery(DEVICES.isMobile);
// const isMobile = useMediaQuery('(max-width: 767px)');
// const isLaptop = useMediaQuery('(min-width: 1024px) and (max-width: 1399px)');
// const isLaptop = useMediaQuery('(max-width: 1399px)');
