import { BodyType, HeaderContentType } from '../../../api/types/apiGetReport';
import cn from '../../../lib/classNames';

import Info from './Info';

import styles from './Header.module.scss';

type ContentListType = {
	dataList: HeaderContentType[];
};

function ContentList(props: ContentListType) {
	const { dataList } = props;
	return (
		<div className={styles['headered-list']}>
			{dataList.map((dataItem) => (
				<div key={dataItem.header} className={styles['list-item']}>
					<h4 className={styles['list-item-header']}>{dataItem.header}</h4>
					<p className={styles['list-item-content']}>{dataItem.content}</p>
				</div>
			))}
		</div>
	);
}

type OrderedListType = {
	dataList: HeaderContentType[];
};

function OrderedList(props: OrderedListType) {
	const { dataList } = props;
	return (
		<ul className={styles['ordered-list']}>
			{dataList.map((dataItem) => (
				<li key={dataItem.header} className={styles['list-item']}>
					<span className={styles['list-item-header']}>{dataItem.header}</span>
					{dataItem.header && <>:&nbsp;</>}
					<span className={styles['list-item-content']}>{dataItem.content}</span>
				</li>
			))}
		</ul>
	);
}

type HeaderType = {
	className?: string;
	bluf: HeaderContentType;
	body: BodyType;
};

export default function Header(props: HeaderType) {
	const { className, bluf, body } = props;

	const { header, content } = bluf;
	const {
		crime_and_security: crimeAndSecurity,
		health_and_safety: healthAndSafety,
		recommendations,
	} = body;

	const bodyInfo = (
		<>
			<h2 className={styles.header}>Crime and security</h2>
			<div className={styles['body-section']}>
				<ContentList dataList={crimeAndSecurity} />
			</div>
			<h2 className={styles.header}>Health and safety</h2>
			<div className={styles['body-section']}>
				<ContentList dataList={healthAndSafety} />
			</div>
			<h2 className={styles.header}>Top recommendations</h2>
			<div className={styles['body-section']}>
				<OrderedList dataList={recommendations} />
			</div>
		</>
	);

	return (
		<>
			<section className={cn(styles.bluf, className)}>
				{header && <h3 className={styles.header}>{header}</h3>}
				<div className={styles.description}>{content}</div>
			</section>
			<section className={cn(styles.body, className)}>
				<Info description={bodyInfo} title="" />
			</section>
		</>
	);
}
