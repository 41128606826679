import { useState } from 'react';
import { InputAdornment, TextField as MUITextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Color } from '../theme';

function TextField(props) {
	const { onChange, type, border = true, withOutMargin, withOutPadding, required, ...rest } = props;

	const inputBorder = !border
		? `borderBottom: 2px solid ${Color.grayLight} !important,`
		: `border: 'none',`;

	const useStyles = makeStyles({
		root: {
			width: '100%',
			marginBottom: withOutMargin ? 0 : 20,

			'& .password-icon': {
				marginTop: -10,
				cursor: 'pointer',
			},

			'& label': {
				fontSize: 16,
				color: `${Color.grayLight}!important`,
				top: -5,
			},

			'& input': {
				fontSize: 16,
				color: Color.blueDark,
				padding: withOutPadding ? 0 : '0 1px 10px',
			},

			'& .MuiInput-formControl': {
				'&:before, &:after, &:hover:before': {
					borderBottom: border ? `2px solid ${Color.grayLight} !important` : `none !important`,
				},
			},
		},
	});

	const classes = useStyles({ border });

	const [values, setValues] = useState({
		amount: '',
		password: '',
		weight: '',
		weightRange: '',
		showPassword: false,
		value: '',
	});

	const handleChange = (e) => {
		setValues({ ...values, value: e.target.value });
		if (onChange) {
			onChange(e.target.value);
		}
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const passwordIcon = (showPassword) => {
		return (
			<svg
				width="20"
				height="12"
				viewBox="0 0 20 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M18.7645 6.26332L20 5.34112C19.9972 5.33763 19.9931 5.33251 19.9878 5.32582C19.7006 4.96385 15.7622 0 10 0C4.23775 0 0.299373 4.96384 0.0121698 5.32582C0.00686258 5.33251 0.00280213 5.33763 0 5.34112L1.23552 6.30175C1.27413 6.2249 4.90347 1.53701 10 1.53701C14.9109 1.53701 18.4595 5.88961 18.7461 6.24107C18.7569 6.25436 18.7631 6.26192 18.7645 6.26332ZM6 8C6 5.78571 7.7856 4 9.99974 4C12.2139 4 14.0352 5.78571 13.9995 8C13.9995 10.2143 12.2139 12 9.99974 12C7.7856 12 6 10.2143 6 8Z"
					fill={values.showPassword ? '#233544' : '#DEE2E5'}
				/>
			</svg>
		);
	};

	return (
		<MUITextField
			classes={classes}
			type={values.showPassword ? 'text' : type}
			onChange={handleChange}
			required={required}
			inputProps={{ className: classes.input }}
			InputLabelProps={{ className: classes.label }}
			InputProps={{
				className: classes.InputProps,
				endAdornment:
					type === 'password' ? (
						<InputAdornment position="end">
							<div
								className="password-icon"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
							>
								{values.value ? passwordIcon(values.showPassword) : null}
							</div>
						</InputAdornment>
					) : null,
			}}
			{...rest}
		/>
	);
}

export default TextField;
