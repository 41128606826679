import InfoLGBTQI from '../../components/InfoLGBTQI';

type HeaderType = {
	title?: string;
	description?: string;
	displayLGBTQI?: boolean;
};

export default function Header(props: HeaderType) {
	const { title = '', description = '', displayLGBTQI = false } = props;

	if (title || description) {
		return (
			<div className="header">
				{title && (
					<h2 className="title-box">
						{title} {displayLGBTQI && <InfoLGBTQI />}
					</h2>
				)}
				{description && <p>{description}</p>}
			</div>
		);
	}

	return null;
}
