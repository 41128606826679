import { ReactNode, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import Button from '../../../components/Buttons/Button';
import cn from '../../../lib/classNames';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './AcademySlider.module.scss';

type AcademySliderType = {
	children?: ReactNode;
	isReadSlide: boolean;
	onClickBtnGotIt: () => void;
	getCurrentSlide: (_currentSlide: number) => void;
	isNavigate: boolean;
};

type InnerSliderType = {
	state: { currentSlide: number };
};

export default function AcademySlider(props: AcademySliderType) {
	const { children, isReadSlide, onClickBtnGotIt, getCurrentSlide, isNavigate } = props;

	const [indexCurrentSlide, setIndexCurrentSlide] = useState(0);

	const sliderRef = useRef<Slider>(null);

	const settings = {
		arrows: false,
		autoplay: false,
		autoplaySpeed: 5000,
		dots: isNavigate,
		swipe: isNavigate,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover: true,
		beforeChange: (oldIndex: number, newIndex: number) => {
			if (newIndex >= 0) setIndexCurrentSlide(newIndex);
		},
	};

	useEffect(() => {
		const slider = sliderRef.current;
		if (slider && slider.innerSlider) {
			const sliderState = (slider.innerSlider as InnerSliderType).state;
			const { currentSlide } = sliderState;
			if (currentSlide >= 0) {
				setIndexCurrentSlide(currentSlide);
			}
		}
	}, []);

	useEffect(() => {
		getCurrentSlide(indexCurrentSlide);
	}, [indexCurrentSlide]);

	const renderBtn = () => {
		return (
			<div className={styles['btn-wrap']}>
				<Button
					className={cn(styles['btn-nav'], styles['btn-prev'])}
					onClick={() => sliderRef?.current?.slickPrev()}
					disabled={!isNavigate}
					iconBefore="arrow_back"
					variant="text"
					color="secondary"
				>
					<span className={styles['btn-nav-text']}>PREV</span>
				</Button>

				<Button
					className={cn(styles['btn-got-it'], { [styles['btn-hide']]: isReadSlide })}
					iconBefore="check"
					onClick={onClickBtnGotIt}
					disabled={isReadSlide}
					variant="outlined"
					color="secondary"
				>
					Got it
				</Button>

				<Button
					className={cn(styles['btn-nav'], styles['btn-next'])}
					onClick={() => sliderRef?.current?.slickNext()}
					disabled={!isNavigate}
					iconAfter="arrow_forward"
					variant="text"
					color="secondary"
				>
					<span className={styles['btn-nav-text']}>NEXT</span>
				</Button>
			</div>
		);
	};

	return (
		<div className={styles['slider-slick-container']}>
			<Slider ref={sliderRef} {...settings}>
				{children}
			</Slider>
			{renderBtn()}
		</div>
	);
}
