import './AdditionalFeaturesDivider.scss';

type AdditionalFeaturesDividerType = {
	dividerText: string;
};

function AdditionalFeaturesDivider(props: AdditionalFeaturesDividerType) {
	const { dividerText = 'OR' } = props;

	return (
		<div className="additional-features-divider show-mobile hide-desktop">
			<div className="additional-features-divider-line" />
			<div className="additional-features-divider-text">{dividerText}</div>
			<div />
		</div>
	);
}

export default AdditionalFeaturesDivider;
