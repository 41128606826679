import { useHistory } from 'react-router-dom';

import limitIcon from '../../../images/limit_icon.svg';
import { ReactComponent as DestinationIcon } from '../../../images/location_icon.svg';
import { ApiReportHistoryListType } from '../../api/types/apiGetReportHistoryList';
import ButtonBuyReport from '../../components/ButtonBuyReport';
import Button from '../../components/Buttons/Button';
import { PRICES } from '../../lib/constats';

import ButtonToDestinationInput from './ButtonToDestinationInput';

import './ViewLimit.scss';

type ViewLimitType = {
	viewedLocations: ApiReportHistoryListType[];
	onClickBuyNow: (_buyNow: boolean) => void;
	destination?: string;
};

export default function ViewLimit(props: ViewLimitType) {
	const { viewedLocations, onClickBuyNow, destination } = props;

	const history = useHistory();

	const renderReportItems = () => {
		return viewedLocations.map((e, idx) => {
			return (
				<li
					className={`previous-report-item previous-report-item-${idx + 1}`}
					title={e.mbLocName}
					key={e.hashLink}
				>
					<a href={`/home-destination-report/${e.hashLink}`}>
						<DestinationIcon className="location-icon" />{' '}
						<span className="previous-report-item-name overflow-row-1">{e.mbLocName}</span>
					</a>
				</li>
			);
		});
	};

	const handleBack = () => {
		const locationState = (history.location as { state: { backTo: string } } | undefined)?.state;
		if (locationState && locationState.backTo !== '') {
			history.goBack();
		} else {
			history.push('/');
		}
	};

	return (
		<div className="view-limit">
			<div className="view-limit-content">
				<div className="block-icon">
					<img src={limitIcon} alt="" />
				</div>
				<div className="block-text">
					<h3 className="title">You’ve reached your free daily limit of 3 reports</h3>
					<p className="advice">
						Please try again tomorrow, or{' '}
						<a className="link-to-pro" href="https://xplore.safe-esteem.com/login">
							Learn more about Safe-xplore Pro
						</a>
					</p>
				</div>
				<div className="previous-reports-wrap">
					<p className="previous-reports-title">Your previous travel risk reports</p>
					<ul className="previous-reports-data">{renderReportItems()}</ul>
				</div>
				<div className="btn-wrap">
					<ButtonToDestinationInput
						buttonText="BUY NOW FOR &nbsp;"
						buttonNumberOld={PRICES.priceOld}
						buttonNumber={`\u00A0\u00A0${PRICES.priceNew}`}
						onClickBuyNow={() => onClickBuyNow(true)}
						destination={destination}
					/>

					<Button
						variant="outlined"
						color="primary"
						onClick={() => {
							handleBack();
							onClickBuyNow(false);
						}}
						iconBefore="arrow_back"
					>
						BACK to main page
					</Button>
				</div>
			</div>
		</div>
	);
}
