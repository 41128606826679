import { PRICES } from '../../../lib/constats';

import AdditionalFeaturesDivider from './AdditionalFeaturesDivider';
import AdditionalFeaturesItem from './AdditionalFeaturesItem';

import './AdditionalFeaturesContainer.scss';

type AdditionalFeaturesContainerType = {
	buyReportLink?: string | null;
	reportVariant?: string;
	destination?: string;
};

function AdditionalFeaturesContainer(props: AdditionalFeaturesContainerType) {
	const { buyReportLink, reportVariant, destination } = props;

	const proReportFeaturesList = [
		'Personalized health, accidents, and crime risk ratings',
		'AI-powered destination risk intelligence brief',
		'Critical emergency numbers, high-risk and safe locations',
		'Professional insights, checklists, and recommendations',
		'Active travel alerts and restrictions',
	];

	const proReportFeaturesContent = (
		<ul className="pro-features-list">
			{proReportFeaturesList.map((el, idx) => {
				const key = `key-${idx}`;
				return (
					<li className="pro-features-item" key={key}>
						<span className="check-icon material-icons">done</span>
						<span>{el}</span>
					</li>
				);
			})}
		</ul>
	);

	return (
		<div className="additional-features-container">
			<div className="block-additional-features">
				<AdditionalFeaturesItem
					title="Unlock the Pro Report Now"
					description={proReportFeaturesContent}
					buttonText="BUY NOW FOR &nbsp;"
					buttonNumberOld={PRICES.priceOld}
					buttonNumber={`\u00A0\u00A0${PRICES.priceNew}`}
					extraClass="unlock-pro-version-report"
					link={buyReportLink}
					destination={destination}
				/>
			</div>

			{reportVariant === 'PE-report' ? null : (
				<>
					<AdditionalFeaturesDivider dividerText="" />

					<div className="block-additional-features">
						<AdditionalFeaturesItem
							title="Not ready yet?"
							description="Get the free Safe-xplore AI app. Travel with confidence to over 500 U.S. and global destinations, and improve the quality of your personal risk judgment and decision-making."
							buttonText="Visit Safe-explore.com"
							extraClass="try-our-free-tool"
							link="https://safe-xplore.com"
						/>
					</div>
				</>
			)}
		</div>
	);
}

export default AdditionalFeaturesContainer;
