import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { withRouter } from 'react-router-dom';

function GoogleAnalytics(props) {
	const sendGA = () => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	};

	useEffect(() => {
		const { history } = props;
		sendGA();
		let unlisten = null;
		if (history) {
			unlisten = history.listen((location, action) => {
				sendGA();
			});
		}
		return () => {
			if (unlisten) unlisten();
		};
	}, []);

	return <></>;
}

export default withRouter(GoogleAnalytics);
