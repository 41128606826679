import { ReactNode } from 'react';
import cn from '../../../lib/classNames';

type ProfileFieldType = {
	helpText: string;
	errorText?: string;
	value: string;
	placeholder?: string;
	innerClassName?: string;
	children?: ReactNode;
	className?: string;
};

export default function ProfileField(props: ProfileFieldType) {
	const { helpText, errorText, value, placeholder, innerClassName, children, className } = props;

	const classNameInner = cn('field-inner', innerClassName, { 'field-value-placeholder': !value });

	const classNameHelpText = cn('help-text', { 'help-text-error': !!errorText });

	return (
		<div className={cn('form-field-wrap', className)}>
			<div className={classNameInner}>
				<span className={classNameHelpText}>{errorText || helpText}</span>
				<div className="field-value" title={value}>
					{value || placeholder}
				</div>
				{children}
			</div>
		</div>
	);
}
