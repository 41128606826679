const localStoreFactory = () => {
	const keys = {
		token: 'token',
		refreshToken: 'refresh_token',
		loggedAs: 'logged_as', // FB | GOOGLE | APPLE
		firebaseToken: 'firebase_token',
		isFirstAuth: 'First_auth',
	};

	const setToken = (token) => {
		window.localStorage.setItem(keys.token, token);
	};

	const getToken = () => {
		return window.localStorage.getItem(keys.token);
	};

	const setRefreshToken = (token) => {
		window.localStorage.setItem(keys.refreshToken, token);
	};

	const getRefreshToken = () => {
		return window.localStorage.getItem(keys.refreshToken);
	};

	const setLoggedAs = (provider) => {
		window.localStorage.setItem(keys.loggedAs, provider);
	};

	const getLoggedAs = () => {
		return window.localStorage.getItem(keys.loggedAs);
	};

	const setFirebaseToken = (firebaseToken) => {
		window.localStorage.setItem(keys.firebaseToken, firebaseToken);
	};

	const getFirebaseToken = () => {
		return window.localStorage.getItem(keys.firebaseToken);
	};

	const setFirstAuth = (user, date) => {
		const data = { user, date };
		window.localStorage.setItem(keys.isFirstAuth, JSON.stringify(data));
	};

	const getFirstAuth = () => {
		return window.localStorage.getItem(keys.isFirstAuth);
	};

	return {
		setToken,
		getToken,
		setRefreshToken,
		getRefreshToken,
		setLoggedAs,
		getLoggedAs,
		setFirebaseToken,
		getFirebaseToken,
		setFirstAuth,
		getFirstAuth,
	};
};

export default localStoreFactory();
