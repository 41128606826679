import { Link } from 'react-router-dom';
import { useContextNavigation } from '../../../components/ContextNavigation';
import MenuItems from '../../Dashboard/DashboardHeader/MenuItems';
import cn from '../../../lib/classNames';

import Logotype from '../../../components/Logotype';
import { ROUTE } from '../../../App';

import useMediaQuery from '../../../hooks/MediaQuery';

import { DEVICES } from '../../../lib/breakpoints';

import styles from './Header.module.scss';

type headerType = {
	exportToPDF: () => void;
	isLoaderExportImg: boolean;
};

export default function Header(props: headerType) {
	const { exportToPDF, isLoaderExportImg } = props;

	const { isReportMenuVisible } = useContextNavigation();

	const isMobile = useMediaQuery(DEVICES.isMobile);

	return (
		<header className={styles['header-content-wrap']}>
			<div className={styles.content}>
				<div className={cn(styles.row, styles['row-1'])}>
					<div>
						<div className={styles.logo}>
							<Link to={ROUTE.default}>
								<Logotype color="dark" />
							</Link>
						</div>
					</div>
					<div className={styles['col-2']}>
						<div className={styles['btn-wrap']}>
							{!isMobile && isReportMenuVisible && <MenuItems />}
							<span
								className={cn(styles.btn, styles['btn-to-img'], 'material-icons-outlined', {
									[styles['btn-loading']]: isLoaderExportImg,
								})}
								onClick={exportToPDF}
							>
								print
							</span>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
